/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./AdminDashboard.css";
import SideMenu from "./SideMenu";
import { useNavigate } from "react-router-dom";
import {
  DashboardBellIcon,
  DashboardToogleIcon,
} from "../../../components/Dashboard/DashboardIcons";
import { NavDropdown } from "react-bootstrap";
import {
  Baseurl,
  ClearLocalStorage,
  GetLocalStorage,
  GetLocalStorageParsed,
  SetLocalStorage,
  SetLocalStorageStringify,
} from "../../../Utils/Utils";
import { getRefreshToken, latestNotificationCountAdmin, notificationDataAdmin, notificationDataReadAdmin, userLogout } from "../../../services/api/user";
import { getAllFooterContentlListData, getHomepageLogo } from "../../../services/api/admin";
import { useLanguage } from "../../../components/LanguageProvider/LanguageProvider ";
import { capitalizeFirstLetter, formatDateTimeWithSlash, formatDateWithSlash } from "../../../Utils/common";

const AdminDashboard = ({ children }) => {
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [notificationDetails, setNotificationDetails] = useState([]);
  const [unreadCount, setUnReadCount] = useState(0);
  const [copyRightContentEn, setCopyRightContentEn] = useState(0);
  const [copyRightContentHi, setCopyRightContentHi] = useState(0);
  const [imagePath, setImagePath] = useState(
    "https://utdbtraveltstg.dilonline.in/static/media/uttrLogo.5f55d1f48deb13a04f1a.png"
  );
  const { language, setLanguage } = useLanguage();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const token = GetLocalStorage("userInfo");
  const userDetail = GetLocalStorageParsed("userDetail");

  useEffect(() => {
    if (!token) {
      navigate("/");
    }
    unReadNotificationCount()
    const fetchNotification = async () => {
      try {
        getNotifications();

      } catch (error) {
        console.error('Error fetching data:', error);
        setNotificationDetails([]);
      }
    };
    fetchNotification();
    WebsiteLogo();
    footerContentData();
  }, []);

  const WebsiteLogo = async () => {
    try {
      const usersListData = await getHomepageLogo();
      if (usersListData.status === 200) {
        setImagePath(Baseurl() + "/" + usersListData?.data?.path);
      } else {
        setImagePath(
          "https://utdbtraveltstg.dilonline.in/static/media/uttrLogo.5f55d1f48deb13a04f1a.png"
        );
      }
    } catch (err) {
      setImagePath(
        "https://utdbtraveltstg.dilonline.in/static/media/uttrLogo.5f55d1f48deb13a04f1a.png"
      );
    }
  };

  const handleLogout = async (e) => {
    try {
      // if (e === "image") {
      const logout = await userLogout();
      if (logout) {
        navigate("/");
        ClearLocalStorage();
      }
      // }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const checkViewportSize = () => {
      if (window.innerWidth <= 1199) {
        setIsSidebarOpen(true);
      } else if (window.innerWidth > 1199) {
        setIsSidebarOpen(false);
      }
    };

    checkViewportSize();

    window.addEventListener("resize", checkViewportSize);

    return () => window.removeEventListener("resize", checkViewportSize);
  }, []);

  const handleNavigation = () => {
    navigate("/admin/change-password")
  }

  const handleBellClick = (e) => {
    e.preventDefault();
    markAllAsRead();
  };

  const markAllAsRead = async () => {
    try {
      const payload = new FormData()
      payload.append('admin_id', userDetail?.id)
      payload.append('district_id', userDetail?.district)
      payload.append('role', userDetail?.roles)
      const data = await notificationDataReadAdmin(payload);
      // getNotifications();

    } catch (error) {
      console.error('Failed to mark notifications as read:', error);
    }
  };

  const handleViewAllClick = (e) => {
    e.preventDefault();
    unReadNotificationCount()
    navigate('/admin/notification', { state: "admin" },);
  };


  const getNotifications = async () => {
    try {
      const payload = new FormData()
      payload.append('admin_id', userDetail?.id)
      payload.append('district_id', userDetail?.district)
      payload.append('role', userDetail?.roles)

      const response = await notificationDataAdmin(payload);
      if (response?.status == 200) {

        setNotificationDetails(response.data);
      } else {
        console.error('Failed to fetch updated notifications.');

      }
    } catch (error) {
      console.error('Error fetching notifications:', error);
      setNotificationDetails([]);

    }
  }

  const unReadNotificationCount = async () => {
    try {
      const payload = new FormData();
      payload.append("admin_id", userDetail?.id);
      payload.append('district_id', userDetail?.district)
      payload.append('role', userDetail?.roles)
      const count = await latestNotificationCountAdmin(payload)
      if (count?.status == 200) {
        setUnReadCount(count?.data?.latest);
      }
    } catch (err) {
      console.log(err?.message)
    }
  }

  const notificationString = (data) => {
      try {
          if (typeof JSON.parse(data) == "object") {  
              const str = JSON.parse(data);
              // console.log(str)
              return `${capitalizeFirstLetter(str?.role || "")} ${str?.message || ""} ${str?.AdminRemark ? str?.AdminRemark : ""
                  } ${str?.pendingDocs && str?.pendingDocs != '[]' ? str?.pendingDocs?.replace(/\[/g, '(')?.replace(/\]/g, ')')?.replace(/"/g, '')?.replace(/,/g, ', ') : ''}`;
          }
      } catch (error) {
          // console.log(error)
          // If JSON.parse fails, it's a normal string
          return data;
      }
  }

  const footerContentData = async () => {
      try {
        const dataList = await getAllFooterContentlListData();
        if(dataList?.status === 200) {
          const data = dataList?.data.find(
            (x) => x.type === "3"
          );
          setCopyRightContentEn(data?.content_en)
          setCopyRightContentHi(data?.content_hi)
        }
      } catch (err) {}
    };

  return (
    <div className="layout">
      <div className="dh-cont fixed-top" id="AdminDashRight">
        <nav className="header_cont navbar navbar-expand navbar-light sticky-top">
          <div className="logo-toggler-section">
            <img
              src={`${imagePath}`}
              onClick={() => navigate("/admin/")}
              alt="logo"
              className="logo-image"
            />
            <button className="sidebar-toggle" onClick={toggleSidebar}>
              <DashboardToogleIcon />
            </button>
          </div>

          <div className="main-section-userAvatar">

            <div className="nav-item dropdown adminDropdown">
              <a className="nav-link nav-icon" href="#" onClick={handleBellClick} data-bs-toggle="dropdown">
                <DashboardBellIcon />
                {unreadCount > 0 && (
                  <span className="badge-number">{unreadCount}</span>
                )}
              </a>

              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications" >
                {notificationDetails.length > 0 ? (
                  <>
                    <li className="dropdown-header">
                      <h6 className="username-header">Notification</h6>

                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    {notificationDetails?.length > 0 ? notificationDetails?.sort((a, b) => new Date(b?.created_at) - new Date(a?.created_at))?.slice(0, 3).map((item, index) => (
                      <li key={index} className={item.seen ? "notification-read" : "notification-unread"}>
                        <a
                          className="dropdown-item d-flex align-items-center"
                          to="#"
                          onClick={handleViewAllClick}
                        >
                          <div className="notification-detail">
                            <p className="notification-text">{notificationString(item?.remark_en)}</p>
                            <p className="notification-time">

                              {/* <FontAwesomeIcon icon={faClock} className="me-2   txtColor info wh13 mb3" /> */}

                              {formatDateTimeWithSlash(item?.created_at)}
                            </p>
                          </div>
                        </a>
                      </li>
                    )) : <li>No notification</li>
                    }

                  </>
                ) : (
                  <li className="dropdown-item"></li>
                )}
                <li>
                  <hr className="dropdown-divider" />
                </li>
                {/* {notificationDetails.length > 3 && ( */}
                <li className="dropdown-footer">
                  <a className="dropdown-item d-flex align-items-center justify-content-center" onClick={handleViewAllClick}>
                    {language ? "सभी अधिसूचनाएं दिखाएं" : "Show all Notifications"}
                  </a>
                </li>
               
              </ul>
             
            </div>

            <NavDropdown
              title={`Hi! ${userDetail &&
                userDetail.permissions &&
                userDetail.permissions.length > 0
                ? userDetail.name
                : "Super Admin"
                }`}
              id="basic-nav-dropdown"
              className="dh-nav-dropdown"
            >
              <NavDropdown.Item onClick={handleNavigation}>Change Password</NavDropdown.Item>
              <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
            </NavDropdown>
          </div>
        </nav>
      </div>
      <div
        id="AdminDash"
        className={
          isSidebarOpen === true
            ? " dash-main-div dash-main-coll pt-4 mt-5"
            : " dash-main-div"
        }
      >
        <div
          className={`p-0 sidePanel ${isSidebarOpen === true
            ? "ds-cont ds-cont-coll margin-left-0"
            : "ds-cont"
            }`}
        >
          <SideMenu />
        </div>
        <div className={`col-md-12`}>{children}</div>
        <div className="ds-footer">
          <div
            style={
              isSidebarOpen === true
                ? { marginLeft: "0px" }
                : { marginLeft: "300px" }
            }
            className="footer-text"
          >
            {language
              ? copyRightContentHi
              : copyRightContentEn}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
