import React from 'react'
import { useLanguage } from '../../LanguageProvider/LanguageProvider '
import { Baseurl } from '../../../Utils/Utils'
const HomeStepByStep = ({ registrationSteps }) => {
  const { language, setLanguage } = useLanguage()
  return (
    <section>
     
      <div className='hsbs-main'>
        <div className="container">
          <div className="text-center pt-4 hcat-main">
            <p className='hex-p mb-2'>{language ? "चरण दर चरण रेजिस्ट्रेशन" : "STEP BY STEP REGISTRATION"}</p>
            <div className='hsbs-head'>{language ? "रेजिस्ट्रेशन की प्रक्रिया" : "Registration Process"}</div>
            <main>
              <ul className="infoGraphic">
              {registrationSteps?.sort((a, b) => a.sort - b.sort)?.map((step, index) => {
                    return (
                      <li key={index}>
                        <div className="numberWrap" key={index}>
                          <div className={
                              index > 8
                                ? "double-number number"
                                : `number`
                            } style={{ color: step?.font_color }}>{index + 1}</div>
                          <div className="coverWrap">
                            <div className="numberCover"></div>
                          </div>
                        </div>
                        <div className="content">
                          <div className='icon'> <img src={`${Baseurl()}/${step?.icon}`} alt="" /> </div>
                          <h2 style={{ color: step?.font_color }}>{language ? step?.name_hi : step?.name_en}</h2>
                          <p>{language ? step?.description_hi : step?.description_en}</p>
                        </div>
                      </li>
                    )
                  })}
              </ul>
            </main>
          </div>
        </div>
      </div>
    </section>
  )
}
export default HomeStepByStep