import React, { useEffect, useState } from "react";
import AdminDashboard from "../AdminDashboard/AdminDashboard";
import { useLocation, useNavigate } from "react-router-dom";
import { GetLocalStorage } from "../../../Utils/Utils";
import { Message } from "../../../components/message";
import { BsChevronLeft } from "react-icons/bs";
import { addContactUsSection, updateContactUsSection } from "../../../services/api/admin";
import ReactQuill from "react-quill";

const EditAdminContactUs = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const token = GetLocalStorage("userInfo");
    const [apiMessage, setApiMessage] = useState({ type: "", message: "" });
    const [contentEn, setContentEn] = useState("");
    const [contentHi, setContentHi] = useState("");
    const [id, setId] = useState("");
    const [editData, setEditData] = useState();
    const [contentEnErr, setContentEnErr] = useState("");
    const [contentHiErr, setContentHiErr] = useState("");
    const [sectionType, setSectionType] = useState("");
    const [sectionName, setSectionName] = useState("");

    const contactUsSectionTypes = [
        { value: "1", label: "Enquiry Section" },
        { value: "2", label: "DTDO Section" },
        { value: "3", label: "IAS Section" },
        { value: "4", label: "Content Information" },
      ];
  
    const modules = {
      toolbar: [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        ["blockquote", "code-block"],
        ["link", "image", "video", "formula"],
  
        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ script: "sub" }, { script: "super" }], // superscript/subscript
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ direction: "rtl" }], // text direction
  
        [{ size: ["small", false, "large", "huge"] }], // custom dropdown
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
  
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ font: [] }],
        [{ align: [] }],
  
        ["clean"],
      ],
    };
  
    const formats = [
      "header",
      "font",
      "bold",
      "italic",
      "underline",
      "align",
      "strike",
      "script",
      "blockquote",
      "background",
      "list",
      "bullet",
      "indent",
      "link",
      "image",
      "color",
      "code-block",
    ];
  
    useEffect(() => {
      if (!token) {
        navigate("/");
      } else {
        setSectionType(location?.state?.type ?? "1")
        setSectionName(contactUsSectionTypes?.find((c) => c?.value == location?.state?.type)?.label ?? "")
        if (location?.state?.data) {
          editForm(location?.state?.data);
        }
      }
    }, [location?.state]);
  
    const editForm = (data) => {
      setEditData(data);
      setId(data?.id);
      setContentEn(data?.content_en);
      setContentHi(data?.content_hi);
    };
  
    const updateSection = async (e) => {
      e.preventDefault();
      if (!validateForm("both")) return;
      try {
        const formData = new FormData();
        formData.append("id", id);
        formData.append("content_en", contentEn);
        formData.append("content_hi", contentHi);
        formData.append("type", sectionType);
        const updateData = await updateContactUsSection(formData);
        if (updateData?.status == 200) {
          navigate(`/admin/contactus`, {
            state: { message: updateData?.message, type: sectionType },
          });
        } else {
          setApiMessage({ type: "error", message: updateData?.message });
        }
      } catch (err) {
        setApiMessage({ type: "error", message: err?.message });
      }
    };
  
    const addSection = async (e) => {
      e.preventDefault();
      if (!validateForm("both")) return;
      try {
        const formData = new FormData();
        formData.append("content_en", contentEn);
        formData.append("content_hi", contentHi);
        formData.append("type", sectionType);
        const updateData = await addContactUsSection(formData);
        if (updateData?.status == 200) {
          navigate(`/admin/contactus`, {
            state: { message: updateData?.message, type: sectionType },
          });
        } else {
          setApiMessage({ type: "error", message: updateData?.message });
        }
      } catch (err) {
        setApiMessage({ type: "error", message: err?.message });
      }
    };
  
    const validateForm = (item) => {
      let isValid = true;
  
      if ((item === "contentEn" || item === "both")) {
        if (!contentEn || contentEn.trim() === "" || contentEn.trim() === "<p><br></p>") {
          setContentEnErr("Please Enter English Content");
          isValid = false;
        } else {
          setContentEnErr("");
        }
      }
  
      if ((item === "contentHi" || item === "both")) {
        if (!contentHi || contentHi.trim() === "" || contentHi.trim() === "<p><br></p>") {
          setContentHiErr("Please Enter Hindi Content");
          isValid = false;
        } else {
          setContentHiErr("");
        }
      }
  
      return isValid;
    };
  
  
    const handleEditorChange = (field, value) => {
      // Update content
      if (field === "contentEn") {
        if (!value.trim() || value === "<p><br></p>") {
          setContentEnErr("Please Enter English Content");
          setContentEn("");
        } else {
          setContentEn(value);
          setContentEnErr("");
        }
      } else if (field == "contentHi") {
        if (!value.trim() || value === "<p><br></p>") {
          setContentHiErr("Please Enter Hindi Content");
          setContentHi("");
        } else {
          setContentHi(value);
          setContentHiErr("");
        }
      }
    };
  
    return (
      <AdminDashboard>
        <div className="aod-outer">
          {apiMessage && (
            <Message
              apiMessage={apiMessage}
              setApiMessage={setApiMessage}
            ></Message>
          )}
          <div className="ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb">
            <div>
              <b onClick={() => navigate("/admin/")}>Home /</b>
              <span> Pages/ {editData?.id ? "Update" : "Add"}</span>
            </div>
          </div>
          <div className="aod-inner">
            <div className="aod-bottom">
              <div className="aod-head text-center adm-head">
                <button
                  onClick={() => navigate(`/admin/contactus`, {
                    state: { type: sectionType },
                  })}
                >
                  <BsChevronLeft /> Back
                </button>
                {editData?.id ? `Update Contact Us ${sectionName}` : `Add Contact Us ${sectionName}`}
              </div>
              <form>
                <div className="row py-4">
                  <div className="suf-input-box aod-form-input mb-5 mt-3">
                    <label className="lf-label w-100">Content English</label>
                    <ReactQuill
                      name="contentEn"
                      value={contentEn}
                      onChange={(value) => handleEditorChange("contentEn", value)}
                      modules={modules}
                      formats={formats}
                      theme="snow"
                    />
                    {contentEnErr && (
                      <p className="validate-error">{contentEnErr}</p>
                    )}
                  </div>
                  <div className="suf-input-box aod-form-input mb-5">
                    <label className="lf-label w-100">Content Hindi</label>
                    <ReactQuill
                      name="contentHi"
                      value={contentHi}
                      onChange={(value) => handleEditorChange("contentHi", value)}
                      modules={modules}
                      formats={formats}
                      theme="snow"
                    />
                    {contentHiErr && (
                      <p className="validate-error">{contentHiErr}</p>
                    )}
                  </div>
                  <div className="row ad-cat-row">
                    <div className=" update-btns-block mt-3">
                      {editData?.id ? (
                        <>
                          <button onClick={updateSection}>Update</button>
                        </>
                      ) : (
                        <button onClick={addSection}>Submit</button>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </AdminDashboard>
    );
}

export default EditAdminContactUs