import React, { useEffect, useRef, useState } from 'react'
import { empDesignationData, subSectionData } from '../../services/api/user';

const TableTab = ({ fieldData, inputTypeList, subCatId, language, formValues, onChange, handleEmpDesignationUpdate, tabsWholeData, setTabsWholeData, applicationFromData, fieldsDisabled }) => {
    const [designationList, setDesignationList] = useState([])
    const [tableValue, setTableValue] = useState({})
    useEffect(() => {

        // If formValues exist, use them to initialize tableValue
        // if (formValues && formValues[subCatId]) {
        //     setTableValue(formValues[subCatId]);
        // } else {
            // If no initial formValues, fetch data to populate designationList
            const fetchOptions = async () => {
                try {
                    const formData = new FormData();
                    formData.append("sub_category_id", subCatId);

                    const response = await empDesignationData(formData);

                    const jsonDataFromAppData = applicationFromData?.["f_" + fieldData?.field_key]; // Use empty array as fallback if data is missing
                    if(jsonDataFromAppData) {
                    } else {
                        setTabsWholeData(prevData => {
                            const updatedSection = () => {
                                if (prevData?.section_fields?.[fieldData?.field_key] !== undefined) {
                                    return {
                                      ...prevData?.section_fields,
                                      [fieldData?.field_key]: Array(response?.data).fill().map(() => {
                                        // Create an object with each field in parsedValue set to null
                                        return response?.data?.reduce((acc, item) => {
                                          const fieldKeyJson = `${item.name_en}`;  // Using `field_key_json` format
                                          acc[fieldKeyJson] = {
                                            "No. of People": "1",
                                            "Average Experience": "0",
                                            "Employee Details": [
                                                {
                                                    "Employee Name": "",
                                                    "Date Of Joining": "",
                                                    "Contact No.": "",
                                                    "Staff Aadhaar No.": "",
                                                    "Bank Account No.": "",
                                                    "Bank Name": "",
                                                    "IFSC Code": "",
                                                    "Status": "",
                                                }
                                            ]
                                          };
                                          return acc;
                                        }, {});
                                      })
                                    };
                                } else {
                                    return{
                                        ...prevData?.section_fields
                                    }
                                }
                            }
                      
                            return {
                              ...prevData,
                              section_fields: updatedSection(),
                            };
                        });
                    }

                    setDesignationList(response?.data || []);

                    // Initialize tableValue for each item in designationList if not already in formValues
                    // const initialTableValue = {};
                    // response?.data.forEach(item => {
                    //     initialTableValue[item.id] = formValues[item.id] || { name: '', exp: '' }; // Use formValues if available
                    // });
                    // setTableValue(initialTableValue);

                } catch (error) {
                    console.error("Error fetching dropdown options:", error);
                }
            };
            fetchOptions();
        //}
    }, [fieldData, subCatId]);


    const handleInputChanges = (id, name, value, itemName) => {
        setTabsWholeData(prevData => {
            const currentJsonArray = prevData?.section_fields?.[`${fieldData?.field_key}`] || [];
            const updatedName = name === "name" ? "No. of People" : "Average Experience";
            
            const updatedJsonArray = [...currentJsonArray];
            
            // Ensure the itemName object exists
            if (!updatedJsonArray[0][itemName]) {
                updatedJsonArray[0][itemName] = {};
            }
            
            // Update the "No. of People" or "Average Experience" value
            updatedJsonArray[0][itemName][updatedName] = value;
    
            if (updatedName === "No. of People") {
                const numPeople = parseInt(value, 10);
    
                // Ensure "Employee Details" array exists and initialize if not
                if (!Array.isArray(updatedJsonArray[0][itemName]["Employee Details"])) {
                    updatedJsonArray[0][itemName]["Employee Details"] = [];
                }
    
                const currentEmployeeDetails = updatedJsonArray[0][itemName]["Employee Details"];
    
                // Add or remove entries in the "Employee Details" array based on the "No. of People" value
                if (numPeople > currentEmployeeDetails.length) {
                    // Add new objects to reach the desired length
                    for (let i = currentEmployeeDetails.length; i < numPeople; i++) {
                        currentEmployeeDetails.push({
                            "Employee Name": "",
                            "Date Of Joining": "",
                            "Contact No.": "",
                            "Staff Aadhaar No.": "",
                            "Bank Account No.": "",
                            "Bank Name": "",
                            "IFSC Code": "",
                            "Status": "",
                        });
                    }
                } else if (numPeople < currentEmployeeDetails.length) {
                    // Remove excess entries to reach the desired length
                    currentEmployeeDetails.splice(numPeople);
                }
            }
    
            return {
                ...prevData,
                section_fields: {
                    ...prevData.section_fields,
                    [fieldData?.field_key]: updatedJsonArray,
                },
            };
        });
    };
    
    return (
        <div className="">
            <div className="row">
                <div className="col-md-12">
                    <div className="additional-detail-c">
                        <p className="fw-semibold text-danger">
                            {language ? "पंजीकरण पूरा करने के लिए मालिक को सही और वर्तमान स्टाफ विवरण प्रदान करना होगा। एक बार अंतिम सबमिशन हो जाने के बाद, मालिक किसी भी स्टाफ विवरण को संशोधित नहीं कर पाएगा। प्रतिस्थापन या स्टाफ विवरण में किसी भी बदलाव के मामले में, मालिक संबंधित डीटीडीओ से रिकॉर्ड में बदलाव करने का अनुरोध कर सकता है।" :
                            "Owner must provide correct and current staff details to complete the registration. Once final submission is done, owner will not be able to modify any staff detail. In case of replacement or any change in the staff details, owner may request to the respective DTDO to make changes in the records."}
                            {/* {JSON.stringify(designationList)} */}
                        </p>
                    </div>
                    <div className=""></div>
                </div>
                <div className="col-lg-12">
                    <div className="card mb-3">
                        <div className="card-body bg-card user-tab-table ">
                            <div className="table-responsive">
                                <table className="table  bg-table mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col">{language ? "पद का नाम" : "Designation"}</th>
                                            <th scope="col">{language ? "लोगों की संख्या" : "No. of People"}</th>
                                            <th scope="col">{language ? "औसत अनुभव" : "Average Experiance"}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {designationList?.map((item, index) => (
                                            <tr key={item.id}>
                                                <td>{language ? item?.name_hi : item?.name_en}</td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        max={item?.max_people ?? 100}
                                                        min={1}
                                                        // min={1}
                                                        name="name"
                                                        value={tabsWholeData?.section_fields?.[fieldData?.field_key]?.[0]?.[item?.name_en]?.["No. of People"]}
                                                        className="form-control txt-input"
                                                        placeholder="Enter count"

                                                        onInput={(e) => {
                                                            let value = e.target.value;

                                                            // Remove leading zeros
                                                            value = parseInt(value, 10) || ''; // parseInt removes leading zeros, "|| ''" handles empty case

                                                            // Limit value to a maximum of item?.max_average_experience or 100 and a minimum of 1
                                                            if (value > (item?.max_people ?? 100)) {
                                                                value = item?.max_people ?? 100;
                                                            } else if (value < 1) {
                                                                value = 1;
                                                            }

                                                            e.target.value = value;
                                                            handleInputChanges(item.id, e.target.name, value, item?.name_en);

                                                        }}
                                                        onPaste={(e) => {
                                                            e.preventDefault(); // Prevent pasting
                                                        }}
                                                        disabled={fieldsDisabled}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        max={item?.max_average_experience ?? 100}
                                                        min={1}
                                                        // min={1}
                                                        name="exp"
                                                        value={tabsWholeData?.section_fields?.[fieldData?.field_key]?.[0]?.[item?.name_en]?.["Average Experience"]}
                                                        className="form-control txt-input"
                                                        placeholder="Enter experience"
                                                        onInput={(e) => {
                                                            let value = e.target.value;

                                                            // Remove leading zeros
                                                            value = parseInt(value, 10) || ''; // parseInt removes leading zeros, "|| ''" handles empty case

                                                            // Limit value to a maximum of item?.max_average_experience or 100 and a minimum of 1
                                                            if (value > (item?.max_average_experience ?? 100)) {
                                                                value = item?.max_average_experience ?? 100;
                                                            } else if (value < 1) {
                                                                value = 1;
                                                            }

                                                            e.target.value = value;
                                                            handleInputChanges(item.id, e.target.name, value, item?.name_en);
                                                        }}
                                                        onPaste={(e) => {
                                                            e.preventDefault(); // Prevent pasting
                                                        }}
                                                        disabled={fieldsDisabled}
                                                    />
                                                </td>
                                            </tr>
                                        ))}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default TableTab