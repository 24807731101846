import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../LanguageProvider/LanguageProvider ";
import Dashboard from "../../pages/Dashboard/Dashboard";
import { Baseurl, GetLocalStorage, GetLocalStorageParsed } from "../../Utils/Utils";
import ComingSoon from "../ComingSoon/ComingSoon";
import { userCertificateListData } from "../../services/api/admin";
import { formatDateTime } from "../../Utils/common";


const Certificate = () => {
  const { language, setLanguage } = useLanguage();

  const userDetail = GetLocalStorageParsed("userDetail");
  const [certificateList, setCertificateList] = useState({});
  const token = GetLocalStorage("userInfo");
  const navigate = useNavigate();
  const [baseUrl, setBaseUrl] = useState([]);

  useEffect(() => {
    if (!token) {
      navigate("/");
    } else {
      getUserCertList();
    }
  }, []);

  const getUserCertList = async () => {
    try {
      const formData = new FormData();
      formData.append("user_id", userDetail?.id);
      const list = await userCertificateListData(formData);
        setCertificateList(list?.data);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Dashboard
      first={language ? "होम" : "Home"}
      second={language ? "प्रमाणपत्र" : "Certificates"}
      head={language ? "प्रमाणपत्र" : "Certificates"}
    >
      <>
        <div className="aod-bottom">
          <div className="page_heading">
            <h5>{language ? "प्रमाणपत्र" : "Certificates"}</h5>
          </div>
          <div className="row">
            <div className="reciept-table table-responsive mt-2">
              <table
                className="table table mb-0 dataTable transaction_table"
                id="receipt_table"
                role="grid"
              >
                <thead className="">
                  <tr role="row">
                    <th>{language ? "क्रम संख्या" : "S.No"}</th>
                    <th>{language ? "सर्टिफिकेट नंबर" : "Certificate Number"}</th>
                    <th>{language ? "तारीख" : "Date"}</th>
                    <th>{language ? "देखना" : "View"}</th>
                  </tr>
                </thead>
                <tbody>
                {certificateList?.length > 0 ? (
                  certificateList?.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.certificate_number}</td>
                      <td>{item?.created_at
                            ? formatDateTime(
                              item?.created_at
                          )
                         : "--"}</td>
                      <td>
                        {item.path ? (
                          <a
                            className="btn btn-green-view"
                            rel="noopener noreferrer"
                            onClick={(e) => {
                              e.preventDefault();
                              window.open(
                                Baseurl() +
                                item.path,
                                "_blank"
                              );
                            }}
                          >
                            {language ? "डाउनलोड करना" : "Download"}
                          </a>
                        ) : (
                          "--"
                        )}
                        {/* <a
                          href={Baseurl()+'/api/category-certificate'}
                          className="btn btn-green-view"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {language ? "डाउनलोड करना" : "Download"}
                        </a> */}
                      </td>
                    </tr>
                  ))
                ) : (
                  <></>
                )}     
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    </Dashboard>
  );
};

export default Certificate;
