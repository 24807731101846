import React, { useEffect, useState } from "react";
import AdminDashboard from "../AdminDashboard/AdminDashboard";
import { Message } from "../../../components/message";
import { useNavigate } from "react-router-dom";
import { Table } from "react-bootstrap";
import { HomeIcon } from "../../../components/Application/ApplicationIcon";
import dataicon from "../../../../src/assets/images/data-icon.png";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { DeleteIcon, EditIcon, EyeIcon } from "../AdminIcon";
import { adminCertificateListByStatusPdf, adminCertificateListPdf, allAdminCertificateListPdf } from "../../../services/api/admin";
import NotFoundTable from "../../../components/notFound/NotFoundTable";
import { Baseurl } from "../../../Utils/Utils";
import { stateDistrict } from "../../../services/api/auth";
import { formatDateWithdash } from "../../../Utils/common";
import ReactPaginate from 'react-paginate';

const AdminExpiredCertificates  = () => {
  const navigate = useNavigate();
  const [baseUrl, setBaseUrl] = useState();
  const [apiMessage, setApiMessage] = useState({ type: '', message: '' });
  const [userList, setuserList] = useState([])
  const [orderBy, setOrderBy] = useState("desc");
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteData, setDeleteData] = useState();
  const [totalPages, setTotalPages] = useState(0); // Total number of pages
  const [searchTerm, setSearchTerm] = useState();
  const [districtList, setDistrictList] = useState([]);
  const [sortBy, setSortBy] = useState("tracking_number");
  const [searchData, setSearchData] = useState("");

  useEffect(() => {
    getAdminUserList()
    setBaseUrl(Baseurl);
    getDistrictList()
  }, [])

  const getAdminUserList = async () => {
    try {
      const formData = new FormData()
      formData.append('status', 0)
      const data = await adminCertificateListByStatusPdf(formData)
      if (data.status == 200) {
        setuserList(data?.data)
      }
    } catch (err) {
      setApiMessage({ type: 'err', message: err.message });
    }
  }

  // const searchSortFromList = async (e, sortBy) => {
  //   try {
  //     const searchBy = e.target.value ? e.target.value : '';
  //     setOrderBy(orderBy == 'asc' ? 'desc' : 'asc')
  //     const tabList = await allAdminCertificateListPdf(sortBy, orderBy, searchBy, currentPage)
  //     const data = tabList?.data?.data
  //     setuserList(data)
  //   } catch (err) {
  //     console.log("error on categoryList: ", err)
  //     setApiMessage({ type: 'err', message: err.message });
  //   }
  // }

  // const handlePageChange = (selectedPage) => {
  //   setCurrentPage(selectedPage.selected);
  //   getAdminUserList(sortBy, orderBy, searchData, selectedPage.selected + 1) // Increment by 1 if the API starts page numbers from 1
  // };

  const getDistrictList = async () => {
    try {
      const districtData = await stateDistrict()
      const data = districtData?.data?.data?.map((district) => ({
        label: district?.name_en,
        value: district?.id,
      }));
      setDistrictList(data)
    } catch (err) {
      setApiMessage({ type: 'error', message: err?.message });
    }
  }
  return (
    <AdminDashboard>
      <div className="aod-outer">
        {/* {apiMessage && (<Message apiMessage={apiMessage} setApiMessage={setApiMessage}></Message>)} */}
        <div className="ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb">
          <div>
            <HomeIcon />
            <span>Expired Certificates</span>
          </div>
        </div>
        <div className="aod-inner pt-0">
          <div className="aod-bottom">
            <div className="adm-tabs-outer">
              <h4 className="mb-0 w-100">Expired Certificates</h4>
            </div>
            <div className="aod-head"></div>
            <div className="ad-reg-table">
              <Table size="sm" className="ad-cat-table mt-4">
                <thead>
                  <tr>
                    <th className="ad-sno">S. No</th>
                    <th className="ad-long">District </th>
                    <th className="ad-long">Tracking Number</th>
                    <th className="ad-long">Created At</th>
                    <th className="ad-long">Expiry Date</th>
                    <th className="ad-long">Status</th>
                    <th className="ad-long">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {userList?.length > 0 ? userList?.sort((a, b) => (b?.created_at - a?.created_at)).map((item, index) => (
                    <tr key={index}>
                      <td>{currentPage * 10 + (index + 1)}</td>
                      <td>{item?.district_id ? districtList?.find(x => x.value == item?.district_id)?.label : "--"}</td>
                      <td>{item?.tracking_number}</td>
                      <td>{formatDateWithdash(item?.created_at)}</td>
                      <td>{formatDateWithdash(item?.expired_at)}</td>
                      <td>Expired</td>
                      <td>
                        <div className="action-btn d-flex">
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>View</Tooltip>}
                          >
                            <span className="ad-cat-edit" onClick={(e) => {
                              e.preventDefault();
                              window.open(
                                baseUrl +
                                "/" +
                                item?.path,
                                "_blank"
                              );
                            }}>
                              <EyeIcon />
                            </span>
                          </OverlayTrigger>
                        </div>
                      </td>
                    </tr>
                  )) : <NotFoundTable colSpan="7"></NotFoundTable>}

                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </AdminDashboard>
  );
};

export default AdminExpiredCertificates ;
