import React, { useEffect, useState } from "react";
import AdminDashboard from "../AdminDashboard/AdminDashboard";
import { useLocation, useNavigate } from "react-router-dom";
import { HomeIcon } from "../../../components/Application/ApplicationIcon";
import { GetLocalStorage } from "../../../Utils/Utils";
import { formatDateTime } from "../../../Utils/common";
import { city, district, states } from "../../../services/api/auth";
import { getUserById, updateUserProfileAPI } from "../../../services/api/user";
import { BsChevronLeft } from "react-icons/bs";
import { Message } from "../../../components/message";
import { Spinner } from "react-bootstrap";

const UserDetails = () => {
  const navigate = useNavigate();
  const location = useLocation()
  const token = GetLocalStorage("userInfo")
  const [apiMessage, setApiMessage] = useState({ type: '', message: '' });
  const [districtList, setDistrictList] = useState([]);
  const [districtId, setDistrictId] = useState('');
  const [roleList, setRoleList] = useState([]);
  const [roleId, setRoleId] = useState('');
  const [titleId, setTitleId] = useState('');
  const [image, setImage] = useState();
  const [imageError, setImageError] = useState();
  const [userId, setUserId] = useState('')
  const [file, setFile] = useState();
  const [loading, setLoading] = useState(false);
  const [existingMobileNo, setExistingMobileNo] = useState()
  const [name, setName] = useState("");
  const [joiningDate, setJoiningDate] = useState("")
  const [status, setStatus] = useState("");
  const [gender, setGender] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [districtName, setDistrictName] = useState("");
  const [cityName, setCityName] = useState("");
  const [pincode, setPincode] = useState("");
  const [stateLoaded, setStateLoaded] = useState(false);
  const [districtLoaded, setDistrictLoaded] = useState(false);
  const [cityLoaded, setCityLoaded] = useState(false);
  const [statesData, setStatesData] = useState([]);
  const [districtsData, setDistrictsData] = useState([]);
  const [citiesData, setCitiesData] = useState([]);
  const [userDetail, setUserDetail] = useState("");
  const [errors, setErrors] = useState({});
  const [editable, setEditable] = useState(false)
  const [uneditName, setUneditName] = useState("");
  const [uneditGender, setUneditGender] = useState("");
  const [uneditPhoneNumber, setUneditPhoneNumber] = useState("");
  const [uneditEmail, setUneditEmail] = useState("");
  const [uneditStatus, setUneditStatus] = useState("");
  const [user, setUser] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const genderList = [
    {
      "id": 1,
      "name_en": "Male",
      "name_hi": "पुरुष"
    },
    {
      "id": 2,
      "name_en": "Female",
      "name_hi": "महिला"
    },
    {
      "id": 0,
      "name_en": "Other",
      "name_hi": "अन्य"
    }
  ]

  const statusList = [
    { label: "Inactive", value: "0" , bg : "#ffcc00 !important", class: "user-inactive"},
    { label: "Active", value: "1", bg: "#198754 !important", class: ""},
    { label: "Blocked", value: "2", bg : "#ff0017 !important", class: "user-blocked"},
    { label: "Pending", value: "3", bg: "#6C757D !important", class: "user-pending"},
  ]

  useEffect(() => {
    if (!token) {
        navigate("/")
    } else {
        if (location?.state && location?.pathname == "/admin/user-details") {
            editAdminUser(location?.state)
        }
    }
  }, [location?.state])

  const editAdminUser = async (item) => {
    const formData = new FormData();
    formData.append("user_id", item?.id);
    const user = await getUserById(formData)
    setUser(user?.data)
    const data = user?.data

    setUserId(data.id)
    setImage(data?.upload_photo)
    setRoleId(data?.role_id)
    setUneditName(data?.name || "")
    setUneditGender(genderList?.find((gen) => gen.id == data?.gender)?.name_en || "")
    setUneditPhoneNumber(data?.mobile_number || "")
    setUneditEmail(data?.email || "")
    setUneditStatus(statusList?.find(option => option?.value == data?.status)?.label || "")
    setStatus(data?.status || "0")
    setJoiningDate(formatDateTime(data?.created_at))
    setTitleId(data?.unique_id)
    setDistrictId(data?.district)
    setExistingMobileNo(data?.mobile_number)
    setUserDetail(data);
    setName(data?.name || "");
    setGender(data?.gender || "");
    setPhoneNumber(data.mobile_number || "");
    setEmail(data.email || "");
    setAddress(data?.address || "");
    setState(data?.state || "");
    setStateLoaded(true)
    setDistrictName(data?.district || ""); // Ensure this is set correctly
    setCityName(data?.city || ""); // Ensure this is set correctly
    setPincode(data?.pincode || "");
    setUserId(data?.id || "");

    loadStates()    
  }

  const loadStates = async () => {
    try {
      const response = await states(); // Fetch states
      const data = response.data.data.map((state) => ({
        label: state.name_en,
        value: state.id
      }));
      setStatesData(data);
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };

  useEffect(() => {
    // Fetch districts based on selected state
    const loadDistricts = async () => {
      if (state) {
        try {
          const response = await district(state); // Fetch districts for selected state
          const filteredDistricts = response.data.data.filter(district => district.state_id === parseInt(state));
          const data = filteredDistricts.map((district) => ({
            label: district.name_en,
            value: district.id
          }));
          setDistrictsData(data);
          setDistrictName(!districtLoaded ? userDetail.district || "" : ""); // Set the district name from user detail
          setDistrictLoaded(true)
        } catch (error) {
          console.error("Error fetching districts:", error);
        }
      } else {
        setDistrictsData([]);
        setDistrictName(""); // Clear district and city when state is cleared
        //setCitiesData([]);
      }
    };

    loadDistricts();
  }, [state, location?.state]);

  useEffect(() => {
    // Fetch districts based on selected state
    const loadCities = async () => {
          if (districtName) {
            try {
              const response = await city(); // Fetch all cities
              const filteredCities = response.data.data.filter(city => city.district_id === parseInt(districtName));
              const data = filteredCities.map((city) => ({
                label: city.name_en,
                value: city.id
              }));
              setCitiesData(data);
              setCityName(!cityLoaded ? userDetail.city || "" : "");
              setCityLoaded(true) // Set the city name from user detail
            } catch (error) {
              console.error("Error fetching cities:", error);
            }
          } else {
            setCitiesData([]);
            setCityName("");
          }
        };
    
        loadCities();
  }, [districtName, location?.state]);

  const validateFields = () => {
    let formErrors = {};
   if (!name?.trim()) {formErrors.name = "Name is required"} else if(!/^[a-zA-Z ]*$/.test(name?.trim())) {formErrors.name = "Only Alphabets are allowed"} else if(name?.trim()?.length < 3) {formErrors.name = "Name must be atleast 3 characters"};
if (!gender) formErrors.gender = "Gender is required";
if (!phoneNumber) formErrors.phoneNumber = "Phone number is required";
if (!email) formErrors.email = "Email is required";
if (!address) {formErrors.address = "Address is required"} else if (address?.length < 5) {formErrors.address = "Address must be atleast 5 characters"};
if (!state) formErrors.state = "State is required";
if (!districtName) formErrors.district = "District is required";
if (!status) formErrors.status = "Status is required";
if (!cityName) formErrors.city = "City is required";
if (!pincode || pincode.toString().length < 6 || !/^[0-9\b]+$/.test(pincode)) formErrors.pincode = "Valid 6-digit pincode is required";
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  // Submit handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateFields()) {
      const payload = {
        name,
        gender,
        phoneNumber,
        email,
        address,
        state,
        city: cityName, // Ensure cityName is used correctly
        district: districtName,
        pincode,
        status: status,
        user_id: userId
      };

      try {
        setSubmitting(true)
        const response = await updateUserProfileAPI(payload);
        if (response.status === 200) {
          setEditable(false)
          const formData = new FormData();
          formData.append("user_id", userId);
          const user = await getUserById(formData)
          setDistrictLoaded(false)
          setCityLoaded(false)
          setUser(user?.data)
          editAdminUser(user?.data)
          setApiMessage({ type: 'success', message: "User updated successfully" })          
        } else {
          setApiMessage({ type: 'error', message: "Unauthenticated" })
        }
        setSubmitting(false)
        window.scrollTo(0, 0);
      } catch (error) {
        setSubmitting(false)
        setApiMessage({ type: 'error', message: error.message })
        window.scrollTo(0, 0);
      }
    }
  };

  // Clear handler
  const handleClear = () => {
    setName("");
    setGender("");
    setPhoneNumber("");
    setEmail("");
    setAddress("");
    setState("");
    setDistrictName("");
    setCityName("");
    setPincode("");
    setErrors({});
  };

  const handlechanges = async (e) => {
    const { name, value } = e?.target
    let formErrors = {...errors};
    switch (name) {
      case 'name':
        if (!value?.trim()) {
          formErrors.name = "Name is required";
        } else {
          if(!/^[a-zA-Z ]*$/.test(value?.trim())) {
            formErrors.name = "Only Alphabets are allowed"
          }
          else if(value?.trim()?.length < 3){
            formErrors.name = "Name must be atleast 3 characters";
          } else {
            formErrors.name = ''
          }
        }
        break;
      case 'gender':
        if (!value?.trim()) {
          formErrors.gender = "Gender is required";
        } else {
          formErrors.gender = ''
        }
        break;
      case 'address':
        if (!value?.trim()) {
          formErrors.address = "Address is required";
        } else {
          if(value?.trim()?.length < 5){
            formErrors.address = "Address must be atleast 5 characters";
          } else{
            formErrors.address = ''
          }
          
        }
        break;
      case 'pincode':
        if (!value || value.toString().length < 6 || !/^[0-9\b]+$/.test(value)) {
          formErrors.pincode = "Valid 6-digit pincode is required";
        } else {
          formErrors.pincode = ''
        }
        break;
      case 'state':
        if (!value?.trim()) {
          formErrors.state = "State is required";
        } else {
          formErrors.state = ''
        }
        break;
      case 'district':
        if (!value?.trim()) {
          formErrors.district = "District is required";
        } else {
          formErrors.district = ''
        }
        break;
        case 'status':
          if (!value?.trim()) {
            formErrors.status = "Status is required";
          } else {
            formErrors.status = ''
          }
          break;
      case 'city':
        if (!value?.trim()) {
          formErrors.city = "City is required";
        } else {
          formErrors.city = ''
        }
        break;
    }
    setErrors(formErrors);
  }

  return (
    <AdminDashboard>
      <div className="aod-outer">
      {apiMessage && (<Message apiMessage={apiMessage} setApiMessage={setApiMessage}></Message>)}
        <div className="ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb">
          <div>
            <HomeIcon />
            <b onClick={() => navigate("/admin/")}>Home / </b>
            <span>User Details</span>
          </div>
        </div>
        <div className="aod-top user-aod-top">
        <div  className=" text-center mb-2 adm-head"><button 
          onClick={() => {
            if(editable){
              setEditable(false)
              setErrors({})
              editAdminUser(user)
            } else {
              navigate("/admin/user")
            }
        }}><BsChevronLeft /> Back</button></div>          
        {!editable ? <div class="d-flex gap-2 BtnSize15 justify-content-end user-details-edit-btns">
                              {/* <a class="btn btn-primary" >Update
                                  Status</a>
                              <a class="btn btn-primary">Change
                                  Password</a> */}
                              <a class="btn admin-theme-btn" onClick={() => setEditable(true)} id="editdiabled">Edit</a>
                          </div> : <div class="d-flex gap-2 BtnSize15 justify-content-end user-details-edit-btns"></div>}
        </div>
        <div id="user-details">
        <div className="row">
        <div class="col-md-4">
          <div class="card mt-3 detail_card_c user_card_h">
            <div class="card-body">
              <img
                class="detail_card_user_img"
                src="https://utdbhomestayapi.dilonline.in/assets/backend/img/dummy_user.svg"
                alt=""
                srcset=""
              />
              <h6 class="text-center mb-0 mt-3">
                <strong>{uneditName}</strong>
              </h6>
              <p class="text-center user-gender">
                <span class={`badge rounded-pill text-bg-success ${statusList?.find(option => option?.label == uneditStatus)?.class}`}>{uneditStatus}</span>
              </p>

              <div class="mt-4 row">
                <div class="col-md-12 col-sm-12">
                  <div class="detail_card_header col-md-12">
                    <h6 class="mb-0 text-center">
                      <strong>Application Details</strong>
                    </h6>
                  </div>

                  <div class="row g-2 mt-2">
                    <div class="col-md-12 user_detail_card_desc user-left-card-desc mt-0">
                      <p class="mb-0">Customer ID </p>
                      <small>{titleId}</small>
                    </div>
                    <div class="col-md-12 user_detail_card_desc user-left-card-desc mt-0">
                      <p class="mb-0">Joining Date </p>
                      <small>{joiningDate}</small>
                    </div>

                    <div class="col-md-12 user_detail_card_desc user-left-card-desc mt-0">
                      <p class="mb-0">Gender </p>
                      <small>{uneditGender}</small>
                    </div>

                    <div class="col-md-12 user_detail_card_desc user-left-card-desc mt-0">
                      <p class="mb-0">Email</p>
                      <small class="text-truncate">{uneditEmail}</small>
                    </div>

                    <div class="col-md-12 user_detail_card_desc user-left-card-desc mt-0">
                      <p class="mb-0">Phone Number</p>
                      <small>{uneditPhoneNumber}</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-8">
                    <div class="card mt-3 detail_card_c user_card_h">
                        <div class="card-body">
                            <div class=" row">

                                <div class="col-md-12 col-sm-12">
                                    <div class="detail_card_header user-left-card-desc user_card_t col-md-12 pb-0 dfdfdfdfd">
                                        <h3 class="mb-0 fw-bold Ptilte">Basic Details</h3>
                                    </div>

                                    <form onSubmit={handleSubmit}>
                                        <div class="row g-2 mt-2">
                                            <input type="hidden" name="user_id" id="user_id" />
                                            <div class="col-md-12 user_detail_card_desc user-left-card-desc right-card-left-details mt-0 gap-2">
                                                <p class="mb-0 col-md-3">Name </p>
                                                <input type="text" class="form-control" maxlength="50" name="name" id="name" required="" 
                                                placeholder="Enter Name"
                                                value={name}
                                                onChange={(e) => {
                                                  setName(e.target.value)
                                                  handlechanges(e)
                                                }} disabled={!editable}/>
                                            </div>
                                            {errors.name && <div class="col-md-12 mt-0">
                                              <span className="text-danger offset-3 ps-2">{errors.name}</span>
                                            </div>}

                                            <div class="col-md-12 user_detail_card_desc user-left-card-desc right-card-left-details mt-0 gap-2">
                                                <p class="mb-0 col-md-3">Gender </p>
                                                <select class="form-select" name="gender" id="gender" required="" disabled={!editable}
                                                    value={gender}
                                                    onChange={(e) => { setGender(e.target.value); handlechanges(e) }}
                                                  >
                                                    <option value="">Select Gender</option>
                                                    <option value="1">Male</option>
                                                    <option value="2">Female</option>
                                                    <option value="0">Other</option>
                                                  </select>
                                            </div>
                                            {errors.gender && <div class="col-md-12 mt-0">
                                              <span className="text-danger offset-3 ps-2">{errors.gender}</span>
                                            </div>}

                                            <div class="col-md-12 user_detail_card_desc user-left-card-desc right-card-left-details mt-0 gap-2">
                                                <p class="mb-0 col-md-3">Email </p>
                                                <input type="email" class="form-control" name="email" id="email" required="" 
                                                placeholder="Enter Email"
                                                value={email}
                                                maxLength={100}
                                                onChange={(e) => {
                                                  setEmail(e.target.value)
                                                  handlechanges(e)
                                                } } disabled={true}/>
                                            </div>

                                            {errors.email && <div class="col-md-12 mt-0">
                                              <span className="text-danger offset-3 ps-2">{errors.email}</span>
                                            </div>}

                                            <div class="col-md-12 user_detail_card_desc user-left-card-desc right-card-left-details mt-0 gap-2">
                                                <p class="mb-0 col-md-3">Phone Number </p>
                                                <input type="number" oninput="numberMaxLength(this, 10)" name="phone" 
                                                placeholder="Enter Phone Number"
                                                value={phoneNumber}
                                                maxLength={10}
                                                onChange={(e) => {
                                                  setPhoneNumber(e.target.value)
                                                  handlechanges(e)
                                                } } required=""  class="form-control " id="phone" disabled={true}/>
                                            </div>

                                            {errors.phoneNumber && <div class="col-md-12 mt-0">
                                               <span className="text-danger offset-3 ps-2">{errors.phoneNumber}</span>
                                            </div>}

                                            <div class="col-md-12 user_detail_card_desc user-left-card-desc right-card-left-details mt-0 gap-2">
                                                <p class="mb-0 col-md-3">Address </p>
                                                <textarea disabled={!editable} name="address" class="form-control" id="address" cols="20" rows="5" required="" 
                                                 value={address}
                                                 maxLength={100}
                                                 onChange={(e) => {
                                                   setAddress(e.target.value)
                                                   handlechanges(e)
                                                 } } ></textarea>
                                                
                                            </div>

                                            {errors.address && <div class="col-md-12 mt-0">
                                              <span className="text-danger offset-3 ps-2">{errors.address}</span>
                                            </div>}

                                            <div class="col-md-12 user_detail_card_desc user-left-card-desc right-card-left-details mt-0 gap-2">
                                                <p class="mb-0 col-md-3">Pincode</p>
                                                <input type="tel" name="pincode" maxLength={6} value={pincode}
                                                onChange={(e) => {
                                                  setPincode(e.target.value)
                                                  handlechanges(e)
                                                } }  required="" maxlength="6" class="form-control " id="pincode" disabled={!editable}/>
                                                
                                            </div>
                                            
                                            {errors.pincode && <div class="col-md-12 mt-0">
                                              <span className="text-danger offset-3 ps-2">{errors.pincode}</span>
                                            </div>}

                                            <div class="col-md-12 user_detail_card_desc user-left-card-desc right-card-left-details mt-0 gap-2">
                                                <p class="mb-0 col-md-3">State</p>
                                                <select
                                                   name="state" id="state" required="" data-container="body" disabled={!editable}
                                                    className="form-select"
                                                    value={state}
                                                    onChange={(e) => {
                                                      setState(e.target.value);
                                                      setDistrictsData([])
                                                      setCitiesData([]);
                                                      setDistrictName(""); // Reset district and city when state changes
                                                      setCityName("");
                                                      handlechanges(e)
                                                    }}
                                                  >
                                                    <option value="">Select State</option>
                                                    {statesData.map((state) => (
                                                      <option key={state.value} value={state.value}>{state.label}</option>
                                                    ))}
                                                </select>
                                                
                                                
                                            </div>

                                            {errors.state && <div class="col-md-12  mt-0">
                                              <span className="text-danger offset-3 ps-2">{errors.state}</span>
                                            </div>}

                                            <div class="col-md-12 user_detail_card_desc user-left-card-desc right-card-left-details mt-0 gap-2">
                                                <p class="mb-0 col-md-3">District</p>
                                                <select
                                                  class="form-select" name="district" id="district" required="" data-container="body" disabled={!editable}
                                                  value={districtName}
                                                  onChange={(e) => {
                                                    setDistrictName(e.target.value);
                                                    handlechanges(e)
                                                    setCityName(""); // Reset city when district changes
                                                  }}
                                                >
                                                  <option value="">Select District</option>
                                                  {districtsData.map((district) => (
                                                    <option key={district.value} value={district.value}>{district.label}</option>
                                                  ))}
                                                </select>
                                                
                                            </div>

                                            {errors.district && <div class="col-md-12 mt-0">
                                              <span className="text-danger offset-3 ps-2">{errors.district}</span>
                                            </div>}

                                            <div class="col-md-12 user_detail_card_desc user-left-card-desc right-card-left-details mt-0 gap-2">
                                                <p class="mb-0 col-md-3">City</p>
                                                <select
                                                  class="form-select" name="city" id="city" required="" data-container="body" disabled={!editable}
                                                  value={cityName}
                                                  onChange={(e) => {
                                                    setCityName(e.target.value)
                                                    handlechanges(e)
                                                  }}
                                                >
                                                  <option value="">Select City</option>
                                                  {citiesData.map((city) => (
                                                    <option key={city.value} value={city.value}>{city.label}</option>
                                                  ))}
                                                </select>
                                                
                                            </div>

                                            {errors.city && <div class="col-md-12 mt-0">
                                              <span className="text-danger offset-3 ps-2">{errors.city}</span>
                                            </div>}

                                            <div class="col-md-12 user_detail_card_desc user-left-card-desc right-card-left-details mt-0 gap-2 border-0">
                                                <p class="mb-0 col-md-3">Status </p>
                                                <select class="form-select" name="status" id="status" required="" disabled={!editable}
                                                    value={status}
                                                    onChange={(e) => { setStatus(e.target.value); handlechanges(e) }}
                                                  >
                                                    <option value="">Select Status</option>
                                                    <option value="1">Active</option>
                                                    <option value="0">Inactive</option>
                                                    <option value="2">Blocked</option>
                                                    <option value="3">Pending</option>
                                                  </select>
                                                  
                                            </div>

                                            {errors.status && <div class="col-md-12 mt-0">
                                              <span className="text-danger offset-3 ps-2">{errors.status}</span>
                                            </div>}

                                            <div class="col-md-12 user_detail_card_desc user-left-card-desc right-card-left-details mt-0 gap-2 border-0">
                                            
                                            {editable ? <div class="d-flex mt-3">
                                              {submitting ? <button class="btn admin-theme-btn">
                                                <span>Updating...</span>
                                                <Spinner animation="border" role="status" style={{ 'marginLeft': '5px' }}>
                                                  <span className="visually-hidden"></span>
                                                </Spinner>
                                              </button> : 
                                              <input type="submit" class="btn admin-theme-btn" value="Update"></input>}
                                            </div> : <></>}
                                            </div>

                                        </div>


                                    </form>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
                </div>
                </div>
      </div>
    </AdminDashboard>
  );
};

export default UserDetails;
