import React, { useEffect, useState } from "react";
import { UploadIcon } from "../Application/ApplicationIcon";
import { CgSoftwareUpload } from "react-icons/cg";
import { validateonSubmitHandle } from "../../Utils/Utils";

const InputTypeText = ({ id, type, name, value, placeholder, onChange, isSubSectionInput, fieldsDisabled, field, setErrors }) => {
  // const [image, setImage] = useState(value);
  // const handleFileChange = (e) => {
  //   const file = e?.target?.files?.[0];
  //   if (file) {
  //     console.log(file?.name);
  //     setImage(file?.name);
  //     onchange()
  //   }
  // };

  const [maxLength, setMaxLength] = useState(null);

  
  useEffect(() => {
    try{
      if (
        JSON.parse(JSON.parse(field?.validations)?.validation)?.onKeyUp &&
        JSON.parse(JSON.parse(field?.validations)?.validation)?.onKeyUp
          ?.length > 0
      ) {
        const data = JSON.parse(field?.validations);
        const validations = JSON.parse(data?.validation)?.onKeyUp;
    
        for (let item of validations) {
          if (item?.unit === "Maximum length") {
            setMaxLength(item.value); // Update state
            break; // Stop further iteration
          }
        }
      }
    }
    catch {
    }
  }, [field])

  const handleKeupChange = (e, field, value) => {
    const name = e.target.name;
    const isRequired = field?.required === "1";
    let err = null;

    if (isRequired && !value) {
      err = {
        hi: `${field?.name_hi} आवश्यक है`,
        en: `${field?.name_en} is required`,
      };
    } else {
      try{
        if (
          JSON.parse(JSON.parse(field?.validations)?.validation)?.onKeyUp &&
          JSON.parse(JSON.parse(field?.validations)?.validation)?.onKeyUp
            ?.length > 0
        ) {
          const validations = validateonSubmitHandle(field, value, null);
          err = validations === "" ? null : validations;
        } else {
          err = null;
        }
      }
      catch{}
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: err, // Include field name in the error message
    }));
  }

  return (
    <>
      {type == "file" ? (
        <>
          <input
            type="file"
            id={id}
            name={name}
            className="w-100 ad-cat-input-field-image "
            onChange={onChange}
          />
          <label
            htmlFor={id}
            className="ad-cat-input-upload-image p-0 fire-file-upload-img"
          >
            <p className="ad-cat-text-upload-image">
              <span>
                <CgSoftwareUpload />
              </span>{" "}
              {value ? (
                <p className="mb-0 fw-semibold">
                  {value.includes('\\') ? value.split('\\').pop() : value}
                </p>
              ) : (
                "Upload File"
              )}
            </p>
          </label>
        </>
      ) : (
        <input
          type={type}
          id={id}
          name={name}
          className={type === "checkbox" ? "" : "form-control"}
          value={value} // Controlled input
          onChange={onChange} // Trigger change in parent
          placeholder={placeholder}
          max
          disabled={fieldsDisabled}
          onBlur={(e) => {
            if(isSubSectionInput) {
              if(e.target.value === ""){
                e.target.value = "1"
              }
            } else {
              handleKeupChange(
                e,
                field,
                e?.target?.value || e?.target?.files)
            }
          }}
          maxLength={maxLength ? maxLength : undefined}
        />
      )}
    </>
   
  );
};

export default InputTypeText;
