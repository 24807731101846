import React, { useEffect, useState } from 'react'
import AdminDashboard from '../AdminDashboard/AdminDashboard'
import { useLocation, useNavigate } from 'react-router-dom'
import { Message } from '../../../components/message'
import { BsChevronLeft } from 'react-icons/bs'
import { GetLocalStorage } from '../../../Utils/Utils'
import { addCertificateType, getRolesAllListData, getRolesListData, submitAdminUserData, updateAdminUserData, updateCertificateType } from '../../../services/api/admin'
import Select from 'react-select';
import { checkPattern, checkPatternWithSpecialCharacter, checkValidEmail, checkValidMobile, customStylesAcc, selectTheme } from '../../../Utils/common'
import { UploadIcon } from '../AdminIcon'
import BeatLoader from 'react-spinners/BeatLoader';
import { stateDistrict, checkEmailMobile } from '../../../services/api/auth'

const CertificateCategoryAdd = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const token = GetLocalStorage("userInfo")
  const [apiMessage, setApiMessage] = useState({ type: '', message: '' });
  const [districtList, setDistrictList] = useState([]);
  const [districtId, setDistrictId] = useState('');
  const [roleList, setRoleList] = useState([]);
  const [roleId, setRoleId] = useState('');
  const [titleId, setTitleId] = useState('');
  const [image, setImage] = useState();
  const [imageError, setImageError] = useState();
  const [userId, setUserId] = useState('')
  const [file, setFile] = useState();
  const [existingMobileNo, setExistingMobileNo] = useState()
  const [formData, setFormData] = useState({
    name_en:'',
    name_hi: '',
  })
  const [error, setError] = useState({
    name_en: '',
    name_hi: '',
  })

  useEffect(() => {
    if (!token) {
      navigate("/")
    } else {
      if (location?.state && location?.pathname == "/admin/certificate-type/update") {
        editAdminUser(location?.state)
      }
    }
  }, [location?.state])

  const handleInputChange = async (e) => {
    const { name, value } = e.target
    const cursorPosition = e.target.selectionStart;

    if (name == 'name_en') {
      if (await checkPattern(value) == false) {
        e.preventDefault()
        return;
      }
    } else if (name == 'name_hi') {
      if (await checkPattern(value) == false) {
        e.preventDefault()
        return;
      }
    }

    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));

    setTimeout(() => {
      e.target.setSelectionRange(cursorPosition, cursorPosition);
    }, 0);

    validateOnChangeHandle(name, value);
  }

  const validateOnChangeHandle = async (name, value) => {
    let formErrors = error;
    switch (name) {
      case 'name_en':
        if (!value?.trim()) {
          formErrors.name_en = 'Please enter certificate type in english';
        } else {
          formErrors.name_en = ''
        }
        break;
      case 'name_hi':
        if (!value?.trim()) {
          formErrors.name_hi = 'Please enter certificate type in hindi';
        } else {
          formErrors.name_hi = ''
        }
        break;
    }
    setError(formErrors);
    return formErrors;
  }

  const validateOnBlurHandle = async (e) => {
    const { name, value } = e?.target;

    // Create a copy of the current errors state
    let formErrors = { ...error };
    if (name == 'name_en') {
      if (!value.trim()) {
        formErrors.name_en = 'Please enter certificate type in english';
      } else {
        formErrors.name_en = '';
      }
    } else if (name == 'name_hi') {
      if (!value.trim()) {
        formErrors.name_hi = 'Please enter certificate type in hindi';
      } else {
        formErrors.name_hi = '';
      }
    }
    // Update the error state
    setError(formErrors);
  };


  const editAdminUser = (data) => {
    setFormData({
      name_en: data?.name_en,
      name_hi: data?.name_hi,
    })
    setUserId(data.id)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let validationErrors = { ...error };
      await Promise.all(Object.keys(formData).map(async (key) => {
        const value = formData[key];
        const errors = await validateOnChangeHandle(key, value);
        validationErrors = { ...validationErrors, ...errors };

      }));
      setError(validationErrors);
      if (Object.values(validationErrors).some(error => error !== '')) {
        return;
      }
      const AdminUserData = new FormData();
      AdminUserData.append("name_en", formData.name_en)
      AdminUserData.append("name_hi", formData.name_hi)
      const userData = await addCertificateType(AdminUserData)
      if (userData?.status == 200) {
        navigate("/admin/certificate-type", { state: { message: userData.message } })
      }
    } catch (err) {
      setApiMessage({ type: 'error', message: err.message })
    }
  }

  const updateFields = async (e) => {
    e.preventDefault();
    try {
      let validationErrors = { ...error };
      await Promise.all(Object.keys(formData).map(async (key) => {
        const value = formData[key];
        const errors = await validateOnChangeHandle(key, value);
        validationErrors = { ...validationErrors, ...errors };

      }));
      setError(validationErrors);
      if (Object.values(validationErrors).some(error => error !== '')) {
       
        return;
      }
      const AdminUserData = new FormData();
      AdminUserData.append("name_en", formData.name_en)
      AdminUserData.append("name_hi", formData.name_hi)
      AdminUserData.append("certificate_type_id", userId)
      const userData = await updateCertificateType(AdminUserData)
      if (userData?.status == 200) {
        navigate("/admin/certificate-type", { state: { message: userData.message } })
      }
    } catch (err) {
      setApiMessage({ type: 'error', message: err.message })
    }
  }

  return (
    <AdminDashboard>
      <div className='aod-outer'>
        {apiMessage && (<Message apiMessage={apiMessage} setApiMessage={setApiMessage}></Message>)}
        <div className='ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb'>
          <div>
            <b onClick={() => navigate("/admin/")}>Home  /</b><span> Certificate Type/{userId ? "Update" : "Add"}</span>
          </div>
        </div>
        <div className="aod-inner">
          <div className={userId ? "aod-bottom" : "aod-bottom"}>
            <div className="aod-head text-center adm-head"><button onClick={() => navigate("/admin/certificate-type")}><BsChevronLeft /> Back</button>{userId ? "Update" : "Add"} Certificate Type</div>
            <form>
              <div className="row py-4">
                <div className="col-md-4 aod-resp-tab">
                  <div className='suf-input-box aod-form-input mb-2'>

                    <label className='mb-1 w-100'>
                      Name
                    </label>
                    <input
                      className='w-100'
                      maxLength={100}
                      name='name_en'
                      placeholder='Enter Certificate type in english'
                      type="text"
                      onChange={(e) => handleInputChange(e)} // Use onChange for real-time validation
                      onBlur={(e) => validateOnBlurHandle(e)}
                      value={formData.name_en}
                    />
                    {error.name_en && <p className='validate-error'>{error.name_en}</p>}

                  </div>
                </div>
                <div className="col-md-4 aod-resp-tab">
                  <div className='suf-input-box aod-form-input mb-2'>

                    <label className='mb-1 w-100'>
                      User Name
                    </label>
                    <input
                      className='w-100'
                      maxLength={100}
                      name='name_hi'
                      placeholder='Enter Certificate type in hindi'
                      type="text"
                      onChange={(e) => handleInputChange(e)}
                      onBlur={(e) => validateOnBlurHandle(e)} // Use onChange for real-time validation
                      value={formData.name_hi}
                    />
                    {error.name_hi && <p className='validate-error'>{error.name_hi}</p>}

                  </div>
                </div>
                <div className="row ad-cat-row">
                  <div className=" update-btns-block mt-3">
                    {
                      userId ?
                        <>
                          <button onClick={(e) => updateFields(e)}>
                            Update
                          </button>
                        </> :

                        <button onClick={(e) => handleSubmit(e)}>
                          Submit
                        </button>
                    }
                  </div>

                </div>
              </div>
            </form>

          </div>
        </div>
      </div>
    </AdminDashboard>
  )
}

export default CertificateCategoryAdd
