import React, { useState , useEffect } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import "./Header.css";
import { BsList, BsXLg } from 'react-icons/bs';
import { useLanguage } from '../LanguageProvider/LanguageProvider ';
import logoImage from '../../assets/images/uttrLogo.png'
import { getHomepageLogo } from '../../services/api/admin';
import { Baseurl } from '../../Utils/Utils';
const HeaderBottom = ({ homePageContent }) => {
    const { language, setLanguage } = useLanguage()
    const [open, setOpen] = useState(false)
    const [imagePath, setImagePath ] = useState(true);

    useEffect(() => {
        WebsiteLogo();
      }, []);

    const WebsiteLogo = async () => {
        try {
          const usersListData = await getHomepageLogo();
          if (usersListData.status === 200) {
            setImagePath(Baseurl() +"/"+ usersListData?.data?.path);
          }else{
            setImagePath("https://utdbtraveltstg.dilonline.in/static/media/uttrLogo.5f55d1f48deb13a04f1a.png");
          }
        } catch (err) {
          setImagePath("https://utdbtraveltstg.dilonline.in/static/media/uttrLogo.5f55d1f48deb13a04f1a.png");
        }
      };

    return (
        <div className='hd-cont hd-bg'>
          
            <Navbar className="bg-body hd-main container p-0">
                <div className='m-0 w-100 d-flex hd-res-cont'>
                    <NavLink to="/" className="navbar-brand" onClick={window.scrollTo(0, 0)}><img className="hd-logo" src={`${imagePath}`} /></NavLink>
                    <div className='hd-toggle'>{
                        open ? <BsXLg onClick={() => setOpen(false)} /> :
                            <BsList onClick={() => setOpen(true)} />}
                    </div>
                    <div id="basic-navbar-nav" className={`${open ? "open-toggle" : "close-toggle"} justify-content-end pe-5`}>
                            <Nav className="hd-links">
                              <NavLink NavLink
                                key="0"
                                to="/"
                              >
                                {language ? "होम" : "Home"}
                            </NavLink>
                            {
                              homePageContent?.new_header_menu
                                ?.filter((item) => item.status === "1") // Filter items with status 1
                                .map((item) =>
                                  item?.url_type === "2" ? ( // Check if it's an external link
                                    <a
                                      key={item.id}
                                      href={item.url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {language ? item?.name_hi : item?.name_en}
                                    </a>
                                  ) : (
                                    <NavLink
                                      key={item.id}
                                      to={"/" + item?.url}
                                      state={{
                                        content_en: item?.content_en,
                                        content_hi: item?.content_hi,
                                        page_id: item?.id,
                                        title_en: item?.name_en,
                                        title_hi: item?.name_hi,
                                      }} // Pass content as state
                                    >
                                      {language ? item?.name_hi : item?.name_en}
                                    </NavLink>
                                  )
                                )
                            }
                            <NavLink
                                key={homePageContent?.new_header_menu
                                  ?.filter((item) => item.status === "1")?.length ?? 0 + 1}
                                to="/contact-us"
                              >
                                {language ? "संपर्क करें" : "Contact Us"}
                            </NavLink>
                        </Nav>
                    </div>
                </div>
            </Navbar>
        </div>
    )
}
export default HeaderBottom