import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Dashboard from "../../pages/Dashboard/Dashboard";
import { getSubCategories } from "../../services/api/outer";
import { applicationGenerate, changeApplicationStatus, checkApplicationData, checkApplicationsData, downloadApplicationPDFAPI } from "../../services/api/user";
import { formatDateWithSlash } from "../../Utils/common";
import {
    GetLocalStorageParsed,
    SetLocalStorageStringify
} from "../../Utils/Utils";
import { useLanguage } from "../LanguageProvider/LanguageProvider ";
import { FaArrowCircleLeft, FaBan, FaEye, FaHistory, FaRegEdit, FaSync, FaTimesCircle, FaTrashAlt, FaUndoAlt,faRotate  } from "react-icons/fa";
import { RiFileDownloadLine } from "react-icons/ri";
import { Modal, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";


const Applications = () => {
    const { language, setLanguage } = useLanguage();
    const [applicationData, setApplicationData] = useState(null);
    const formData = new FormData();
    const userDetail = GetLocalStorageParsed("userDetail");
    const navigate = useNavigate();
    const [subCategoryData, setSubCategoryData] = useState([]);
    const [pdfLoadingId, setPdfLoadingId] = useState(null)
    const [withdrawData, setWithdrawData] = useState();
    const [withdrawing, setWithdrawing] = useState(false);

    useEffect(() => {
        getApplicationData();
    }, []);

    const getApplicationData = async () => {
        const formData = new FormData();
        formData.append("user_id", userDetail?.id);
        const data = await checkApplicationsData(formData);
        setApplicationData(data?.data)
        try {
        } catch (err) {
            console.log(err);
        }
    };


     // Sub category click function
  const handleSubCatClick = async (item, eventFor) => {
    try {
      if(eventFor === "withdraw") {
        setWithdrawing(true)
        const formData = new FormData();
        formData.append("status", "10");
        formData.append("application_id", item?.id);
        const changeApplicationStatusResponse = await changeApplicationStatus(formData);
        if (changeApplicationStatusResponse.status === "200" || changeApplicationStatusResponse.status === 200) {
          getApplicationData()
        } 
        setWithdrawData("")
        setWithdrawing(false)
      } else if(eventFor === "renew") {
        const formData = new FormData();
        formData.append("sub_category_id", item?.category_id);
        formData.append("district_id", item?.district_id);
        formData.append("user_id", userDetail?.id);
        formData.append("application_type", 1);
        formData.append("parent_application_id", item?.id);
        const appGenerate = await applicationGenerate(formData);
          if(appGenerate?.status === 200 && appGenerate?.data?.application_details){
            SetLocalStorageStringify("application_id", appGenerate?.data?.application_details?.id);
            SetLocalStorageStringify("district_id", appGenerate?.data?.application_details?.district_id);
            navigate("/application/application-form");
          }
      } else {
        const formData = new FormData();
        formData.append("sub_category_id", item?.category_id);
        formData.append("user_id", userDetail?.id);
        const data = await checkApplicationData(formData);
        SetLocalStorageStringify("LoginSubCatData", item?.sub_category);
        if (data?.status == 200) {
            SetLocalStorageStringify("application_id", data?.data?.id);
            SetLocalStorageStringify("district_id", data?.data?.district_id);
            if (eventFor == "track") {
                navigate("/track-application");
            } else if(eventFor == "view") {
                navigate("/application-draft");
            } else {
                navigate("/application/application-form");
            }
        } else {
            navigate("/guidelines");
        }
      }
    } catch (err) {
      console.log(err);
      setWithdrawData("")
      setWithdrawing(false)
    }
  };

  const downloadPDF = async (item) => {
    try {
        setPdfLoadingId(item?.id)
        const formData = new FormData();
        formData.append("application_id", item?.id);
        const downloadApplicationPDF = await downloadApplicationPDFAPI(
          formData
        );
        if (downloadApplicationPDF.success === true) {
          window.open(downloadApplicationPDF?.data);
        }
        setPdfLoadingId(null)
    } catch (err) {
        setPdfLoadingId(null)
        console.log(err);
    }
  };
    return (
        <Dashboard
            first={language ? "होम" : "Home"}
            second={language ? "प्रमाणपत्र" : "Applications"}
            head={language ? "प्रमाणपत्र" : "Applications"}
        >
            <>
                <div className="aod-bottom">
                    <div className="page_heading">
                        <h5>{language ? "अनुप्रयोग" : "Applications"}</h5>
                    </div>
                    <div className="row">
                        <div className="reciept-table table-responsive mt-2">
                            <table
                                className="table table mb-0 dataTable transaction_table"
                                id="receipt_table"
                                role="grid"
                            >
                                <thead className="">
                                    <tr role="row">
                                        <th>{language ? "क्रम संख्या" : "S.No"}</th>
                                        <th>{language ? "आवेदन संख्या" : "Application Number"}</th>
                                        <th>{language ? "श्रेणी नाम" : "Category Name"}</th>
                                        <th>{language ? "तारीख" : "Date"}</th>
                                        <th>{language ? "स्थिति" : "Status"}</th>
                                        <th>{language ? "कार्रवाई" : "Action"}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {applicationData?.length > 0 ? (
                                        applicationData?.map((item, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{item.application_number}</td>
                                                <td>{language ? item?.sub_category?.name_hi : item?.sub_category?.name}</td>
                                                <td>
                                                    {item?.created_at
                                                        ? formatDateWithSlash(item?.created_at)
                                                        : "--"}
                                                </td>
                                                <td>
                                                    {(() => {
                                                        switch (item?.status) {
                                                            case "2": return language ? "आवश्यक दस्तावेज़ लंबित" : "Required Document Pending";
                                                            case "3": return language ? "मसौदा" : "Draft";
                                                            case "4": return language ? "Approved" : "Approved";
                                                            case "5": return language ? "रक्ष्" : "Rejected";
                                                            case "6": return language ? "खत्म हो चुका" : "Expired";
                                                            case "7": return language ? "समीक्षाधीन" : "Under Review";
                                                            case "8": return language ? "प्रस्तुत" : "Submitted";
                                                            case "10": return language ? "वापस लिया गया" : "Withdrawn";
                                                            default: return language ? "अज्ञात" : "Unknown"; // in case of an undefined status
                                                        }
                                                    })()}
                                                </td>
                                                <td>
                                                    {item?.status !== "4" && item?.status !== "3" && item?.is_filled_record === "1" ?  
                                                                <a
                                                                    className="btn btn-green-view me-2 mb-2"
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    onClick={() => handleSubCatClick(item, 'view')}
                                                                >{language ? "देखना" : "View"}
                                                                    {/* <OverlayTrigger
                                                                            placement="top"
                                                                            overlay={<Tooltip>{language ? "देखना" : "View"}</Tooltip>}
                                                                        >
                                                                            <span>
                                                                                <FaEye style={{ color: "#fff" }} />
                                                                            </span>
                                                                        </OverlayTrigger> */}
                                                     </a> : <></>}
                                                    {item?.status == "4" || item?.status == "8" || item?.status == "7" || item?.status == "6" || item?.status == "10" || item?.status == '5' || item?.status == '2' ? (
                                                       <>
                                                            {item?.status === "6" ? 
                                                                <a
                                                                    className="btn btn-green-view me-2 mb-2"
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    onClick={() => handleSubCatClick(item, 'renew')}
                                                                >{language ? "नवीकरण" : "Renew"}
                                                                    {/* <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={<Tooltip>{language ? "नवीकरण" : "Renew"}</Tooltip>}
                                                                    >
                                                                        <span>
                                                                            <FaSync style={{ color: "#fff" }} />
                                                                        </span>
                                                                    </OverlayTrigger> */}
                                                                </a> : 
                                                                    <a
                                                                        className="btn btn-green-view me-2 mb-2"
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        onClick={() => handleSubCatClick(item, 'track')}
                                                                    >{language ? "ट्रैक" : "Track"}
                                                                        {/* <OverlayTrigger
                                                                            placement="top"
                                                                            overlay={<Tooltip>{language ? "ट्रैक" : "Track"}</Tooltip>}
                                                                        >
                                                                            <span>
                                                                                <FaHistory style={{ color: "#fff" }} />
                                                                            </span>
                                                                        </OverlayTrigger> */}
                                                                    </a>
                                                            }
                                                            {item?.status == '5' || item?.status == '2'  ?
                                                            <a
                                                                    className="btn btn-green-view me-2 mb-2"
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                onClick={() => handleSubCatClick(item, 'resume')}
                                                            >{language ? "आवेदन फार्म" : "Application Form"}
                                                                {/* <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={<Tooltip>{language ? "जारी रखना" : "Continue"}</Tooltip>}
                                                                    >
                                                                        <span>
                                                                            <FaRegEdit style={{ color: "#fff" }} />
                                                                        </span>
                                                                    </OverlayTrigger> */}
                                                            </a> : null

                                                                }
                                                            {item?.is_filled_record === "1" ? (pdfLoadingId === item?.id ? <a className="btn btn-green-view me-2 mb-2">
                                                                
                                                                <span>  {language ? "डाउनलोड हो रहा है..." : "Downloading..."}</span>
                                                                    <span>
                                                                    <Spinner animation="border" role="status" 
                                                                        style={{
                                                                            height: "16px",
                                                                            width: "16px",
                                                                            borderWidth: "2px", // Matches the size of the icon stroke
                                                                            color: "#fff",
                                                                        }}>
                                                                    <span className="visually-hidden">{language ? "डाउनलोड हो रहा है..." : "Downloading..."}</span>
                                                                </Spinner>
                                                                    </span>
                                                            </a> : <a
                                                                className="btn btn-green-view me-2 mb-2"
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                onClick={() => downloadPDF(item)}
                                                            >{language ? "पीडीएफ डाउनलोड करें" : "Download PDF"}
                                                                {/* <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={<Tooltip>{language ? "पीडीएफ डाउनलोड करें" : "Download PDF"}</Tooltip>}
                                                                >
                                                                    <span>
                                                                        <RiFileDownloadLine style={{ color: "#fff" }} />
                                                                    </span>
                                                                </OverlayTrigger> */}
                                                            </a>) : <></>}
                                                       </>
                                                        
                                                    ) :
                                                        (
                                                            <>
                                                                {item?.status == '5' || item?.status == '2'  ?
                                                            <a
                                                                className="btn btn-green-view me-2 mb-2"
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                onClick={() => handleSubCatClick(item, 'track')}
                                                            >{language ? "ट्रैक" : "Track"}
                                                                {/* <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={<Tooltip>{language ? "ट्रैक" : "Track"}</Tooltip>}
                                                                >
                                                                    <span>
                                                                        <FaHistory style={{ color: "#fff" }} />
                                                                    </span>
                                                                </OverlayTrigger> */}
                                                                </a> : null

                                                                }
                                                            <a
                                                                    className="btn btn-green-view me-2 mb-2"
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                onClick={() => handleSubCatClick(item, 'resume')}
                                                            >{language ? "आवेदन फार्म" : "Application Form"}
                                                                 {/* <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={<Tooltip>{language ? "जारी रखना" : "Continue"}</Tooltip>}
                                                                    >
                                                                        <span>
                                                                            <FaRegEdit style={{ color: "#fff" }} />
                                                                        </span>
                                                                    </OverlayTrigger> */}
                                                            </a>
                                                       </>
                                                        )}
                                                        {item?.status === "4" ? 
                                                        (
                                                            <>
                                                                <a
                                                                    className="btn btn-green-view me-2 mb-2"
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    onClick={() => setWithdrawData(item)}
                                                                >{language ? "निकालना" : "Withdraw"}
                                                                    {/* <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={<Tooltip>{language ? "निकालना" : "Withdraw"}</Tooltip>}
                                                                    >
                                                                        <span>
                                                                            <faRotate  style={{ color: "#fff" }} />
                                                                        </span>
                                                                    </OverlayTrigger> */}
                                                                </a>
                                                            </>
                                                        )  : (<></>)
                                                        }
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <></>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <Modal show={withdrawData?.id} onHide={() => setWithdrawData("")}>
                    <Modal.Header closeButton>
                    <Modal.Title>{language ? "क्या आप वाकई इस आवेदन को वापस लेना चाहते हैं" : "Are you sure you want to Withdraw this Application"} ?</Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                    <div className="suf-btn p-2 d-flex flex-row">
                        {withdrawing ? <button>
                            <span></span>
                            <Spinner animation="border" role="status" style={{ 'marginTop': '4px' }}>
                            <span className="visually-hidden"></span>
                            </Spinner>
                        </button> : <button className="mx-2" onClick={() => {
                                handleSubCatClick(withdrawData, 'withdraw');
                            }}>
                            {language ? "हाँ" : "Yes"}
                        </button>}
                        <button
                            className="suf-can-butt mx-2"
                            onClick={() => setWithdrawData("")}
                            >
                        {language ? "नहीं" : "No"}
                        </button>
                    </div>
                    </Modal.Footer>
                </Modal>
            </>
        </Dashboard>
    );
};

export default Applications;
