import React, { useEffect, useRef, useState } from "react";
import HomebannerLogin from "./HomebannerLogin";
import { useLanguage } from "../../LanguageProvider/LanguageProvider ";
import { Baseurl } from "../../../Utils/Utils";
import { Modal } from "react-bootstrap";
import { faL } from "@fortawesome/free-solid-svg-icons";
import { ApplicationStatus, certificateView, checkEmailMobile, sendMobileOtp, sendSMSOtp, verifyBannerOtp, verifyMobile, verifyMobileOtp, verifyOtp } from "../../../services/api/auth";
import clock from "../../../assets/images/clock.png"
import { getConfigurationData } from "../../../services/api/user";

const HomeBanner = ({ bannerData, htmlRendered, banner }) => {
  const { language, setLanguage } = useLanguage();
  const loginRef = useRef(null); // Ref to check the DOM
  const [showApplicationStatus, setShowApplicationStatus] = useState(false);
  const [applicationNumber, setApplicationNumber] = useState('')
  const [mobileNumber, setMobileNumber] = useState('')
  const [modalTitle, setModalTitle] = useState('')
  const [errorsPhone, setErrorsPhone] = useState({});
  const [errorsApplicationNumber, setErrorsApplicationNumber] = useState({});
  const [tableHeader, setTableHeader] = useState('')
  const [apiResponse, setApiResponse] = useState()
  const [showMobileSendSection, setShowMobileSendSection] = useState(false);
  const [expiryMobile, setExpiryMobile] = useState(0)
  const inputRefs = useRef([]);
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [otpMobile, setOtpMobile] = useState(new Array(6).fill(''));
  const [otpError, setOtpError] = useState(new Array(6).fill(''));
  const [otpErrorMobile, setOtpErrorMobile] = useState(new Array(6).fill(''));
  const [showMobileOtp, setShowMobileOtp] = useState()
  const [isFieldDisabled, setIsFieldDisabled] = useState(false)
  const [resend, setResend] = useState(false)
  const [mobileNumberError, setMobileNumberError] = useState('');
  const [showTable, setShowTable] = useState(false);
  const [baseUrl, setBaseUrl] = useState('');
  const [errMessage, setErrMessage] = useState('');
  const [sendMobileOtp, setSendMobileOtp] = useState(false)

  useEffect(() => {
    if (loginRef.current) {
      htmlRendered();
    }
  }, [loginRef.current]);

  const handleChangeOtpMobile = (element, index) => {
    const value = element.value;
    if (/^\d$/.test(value)) {
      const newOtp = [...otpMobile];
      newOtp[index] = value;
      setOtpMobile(newOtp);
      if (index < 5 && value) {
        inputRefs.current[index + 1].focus();
      }
    } else if (value === '') {
      const newOtp = [...otpMobile];
      newOtp[index] = '';
      setOtpMobile(newOtp);
    }
    setOtpErrorMobile("");
  };

  const handleKeyDownOtpMobile = (e, index) => {
    if (e.key === 'Backspace' && !otpMobile[index]) {
      if (index > 0) {
        document.getElementById(`otp-input-mobile-${index - 1}`).focus();
      }
    }
  };

  useEffect(() => {
    if (expiryMobile > 0) {
      const timer = setInterval(() => {
        setExpiryMobile(prevTime => prevTime - 1);
      }, 1000);
      return () =>
        clearInterval(timer);
    }
    if (expiryMobile == 0) {
      setResend(true)
    }
  }, [expiryMobile]);

  const handleClick = (data) => {
    if (data?.button_type == '1') {
      setModalTitle({en : data?.name , hi : data?.name_hi})
      setTableHeader("Status")
    } else {
      setModalTitle({en : data?.name , hi : data?.name_hi})
      setTableHeader("View")
    }
    getConfiguration()
    setShowApplicationStatus(showApplicationStatus ? false : true);
  };

  const handleMobileCheck = async () => {
    try {
      const formData = new FormData();
      formData.append("mobile_number", mobileNumber);
      const res = await checkEmailMobile(formData)
      if (res.status != 200) {
        setErrorsPhone((prevErrors) => ({ ...prevErrors, newPhoneNumber_en: "Mobile number not registered", newPhoneNumber_hi: "मोबाइल नंबर पंजीकृत नहीं है" }))
        return false;
      } else {
        if(sendMobileOtp) {
          handleSendMobileOtp()
          return true
        } else {
          // setMobileNumberError({
          //   en: "Mobile number verified",
          //   hi: "मोबाइल नंबर सत्यापित",
          // });
          setErrorsPhone({ newPhoneNumber_en: "Service is unavailable, please try again later", newPhoneNumber_hi: "सेवा उपलब्ध नहीं है, कृपया बाद में पुनः प्रयास करें" })
          return true
        }
      }
    } catch (error) {
      console.error(error);
      setErrMessage(error?.message)
    }
  };

  const getConfiguration = async () => {
    try {
      const configData = await getConfigurationData();
      const data = configData?.data?.find((x) => x.type == "sms");
      setSendMobileOtp(data?.status == 1 ? true : false)
    } catch (err) {
    }
  };

  const handleSendMobileOtp = async () => {
    try {

      const formData = new FormData();
      formData.append("mobile_number", mobileNumber);
      if (tableHeader == "View") {
        formData.append("type", "banner_download_certificate");
      } else {
        formData.append("type", "banner_application_status");
      }
      //return await sendMobileOtp(formData).then(reponse => {
      return await sendSMSOtp(formData).then(reponse => {
        if (reponse?.success) {
          setShowMobileSendSection(true);
          setOtpErrorMobile("")
          setExpiryMobile(120)
          setShowMobileOtp(reponse?.data)
          setIsFieldDisabled(true)
        } else{
          setErrorsPhone({ newPhoneNumber_en: "Unable to send OTP", newPhoneNumber_hi: "OTP भेजने में असमर्थ" })
        }
      })
    } catch (error) {
      console.error('Error sending mobile OTP:', error);
      setErrorsPhone({ newPhoneNumber_en: "Unable to send OTP", newPhoneNumber_hi: "OTP भेजने में असमर्थ" })
    }
  }

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const handleVerifyOtp = () => {
    let otpnew = otpMobile?.map(i => i).join("")
    if (!otpnew || otpnew.trim() === "" || otpnew.length !== 6) {
      setOtpError({ en :"Please enter correct OTP.", hi : "कृपया सही ओटीपी दर्ज करें।" })
      return
    }
    handlesendVerifyMobileOtp(otpnew)
  }

  const handlesendVerifyMobileOtp = async (otp) => {
    setOtpError("")
    try {
      const data = {
        "type": tableHeader == "View" ? "banner_download_certificate" : "banner_application_status",
        "mobile_number": mobileNumber,
        "otp": otp
      }
      //const reponse = await axios.post(`${Apiurl()}/verify-otp`, data);
      //return await verifyOtp(data).then(reponse => {
      return await verifyBannerOtp(data).then(reponse => {
        if (reponse?.success) {
          setShowMobileSendSection(false);
          setMobileNumberError({
            en: "Mobile number verified",
            hi: "मोबाइल नंबर सत्यापित",
          });
        } else {
          setOtpError({ en: "The One-Time Password (OTP) you entered isn't valid or may be expired" , hi: "आपके द्वारा दर्ज किया गया वन-टाइम पासवर्ड (OTP) मान्य नहीं है या इसकी समय-सीमा समाप्त हो सकती है"})
        }
      });
    } catch (error) {
      setOtpError({ en: "The One-Time Password (OTP) you entered isn't valid or may be expired" , hi: "आपके द्वारा दर्ज किया गया वन-टाइम पासवर्ड (OTP) मान्य नहीं है या इसकी समय-सीमा समाप्त हो सकती है"})
    }
  }


  const resetData = () => {
    setApplicationNumber('')
    setMobileNumber("")
    setExpiryMobile('')
    setModalTitle('')
    setErrorsPhone({})
    setErrorsApplicationNumber({})
    setTableHeader()
    setApiResponse([])
    setShowMobileSendSection(false)
    setExpiryMobile(0)
    setOtpMobile(new Array(6).fill(''))
    setOtpError(new Array(6).fill(''))
    setOtpErrorMobile(new Array(6).fill(''))
    setShowMobileOtp()
    setIsFieldDisabled(false)
    setResend(false)
    setMobileNumberError('')
    setShowTable(false)
    setErrMessage("")
  }

  const handleSubmit = async (e) => {
    setBaseUrl(Baseurl);
    e.preventDefault();
    try {
      if (mobileNumber != '' && applicationNumber != '') {
        const formData = new FormData()
        formData.append('application_number', applicationNumber);
        formData.append('mobile_number', mobileNumber);
        if (tableHeader == "View") {
          const res = await certificateView(formData)
          if (res?.status == 200) {
            setApiResponse(res?.data)
            setShowTable(true)
          } else {
            setShowTable(false)
            setErrMessage(res?.message)
          }
        } else {
          const res = await ApplicationStatus(formData)
          if (res?.status == 200) {
            setApiResponse(res?.data)
            setShowTable(true)
          } else {
            setShowTable(false)
            setErrMessage(res?.message)
          }
        }
      } else {
        return;
      }
    } catch (err) {
      console.log(err?.message)
      setErrMessage(err?.message)
    }
  }

  return (
    <>
      <div className="hgbg-wrap" ref={loginRef}>
        <section
          className="hb-bg-main ratio custom_ratio"
          id="hg-bh-main-slider"
        >
          <div className="hb-bg-inner">
            <div className="container px-5">
              <div className="HomeBanner-main">
                <div className="HB-first-box">
                  {banner && banner?.status === "1" ? (
                    <>
                      <p className="line-1 anim-typewriter">{language ? banner?.content_hi : banner?.content}</p>
                      <div className="btns-hb-main">
                        {banner?.banner_link.map((bann, index) => {
                          return (
                            <div
                              className="HB-first-box-btn"
                              key={index}
                              onClick={() => handleClick(bann)}
                            >
                              <div className="HB-btn-icon">
                                {" "}
                                <img
                                  src={`${Baseurl()}/${bann?.icon}`}
                                  alt=""
                                />{" "}
                              </div>
                              <p>{language ? bann?.name_hi : bann?.name}</p>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="HB-second-box">
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <HomebannerLogin bannerData={bannerData} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Modal className="status-modal"
        show={showApplicationStatus}
        onHide={() => {
          resetData();
          setShowApplicationStatus(false);
        }}>
        <Modal.Header
          closeButton
          onClick={() => {
            resetData()
            setShowApplicationStatus(false)
          }}
        >
          <Modal.Title>{language ? modalTitle?.hi : modalTitle?.en}</Modal.Title>
        </Modal.Header>
        <div class="modal-body">
          {
            errMessage == '' ? null :
              <div className='alert alert-danger OtpMess'>
                {errMessage}
              </div>
          }
          <div class="mb-3 f_fields">
            <label for="name_of_accommodation_unit_2" class="form-label">
              <span class="field-label required">{language ? 'आवेदन संख्या' : 'Application Number'}</span>
            </label>
            <div>
              <input
                type="text"
                id=""
                name="applicationNumber"
                class="form-control"
                placeholder={language ? 'आवेदन संख्या दर्ज करें' : "Enter Application Number"}
                value={applicationNumber}
                onChange={(e) => {
                  setApplicationNumber(e?.target?.value)
                  setErrorsApplicationNumber({ newPhoneNumber_en: "", newPhoneNumber_hi: '' })
                }}
                onBlur={(e) => {
                  let value = e.target.value
                  if (value == '') {
                    setErrorsApplicationNumber((prevErrors) => ({ ...prevErrors, newPhoneNumber_en: "Application Number is required", newPhoneNumber_hi: "आवेदन संख्या अनिवार्य है" }));
                  } else {
                    setErrorsApplicationNumber({ newPhoneNumber_en: "", newPhoneNumber_hi: '' })
                  }
                }}
              />
            </div>
            {(errorsApplicationNumber.newPhoneNumber_hi || errorsApplicationNumber.newPhoneNumber_en) && <p className="text-danger">{language ? errorsApplicationNumber.newPhoneNumber_hi : errorsApplicationNumber.newPhoneNumber_en}</p>}
          </div>

          <div class="mb-3 f_fields">
            <label for="name_of_accommodation_unit_2" class="form-label">
              <span class="field-label required">{language ? 'मोबाइल नंबर' : 'Mobile Number'}</span>
            </label>
            <div>
              <input
                readOnly={isFieldDisabled}
                maxLength={10}
                type="text"
                id=""
                name="mobileNumber"
                class="form-control"
                placeholder={language ? 'मोबाइल नंबर दर्ज करें' : "Enter Mobile Number"}
                onChange={(e) => {
                  let value = e.target.value
                  if (!/^\d*$/.test(value)) {
                    return;
                  }
                  if (value.length === 1 && /^[0-5]$/.test(value)) {
                    return;
                  }
                  if (e.target.value.length <= 10) {
                    setMobileNumber(e.target.value);
                    setErrorsPhone({ newPhoneNumber_en: "", newPhoneNumber_hi: '' })
                  }
                  setMobileNumberError("")
                }}
                onBlur={async (e) => {
                  let value = e.target.value
                  if (value == '') {
                    setErrorsPhone((prevErrors) => ({ ...prevErrors, newPhoneNumber_en: "Phone number is required", newPhoneNumber_hi: "फ़ोन नंबर अनिवार्य है" }));
                  } else if (value?.length > 10) {
                    setErrorsPhone((prevErrors) => ({ ...prevErrors, newPhoneNumber_en: "Phone number must be 10 digits long.", newPhoneNumber_hi: "फ़ोन नंबर 10 अंक लंबा होना चाहिए." }));
                  }
                }}

                value={mobileNumber}
              />
            </div>
            {mobileNumber?.length == 10 && !showMobileSendSection && !mobileNumberError ?
              <div className="lf-btn border-0 pt-3">
                <button onClick={handleMobileCheck}>
                  {language ? "मोबाइल नंबर सत्यापित करें" : "Verify Mobile number"}
                </button>
              </div>
              : null
            }
            {mobileNumberError && <p className="text-success">{language ? 'मोबाइल नंबर सत्यापित' : 'Mobile number verified'}</p>}
            {(errorsPhone.newPhoneNumber_hi || errorsPhone.newPhoneNumber_en) && <p className="text-danger">{language ? errorsPhone.newPhoneNumber_hi : errorsPhone.newPhoneNumber_en}</p>}
          </div>
          {showMobileSendSection ?

            <>
              <div className="fp-expire">
                <img src={clock} alt="clock" />
                {language
                  ? `ओटीपी ${formatTime(expiryMobile)} मिनट में समाप्त हो जाएगा`
                  : ` OTP will expire in  ${formatTime(expiryMobile)} minutes`}
              </div>
              <div className='validate-error'>
                {showMobileOtp && language
                  ? ` ओटीपी भेजा गया, नीचे ओटीपी दर्ज करें और अपना नंबर सत्यापित करें`
                  : ` Otp sent, Enter the otp below and verify your number`}
              </div>
              <div className="fp-otp">
                {otpMobile.map((data, index) => (
                  <input
                    key={index}
                    id={`otp-input-mobile-${index}`}
                    ref={el => (inputRefs.current[index] = el)}
                    type="text"
                    placeholder="*"
                    maxLength="1"
                    className="fp-otp-input"
                    value={otpMobile[index]}
                    onChange={e => handleChangeOtpMobile(e.target, index)}
                    onKeyDown={e => handleKeyDownOtpMobile(e, index)}
                  />
                ))}
              </div>
              {expiryMobile <= 1 ? (
                <p className="validate-error">{language ? "ओटीपी समाप्त हो गया!" : "OTP Expired!"}</p>
              ) : otpError ? (
                <p className="validate-error">{language ? otpError?.hi : otpError?.en}</p>
              ) : null}
              {/* Verify otp */}
              <div className="lf-btn border-0 pt-3">
                {expiryMobile <= 1 ?

                  <button onClick={handleSendMobileOtp}>
                    {language ? "ओटीपी पुनः भेजें" : "Resend OTP"}
                  </button>
                  :
                  <button onClick={handleVerifyOtp}>
                    {language ? 'ओटीपी सत्यापित करें' : 'Verify Otp'}
                  </button>
                }
              </div>
            </> : null}
          {showTable ? (
            <div class="row">
              <div class="reciept-table table-responsive mt-2">
                <table
                  class="table table mb-0 dataTable transaction_table"
                  id="receipt_table"
                  role="grid"
                >
                  <thead class="">
                    <tr role="row">
                      <th>{language ? "क्र.सं" : "S.No"}</th>
                      <th>{language ? "आवेदन संख्या" : "Application Number"}</th>
                      <th>{tableHeader}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>{applicationNumber}</td>
                      <td>{tableHeader == 'View' ? (
                        <p>{apiResponse.path ? (
                          <a
                            className="pointer-class"
                            onClick={(e) => {
                              e.preventDefault();
                              window.open(
                                baseUrl +
                                "/" +
                                apiResponse.path,
                                "_blank"
                              );
                            }}
                          >
                            {apiResponse?.certificate_number || 'Download'}
                          </a>
                        ) : (
                          "--"
                        )}</p>
                      ) : (
                        <>
                          {(() => {
                            switch (apiResponse?.status) {
                              case "0": return language ? "निष्क्रिय" : "Inactive";
                              case "1": return language ? "सक्रिय" : "Active";
                              case "2": return language ? "आवश्यक दस्तावेज़ लंबित" : "Required Document Pending";
                              case "3": return language ? "मसौदा" : "Draft";
                              case "4": return language ? "आवेदन स्वीकृत" : "Application Approved";
                              case "5": return language ? "आवेदन अस्वीकृत" : "Application Rejected";
                              case "6": return language ? "खत्म हो चुका" : "Expired";
                              case "7": return language ? "आवेदन समीक्षाधीन" : "Application Under Review";
                              case "8": return language ? "आवेदन प्रस्तुत" : "Application Submitted";
                              case "10": return language ? "वापस लिया गया" : "Withdrawn";
                              default: return language ? "अज्ञात" : "Unknown"; // in case of an undefined status
                            }
                          })()}
                        </>

                      )}</td>


                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ) : null
          }
        </div>

        <Modal.Footer>
          {mobileNumberError && !showTable ?
            <div className="modal-status-btn p-2 d-flex flex-row">
              <button

                className="mx-2"
                onClick={(e) => {
                  handleSubmit(e)
                }}
              >
                {language ? "जमा करना" : "Submit"}
              </button>
            </div>
            : null}
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default HomeBanner;
