import React, { useEffect, useState } from 'react'
// import { AdventuresTourismUnit, EntertainmentUnit, FoodDrinkUnit, HomeResidentialUnit, OtherUnit, TravelTradeUnit } from '../HomeIcons'
// import homebg from "../../../assets/images/home-cat-bg.png"
// import { CgClipboard } from "react-icons/cg";
import { Apiurl, GetLocalStorage, Imageurl, SetLocalStorageStringify } from '../../../Utils/Utils';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { useLanguage } from '../../LanguageProvider/LanguageProvider ';
import { BtnGray, BtnGreen } from '../HomeIcons';
import { getCategories, getSubCategories } from '../../../services/api/outer';
import MountainImage from "../../../../src/assets/images/mountains.jpg"

const HomeCategory = ({ filterCategoryData, filterSubCategoryData }) => {
  const { language, setLanguage } = useLanguage();
  const navigate = useNavigate();
  // token from local storage
  const getUserInfo = GetLocalStorage("userInfo");
  const [categoryData, setCategoryData] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState();
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [filteredSelectedCategoryId, setFilteredSelectedCategoryId] = useState([]);
  const [noSubCategoryText, setNoSubCategoryText] = useState("");

  // get Categories List
  const getCategoyList = () => {
    //getCategories().then(res => {
    if (filterCategoryData) {
      //const filterCategory = res?.filter((i) => i?.status !== "0")
      setCategoryData(filterCategoryData)
      setFilteredSelectedCategoryId(filterCategoryData?.[0])
      setSubCategoryData(filterSubCategoryData);
      setNoSubCategoryText(filterSubCategoryData && filterSubCategoryData.length > 0 ? "" : language ? "कोई उपश्रेणियाँ नहीं मिलीं" : "No Sub Categories found")
      setActiveIndex(0);
    }
    //}).catch(err => {

    //});
    // const option = {
    //   method: "GET",
    //   headers: {
    //     "access-control-allow-origin": "*",
    //     "content-type": "application/json",
    //   },
    //   url: `${Apiurl()}/categories`,
    // };
    // axios(option)
    //   .then((e) => {
    //     const filterCategory = e?.data?.data?.filter((i) => i?.status !== "0")
    //     setCategoryData(filterCategory)
    //     setSelectedCategoryId(filterCategory?.[0])
    //     setActiveIndex(0)
    //   })
    //   .catch((error) => {
    //   });
  };
  useEffect(() => {
    getCategoyList();
  }, [])
  // get sub category

  const getSubCategory = () => {
    const formData = new FormData();
    formData.append("category_id", selectedCategoryId?.id)
    getSubCategories(formData).then(res => {
      setNoSubCategoryText(res && res.length > 0 ? "" : language ? "कोई उपश्रेणियाँ नहीं मिलीं" : "No Sub Categories found")
      if (res) {
        setSubCategoryData(res);
      }
    }).catch(err => {
    });
    // try {
    //   const formData = new FormData();
    //   formData.append("category_id", selectedCategoryId?.id)
    //   const option = {
    //     method: "POST",
    //     headers: {
    //       'Content-Type': 'multipart/form-data',
    //       'access-control-allow-origin': '*',
    //     },
    //     url: `${Apiurl()}/get-subcategories-by-category-id `,
    //     data: formData
    //   };
    //   const response = await axios(option)
    //   setSubCategoryData(response?.data?.data)
    // } catch (error) {
    //   console.error(error);
    // }
  };
  useEffect(() => {
    if (selectedCategoryId) {
      setSubCategoryData([]);
      setFilteredSelectedCategoryId(null);
      getSubCategory();
    }
  }, [selectedCategoryId]);

  useEffect(() => {
    setNoSubCategoryText(subCategoryData && subCategoryData.length > 0 ? "" : language ? "कोई उपश्रेणियाँ नहीं मिलीं" : "No Sub Categories found")
  }, [language]);


  // Sub category click function
  const handleSubCatClick = (item) => {
    if (getUserInfo) {
      navigate("/dashboard");
    } else {
      SetLocalStorageStringify("subCategoryClick", item)
      navigate("/categoryInfo")
    }
  }
  
  const [activeIndex, setActiveIndex] = useState(null);
  const onTitleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };
  return (
    <section className="hcat-cont" style={{ backgroundImage : `url(${MountainImage})` }}>
      <div className="container px-5">
        <div className="text-center py-5 hcat-main">
          <p className='hex-p'>{language ? "श्रेणियाँ" : "Categories"}</p>
          <div className='hex-explore'>{language ? "उत्तराखंड में ट्रैवल ट्रेड श्रेणियों का अन्वेषण करें" : "Explore Travel Trade Categories in Uttarakhand"}</div>
          <div className='hex-explore-sub-heading'>{language ? "उत्तराखंड में पर्यटन क्षेत्रों की एक विस्तृत श्रृंखला का अन्वेषण करें। रोमांच से लेकर संस्कृति तक, अपनी आदर्श यात्रा अनुभव खोजें।" : "Discover a wide range of tourism sectors in Uttarakhand. From adventure to culture, find your ideal travel experiences"}</div>
         </div>
        <div className="hcat-second home-hcat-category pb-5">
          <div className="hcat-left p-4">
            {/* <ul className='hcat-ul'> */}
            {categoryData?.map((cat, index) => {
              return (
                <div key={index + 1} className='hcat-new-btn-cont' onClick={() => setSelectedCategoryId(cat)}>
                  {cat?.id === (selectedCategoryId?.id || filteredSelectedCategoryId?.id) ?
                    <BtnGreen /> : <BtnGray />}
                  <span className={cat?.id === (selectedCategoryId?.id || filteredSelectedCategoryId?.id) ? 'hcat-inner-gg' : 'hcat-inner-bb'}>
                    <img src={`${Imageurl()}/${cat?.category_image}`} alt="" /> <span style={(cat?.id === (selectedCategoryId?.id || filteredSelectedCategoryId?.id)) ? { color: 'white' , fontSize: cat?.font_size ? cat?.font_size + 'px' : '16px' } : { color: cat?.font_color, fontSize: cat?.font_size ? cat?.font_size + 'px' : '16px' }} > {language ? cat?.category_name_hi : cat?.category_name}</span>
                  </span>
                </div>
                // <> 
                //   <li onClick={() => setSelectedCategoryId(cat)}>
                //     <button className={cat?.id === selectedCategoryId?.id ? 'bg_image_green_home' : 'bg_image_white_home'}   > <img src={`${Imageurl()}/${cat?.category_image}`} alt="" /> <span>{language ? cat?.category_name_hi : cat?.category_name}</span></button>
                //   </li>
                // </>
              )
            })}
            {/* </ul> */}
          </div>
          <div className="hcat-right py-4">
            <div className={subCategoryData && subCategoryData.length > 0 ? "hcat-col-div" : ""}>
              {subCategoryData && subCategoryData.length > 0 ? subCategoryData?.map((sub , index) => {
                return (
                  <div key={index} className="hcat-col " onClick={() => handleSubCatClick(sub)}>
                    <div className='hcat-col-text' style={{fontSize : sub?.font_size + 'px', color: sub?.font_color}}>{language ? sub?.category_name_hi : sub?.category_name}</div>
                    <span className='hcat-span'> <span className='hcat-inner-span'><img src={`${Imageurl()}/${sub?.category_image}`} alt="" /></span></span>
                  </div>
                )
              }) : <div className='hex-explore no-explore-found'>{noSubCategoryText}</div>}
            </div>
          </div>
        </div>
        <div className="hcat-second-res pb-5">
          {
            categoryData?.map((cat, index) => {

              const active = index === activeIndex ? 'active' : '';
              return (
                <div key={index} className='hcat-res-bg'>
                  <div
                    className={`hcat-title ${active}`}
                    onClick={() => { onTitleClick(index); setSelectedCategoryId(cat) }}
                  >
                    <span><img src={`${Imageurl()}/${cat?.category_image}`} alt="" /> <span>{language ? cat.category_name_hi : cat.category_name}</span></span>
                    {active ? <BsChevronUp /> : <BsChevronDown />}
                  </div>
                  <div className={`hcat-content ${active}`}>
                    <div className="hcat-col-div">
                      {subCategoryData && subCategoryData.length > 0 ? subCategoryData?.map((sub , index) => {
                        return (
                          <div key={index}  className="hcat-col " onClick={() => handleSubCatClick(sub)}>
                            <div className='hcat-col-text'>    {language ? sub?.category_name_hi : sub?.category_name}

                              <span className='hcat-inner-span'><img src={`${Imageurl()}/${sub?.category_image}`} alt="" /></span>
                            </div>
                          </div>
                        )
                      }) : <div className='py-3 hex-title text-center'>{noSubCategoryText}</div>}
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
      {/* <div className='hsbs-mountain'></div> */}
    </section >
  )
}
export default HomeCategory