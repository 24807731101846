/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react'
import { BsChevronLeft } from 'react-icons/bs'
import { useLocation, useNavigate } from 'react-router-dom'
import { Message } from '../../../components/message'
import { updateBannerLink } from '../../../services/api/admin'
import { checkPattern, customStylesAcc, selectTheme } from '../../../Utils/common'
import { GetLocalStorage } from '../../../Utils/Utils'
import "../Admin.css"
import AdminDashboard from '../AdminDashboard/AdminDashboard'
import { UploadIcon } from '../AdminIcon'
import Select from 'react-select';

const BannerLinksUpdate = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const navigate = useNavigate()
    const location = useLocation()
    const [apiMessage, setApiMessage] = useState({ type: '', message: '' });
    const token = GetLocalStorage("userInfo")
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState();

    const [nameHi, setNameHi] = useState("");
    const [nameHiError, setNameHiError] = useState();

    const [btnType, setBtnType] = useState("");
    const [btnTypeError, setBtnTypeError] = useState();

    const [image, setImage] = useState();
    const [imageError, setImageError] = useState();

    const [editData, setEditData] = useState()
    const [file, setFile] = useState();

    const [url, setUrl] = useState();
    const [urlError, setUrlError] = useState();

    useEffect(() => {
        editBannerLinks(location?.state)
    }, [location?.state])

    const handleKeyUpLoginDetails = (item) => {
        validateForm(item);
    }

    const typeList = [
        { label: 'Download', value: '0' },
        { label: 'View', value: '1' }
    ]

    const handleInputChange = async (e) => {
        // const { name, value } = e?.target;
        const name = e?.target?.name
        const value = e?.target?.value

        const cursorPosition = e.target.selectionStart;
        // Check for "nameEn" field
        if (name === "nameEn") {

            if (await checkPattern(value)) {
                setNameError("")
                setName(value);
            } else {
                e.preventDefault()
                return;
            }
        }
        if (name === "url") {
            if (await checkPattern(value)) {
                setUrlError("")
                setUrl(value);
            } else {
                e.preventDefault()
                return;
            }
        }
        // Check for "nameHi" field
        if (name === "nameHi") {
            if (await checkPattern(value)) {
                setNameHiError("")
                setNameHi(value);
            } else {
                e.preventDefault()
                return;
            }
        }
        setTimeout(() => {
            e.target.setSelectionRange(cursorPosition, cursorPosition);
        }, 0);
    };



    //validations
    const validateForm = (item) => {
        let isValid = true;

        if (item === "name" || item === "both") {
            if (!name || name.trim() === "") {
                setNameError("Please Enter English Name");
                isValid = false;
            } else {
                setNameError("");
            }
        }
        if (item === "nameHi" || item === "both") {
            if (!nameHi || nameHi.trim() === "") {
                setNameHiError("Please Enter Hindi Name");
                isValid = false;
            } else {
                setNameHiError("");
            }
        }
       
        if (item === "url" || item === "both") {
            if (!image) {
                console.log('imageErr', "Please Add Url")
                setUrlError("Please Add URL");
                isValid = false;
            } else {
                setUrlError("");
            }
        }
        return isValid;
    };

    //edit category
    const editBannerLinks = (item) => {
        setEditData(item)
        setBtnType(item?.button_type)
        setName(item?.name);
        setNameHi(item?.name_hi);
        setImage(item?.icon)
        setUrl(item?.url)
        window.scrollTo(0, 0);
    }
    //update category
    const updateBannerLinks = async () => {
        if (!validateForm("both")) {
            return
        }
         if(!btnType){
             setBtnTypeError("Please select the button type")
             return;    
         }
        try {
            const formData = new FormData();
            formData.append("banner_link_id", editData?.id)
            formData.append("name", name)
            formData.append("button_type", btnType)
            formData.append("name_hi", nameHi)
            formData.append("icon", image)
            const response = await updateBannerLink(formData)
            if (response.status == 200) {
                navigate("/admin/banner-link", { state: { message: response.message } })
            } else {
                setApiMessage({ type: 'error', message: response.message });
            }

        } catch (error) {
            console.error(error);
            setApiMessage({ type: 'error', message: error?.message });
        }
    }

    const handleFileChange = (e) => {
        const file = e?.target?.files?.[0];
        if (file) {
            if (file.type.startsWith('image/')) {
                setImage(file);
                setFile(URL.createObjectURL(e.target.files[0]));
                setImageError('');
            } else {
                setImage(null);
                setImageError('Please Upload Image.');
                setFile('')
                e.target.value = '';
            }
        }
    };
    return (
        <AdminDashboard>
            <div className='aod-outer'>
                {apiMessage && (<Message apiMessage={apiMessage} setApiMessage={setApiMessage}></Message>)}
                <div className='ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb'>
                    <div>
                        <b onClick={() => navigate("/admin/")}>Home  /</b><span> Banner Link Update</span>
                    </div>
                </div>
                <div className="aod-inner">
                    <div className={editData?.id ? "aod-bottom" : "aod-bottom"}>
                        <div className="aod-head text-center adm-head"><button onClick={() => navigate("/admin/banner-link")}><BsChevronLeft /> Back</button>Update Banner Link</div>
                        <div className="row py-4">
                            <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>

                                    <label className='mb-1 w-100'>
                                        English Name <span className="text-danger required_f">*</span>
                                    </label>
                                    <input
                                        className='w-100'
                                        maxLength={100}
                                        name='nameEn'
                                        placeholder='Enter English Name'
                                        type="text"
                                        onChange={handleInputChange} // Use onChange for real-time validation
                                        value={name}
                                    />
                                    {/* <input className='w-100' maxLength={100} name='name' placeholder='Enter Category English Name' type="text" onChange={(e) => handleKeyUpLoginDetails(e)} value={name} /> */}

                                    {nameError && <p className='validate-error'>{nameError}</p>}

                                </div>
                            </div>
                            <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>

                                    <label className='mb-1 w-100'>
                                        Hindi Name <span className="text-danger required_f">*</span>
                                    </label>
                                    <input maxLength={100} className='w-100' placeholder='Enter Hindi Name' type="text" name='nameHi' value={nameHi} onChange={handleInputChange} />

                                    {nameHiError && <p className='validate-error'>{nameHiError}</p>}

                                </div>
                            </div>
                            <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>

                                    <label className='mb-1 w-100'>
                                        Button Type<span className="text-danger required_f">*</span>
                                    </label>
                                    {/* <input maxLength={100} className='w-100' placeholder='Enter Button Type' type="text" name='btnType' value={btnType} onChange={handleInputChange} /> */}
                                    <Select
                                        styles={customStylesAcc}
                                        theme={selectTheme}
                                        components={{
                                            IndicatorSeparator: () => null
                                        }}
                                        isDisabled
                                        className='menu_open_select'
                                        classNamePrefix='ad-menu_open_select_class'
                                        aria-label="Default select example"
                                        value={typeList?.find(x => x.value == btnType)}
                                        options={typeList}
                                        onChange={(selected) => { setBtnType(selected?.value); setBtnTypeError(''); }}
                                        placeholder="Select Button Type"
                                        onMenuOpen={() => {
                                            if (name === "btnType") {
                                                if (!btnType) {
                                                    setBtnTypeError("Please select the button type")
                                                } else {
                                                    setBtnTypeError("")
                                                    return;
                                                }
                                            }
                                        }}
                                        onMenuClose={() => {
                                            if (name === "btnType") {
                                                if (!btnType) {
                                                    setBtnTypeError("Please select the button type")
                                                } else {
                                                    setBtnTypeError("")
                                                    return;
                                                }
                                            }
                                        }}
                                    />

                                    {btnTypeError && <p className='validate-error'>{btnTypeError}</p>}

                                </div>
                            </div>
                            <div className="col-md-4 aod-resp-tab d-flex  align-items-top">
                                <div className='suf-input-box aod-form-input mb-2 w-100'>
                                    <label className='mb-1 w-100'>
                                        Image <span className="text-danger required_f">*</span>
                                    </label>
                                    <input type="file" name="partsuploadfile" id="profileimg" className="w-100 ad-cat-input-field-image" accept="image/*" onKeyUp={() => handleKeyUpLoginDetails("img")} onChange={(e) => handleFileChange(e)} />
                                    <label htmlFor="profileimg" className="ad-cat-input-upload-image p-0">
                                        <p className="ad-cat-text-upload-image">
                                            <span><UploadIcon /></span>  {
                                                typeof image === 'string' ? (
                                                    <p className="mb-0">{image.split('\\').pop().split('/').pop()}</p>
                                                ) : (
                                                    image?.name ? (
                                                        <p className="mb-0 fw-semibold">{image?.name}</p>
                                                    ) : (
                                                        "Choose Category Image"
                                                    )
                                                )
                                            }
                                        </p>
                                    </label>
                                    {
                                        imageError ? <p className="validate-error">{imageError}</p> : ""


                                    }
                                </div>
                                {file &&
                                    <div id="img_preview" className="pt-4">
                                        <img className="ms-1 uplaoed_img_preview" src={file} alt="" />
                                    </div>

                                }
                            </div>
                        </div>
                        <div className="row ad-cat-row">
                            <div className=" update-btns-block">
                                <button onClick={() => updateBannerLinks()}>
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AdminDashboard>
    )
}

export default BannerLinksUpdate
