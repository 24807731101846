import React, { useEffect, useState } from "react";
import AdminDashboard from "../AdminDashboard/AdminDashboard";
import { useLocation, useNavigate } from "react-router-dom";
import { Message } from "../../../components/message";
import { BsChevronLeft, BsEye, BsEyeSlash } from "react-icons/bs";
import { changePasswordAPI } from "../../../services/api/user";
import { changeUserPassword } from "../../../services/api/admin";

const ChangeUserPassword = () => {
  const navigate = useNavigate();
  const location = useLocation()
  const [apiMessage, setApiMessage] = useState({ type: "", message: "" });
  const [userId, setUserId] = useState("");
  // State for password fields and errors
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [adminNewPasswordVisible, setAdminNewPasswordVisible] = useState(false);
  const [adminConfirmPasswordVisible, setAdminCofirmPasswordVisible] =
    useState(false);

  const validatePasswords = () => {
    const errors = {};
    // Validation for New Password
    if (!newPassword) {
      errors.newPassword_en = "New password is required";
      errors.newPassword_hi = "नया पासवर्ड आवश्यक है";
    } else if (newPassword.length < 6) {
      errors.newPassword_en = "Password must be at least 6 characters";
      errors.newPassword_hi = "पासवर्ड कम से कम 6 वर्णों  होना चाहिए";
    } else if (newPassword.length > 39) {
      errors.newPassword_en = "Password cannot exceed 39 characters";
      errors.newPassword_hi = "पासवर्ड अधिकतम 39 वर्णों का होना चाहिए";
    } else {
      // Additional password strength requirements
      if (!/[a-z]/.test(newPassword)) {
        errors.newPassword_en =
          "Password must contain at least one lowercase letter (a-z)";
        errors.newPassword_hi =
          "पासवर्ड में कम से कम एक लोअरकेस अक्षर (a-z) होना चाहिए";
      }
      if (!/[A-Z]/.test(newPassword)) {
        errors.newPassword_en =
          "Password must contain at least one uppercase letter (A-Z)";
        errors.newPassword_hi =
          "पासवर्ड में कम से कम एक बड़ा अक्षर (A-Z) होना चाहिए";
      }
      if (!/[0-9]/.test(newPassword)) {
        errors.newPassword_en =
          "Password must contain at least one number (0-9)";
        errors.newPassword_hi = "पासवर्ड में कम से कम एक नंबर होना चाहिए (0-9)";
      }
      if (!/[@$!%*?&]/.test(newPassword)) {
        errors.newPassword_en =
          "Password must contain at least one special character (@$!%*?&)";
        errors.newPassword_hi =
          "पासवर्ड में कम से कम एक विशेष अक्षर (@$!%*?&) होना चाहिए";
      }
    }
    // Validation for Confirm Password
    if (!confirmPassword) {
      errors.confirmPasswor_en = "Please confirm your new password";
      errors.confirmPassword_hi = "कृपया अपने नये पासवर्ड की पुष्टि करें";
    } else if (newPassword !== confirmPassword) {
      errors.newPassword_en = "Passwords do not match";
      errors.newPassword_hi = "सांकेतिक शब्द मेल नहीं खाते";
    }
    // Set error messages in English and Hindi
    if (Object.keys(errors).length > 0) {
      setErrors({
        ...errors,
        hindi: {
          newPassword: {
            required: "नया पासवर्ड आवश्यक है",
            length: "पासवर्ड कम से कम 6 और अधिकतम 39 वर्णों का होना चाहिए",
            lowercase: "पासवर्ड में कम से कम एक लोअरकेस अक्षर होना चाहिए",
            uppercase: "पासवर्ड में कम से कम एक बड़ा (अपरकेस) अक्षर होना चाहिए",
            numeric: "पासवर्ड में कम से कम एक नंबर होना चाहिए [0-9]",
            special: "पासवर्ड में कम से कम एक विशेष वर्ण होना चाहिए (@$!%*?&)",
            different: "नया पासवर्ड पुराने पासवर्ड से भिन्न होना चाहिए",
          },
          confirmPassword: {
            required: "कृपया अपना नया पासवर्ड पुष्टि करें",
            match: "पासवर्ड मेल नहीं खाते",
          },
        },
      });
    } else {
      setErrors(errors);
    }

    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validatePasswords()) return;

    try {
      const formData = new FormData();
      //formData.append("old_password", oldPassword);
      formData.append("user_id", location?.state?.id);
      formData.append("password", newPassword);
      formData.append("password_confirmation", confirmPassword);

      const response = await changeUserPassword(formData);

      // Assuming the API response structure as provided
      if (response?.status === 200) {
        setApiMessage({ type: "success", message: response.message });
        if(location?.state?.roles == "admin"){
          navigate("/admin/admin-users", { state: { message: response.message } })
        } else {
          navigate("/admin/user", { state: { message: response.message } })
        }
      } else if (response?.data) {
        // Handle validation errors from the API response
        const errorMessages = [];
        if (response.data.password && response.data.password.length > 0) {
          errorMessages.push(response.data.password[0]);
        }
        if (
          response.data.password_confirmation &&
          response.data.password_confirmation.length > 0
        ) {
          errorMessages.push(response.data.password_confirmation[0]);
        }

        setApiMessage({
          type: "error",
          message: errorMessages.join(", ") || response.message,
        });
      } else if (response?.message) {
        setApiMessage({
          type: "error",
          message: response.message,
        });
      }
    } catch (err) {
      setApiMessage({ type: "error", message: err.message });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <AdminDashboard>
      <div className="aod-outer">
        {apiMessage && (
          <Message
            apiMessage={apiMessage}
            setApiMessage={setApiMessage}
          ></Message>
        )}
        <div className="ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb">
          <div>
            <b onClick={() => navigate("/admin/")}>Home /</b>
            <span> Admin Users/ Change Password</span>
          </div>
        </div>
        <div className="aod-inner">
          <div className={userId ? "aod-bottom" : "aod-bottom"}>
            <div className="aod-head text-center adm-head">
              <button onClick={() => {
                if(location?.state?.roles == "admin"){
                  navigate("/admin/admin-users")
                } else {
                  navigate("/admin/user")
                }
              }}>
                <BsChevronLeft /> Back
              </button>
              Change Password
            </div>
            <div className="row py-4">
              <div className="col-md-4">
                <div className="mb-3 f_fields pwd-fields">
                  <label className="form-label">
                    <span className="en-label">New Password</span>
                  </label>
                  <input
                    type={adminNewPasswordVisible ? "text" : "password"}
                    className="form-control"
                    placeholder="Enter New Password"
                    value={newPassword}
                    onChange={(e) => {
                      setNewPassword(e.target.value);
                      errors.newPassword_en = "";
                      errors.newPassword_hi = "";
                    }}
                  />
                  {adminNewPasswordVisible ? (
                    <BsEye
                      onClick={() =>
                        setAdminNewPasswordVisible(!adminNewPasswordVisible)
                      }
                    />
                  ) : (
                    <BsEyeSlash
                      onClick={() =>
                        setAdminNewPasswordVisible(!adminNewPasswordVisible)
                      }
                    />
                  )}
                  {(errors.newPassword_en || errors.newPassword_hi) && (
                    <small className="text-danger">
                      {errors.newPassword_en}
                    </small>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3 f_fields pwd-fields">
                  <label className="form-label">
                    <span className="en-label">Confirm Password</span>
                  </label>
                  <input
                    type={adminConfirmPasswordVisible ? "text" : "password"}
                    className="form-control"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                      errors.confirmPassword_en = "";
                      errors.confirmPassword_hi = "";
                    }}
                  />
                  {adminConfirmPasswordVisible ? (
                    <BsEye
                      onClick={() =>
                        setAdminCofirmPasswordVisible(
                          !adminConfirmPasswordVisible
                        )
                      }
                    />
                  ) : (
                    <BsEyeSlash
                      onClick={() =>
                        setAdminCofirmPasswordVisible(
                          !adminConfirmPasswordVisible
                        )
                      }
                    />
                  )}
                  {(errors.confirmPassword_en || errors.confirmPassword_hi) && (
                    <small className="text-danger">
                      {errors.confirmPasswor_en}
                    </small>
                  )}
                </div>
              </div>
              <div className="row ad-cat-row">
                <div className=" update-btns-block mt-3">
                  <button onClick={(e) => handleSubmit(e)}>Update</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminDashboard>
  );
};

export default ChangeUserPassword;
