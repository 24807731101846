import React, { useEffect, useState } from "react";
import "./DashboardHeader.css";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { DashboardBellIcon, DashboardToogleIcon } from "../DashboardIcons";
import {
  Baseurl,
  ClearLocalStorage,
  GetLocalStorageParsed,
} from "../../../Utils/Utils";
import { useLanguage } from "../../LanguageProvider/LanguageProvider ";
import {
  userLogout,
  notificationDataUser,
  notificationDataReadUser,
  latestNotificationCountUser,
} from "../../../services/api/user";
import { FaUserEdit, FaMobileAlt, FaKey, FaSignOutAlt } from "react-icons/fa";
import { getHomepageLogo } from "../../../services/api/admin";
import { capitalizeFirstLetter, formatDateTimeWithSlash, formatDateWithSlash } from "../../../Utils/common";

const DashboardHeader = ({
  sidebarCollapse,
  setSideBarCollapse,
  userDetail,
}) => {
  const { language, setLanguage } = useLanguage();
  const [notificationDetails, setNotificationDetails] = useState([]);
  const [unreadCount, setUnReadCount] = useState(0);
  const [imagePath, setImagePath] = useState(
    "https://utdbtraveltstg.dilonline.in/static/media/uttrLogo.5f55d1f48deb13a04f1a.png"
  );

  const WebsiteLogo = async () => {
    try {
      const usersListData = await getHomepageLogo();
      if (usersListData.status === 200) {
        setImagePath(Baseurl() + "/" + usersListData?.data?.path);
      } else {
        setImagePath(
          "https://utdbtraveltstg.dilonline.in/static/media/uttrLogo.5f55d1f48deb13a04f1a.png"
        );
      }
    } catch (err) {
      setImagePath(
        "https://utdbtraveltstg.dilonline.in/static/media/uttrLogo.5f55d1f48deb13a04f1a.png"
      );
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    WebsiteLogo();
    unReadNotificationCount()
    const fetchNotification = async () => {
      try {
        getNotifications();
      } catch (error) {
        console.error("Error fetching data:", error);
        setNotificationDetails([]);
      }
    };
    fetchNotification();
  }, []);

  const navigate = useNavigate();
  const handleLogout = async (e) => {
    try {
      const logout = await userLogout();
      if (logout) {
        navigate("/");
        ClearLocalStorage();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleBellClick = (e) => {
    e.preventDefault();
    markAllAsRead();
  };

  const markAllAsRead = async () => {
    try {
      const payload = new FormData();
      payload.append("user_id", userDetail?.id);
      // payload.append('district_id', userDetail?.district)
      // payload.append("user_type", 1);
      const data = await notificationDataReadUser(payload);
    } catch (error) {
      console.error("Failed to mark notifications as read:", error);
    }
  };

  const handleViewAllClick = (e) => {
    e.preventDefault();
    markAllAsRead();
    navigate("/notification", { state: "user" });
  };

  const getNotifications = async () => {
    try {
     
      const payload = new FormData();
      payload.append("user_id", userDetail?.id);
      // payload.append('district_id', userDetail?.district)
      // payload.append("user_type", 1);
      const response = await notificationDataUser(payload);
      if (response?.status == 200) {
        setNotificationDetails(response.data);
      } else {
        console.error("Failed to fetch updated notifications.");
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
      setNotificationDetails([]);
    }
  };

  const unReadNotificationCount = async()=>{
    try{
      const payload = new FormData();
      payload.append("user_id", userDetail?.id);
      // payload.append('district_id', userDetail?.district)
      // payload.append("user_type", 1);
      const count = await latestNotificationCountUser(payload)
      if(count?.status == 200){
        setUnReadCount(count?.data?.latest);
      }
    }catch(err){
      console.log(err?.message)
    }
  }

    const notificationString = (data) => {
        try {
            if (typeof JSON.parse(data) == "object") {  
                const str = JSON.parse(data);
                // console.log(str)
                return `${capitalizeFirstLetter(str?.role || "")} ${str?.message || ""} ${str?.AdminRemark ? str?.AdminRemark : ""
                    } ${str?.pendingDocs && str?.pendingDocs != '[]' ? str?.pendingDocs?.replace(/\[/g, '(')?.replace(/\]/g, ')')?.replace(/"/g, '')?.replace(/,/g, ', ') : ''}`;
            }
        } catch (error) {
            // console.log(error)
            // If JSON.parse fails, it's a normal string
            return data;
        }
    }

  return (
    <div>
      <div className="dh-cont fixed-top">
        <Navbar className="bg-body hd-main">
          <div className="m-0 w-100 d-flex main-dash-head px-4">
            <div className="header-side-sec d-flex justify-content-between align-items-center">
              <div
                onClick={() => navigate("/dashboard")}
                className="navbar-brand d-flex"
              >
                <img className="dh-logo" src={`${imagePath}`} />
              </div>
              <div onClick={() => setSideBarCollapse(!sidebarCollapse)}>
                <DashboardToogleIcon />
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-end gap-3 main-dash-second-col ms-auto">
              <div className="main-dash-second-inner">
               <div className="d-flex align-items-center">
                  <div className="hdt-language-dash d-flex mx-3">
                    <label className="form-check-label mx-2 " htmlFor="english">
                      English
                    </label>
                    <div className="hdt-toggle-block-dash form-check  form-switch">
                      <input
                        className="form-check-input lightgreen flexSwitchCheckChecked"
                        checked={language}
                        type="checkbox"
                        role="switch"
                        id="changeLang"
                        value="in"
                        onChange={() => {
                          setLanguage(!language);
                        }}
                      />
                    </div>
                    <label className="form-check-label mx-1 " htmlFor="hindi">
                      हिंदी
                    </label>
                  </div>
                  <div className="nav-item dropdown notification-wrap">
                    <a
                      className="nav-link nav-icon"
                      href="#"
                      onClick={handleBellClick}
                      data-bs-toggle="dropdown"
                    >
                      <DashboardBellIcon />
                      {unreadCount > 0 && (
                        <span className="badge-number">{unreadCount}</span>
                      )}
                    </a>

                    <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications">
                      {notificationDetails.length > 0 ? (
                        <>
                          <li className="dropdown-header">
                            <h6 className="username-header">
                              {language ? "अधिसूचना" : "Notification"}
                            </h6>
                          </li>
                          <li>
                            <hr className="dropdown-divider" />
                          </li>
                          {notificationDetails?.length > 0 ? (
                            notificationDetails?.sort((a, b) => new Date(b?.created_at) - new Date(a?.created_at))?.slice(0, 3).map((item, index) => (
                              <li
                                key={index}
                                className={
                                  item.seen
                                    ? "notification-read"
                                    : "notification-unread"
                                }
                              >
                                <a
                                  className="dropdown-item d-flex align-items-center"
                                  to="#"
                                  onClick={handleViewAllClick}
                                >
                                  <div className="notification-detail">
                                    <p className="notification-text">
                                      {language ? notificationString(item?.remark_hi) : notificationString(item?.remark_en)}
                                    </p>
                                    <p className="notification-time">
                                      <svg
                                        aria-hidden="true"
                                        focusable="false"
                                        data-prefix="far"
                                        data-icon="clock"
                                        class="svg-inline--fa fa-clock me-2   txtColor info wh13 mb3"
                                        role="img"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 512 512"
                                      >
                                        <path
                                          fill="currentColor"
                                          d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120l0 136c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2 280 120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"
                                        ></path>
                                      </svg>

                                      {/* <FontAwesomeIcon icon={faClock} className="me-2   txtColor info wh13 mb3" /> */}

                                    {formatDateTimeWithSlash(item?.created_at)}
                                  </p>
                                </div>
                              </a>
                            </li>
                          ))
                        ) : (
                          <li>No notification</li>
                        )}
                      </>
                    ) : (
                      <li className="dropdown-item"></li>
                    )}
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    {/* {notificationDetails.length > 3 && ( */}
                    <li className="dropdown-footer">
                      <a
                        className="dropdown-item d-flex align-items-center justify-content-center"
                        onClick={handleViewAllClick}
                      >
                        {language ? "सभी अधिसूचनाएं दिखाएं" : "Show all Notifications"}
                      </a>
                    </li>
                    {/* )} */}
                    </ul>
                  </div>
               </div>

                <NavDropdown
                  title={
                    <div>
                      <span>{`${language ? "नमस्ते" : "Hi"},  ${
                        userDetail?.name
                      }`}</span>
                      <br />
                      {userDetail?.unique_id && (
                        <>
                          <small>
                            <strong>{userDetail.unique_id}</strong>
                          </small>
                        </>
                      )}
                    </div>
                  }
                  id="basic-nav-dropdown"
                  className="dh-nav-dropdown user-dh-nav-dropdown user-nav-dropdown"
                >
                  <NavDropdown.Item onClick={() => navigate("/edit-profile")}>
                    <FaUserEdit />{" "}
                    {language ? "प्रोफ़ाइल संपादित करें" : "Edit Profile"}
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={() => navigate("/change-request")}>
                    <FaMobileAlt />{" "}
                    {language
                      ? "मोबाइल/ईमेल परिवर्तन अनुरोध"
                      : "Mobile/Email Change Request"}
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item
                    onClick={() => navigate("/change-password")}
                  >
                    <FaKey /> {language ? "पासवर्ड बदलें" : "Change Password"}
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={handleLogout}>
                    <FaSignOutAlt /> {language ? "लॉगआउट" : "Logout"}
                  </NavDropdown.Item>
                </NavDropdown>
              </div>
            </div>
          </div>
        </Navbar>
      </div>
    </div>
  );
};

export default DashboardHeader;
