import React, { useEffect, useState } from "react";
import { useLanguage } from "../LanguageProvider/LanguageProvider ";
import Dashboard from "../../pages/Dashboard/Dashboard";
import "../User/User.css";
import { updateUserEmailMobileNumberAPI, getUserEmailMobileNumberAPI, sendNotificationDataAdmin } from "../../services/api/user";
import { Message } from '../../components/message';
import { GetLocalStorageParsed } from "../../Utils/Utils";
import { useNavigate } from "react-router-dom";
import { checkEmailMobile } from "../../services/api/auth";

const ChangeRequest = () => {
  const navigate = useNavigate();
  const { language } = useLanguage();
  const userDetail = GetLocalStorageParsed("userDetail");
  const [oldEmail, setOldEmail] = useState(userDetail.email || "");
  const [oldPhoneNumber, setOldPhoneNumber] = useState(userDetail.mobile_number || "");
  const [newEmail, setNewEmail] = useState("");
  const [newPhoneNumber, setNewPhoneNumber] = useState("");
  const [errors, setErrors] = useState({});
  const [errorsPhone, setErrorsPhone] = useState({});
  const [errorsEmail, setErrorsEmail] = useState({});
  const [apiMessage, setApiMessage] = useState({ type: '', message: '' });
  const [userId, setUserId] = useState("");
  const [userDetails, setUserDetail] = useState({});
  const [status, setStatus] = useState(null);
  const [isNewEmailDisabled, setIsNewEmailDisabled] = useState(false);
  const [isNewPhoneDisabled, setIsNewPhoneDisabled] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    setUserId(userDetail.id || "");
  }, []);

  useEffect(() => { 
    const loadData = async () => {
      try {
        const formData = new FormData ()
        formData.append("user_id", userDetail?.id)
        const response = await getUserEmailMobileNumberAPI(formData);
        const emailChangeReq = response?.data?.sort((a, b) => Number(new Date(a.created_at)) - Number(new Date(b.created_at)))?.filter((x) => x.new_email != null)
        const phoneChangeReq = response?.data?.sort((a, b) => Number(new Date(a.created_at)) - Number(new Date(b.created_at)))?.filter((x) => x.new_mobile != null)
        const newEmailId = emailChangeReq[emailChangeReq.length - 1];
        const newMobileNumber = phoneChangeReq[phoneChangeReq.length - 1];
        console.log(newEmailId, newMobileNumber)
        if (newEmailId !=undefined ) {
          if (newEmailId?.status == '0') {
            setIsNewEmailDisabled(true)
            setNewEmail(newEmailId?.new_email);
          } else if (newEmailId?.status == '1') {
            setIsNewEmailDisabled(false)
            setOldEmail(newEmailId.new_email)
          }else{
            setIsNewEmailDisabled(false)
          }
        }

        if (newMobileNumber != undefined) {
          if (newMobileNumber?.status == '0') {
            setIsNewPhoneDisabled(true)
            setNewPhoneNumber(newMobileNumber?.new_mobile);
          } else if (newMobileNumber?.status == '1') {
            setIsNewPhoneDisabled(false)
            setOldPhoneNumber(newMobileNumber.new_mobile)
          } else {
            setIsNewPhoneDisabled(false)
          }
        }

        if (isNewEmailDisabled && isNewPhoneDisabled) {
          setIsSubmitDisabled(true)
        } else {
          setIsSubmitDisabled(false)
        }


      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    loadData();
  }, []);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^[6-9]\d{9}$/;

  const validateEmail = async() => {
    if (newEmail && !emailRegex.test(newEmail)) {
      setErrorsEmail((prevErrors) => ({ ...prevErrors, newEmail_en: "Invalid email format.", newEmail_hi: "अमान्य ईमेल प्रारूप." }));
      return false;
    } else {
      if (emailRegex.test(newEmail)){
        const checkInput = await handleEmailMobileCheck("email", newEmail)
        if (!checkInput) {
          setErrorsEmail((prevErrors) => ({ ...prevErrors, newEmail_en: "Email already exist.", newEmail_hi: "ईमेल पहले से मौजूद है|" }));
          return false;
        }
      }
    }
    setErrorsEmail((prevErrors) => {
      const { newEmail_en, newEmail_hi, ...rest } = prevErrors;
      return rest;
    });
    return true;
  };

  const validatePhoneNumber = async() => {
    if (newPhoneNumber && !phoneRegex.test(newPhoneNumber)) {
      setErrorsPhone((prevErrors) => ({ ...prevErrors, newPhoneNumber_en: "Phone number must be 10 digits long.", newPhoneNumber_hi: "फ़ोन नंबर 10 अंक लंबा होना चाहिए." }));
      return false;
    }else{
     if(newPhoneNumber.length == 10){
       const checkInput = await handleEmailMobileCheck("mobile_number", newPhoneNumber)
       if (!checkInput) {
         setErrorsPhone((prevErrors) => ({ ...prevErrors, newPhoneNumber_en: "Phone number already exist.", newPhoneNumber_hi: "फ़ोन नंबर पहले से मौजूद है|" }));
         return false;
       }
     }
    }
    setErrorsPhone((prevErrors) => {
      const { newPhoneNumber_en, newPhoneNumber_hi, ...rest } = prevErrors;
      return rest;
    });
    return true;
  };

  const validateAtLeastOneField = () => {
    if (!newEmail && !newPhoneNumber) {
      setErrors({ form_en: "Please enter at least one of the new email or new phone number.", form_hi: 'कृपया नया ईमेल या नया फ़ोन नंबर में से कम से कम एक दर्ज करें।' });
      return false;
    }
    setErrors((prevErrors) => {
      const { form, ...rest } = prevErrors;
      return rest;
    });
    return true;
  };

  const handleEmailMobileCheck = async (payloadKey, payloadValue) => {
    try {
      const formData = new FormData();
      formData.append(payloadKey, payloadValue);
      const res =  await checkEmailMobile(formData)
        if(res.status == 200){
        return false
        }else{
         return true
        }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isNewPhoneDisabled && newEmail == '' ){
      setErrorsEmail((prevErrors) => ({ ...prevErrors, newEmail_en: "Email is required", newEmail_hi: "ईमेल अनिवार्य है" }));
      return;
    }
    if (isNewEmailDisabled && newPhoneNumber == '') {
      setErrorsPhone((prevErrors) => ({ ...prevErrors, newPhoneNumber_en: "Phone number is required", newPhoneNumber_hi: "फ़ोन नंबर अनिवार्य है" }));
      return;

    }
    const isAtLeastOneFieldFilled = validateAtLeastOneField();
    const isEmailValid = await validateEmail();
    const isPhoneValid = await validatePhoneNumber();
    
    if (isAtLeastOneFieldFilled && isEmailValid && isPhoneValid) {
      let requestType = 0;
      if (!isNewEmailDisabled && !isNewPhoneDisabled) {
        if (newEmail != '' && newPhoneNumber != '' ) {
          requestType = 3;
        } else if (newEmail == '' && newPhoneNumber != '' ) {
          requestType = 2;
        } else if (newEmail != '' && newPhoneNumber == '' ) {
          requestType = 1;
        } else {
          return;
        }
      } else {
        if (!isNewEmailDisabled && isNewPhoneDisabled) {
          if (newEmail != '') {
            requestType = 1;
          }
        } else if (isNewEmailDisabled && !isNewPhoneDisabled ) {
          if (newPhoneNumber != '') {
            requestType = 2;
          }
        } else {
          return;
        }
      }

      const payload = {
        old_email: oldEmail,
        old_mobile_number: oldPhoneNumber,
        new_email: '',
        new_mobile: '',
        user_id: userId,
        type: requestType,
      };
      if (requestType == '3'){
        payload.new_email= newEmail
        payload.new_mobile = newPhoneNumber
      } else if (requestType == '1'){
        payload.new_email = newEmail
        payload.new_mobile = ''
      } else if (requestType == '2') {
        payload.new_email = ''
        payload.new_mobile = newPhoneNumber
      }
      try {
        const remarkEn = {
          role: userDetail?.name,
          message: ` created a request for change phone number or email. Confirm the new contact information `,
        }
        const response = await updateUserEmailMobileNumberAPI(payload);
        if (response?.status === 200) {
          // navigate("/dashboard", { state: response?.message })
          try {
            const notifcation = new FormData()
            notifcation.append("remark_en", JSON.stringify(remarkEn));
            notifcation.append("remark_hi", 'परिवर्तन अनुरोध सबमिट किया गया');
            notifcation.append('district_id', userDetail?.district)
            const notificationSend = await sendNotificationDataAdmin(notifcation)
          } catch (err) {
            console.error("Error submitting the form:", err);
          } finally {
            setApiMessage({ type: "success", message: response?.message})
            navigate("/dashboard", { state: response?.message })
          }

        } else if (response?.data) {
          const errorMessages = [];
          for (const field in response.data) {
            if (response.data[field] && response.data[field].length > 0) {
              errorMessages.push(response.data[field][0]);
            }
          }
          setApiMessage({ type: "error", message: errorMessages.join(", ") || response.message });
        }
      } catch (error) {
        setApiMessage({ type: "error", message: error.message });
      }
    }else{
      return;
    }
  };



  return (
    <div>
      <Dashboard
        first={language ? "होम" : "Home"}
        second={language ? "मोबाइल/ईमेल परिवर्तन अनुरोध" : "Mobile/Email Change Request"}
        head={language ? "मोबाइल/ईमेल परिवर्तन अनुरोध" : "Mobile/Email Change Request"}
      >
        <form onSubmit={handleSubmit} className="aod-bottom">
          {apiMessage && (<Message apiMessage={apiMessage} setApiMessage={setApiMessage} />)}
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3 f_fields">
                <label className="form-label">
                  <span className="en-label">{language ? "ईमेल" : "Email"}</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="test@gmail.com"
                  value={oldEmail}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3 f_fields">
                <label className="form-label">
                  <span className="en-label">{language ? "मोबाइल नंबर " : "Mobile Number"}</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="9876543210"
                  value={oldPhoneNumber}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3 f_fields">
                <label className="form-label">
                  <span className="en-label">{language ? "नया ईमेल" : "New Email"}</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={language ? "नया ईमेल दर्ज करें" : "Enter new Email"}
                  value={newEmail}
                  onChange={(e) => { setNewEmail(e.target.value); setErrorsEmail({ newEmail_en: "", newEmail_hi: '' }); setErrors({ form_en: "", form_hi: '' }) }}
                  onBlur={validateEmail}
                  disabled={isNewEmailDisabled}
                />
                {(errorsEmail.newEmail_hi || errorsEmail.newEmail_en) && <p className="text-danger">{language ? errorsEmail.newEmail_hi : errorsEmail.newEmail_en}</p>}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3 f_fields">
                <label className="form-label">
                  <span className="en-label">{language ? "नया मोबाइल नंबर " : "New Mobile Number"}</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={language ? 'नया फ़ोन नंबर दर्ज करें' : "Enter New Phone Number"}
                  value={newPhoneNumber}
                  onChange={(e) => {
                    let value = e.target.value
                    if (!/^\d*$/.test(value)) {
                      return;
                    }
                    if (value.length === 1 && /^[0-5]$/.test(value)) {
                      return;
                    }
                    if (e.target.value.length <= 10) {
                      setNewPhoneNumber(e.target.value);
                      setErrorsPhone({ newPhoneNumber_en: "", newPhoneNumber_hi: '' })
                      setErrors({ form_en: "", form_hi: '' })
                    }
                  }}
                  onBlur={validatePhoneNumber}
                  disabled={isNewPhoneDisabled}
                />
                {(errorsPhone.newPhoneNumber_hi || errorsPhone.newPhoneNumber_en) && <p className="text-danger">{language ? errorsPhone.newPhoneNumber_hi : errorsPhone.newPhoneNumber_en}</p>}
              </div>
            </div>
          </div>
          {(errors.form_hi || errors.form_en) && <p className="text-danger">{language ? errors.form_hi : errors.form_en}</p>}
          <button type="submit" className="btn btn-primary user-theme-btn mt-3" disabled={isNewEmailDisabled && isNewPhoneDisabled}>
            {language ? 'जमा करना' : 'Submit'}
          </button>
        </form>
      </Dashboard>
    </div>
  );
};

export default ChangeRequest;
