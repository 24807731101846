import * as React from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import { Box, Typography } from "@mui/material"; // Import Box and Typography for layout and styling

export default function PieActiveArc({ responseData }) {
  // Parse the JSON data dynamically to match PieChart input structure
  const dataKeysToLabels = {
    inactive: "Inactive",
    active: "Active",
    pending: "Document Pending",
    draft: "Draft",
    approved: "Approved",
    rejected: "Rejected",
    expired: "Expired",
    under_review: "Under Review",
    submitted: "Submitted",
    withdraw: "Withdrawal",
  };

  const colorMapping = {
    Inactive: "#b0b0b0",
    Active: "#02b2af",
    "Document Pending": "#ffb300",
    Draft: "#005eff",
    Approved: "#4caf50",
    Rejected: "#d32f2f",
    Expired: "#9e9e9e",
    "Under Review": "#ff9800",
    Submitted: "#0288d1",
    Withdrawal: "#7b1fa2",
  };
  
  const desktopOS = Object.entries(responseData).map(([key, value]) => ({
    label: dataKeysToLabels[key] || key, // Map keys to human-readable labels
    value: value, // Use corresponding values
    color: colorMapping[dataKeysToLabels[key] || key] || "gray"
  }));

  const valueFormatter = (item) => `${item.value}`;

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      {/* PieChart without legend */}
      <PieChart
        series={[
          {
            data: desktopOS,
            highlightScope: { fade: "global", highlight: "item" },
            faded: { innerRadius: 30, additionalRadius: -30, color: "gray" },
            valueFormatter,
            // Explicitly not adding any legend-related properties here
          },
        ]}
        height={300}
        marginRight={-20}
      />

      {/* Custom Legend */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",

          marginTop: 2,
        }}
      >
        {desktopOS?.filter((d) => d.value > 0).map((item) => (
          <Box
            key={item.label}
            sx={{
              display: "flex",
              alignItems: "center",
              marginRight: 2,
              marginTop: 1,
            }}
          >
            <Box
              sx={{
                width: 12,
                height: 12,
                backgroundColor: colorMapping[item.label] || "gray",
                marginRight: 1,
              }}
            />
            <Typography variant="body2">{item.label}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
