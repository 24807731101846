import React, { useState, useEffect } from "react";
import "./GuidelinesComponent.css";
import { CheckIcon, CircleIcon, MandatoryDocumentIcon } from "./GuidelineIcon";
import { useNavigate } from "react-router-dom";
import { GetLocalStorage, GetLocalStorageParsed ,SetLocalStorage,SetLocalStorageStringify} from "../../Utils/Utils";
import { getGuideLines } from "../../services/api/outer";
import { useLanguage } from "../LanguageProvider/LanguageProvider ";
import { CgLoadbarDoc, CgCalendar, CgAwards } from "react-icons/cg";
import {
  district,
  getApplicationFees,
  stateDistrict,
} from "../../services/api/auth";
import { customStylesAccUser, selectThemeUser } from "../../Utils/common";
import Select from "react-select";
import { applicationGenerate } from "../../services/api/user";

const GuidelinesComponent = () => {
  const getLoginSubCabData = GetLocalStorage("LoginSubCatData");
  const [districtsData, setDistrictsData] = useState([]);
  const navigate = useNavigate();
  const { language } = useLanguage();
  const [districtName, setDistrictName] = useState();
  const [districtNameErr, setDistrictNameErr] = useState(null);
  const [guidelines, setGuidelines] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [applicationFees, setApplicationFees] = useState(null);
  const [timeline, setTimeline] = useState(null);
  const [certificateTenure, setCertificateTenure] = useState(null);
  const userDetail = GetLocalStorageParsed("userDetail");

  const handleNavigate = async () => {
    try {
      if (getLoginSubCabData) {
        if (districtName) {
          const formData = new FormData();
          const getLoginSubCabData = GetLocalStorageParsed("LoginSubCatData");
          formData.append("sub_category_id", getLoginSubCabData?.id);
          formData.append("district_id", districtName?.value);
          formData.append("user_id", userDetail?.id);
          const appGenerate = await applicationGenerate(formData);
          if(appGenerate?.status === 200 && appGenerate?.data?.application_details){
            SetLocalStorageStringify("application_id", appGenerate?.data?.application_details?.id);
            SetLocalStorage("district_id", appGenerate?.data?.application_details?.district_id);
          }
          navigate("/application/application-form");
        } else {
          setDistrictNameErr({en : "Please select district", hi : "कृपया जिला चुनें" });
        }
      }
    } catch (err) {
      console.log(err?.message);
    }
  };

  const loadDistricts = async () => {
    try {
      const response = await stateDistrict();
      const data = response.data.data.map((district) => ({
        label: language ? district.name_hi : district.name_en,
        value: district.id,
      }));
      setDistrictsData(data);
    } catch (error) {
      console.error("Error fetching districts:", error);
    }
  };

  useEffect(() => {
    loadDistricts();
  }, []); // Listen for changes to state

  useEffect(() => {
    // Fetch districts based on selected state
    loadDistricts();
  }, [language]);

  useEffect(() => {
    // Fetch districts based on selected state
    const loadApplcationFees = async () => {
      try {
        const formData = new FormData();
        const getLoginSubCabData = GetLocalStorageParsed("LoginSubCatData");
        formData.append("sub_category_id", getLoginSubCabData.id);
        const response = await getApplicationFees(formData);
        const data = response.data;
        setApplicationFees(response?.data?.fees);
        setTimeline(response?.data?.timeline);
        setCertificateTenure(response?.data?.certificate_tenure);
      } catch (error) {
        console.error("Error fetching districts:", error);
      }
    };

    loadApplcationFees();
  }, []); // Listen for changes to state

  useEffect(() => {
    const fetchGuidelines = async () => {
      try {
        const getLoginSubCabData = GetLocalStorageParsed("LoginSubCatData");
        if (getLoginSubCabData) {
          const payload = {
            category_id: getLoginSubCabData.id,
          };
          const response = await getGuideLines(payload);
          setGuidelines(response);
        }
      } catch (error) {
        setError("Failed to load guidelines");
        console.error("API error:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchGuidelines();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="gc-outline">
      <div className="row">
        <div className="col-xl-12 col-lg-12 col-md-12">
          <div className="district-input">
            <div className=" f_fields">
              {/* <select
                className="form-control"
                value={districtName}
                onChange={(e) => {
                  setDistrictName(e.target.value);
                }}
              >
                <option value="">Select District</option>
                {districtsData.map((district) => (
                  <option key={district.value} value={district.value}>
                    {district.label}
                  </option>
                ))}
              </select> */}
              <Select
                styles={customStylesAccUser}
                theme={selectThemeUser}
                components={{
                  IndicatorSeparator: () => null,
                }}
                className="menu_open_select w-100"
                classNamePrefix="ad-menu_open_select_class"
                aria-label="Default select example"
                value={districtName}
                options={districtsData}
                onChange={(selected) => {
                  setDistrictName(selected); //{label: 'Anantapur', value: 1}
                  if (selected) {
                    setDistrictNameErr("");
                  }
                }}
                placeholder={language ? "जिला चुनें": "Select District"}
              />
              {districtNameErr && (
                <span className="text-danger">{language ? districtNameErr?.hi : districtNameErr?.hi}</span>
              )}
            </div>
            <div className="gc-apply-butt">
              <button onClick={handleNavigate}>
                {language ? "आवेदन करें" : " Apply Now"}
              </button>
            </div>
          </div>
        </div>
        {(applicationFees && applicationFees > 0) || (timeline && timeline !== "") || (certificateTenure && certificateTenure !== "") ? <div className="col-xl-12  col-lg-12 col-md-12">
          <div className="gc-top-blocks">
            {applicationFees && applicationFees > 0 ? <div className="gc-inline-blocks gc-fees-block">
              <div className="gc-icon">
                <CgLoadbarDoc />
              </div>
              <div className="gc-fees-text ms-3">
                <h5 className="mb-0">{language ? "आवेदन शुल्क" : "Application Fees"}</h5>
                <p className="mb-0">
                  {language ? "रु. " + applicationFees : " Rs. " + applicationFees}
                </p>
              </div>
            </div> : <></>}
            {timeline && timeline !== ""  ? <div className="gc-inline-blocks gc-timeline-block">
              <div className="gc-icon">
                <CgCalendar />
              </div>
              <div className="gc-fees-text ms-3">
                <h5 className="mb-0">{language ? "समय" : "Timeline"}</h5>
                <p className="mb-0"> {language ? `${timeline} साल` : timeline === "1" ?  `${timeline} Day` : `${timeline} Days`}</p>
              </div>
            </div> : <></>}
            {certificateTenure && certificateTenure !== "" ? <div className="gc-inline-blocks gc-certificate-block">
              <div className="gc-icon">
                <CgAwards />
              </div>
              <div className="gc-fees-text ms-3">
                <h5 className="mb-0">{language ? "प्रमाणपत्र कार्यकाल" : "Certificate Tenure"}</h5>
                <p className="mb-0">
                {language ? `${certificateTenure} साल` : certificateTenure === "1" ?  `${certificateTenure} Year` : `${certificateTenure} Years`}
                </p>
              </div>
            </div> : <></>}
          </div>
        </div> : <></>}
      </div>

      <div className="gc-inner">
        <div className="gc-col">
          <div className="gc-head">
            <MandatoryDocumentIcon />{" "}
            {language ? "अनिवार्य दस्तावेज़" : "Mandatory Documents"}
          </div>
          <div className="gc-content">
            <span
              dangerouslySetInnerHTML={{
                __html: language
                  ? guidelines?.mandatory_guidelines_hi
                  : guidelines?.mandatory_guidelines_en,
              }}
            ></span>
          </div>
        </div>
        <div className="gc-col">
          <div className="gc-head">
            <MandatoryDocumentIcon />{" "}
            {language ? "वैकल्पिक दस्तावेज़" : "Optional Documents"}
          </div>
          <div className="gc-content-bottom">
            <span
              dangerouslySetInnerHTML={{
                __html: language
                  ? guidelines?.optional_guidelines_hi
                  : guidelines?.optional_guidelines_en,
              }}
            ></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuidelinesComponent;
