import React, { useEffect, useState } from 'react'
import AdminDashboard from '../AdminDashboard/AdminDashboard'
import { Message } from "../../../components/message";
import { useLocation, useNavigate } from "react-router-dom";
import { Table } from "react-bootstrap";
import { HomeIcon } from "../../../components/Application/ApplicationIcon";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { EyeIcon } from "../AdminIcon";
import { allAdminList, allAdminUserList, getYearlyTarget, getYearlyTargetDistrictLog } from '../../../services/api/admin';
import NotFoundTable from '../../../components/notFound/NotFoundTable';
import { BsChevronLeft } from 'react-icons/bs';
import { formatDateTimeWithSlash, formatDateWithdash } from '../../../Utils/common';
import { GetLocalStorage } from '../../../Utils/Utils';
import ReactPaginate from 'react-paginate';

const YearlyTagetLogs = () => {
    const navigate = useNavigate();
    const token = GetLocalStorage("userInfo");
    const location = useLocation()
    const [apiMessage, setApiMessage] = useState({ type: "", message: "" });
    const [targetLogList, setTargetLogList] = useState([]);
    const [orderBy, setOrderBy] = useState("desc");
    const [sortBy, setSortBy] = useState("created_at");
    const [searchData, setSearchData] = useState("");
    const [tableDataLoaded, setTableDataLoaded] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0); // Total number of pages
    const [searchTerm, setSearchTerm] = useState();

    useEffect(() => {
        if (!token) {
            navigate("/")
        } else {
            if (location?.state) {
                fetchData("created_at", "1", "desc", "");
            }
        }
    }, [location?.state])

    const fetchData = async (sortBy, pageNo, orderBy, searchBy) => {
        try {
            setSearchData(searchBy);
            setOrderBy(orderBy);
            setSortBy(sortBy);
            const response = await getYearlyTargetDistrictLog(location?.state?.district_id, sortBy, orderBy, searchBy, pageNo)
            if (response?.status == 200) {
                const data = response?.data?.data;
                setCurrentPage(pageNo > 0 ? pageNo - 1 : pageNo);
                setTargetLogList(data);
                setTotalPages(
                    Math.ceil(response?.data?.total / response?.data?.per_page)
                ); // Calculate total pages
            } else {
                setTargetLogList([])
            }
            setTableDataLoaded(true);
        } catch (err) {
            setTableDataLoaded(true);
            setApiMessage({ type: 'error', message: err.message })
        }
    }

    const handlePageChange = (selectedPage) => {
        fetchData(sortBy, selectedPage.selected + 1, orderBy, searchData);
    };

    return (
        <AdminDashboard>
            <div className="aod-outer">
                {apiMessage && (<Message apiMessage={apiMessage} setApiMessage={setApiMessage}></Message>)}
                <div className="ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb">
                    <div>
                        <HomeIcon />
                        <b onClick={() => navigate("/admin/")}>Home / </b>
                        <span>Target Details</span>
                    </div>
                </div>
                <div className="aod-inner pt-0">
                    <div className="aod-bottom">
                        <div className="adm-tabs-outer d-flex">
                        <div className="aod-head text-center adm-head">
                            <button onClick={() => navigate("/admin/yearly-targets")}>
                                <BsChevronLeft /> Back
                            </button>
                        </div>
                        <h4 className="mb-0 w-100 text-center">Target Details</h4>
                        <div className="position-relative w-25">
                            <input
                                className="px-2 form-control"
                                placeholder="Search"
                                type="text"
                                value={searchTerm} // Add a state for the input value
                                onChange={(e) => {
                                setSearchTerm(e.target.value); // Update the state
                                fetchData(sortBy, 1, orderBy, e.target.value);
                                }}
                            />
                            {searchTerm && ( // Show the clear button only when there's text
                                <button
                                className="btn btn-clear position-absolute"
                                onClick={(e) => {
                                    setSearchTerm(""); // Clear the input value
                                    fetchData(sortBy, 1, orderBy, ""); // Fetch data with empty search term
                                }}
                                style={{
                                    top: "50%",
                                    right: "10px",
                                    transform: "translateY(-50%)",
                                    border: "none",
                                    background: "transparent",
                                    fontSize: "1rem",
                                    cursor: "pointer",
                                }}
                                >
                                &times; {/* This is the "X" icon */}
                                </button>
                            )}
                        </div>
                        </div>
                        
                        <div className="ad-reg-table">
                            <Table size="sm" className="ad-cat-table mt-4">
                                <thead>
                                    <tr>
                                        <th className="ad-sno">S. No</th>
                                        <th className="ad-long" 
                                            onClick={(e) =>
                                                fetchData(
                                                "created_at",
                                                currentPage + 1,
                                                orderBy === "asc" ? "desc" : "asc",
                                                searchData
                                                )
                                            }
                                        >{" "}Date{" "}<span><i className="fa-solid fa-sort"></i></span></th>
                                        <th className="ad-long cursor-pointer" 
                                            onClick={(e) =>
                                                fetchData(
                                                "assigned_user",
                                                currentPage + 1,
                                                orderBy === "asc" ? "desc" : "asc",
                                                searchData
                                                )
                                            }
                                        >{" "}Assigned By{" "}<span><i className="fa-solid fa-sort"></i></span></th>
                                        <th className="ad-long cursor-pointer"
                                            onClick={(e) =>
                                                fetchData(
                                                "target_value",
                                                currentPage + 1,
                                                orderBy === "asc" ? "desc" : "asc",
                                                searchData
                                                )
                                            }
                                        >{" "}Target{" "}<span><i className="fa-solid fa-sort"></i></span></th>
                                        <th className="ad-long cursor-pointer"
                                            onClick={(e) =>
                                                fetchData(
                                                "target_achieved",
                                                currentPage + 1,
                                                orderBy === "asc" ? "desc" : "asc",
                                                searchData
                                                )
                                            }
                                        >{" "}Pending{" "}<span><i className="fa-solid fa-sort"></i></span></th>
                                        <th className="ad-long">{" "}Achieved{" "}</th>
                                        <th className="ad-long cursor-pointer"
                                            onClick={(e) =>
                                                fetchData(
                                                "financial_year",
                                                currentPage + 1,
                                                orderBy === "asc" ? "desc" : "asc",
                                                searchData
                                                )
                                            }
                                        >{" "}Financial Year{" "}<span><i className="fa-solid fa-sort"></i></span></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {targetLogList?.length > 0 ? targetLogList?.map((item, index) => (
                                        <tr
                                            key={index}
                                            className={index % 2 !== 0 ? "adm-even" : ""}
                                            >
                                            <td>{currentPage * 10 + (index + 1)}</td>
                                            <td>{formatDateTimeWithSlash(item?.created_at)}</td>
                                            <td>{item?.assigned_user || '--'}</td>
                                            <td>{item?.target_value || '--'}</td>
                                            <td>{item?.target_achieved}</td>
                                            <td>{item?.target_value - item?.target_achieved}</td>
                                            <td>{item?.financial_year}</td>
                                        </tr>
                                    )) : tableDataLoaded ? (
                                        <NotFoundTable colSpan="8"></NotFoundTable>
                                      ) : (
                                        <></>
                                      )}
                                </tbody>
                            </Table>
                            {totalPages > 1 && targetLogList?.length > 0 && (
                                <div className="d-flex w-100 justify-content-end">
                                <ReactPaginate
                                    previousLabel={"Previous"}
                                    nextLabel={"Next"}
                                    breakLabel={"..."}
                                    pageCount={totalPages} // Total number of pages from API
                                    marginPagesDisplayed={1}
                                    pageRangeDisplayed={1}
                                    onPageChange={handlePageChange} // Method to handle page click
                                    containerClassName={"pagination justify-content-center"} // Custom CSS classes
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </AdminDashboard>
    )
}

export default YearlyTagetLogs;
