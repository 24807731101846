import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLayerGroup,
  faTags,
  faTag,
  faBookOpen,
  faQuestionCircle,
  faClipboardList,
  faTable,
  faThList,
  faList,
  faFileAlt,
  faCaretSquareDown,
  faUserTag,
  faFileSignature,
  faNoteSticky,
  faEject,
  faClipboardCheck,
  faHourglass,
  faCheck,
  faTimes,
  faSyncAlt,
  faPencil,
  faEdit,
  faAward,
  faFileContract,
  faPlayCircle,
  faExclamationCircle,
  faArchive,
  faSignInAlt,
  faKey,
  faUnlockAlt,
  faUserPlus,
  faUsersCog,
  faIdBadge,
  faShieldAlt,
  faUserTie,
  faWrench,
  faMapMarkerAlt,
  faMapSigns,
  faCity,
  faMobileAlt,
  faEnvelopeOpenText,
  faTachometerAlt,
  faFile,
  faMapMarkedAlt,
  faMapPin,
  faBookmark,
  faCreditCard,
  faFileImage,
  faCircleInfo,
  faFileLines,
  faLink,
  faImage,
  faFileShield,
  faCancel,
  faScaleBalanced,
  faShieldHalved,
  faTriangleExclamation,
  faFileInvoice,
  faUsers,
  faUserGroup,
  faPenToSquare,
  faArrowsRotate,
  faChartSimple,
  faPaperPlane,
  faClock,
  faBan,
  faRotate,
  faArrowRightFromBracket,
  faGlobe,
  faHeading,
  faBars,
  faAlignJustify,
  faListCheck,
  faMoneyCheckAlt,
  faBullseye,
  faUserShield
  
} from "@fortawesome/free-solid-svg-icons";
import {
  checkPermission,
  permissionsConfig,
} from "../AdminPermission/PermissionChecker";

const SideMenu = () => {
  const location = useLocation();
  const pathName = location.pathname;
  const navigate = useNavigate();

  const iconsMap = {
    faChartSimple,
    faLayerGroup,
    faTags,
    faTag,
    faPencil,
    faBookOpen,
    faQuestionCircle,
    faClipboardList,
    faEject,
    faTable,
    faNoteSticky,
    faThList,
    faList,
    faFileAlt,
    faCaretSquareDown,
    faUserTag,
    faFileSignature,
    faClipboardCheck,
    faHourglass,
    faCheck,
    faTimes,
    faSyncAlt,
    faCancel,
    faEdit,
    faAward,
    faFileContract,
    faPlayCircle,
    faExclamationCircle,
    faArchive,
    faSignInAlt,
    faKey,
    faUnlockAlt,
    faUserPlus,
    faUsersCog,
    faIdBadge,
    faShieldAlt,
    faUserTie,
    faWrench,
    faMapMarkerAlt,
    faMapSigns,
    faCity,
    faMobileAlt,
    faEnvelopeOpenText,
    faTachometerAlt,
    faFile,
    faMapMarkedAlt,
    faMapPin,
    faBookmark,
    faCreditCard,
    faFileImage,
    faCircleInfo,
    faFileLines,
    faLink,
    faImage,
    faFileShield,
    faScaleBalanced,
    faShieldHalved,
    faTriangleExclamation,
    faFileInvoice,
    faUsers,
    faUserGroup,
    faPenToSquare,
    faArrowsRotate,
    faPaperPlane,
    faClock,
    faBan,
    faRotate,
    faArrowRightFromBracket,
    faGlobe,
    faHeading,
    faBars,
    faAlignJustify,
    faListCheck,
    faMoneyCheckAlt,
    faBullseye,
    faUserShield
  };

  // Simplified permission checks using the permissions config
  const grantPermissionForViewCategories = checkPermission(
    permissionsConfig.viewCategories.role,
    permissionsConfig.viewCategories.action,
    permissionsConfig.viewCategories.type
  );
  const grantPermissionForViewSubCategories = checkPermission(
    permissionsConfig.viewSubCategories.role,
    permissionsConfig.viewSubCategories.action,
    permissionsConfig.viewSubCategories.type
  );
  const grantPermissionForViewGuidelines = checkPermission(
    permissionsConfig.viewGuidelines.role,
    permissionsConfig.viewGuidelines.action,
    permissionsConfig.viewGuidelines.type
  );
  const grantPermissionForViewCategoryFAQList = checkPermission(
    permissionsConfig.viewCategoryFAQList.role,
    permissionsConfig.viewCategoryFAQList.action,
    permissionsConfig.viewCategoryFAQList.type
  );
  const grantPermissionForViewDocumentList = checkPermission(
    permissionsConfig.viewDocumentList.role,
    permissionsConfig.viewDocumentList.action,
    permissionsConfig.viewDocumentList.type
  );

  const grantPermissionForViewFormTabs = checkPermission(
    permissionsConfig.viewFormTabs.role,
    permissionsConfig.viewFormTabs.action,
    permissionsConfig.viewFormTabs.type
  );
  const grantPermissionForViewFormSection = checkPermission(
    permissionsConfig.viewFormSection.role,
    permissionsConfig.viewFormSection.action,
    permissionsConfig.viewFormSection.type
  );
  const grantPermissionForViewFormSubSection = checkPermission(
    permissionsConfig.viewFormSubSection.role,
    permissionsConfig.viewFormSubSection.action,
    permissionsConfig.viewFormSubSection.type
  );
  const grantPermissionForViewFormFields = checkPermission(
    permissionsConfig.viewFormFields.role,
    permissionsConfig.viewFormFields.action,
    permissionsConfig.viewFormFields.type
  );
  const grantPermissionForViewFormDropdowns = checkPermission(
    permissionsConfig.viewFormDropdowns.role,
    permissionsConfig.viewFormDropdowns.action,
    permissionsConfig.viewFormDropdowns.type
  );
  const grantPermissionForViewEmployeeDesignation = checkPermission(
    permissionsConfig.viewEmployeeDesignation.role,
    permissionsConfig.viewEmployeeDesignation.action,
    permissionsConfig.viewEmployeeDesignation.type
  );

  const grantPermissionForTotalApplications = checkPermission(
    permissionsConfig.totalApplications.role,
    permissionsConfig.totalApplications.action,
    permissionsConfig.totalApplications.type
  );
  const grantPermissionForViewPending = checkPermission(
    permissionsConfig.viewPending.role,
    permissionsConfig.viewPending.action,
    permissionsConfig.viewPending.type
  );
  const grantPermissionForViewApproved = checkPermission(
    permissionsConfig.viewApproved.role,
    permissionsConfig.viewApproved.action,
    permissionsConfig.viewApproved.type
  );
  const grantPermissionForViewRejected = checkPermission(
    permissionsConfig.viewRejected.role,
    permissionsConfig.viewRejected.action,
    permissionsConfig.viewRejected.type
  );

  const grantPermissionForCertificates = checkPermission(
    permissionsConfig.certificates.role,
    permissionsConfig.certificates.action,
    permissionsConfig.certificates.type
  );
  const grantPermissionForExpired = checkPermission(
    permissionsConfig.expired.role,
    permissionsConfig.expired.action,
    permissionsConfig.expired.type
  );
  const grantPermissionForCertificateCategory = checkPermission(
    permissionsConfig.expired.role,
    permissionsConfig.expired.action,
    permissionsConfig.expired.type
  );

  const grantPermissionForViewLogin = checkPermission(
    permissionsConfig.viewLogin.role,
    permissionsConfig.viewLogin.action,
    permissionsConfig.viewLogin.type
  );
  const grantPermissionForViewResetPassword = checkPermission(
    permissionsConfig.viewResetPassword.role,
    permissionsConfig.viewResetPassword.action,
    permissionsConfig.viewResetPassword.type
  );
  const grantPermissionForViewForgotPassword = checkPermission(
    permissionsConfig.viewForgotPassword.role,
    permissionsConfig.viewForgotPassword.action,
    permissionsConfig.viewForgotPassword.type
  );
  const grantPermissionForViewRegistration = checkPermission(
    permissionsConfig.viewRegistration.role,
    permissionsConfig.viewRegistration.action,
    permissionsConfig.viewRegistration.type
  );

  const grantPermissionForRolesAndPermissions = checkPermission(
    permissionsConfig.rolesAndPermissions.role,
    permissionsConfig.rolesAndPermissions.action,
    permissionsConfig.rolesAndPermissions.type
  );
  const grantPermissionForViewAdminUser = checkPermission(
    permissionsConfig.viewAdminUser.role,
    permissionsConfig.viewAdminUser.action,
    permissionsConfig.viewAdminUser.type
  );

  const grantPermissionForViewManageStates = checkPermission(
    permissionsConfig.manageStates.role,
    permissionsConfig.manageStates.action,
    permissionsConfig.manageStates.type
  );
  const grantPermissionForViewManageDistricts = checkPermission(
    permissionsConfig.manageDistricts.role,
    permissionsConfig.manageDistricts.action,
    permissionsConfig.manageDistricts.type
  );
  const grantPermissionForViewManageCities = checkPermission(
    permissionsConfig.manageCities.role,
    permissionsConfig.manageCities.action,
    permissionsConfig.manageCities.type
  );
  const grantPermissionForViewSMSSetting = checkPermission(
    permissionsConfig.viewSMSSetting.role,
    permissionsConfig.viewSMSSetting.action,
    permissionsConfig.viewSMSSetting.type
  );
  const grantPermissionForViewEmailSetting = checkPermission(
    permissionsConfig.viewEmailSetting.role,
    permissionsConfig.viewEmailSetting.action,
    permissionsConfig.viewEmailSetting.type
  );

  const grantPermissionForViewMenuPages = checkPermission(
      permissionsConfig.viewPages.role,
      permissionsConfig.viewPages.action,
      permissionsConfig.viewPages.type
  );

  const grantPermissionForViewTravelTradeLink = checkPermission(
    permissionsConfig.viewTravelTradeLink.role,
    permissionsConfig.viewTravelTradeLink.action,
    permissionsConfig.viewTravelTradeLink.type
  );

  const grantPermissionForViewFooterContent = checkPermission(
    permissionsConfig.viewFooterContent.role,
    permissionsConfig.viewFooterContent.action,
    permissionsConfig.viewFooterContent.type
  );

  const grantPermissionForViewRegistrationStep = checkPermission(
    permissionsConfig.viewRegistrationStep.role,
    permissionsConfig.viewRegistrationStep.action,
    permissionsConfig.viewRegistrationStep.type
  );

  const grantPermissionForViewHeaderContent = checkPermission(
    permissionsConfig.viewHeaderContent.role,
    permissionsConfig.viewHeaderContent.action,
    permissionsConfig.viewHeaderContent.type
  );

  const grantPermissionForViewContactUs = checkPermission(
    permissionsConfig.viewContactUs.role,
    permissionsConfig.viewContactUs.action,
    permissionsConfig.viewContactUs.type
  );

  const menuPages = {
    menus: [
      // Category Management
      grantPermissionForViewCategories ||
      grantPermissionForViewSubCategories ||
      grantPermissionForViewGuidelines ||
      grantPermissionForViewCategoryFAQList ||
      grantPermissionForViewDocumentList
        ? {
            name: "Travel Trade Users",
            icon: "faUsers",
            submenus: [
              grantPermissionForViewCategories
                ? { name: "Users List", path: "/admin/user", icon: "faUserGroup" }
                : undefined,
              grantPermissionForViewSubCategories
                ? {
                    name: "Update Details Requests",
                    path: "/admin/request/mobile-email",
                    icon: "faPenToSquare",
                  }
                : undefined,
              grantPermissionForViewSubCategories
                ? {
                    name: "Recover Users",
                    path: "/admin/frontend-users",
                    icon: "faArrowsRotate",
                  }
                : undefined,
            ].filter(Boolean), // This will filter out any undefined values
          }
        : null,

      grantPermissionForViewCategories ||
      grantPermissionForViewSubCategories ||
      grantPermissionForViewGuidelines ||
      grantPermissionForViewCategoryFAQList ||
      grantPermissionForViewDocumentList
        ? {
            name: "Category Management",
            icon: "faLayerGroup",
            submenus: [
              grantPermissionForViewCategories
                ? {
                    name: "Categories",
                    path: "/admin/categories",
                    icon: "faList",
                  }
                : undefined,
              grantPermissionForViewSubCategories
                ? {
                    name: "Sub Categories",
                    path: "/admin/sub-categories",
                    icon: "faTag",
                  }
                : undefined,
              grantPermissionForViewGuidelines
                ? {
                    name: "Guidelines",
                    path: "/admin/guidelines",
                    icon: "faBookOpen",
                  }
                : undefined,
              grantPermissionForViewCategoryFAQList
                ? {
                    name: "FAQs",
                    path: "/admin/faqs",
                    icon: "faQuestionCircle",
                  }
                : undefined,
              grantPermissionForViewDocumentList
                ? {
                    name: "Documents",
                    path: "/admin/category/documents",
                    icon: "faBookmark",
                  }
                : undefined,
              grantPermissionForViewDocumentList
                ? {
                    name: "Fees",
                    path: "/admin/category/fees",
                    icon: "faCreditCard",
                  }
                : undefined,
            ].filter(Boolean), // This will filter out any undefined values
          }
        : null,

      // Form Management
      grantPermissionForViewFormTabs ||
      grantPermissionForViewFormSection ||
      grantPermissionForViewFormSubSection ||
      grantPermissionForViewFormFields ||
      grantPermissionForViewFormDropdowns ||
      grantPermissionForViewEmployeeDesignation
        ? {
            name: "Form Management",
            icon: "faClipboardList",
            submenus: [
              grantPermissionForViewFormTabs
                ? {
                    name: "Form Tabs",
                    path: "/admin/form/tabs",
                    icon: "faTable",
                  }
                : undefined,
              grantPermissionForViewFormSection
                ? {
                    name: "Form Sections",
                    path: "/admin/form/sections",
                    icon: "faThList",
                  }
                : undefined,
              grantPermissionForViewFormSubSection
                ? {
                    name: "Form Sub Sections",
                    path: "/admin/form/sub-sections",
                    icon: "faList",
                  }
                : undefined,
              grantPermissionForViewFormFields
                ? {
                    name: "Form Fields",
                    path: "/admin/form/fields",
                    icon: "faFileAlt",
                  }
                : undefined,
              grantPermissionForViewFormDropdowns
                ? {
                    name: "Form Dropdowns",
                    path: "/admin/form/dropdowns",
                    icon: "faCaretSquareDown",
                  }
                : undefined,
              grantPermissionForViewEmployeeDesignation
                ? {
                    name: "Employee Designations",
                    path: "/admin/employee-designations",
                    icon: "faUserTag",
                  }
                : undefined,
            ].filter(Boolean), // This will filter out any undefined values
          }
        : null,

      // Application Management
      {
        name: "Application Management",
        icon: "faFileSignature",
        submenus: [
          grantPermissionForTotalApplications
            ? {
                name: "All",
                path: "/admin/total-applications",
                icon: "faList",
              }
            : undefined,
          grantPermissionForTotalApplications
            ? {
              name: "New",
                path: "/admin/new-applications",
                icon: "faPaperPlane",
              }
            : undefined,
          {
            name: "Under Review",
            path: "/admin/under-review-applications",
            icon: "faEdit",
          },
          grantPermissionForViewPending
            ? {
              name: "Document Pending",
                path: "/admin/pending-applications",
                icon: "faHourglass",
              }
            : undefined,
          grantPermissionForViewApproved
            ? {
                name: "Approved/Certificate",
                path: "/admin/approved-applications",
                icon: "faCheck",
              }
            : undefined,
          grantPermissionForViewRejected
            ? {
                name: "Rejected",
                path: "/admin/rejected-applications",
                icon: "faTimes",
              }
            : undefined,
            grantPermissionForTotalApplications
            ? {
                name: "Expired",
                path: "/admin/expired-applications",
                icon: "faClock",
              }
            : undefined,
            grantPermissionForTotalApplications
            ? {
                name: "Disable Applications",
                path: "/admin/disable-applications",
                icon: "faBan",
              }
            : undefined,
          // {
          //   name: "Draft",
          //   path: "/admin/draft-applications",
          //   icon: "faPencil",
          // },
          // {
          //   name: "Withdarawl",
          //   path: "/admin/withdrawal-applications",
          //   icon: "faCancel",
          // },
        ].filter(Boolean), // This will filter out any undefined values
      },
      {
        name: "Draft Application",
        icon: "faNoteSticky",
        path: "/admin/draft-applications",
        submenus: [
        ].filter(Boolean)
      },
      {
        name: "Withdrawal Application",
        icon: "faArrowRightFromBracket",
        path: "/admin/withdrawal-applications",
        submenus: [
        ].filter(Boolean)
      },
      {
        name: "Renew Application",
        icon: "faRotate",
        // path: "/admin/withdrawal-applications"
        submenus: [
          {
            name: "All",
            path: "/admin/renew/all",
            icon: "faList",
          },
          {
            name: "New",
            path: "/admin/renew/new",
            icon: "faPaperPlane",
          },
          {
            name: "Under Review",
            path: "/admin/renew/under-review",
            icon: "faEdit",
          },
          {
            name: "Document Pending",
            path: "/admin/renew/document-pending",
            icon: "faHourglass",
          },
          {
            name: "Accepted/Cerificate",
            path: "/admin/renew/accepted",
            icon: "faCheck",
          },
          {
            name: "Rejected",
            path: "/admin/renew/rejected",
            icon: "faTimes",
          },
          {
            name: "Expired",
            path: "/admin/renew/expired",
            icon: "faClock",
          },
          {
            name: "Disable Application",
            path: "/admin/renew/disable",
            icon: "faBan",
          }
        ].filter(Boolean)
      },
      {
        name: "Transaction List",
        icon: "faMoneyCheckAlt",
        path: "/admin/transaction-list",
        submenus: [
        ].filter(Boolean)
      },
      // Certificate Management
      grantPermissionForCertificates || grantPermissionForExpired
        ? {
            name: "Certificate Type",
            icon: "faAward",
          submenus: [
            // grantPermissionForCertificates
            //   ? {
            //     name: "Certificates",
            //     path: "/admin/certificates",
            //     icon: "faFileContract",
            //   }
            //   : undefined,
            // {
            //   name: "Active",
            //   path: "/admin/active-certificates",
            //   icon: "faPlayCircle",
            // },
            // grantPermissionForExpired
            //   ? {
            //     name: "Expired",
            //     path: "/admin/expired-certificates",
            //     icon: "faExclamationCircle",
            //   }
            //   : undefined,
            grantPermissionForExpired
              ? {
                name: "List",
                path: "/admin/certificate-type",
                icon: "faExclamationCircle",
              }
              : undefined,
            //{ name: "Logs", path: "", icon: "faArchive" },
            ].filter(Boolean), // This will filter out any undefined values
          }
        : null,

   
      // Pages
      grantPermissionForViewLogin ||
      grantPermissionForViewResetPassword ||
      grantPermissionForViewForgotPassword ||
      grantPermissionForViewRegistration
        ? {
            name: "Home Page Management",
            icon: "faFile",
            submenus: [
              grantPermissionForViewLogin
                ? { name: "Login", path: "/admin/login", icon: "faSignInAlt" }
                : undefined,
              grantPermissionForViewResetPassword
                ? {
                    name: "Reset Password",
                    path: "/admin/reset-password",
                    icon: "faKey",
                  }
                : undefined,
              grantPermissionForViewForgotPassword
                ? {
                    name: "Forgot Password",
                    path: "/admin/forgot-password",
                    icon: "faUnlockAlt",
                  }
                : undefined,
              grantPermissionForViewRegistration
                ? {
                    name: "Registration",
                    path: "/admin/registration",
                    icon: "faUserPlus",
                  }
                : undefined,
              grantPermissionForViewHeaderContent
                ? {
                    name: "Header Content",
                    path: "/admin/header-content",
                    icon: "faFileAlt",
                  }
                : undefined,
              grantPermissionForViewContactUs
                ? {
                    name: "Contact Us",
                    path: "/admin/contactus",
                    icon: "faFileAlt",
                  }
                : undefined,
              {
                name: "Website Logo",
                path: "/admin/homepage-logo",
                icon: "faImage",
              },
              grantPermissionForViewMenuPages
                ? {
                "name": "Header Pages",
                "path": "/admin/header-pages",
                "icon": "faHeading"
              } : undefined,
              {
                name: "Banner",
                path: "/admin/banner-page",
                icon: "faFileImage",
              },
              {
                name: "Banner Links",
                path: "/admin/banner-link",
                icon: "faTags",
              },
              grantPermissionForViewRegistrationStep
                ? {
                name: "Registration Steps",
                path: "/admin/registration-steps",
                icon: "faListCheck",
              } : undefined,
              //  { name: "Header Menu", path: "/admin/homepage-header", icon: "faTags" },
              // {
              //   name: "Rules",
              //   path: "/admin/rules",
              //   icon: "faCircleInfo",
              // },
              
              {
                name: "Social Media Links",
                path: "/admin/social-media-links",
                icon: "faLink",
              },
              // {
              //   name: "Niyamvali",
              //   path: "/admin/niyamvali/update",
              //   icon: "faScaleBalanced",
              // },
              // {
              //   name: "Privacy Policy",
              //   path: "/admin/privacy-policy/update",
              //   icon: "faShieldHalved",
              // },
              // {
              //   name: "Terms and Conditions",
              //   path: "/admin/terms-conditions/update",
              //   icon: "faFileShield",
              // },
              // {
              //   name: "Disclaimer",
              //   path: "/admin/disclaimer/update",
              //   icon: "faTriangleExclamation",
              // },
              grantPermissionForViewTravelTradeLink
                ? {
                "name": "Travel Trade Links",
                "path": "/admin/travel-trade-links",
                "icon": "faGlobe"
              } : undefined,
              grantPermissionForViewMenuPages
                ? {
                "name": "Top Footer Pages",
                "path": "/admin/top-footer-pages",
                "icon": "faBars"
              } : undefined,
              grantPermissionForViewMenuPages
                ? {
                "name": "Bottom Footer Pages",
                "path": "/admin/bottom-footer-pages",
                "icon": "faAlignJustify"
              } : undefined,
              {
                name: "Page Document",
                path: "/admin/page-document",
                icon: "faFileLines",
              },
              grantPermissionForViewFooterContent
                ? {
                "name": "Footer Content",
                "path": "/admin/footer-content",
                "icon": "faEdit"
              } : undefined,
            ].filter(Boolean), // This will filter out any undefined values
          }
        : null,

      // Admin Management
      grantPermissionForRolesAndPermissions || grantPermissionForViewAdminUser
        ? {
            name: "Admin Management",
            icon: "faUsersCog",
            submenus: [
              grantPermissionForRolesAndPermissions
                ? {
                    name: "Roles and Permissions",
                    path: "/admin/roles",
                    icon: "faIdBadge",
                  }
                : undefined,
              grantPermissionForViewAdminUser
                ? {
                    name: "Admin User",
                    path: "/admin/admin-users",
                    icon: "faUserTie",
                  }
                : undefined,
              grantPermissionForViewAdminUser
              ? {
                  name: "Recover Admin User",
                  path: "/admin/recover-users",
                  icon: "faArrowsRotate",
                }
              : undefined,  
              {
                name: "Yearly Target",
                path: "/admin/yearly-targets",
                icon: "faChartSimple",
              }, 
            ].filter(Boolean), // This will filter out any undefined values
          }
        : null,

      // Settings Module
      grantPermissionForViewManageStates ||
      grantPermissionForViewManageDistricts ||
      grantPermissionForViewManageCities ||
      grantPermissionForViewSMSSetting ||
      grantPermissionForViewEmailSetting
        ? {
            name: "Settings Module",
            icon: "faWrench",
            submenus: [
              grantPermissionForViewManageStates
                ? {
                    name: "Manage States",
                    path: "/admin/states",
                    icon: "faMapMarkedAlt",
                  }
                : undefined,
              grantPermissionForViewManageDistricts
                ? {
                    name: "Manage Districts",
                    path: "/admin/districts",
                    icon: "faMapPin",
                  }
                : undefined,
              grantPermissionForViewManageCities
                ? {
                    name: "Manage Cities",
                    path: "/admin/cities",
                    icon: "faCity",
                  }
                : undefined,
              grantPermissionForViewSMSSetting
                ? {
                    name: "SMS - Settings",
                    path: "/admin/sms-setting",
                    icon: "faMobileAlt",
                  }
                : undefined,
                
              grantPermissionForViewEmailSetting
                ? {
                    name: "Email - Settings",
                    path: "/admin/email-setting",
                    icon: "faEnvelopeOpenText",
                  }
                : undefined,
              grantPermissionForViewEmailSetting
                ? {
                    name: "Payment - Settings",
                    path: "/admin/payment/tab",
                    icon: "faFileInvoice",
                  }
                : undefined,
              {
                name: "Configurations",
                path: "/admin/configurations",
                icon: "faMobileAlt",
              }
            ].filter(Boolean), // This will filter out any undefined values
          }
        : null,
        grantPermissionForCertificates || grantPermissionForExpired
        ? {
          name: "Reports",
          icon: "faAward",
          submenus: [
            {
              name: "District Total Applications",
              path: "/admin/all-applications-report",
              icon: "faFileContract",
            },
          /*   {
              name: "District Active Applications Reports",
              path: "/admin/all-active-report",
              icon: "faPlayCircle",
            },
            {
              name: "District InActive Applications Reports",
              path: "/admin/all-inactive-report",
              icon: "faExclamationCircle",
            }, */
            {
              name: "District Pending Applications",
              path: "/admin/all-pending-report",
              icon: "faFileContract",
            },
            {
              name: "District Draft Applications",
              path: "/admin/all-draft-report",
              icon: "faPlayCircle",
            },
            {
              name: "District Accepted/Certificate Applications",
              path: "/admin/all-approved-report",
              icon: "faExclamationCircle",
            },
            {
              name: "District Rejected Applications",
              path: "/admin/all-rejected-report",
              icon: "faFileContract",
            },
            {
              name: "District Under Review Applications",
              path: "/admin/all-under-review-report",
              icon: "faPlayCircle",
            },
            {
              name: "District Yearly Target",
              path: "/admin/yearly-target-report",
              icon: "faBullseye",
            },
          ].filter(Boolean), // This will filter out any undefined values
        }
        : null,
      {
        name: "Logs",
        icon: "faTable",
        submenus: [
          { name: "OTP Logs", path: "/admin/otp-logs", icon: "faMapMarkedAlt" },
          { name: "Super Admin Logs", path: "/admin/superadmin-logs-list", icon: "faUserShield" },
          { name: "Admin Logs", path: "/admin/admin-logs-list", icon: "faUserTie" },
          { name: "User Logs", path: "/admin/user-logs-list", icon: "faUsers" },
          { name: "Email Logs", path: "/admin/email-log", icon: "faMapMarkedAlt" },
        ].filter(Boolean), // This will filter out any undefined values
      },
    ].filter(Boolean), // Filter out any null sections
  };

  const [openMenuIndex, setOpenMenuIndex] = useState(null);

  useEffect(() => {
    const activeMenuIndex = menuPages.menus.findIndex((menu) =>
      menu.submenus.some(
        (submenu) => pathName.endsWith(submenu.path) && submenu.path.length > 0
      )
    );
    setOpenMenuIndex(activeMenuIndex !== -1 ? activeMenuIndex : null);
  }, [pathName]);

  useEffect(() => {
    const activeElement = document.querySelector(`.sub-menu-item[data-path="${location.pathname}"]`);
    if (activeElement) {
      activeElement.scrollIntoView({
        block: "center",
      });
    }
  }, [openMenuIndex]);

  const toggleMenu = (index) => {
    setOpenMenuIndex((prevState) => (prevState === index ? null : index));
  };

  return (
    <div className="side-menu adm-side-menu" id="LeftMenu">
      <ul className="ds-ul">
        <li
          onClick={() => navigate("/admin")}
          style={
            pathName.endsWith("/admin")
              ? { backgroundColor: "rgb(0 174 239)" }
              : { backgroundColor: "none" }
          }
        >
          <button
            className={
              pathName.endsWith("/admin")
                ? "bg_image_green"
                : "bg_image_white first-li"
            }
          >
            {" "}
            <FontAwesomeIcon icon={faTachometerAlt} /> <span>Dashboard</span>
          </button>
        </li>
        {menuPages.menus.map((menu, index) => (
          <li
              key={menu.path}
              style={
                ((openMenuIndex === index &&
                  menu.submenus.some(
                  (submenu) =>
                    pathName.endsWith(submenu.path) && submenu.path.length > 0
                  )) || 
                  (menu?.path && pathName?.endsWith(menu?.path) &&
                      menu?.path.length > 0))
                  ? { backgroundColor: "rgb(0 174 239)" }
                  : { backgroundColor: "none" }
              }
              onClick={(e) => {
                e.stopPropagation();
                if(menu?.path && menu?.path?.length > 0){
                  navigate(menu?.path);
                }
              }}
            >
            <button
              className={
                menu?.path && pathName?.endsWith(menu?.path) &&
                menu?.path.length > 0
                  ? "bg_image_green"
                  : "bg_image_white"
              }
              onClick={() => toggleMenu(index)}
            >
              <span className="icon-text">
                <FontAwesomeIcon
                  icon={iconsMap[menu.icon] || iconsMap.default}
                />
                <span style={{ width: "auto" }}>{menu.name}</span>
              </span>
              {menu.submenus && menu.submenus.length > 0 ? (
                openMenuIndex === index ? (
                  <FaChevronDown
                    style={{ marginLeft: "7px", marginTop: "2px" }}
                  />
                ) : (
                  <FaChevronRight
                    style={{ marginLeft: "7px", marginTop: "2px" }}
                  />
                ) // Chevron for collapse
              ) : null}
            </button>

            {menu.submenus && menu.submenus.length > 0 && openMenuIndex === index && (
              <ul className="nested-ul">
                {menu.submenus.map((submenu) => (
                  <li
                    key={submenu.path}
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(submenu.path);
                    }}
                    className={`sub-menu-item ${pathName?.endsWith(submenu.path) ? "active" : ""}`}
                    data-path={submenu.path}
                  >
                    <button
                      className={
                        pathName?.endsWith(submenu.path) &&
                        submenu.path.length > 0
                          ? "bg_image_green"
                          : "bg_image_white"
                      }
                    >
                      <FontAwesomeIcon
                        icon={iconsMap[submenu.icon] || iconsMap.default}
                      />
                      <span>{submenu.name}</span>
                    </button>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SideMenu;
