import React, { useEffect, useState } from "react";
import AdminDashboard from "../AdminDashboard/AdminDashboard";
import { Message } from "../../../components/message";
import { useNavigate } from "react-router-dom";
import { Modal, Table } from "react-bootstrap";
import { HomeIcon } from "../../../components/Application/ApplicationIcon";
import dataicon from "../../../../src/assets/images/data-icon.png";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { DeleteIcon, EditIcon, EyeIcon } from "../AdminIcon";
import {
  applicationListCsv,
  applicationListExcel,
  applicationListPdf,
    deleteApplicationData,
  getallApplicationListSort,
  getApplicationListBystatus,
} from "../../../services/api/admin";
import ReactPaginate from "react-paginate";
import { formatDateWithSlash, printData } from "../../../Utils/common";
import { stateDistrict } from "../../../services/api/auth";
import NotFoundTable from "../../../components/notFound/NotFoundTable";

const StatusApplication = ({ appStatus, title, subTitle, application_type, pageFrom }) => {
    const navigate = useNavigate();
    const [applicationList, setApplicationList] = useState([]);
    const [apiMessage, setApiMessage] = useState({ type: "", message: "" });
    const [currentPage, setCurrentPage] = useState(1);
    const [deleteData, setDeleteData] = useState();
    const [totalPages, setTotalPages] = useState(0); // Total number of pages
    const [searchTerm, setSearchTerm] = useState();
    const [orderBy, setOrderBy] = useState("desc");
    const [sortBy, setSortBy] = useState("created_at");
    const [searchData, setSearchData] = useState("");
    const [tableDataLoaded, setTableDataLoaded] = useState(false);

    const applicationStatusList = [
      { value: "0", label: "Inactive." },
      { value: "1", label: "Active." },
      { value: "2", label: "Document Pending" },
      { value: "3", label: "Draft" },
      { value: "4", label: "Approved" },
      { value: "5", label: "Rejected" },
      { value: "6", label: "Expired" },
      { value: "7", label: "Under Review" },
      { value: "8", label: "Submitted" },
    //   { value: "9", label: "expired" },
      { value: "10", label: "Withdrawal" },
    ];
  
    useEffect(() => {
      getApplicationTableData("created_at", "1", "desc", "");
    }, []);
  
    const getApplicationTableData = async (sortBy, pageNo, orderBy, searchBy) => {
      try {
        setSearchData(searchBy);
        setOrderBy(orderBy);
        setSortBy(sortBy);
        const totalapplicationData = await getallApplicationListSort(sortBy, orderBy, searchBy, appStatus, pageNo, application_type);
        if (totalapplicationData?.status == 200) {
            const data = totalapplicationData?.data?.data
            setCurrentPage(pageNo > 0 ? pageNo - 1 : pageNo);
            setApplicationList(data);
            setTotalPages(
            Math.ceil(
              totalapplicationData?.data?.total /
              totalapplicationData?.data?.per_page
            )
          ); // Calculate total pages
        } else {
            setApplicationList([]);
            setApiMessage({ type: "error", message: totalapplicationData?.message });
        }
        setTableDataLoaded(true);
      } catch (err) { }
    };
  
    // delete Category
    const deleteApplication = async (obj) => {
        try {
            const formData = new FormData();
            formData.append("application_id", obj.id);
            await deleteApplicationData(formData);
            getApplicationTableData(sortBy, currentPage, orderBy, searchData);
            setDeleteData("");
        } catch (error) {
            console.error(error);
        }
    };
    
    const handlePageChange = (selectedPage) => {
        getApplicationTableData(sortBy, selectedPage.selected + 1, orderBy, searchData); 
    };

    const pdfDownload = async () => {
        try {
          const response = await applicationListPdf(appStatus, application_type)
          window.open(response?.file_path);
        } catch (err) {
          setApiMessage({ type: "error", message: err?.message })
        }
      }
    
      const excelDownload = async () => {
        try {
          const response = await applicationListExcel(appStatus, application_type)
          window.open(response?.file_path);
        } catch (err) {
          setApiMessage({ type: "error", message: err?.message })
        }
      }
    
      const csvDownload = async () => {
        try {
          const response = await applicationListCsv(appStatus, application_type)
          window.open(response?.file_path);
        } catch (err) {
          setApiMessage({ type: "error", message: err?.message })
        }
      }
    
      const exportPrint = async () => {
        try {
          // Fetch the PDF URL from the API
          const response = await applicationListPdf(appStatus, application_type);
          const pdfUrl = response?.file_path;
      
          if (!pdfUrl) {
            setApiMessage({ type: "error", message: "Error getting printing data"})
            return
          }
      
          // Fetch the PDF file as a Blob
          const res = await fetch(pdfUrl);
          if (!res.ok) {
            setApiMessage({ type: "error", message: "Error getting printing data"})
            return
          }
          const pdfBlob = await res.blob();
      
          printData(pdfBlob)
          
        } catch (err) {
          setApiMessage({ type: "error", message: err?.message });
        }
      }

    return (
      <AdminDashboard>
           <div className="aod-outer">
          {apiMessage && (<Message apiMessage={apiMessage} setApiMessage={setApiMessage}></Message>)}
          <div className="ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb">
            <div>
              <HomeIcon />
              <b onClick={() => navigate("/admin/")}>Home / </b>
              <span>{title} Applications</span>
            </div>
            <div class="dropdown download_file_dropdown ms-auto d-flex gap-2 mb-3 flex-wrap align-items-center">
            <a class=" px-2 btn btn-danger btn-sm" onClick={() => pdfDownload()} > <i class="fa fa-file-pdf me-1" ></i> PDF</a>
             <a class=" px-2 btn btn-success btn-sm"  onClick={() => excelDownload()} ><i class="fa fa-file-excel  me-1"></i>
                            Excel </a>
                        {/* <a class=" px-2 btn btn-primary btn-sm" onClick={() => csvDownload()} ><i class="fa fa-file-csv  me-1"></i>
                            CSV</a> */}
                <a class=" px-2 btn btn-info  btn-sm" onClick={() => exportPrint()}><i class="fa fa-print me-1"></i>
                    Print</a>
            </div> 
          </div>
          <div className="aod-inner pt-0">
            <div className="aod-bottom">
              <div className="adm-tabs-outer">
                <h4 className="mb-0 w-100">{subTitle} Applications</h4>
                <div className="position-relative w-25">
                  <input
                    className="px-2 form-control"
                    placeholder="Search"
                    type="text"
                    value={searchTerm} // Add a state for the input value
                    onChange={(e) => {
                      setSearchTerm(e.target.value);// Update the state
                      getApplicationTableData(sortBy, 1, orderBy, e.target.value);
                    }}
                  />
                  {searchTerm && ( // Show the clear button only when there's text
                    <button
                      className="btn btn-clear position-absolute"
                      onClick={(e) => {
                        setSearchTerm(""); // Clear the input value
                        getApplicationTableData(sortBy, 1, orderBy, ""); // Fetch data with empty search term
                      }}
                      style={{
                        top: "50%",
                        right: "10px",
                        transform: "translateY(-50%)",
                        border: "none",
                        background: "transparent",
                        fontSize: "1rem",
                        cursor: "pointer",
                      }}
                    >
                      &times; {/* This is the "X" icon */}
                    </button>
                  )}
                </div>
              </div>
              <div className="aod-head"></div>
              <div className="ad-reg-table">
                <div className="ad-reg-table">
                  <Table size="sm" className="ad-cat-table mt-4">
                    <thead>
                      <tr>
                        <th className="ad-sno">S. No</th>
                        <th
                          className="ad-long cursor-pointer"
                          onClick={(e) =>
                            getApplicationTableData(
                                "sub_category_name",
                                currentPage + 1,
                                orderBy === "asc" ? "desc" : "asc",
                                searchData
                            )
                          }
                        >
                          Sub category{" "}
                          <span>
                            <i className="fa-solid fa-sort"></i>
                          </span>
                        </th>
                        <th
                          className="ad-long cursor-pointer"
                          onClick={(e) => 
                            getApplicationTableData(
                                "district_name",
                                currentPage + 1,
                                orderBy === "asc" ? "desc" : "asc",
                                searchData
                            )}
                        >
                          District{" "}
                          <span>
                            <i className="fa-solid fa-sort"></i>
                          </span>{" "}
                        </th>
                        <th
                          className="ad-long cursor-pointer"
                          onClick={(e) => 
                            getApplicationTableData(
                                "tracking_number",
                                currentPage + 1,
                                orderBy === "asc" ? "desc" : "asc",
                                searchData
                            )}
                        >
                          Tracking Number{" "}
                          <span>
                            <i className="fa-solid fa-sort"></i>
                          </span>
                        </th>
                        <th
                          className="ad-long cursor-pointer"
                          onClick={(e) => 
                            getApplicationTableData(
                                "customer_id",
                                currentPage + 1,
                                orderBy === "asc" ? "desc" : "asc",
                                searchData
                            )}
                        >
                          Customer Id{" "}
                          <span>
                            <i className="fa-solid fa-sort"></i>
                          </span>
                        </th>
                        {appStatus === "" || !appStatus && <th className="ad-long">User</th>}
                        <th
                          className="ad-long cursor-pointer"
                          onClick={(e) => 
                            getApplicationTableData(
                                "created_at",
                                currentPage + 1,
                                orderBy === "asc" ? "desc" : "asc",
                                searchData
                            )}
                        >
                          Created At{" "}
                          <span>
                            <i className="fa-solid fa-sort"></i>
                          </span>
                        </th>
                        <th className="ad-long">Status</th>
                        <th className="ad-long">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {applicationList?.length > 0 ? (
                        applicationList?.map((item, index) => (
                          <tr
                            key={index}
                            className={index % 2 != 0 ? "adm-even" : ""}
                          >
                            <td>{currentPage * 10 + (index + 1)}</td>
                            <td>
                              {item?.sub_category_name
                                ? item?.sub_category_name
                                : "--"}
                            </td>
                            <td>
                              {item?.district_name ? item?.district_name : "--"}
                            </td>
                            <td>
                              {item?.tracking_number ? item?.tracking_number : "--"}
                            </td>
                            <td>{item?.customer_id ? item?.customer_id : "--"}</td>
                            <td>
                              {item?.created_at
                                ? formatDateWithSlash(item?.created_at)
                                : "--"}
                            </td>
                            <td>
                              {item?.status
                                ? applicationStatusList?.find(
                                  (option) => option.value == item?.status
                                )?.label
                                : "--"}
                            </td>
  
                            <td>
                              <div
                                className="action-btn d-flex"
                              >
                                <span onClick={() => navigate("/admin/application-view", { state: { data: item, pageFrom: pageFrom } })}>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>View</Tooltip>}
                                    >
                                        <span className="ad-cat-edit">
                                        <EyeIcon />
                                        </span>
                                    </OverlayTrigger>
                                </span>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id={`delete-tooltip-${item.id}`}>
                                        Delete
                                        </Tooltip>
                                    }
                                >
                                <span
                                    className="ad-cat-delete"
                                    onClick={() => setDeleteData(item)}
                                  >
                                    <DeleteIcon />
                                  </span>
                                </OverlayTrigger>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : tableDataLoaded ? (
                        <NotFoundTable colSpan="8"></NotFoundTable>
                      ) : (
                        <></>
                      )}
                    </tbody>
                  </Table>
                  {totalPages > 1 && applicationList?.length > 0 && (
                    <div className="d-flex w-100 justify-content-end">
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        pageCount={totalPages} // Total number of pages from API
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={1}
                        onPageChange={handlePageChange} // Method to handle page click
                        containerClassName={"pagination justify-content-center"} // Custom CSS classes
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={deleteData?.id} onHide={() => setDeleteData("")}>
            <Modal.Header closeButton>
            <Modal.Title>
                Do you really want to delete this Application ?
            </Modal.Title>
            </Modal.Header>
            <Modal.Footer>
            <div className="suf-btn p-2 d-flex flex-row">
                <button
                className="mx-2"
                onClick={() => {
                    deleteApplication(deleteData);
                }}
                >
                Yes
                </button>
                <button
                    className="suf-can-butt no-btn mx-2"
                    onClick={() => setDeleteData("")}
                >
                    No
                </button>
            </div>
            </Modal.Footer>
        </Modal>
      </AdminDashboard>
    )
}

export default StatusApplication