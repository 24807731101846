import React, { useEffect, useState } from "react";
import { CgPen, CgRemove, CgAdd } from "react-icons/cg";
import { useLanguage } from "../LanguageProvider/LanguageProvider ";
import { GetLocalStorage, GetLocalStorageParsed } from "../../Utils/Utils";
import {
  createApplicationForm,
  getApplicationFields,
  getApplicationForm,
} from "../../services/api/user";
import { customStylesAccUser, selectThemeUser } from "../../Utils/common";
import Select from 'react-select';

const DesignationTable = ({ tabsData, subCatId, isEditable, designationKey,  parentApplicationId }) => {
  const { language } = useLanguage();
  const loginCatInfo = GetLocalStorageParsed("LoginSubCatData");
  const userDetail = GetLocalStorageParsed("userDetail");
  const applicationId = GetLocalStorage("application_id");
  const [showEditIcon, setShowEditIcon] = useState(true);
  const [tabsWholeData, setTabsWholeData] = useState([]);
  const [fieldData, setFieldData] = useState([]);
  const [errors, setErrors] = useState({});
  const typeList = [
    { field: 'Employee Name', type: "text", maxlength: 60 },
    { field: 'Date Of Joining', type: 'date' },
    { field: 'Contact No.', type: "text",maxlength: 10 },
    { field: 'Staff Aadhaar No.', type: 'text', maxlength: 12 },
    { field: 'Bank Name', type: "text",  maxlength: 60 },
    { field: 'IFSC Code', type: 'text',  maxlength: 11 },
    { field: 'Bank Account No.', type: "text",  maxlength: 60 }
  ];

  const statusList = [
    { label: "Active", value: "1" },
    { label: "Inactive", value: "0" },
  ]


  useEffect(() => {
    fetchApplicationFormData();
  }, [showEditIcon]);

  const fetchApplicationFormData = async () => {
    try {
      const formData = new FormData();
      formData.append("sub_category_id", loginCatInfo?.id);
      //formData.append("application_detail_id", parentApplicationId && parentApplicationId > 0 ? parentApplicationId : applicationId);
      formData.append("application_detail_id", applicationId);
      const applicationFormResponse = await getApplicationForm(formData);
      if (applicationFormResponse.status === 200) {
        const designationEntry = Object.entries(
          applicationFormResponse?.data || {}
        ).find(([key]) => key === "f_" + designationKey);
        setTabsWholeData((prevdata) => ({
          ...prevdata,
          ...applicationFormResponse?.data,
        }));
        const newRow = {
          "Employee Name": "",
          "Date Of Joining": "",
          "Contact No.": "",
          "Staff Aadhaar No.": "",
          "Bank Account No.": "",
          "Bank Name": "",
          "IFSC Code": "",
          "Status": "",
        };

        const designationValue = designationEntry
          ? JSON.parse(designationEntry[1])
          : [];

        Object.keys(designationValue[0]).forEach((designation) => {
          // If 'Employee Details' does not exist, initialize it with newRow
          if (!designationValue[0][designation]["Employee Details"]) {
            designationValue[0][designation]["Employee Details"] = [newRow];
          } else {
            // If 'Employee Details' exists, check each entry for missing keys and add them
            designationValue[0][designation][
              "Employee Details"
            ] = designationValue[0][designation]["Employee Details"].map(
              (employee) => {
                return {
                  ...newRow,
                  ...employee, // Spread the existing employee details to override newRow defaults where present
                };
              }
            );
          }
        });
        setFieldData(designationValue);
      }
    } catch (err) {
      console.error("Error fetching application data:", err);
    }
  };

  const editToggle = async () => {
    setShowEditIcon(!showEditIcon);
    setErrors([]);
    await fetchApplicationFormData();
  };

  const handleSubmit = async () => {
    if (!validateFields()) {
      console.log("Form submission blocked due to validation errors.");
      return;
    }

    try {
      // Filter and prepare section data
      const sectionData = Object.entries(tabsWholeData || {}).reduce(
        (acc, [key, value]) => {
          if (key.startsWith("f_") && !key.endsWith("json") && value) {
            acc[key] = value;
          }
          return acc;
        },
        {}
      );

      // Filter and prepare subsection data
      const subsectionData = Object.entries(tabsWholeData || {}).reduce(
        (acc, [key, value]) => {
          if (key.endsWith("json") && value) {
            acc[key] = value;
          }
          return acc;
        },
        {}
      );

      const updatedTabsWholeData = { ...sectionData };
      const empDesignationKey = Object.keys(updatedTabsWholeData).find((key) =>
        key === "f_" + designationKey
      );
      if (empDesignationKey) {
        updatedTabsWholeData[empDesignationKey] = JSON.stringify(fieldData);
      }

      const payload = {
        sub_category_id: loginCatInfo?.id,
        user_id: userDetail?.id,
        application_id: applicationId,
        section_fields: updatedTabsWholeData, // Section data as an object
        sub_section_fields: subsectionData, // Subsection data as an object
      };

      const sectionResponse = await createApplicationForm(payload);
      if (sectionResponse.status == 200) {
        console.log("Data saved successfully");
        setTabsWholeData(updatedTabsWholeData);
        setShowEditIcon(!showEditIcon);
        setErrors([]);
      }
    } catch (err) {
      console.error("Error submitting form data:", err);
    }
  };

  const validateFields = () => {
    const newErrors = {};

    fieldData.forEach((designationData, designationIndex) => {
      Object.entries(designationData).forEach(([designation, details]) => {
        // Validate No. of People
        if (
          !details["No. of People"] ||
          details["No. of People"].toString().trim() === ""
        ) {
          newErrors[`${designation}-noOfPeople`] = language
            ? `लोगों की संख्या आवश्यक है`
            : `No. of People is required`;
        }

        // Validate Average Experience
        if (
          !details["Average Experience"] ||
          details["Average Experience"].toString().trim() === ""
        ) {
          newErrors[`${designation}-averageExperience`] = language
            ? `औसत अनुभव आवश्यक है`
            : `Average Experience is required`;
        }

        // Validate Employee Details
        details["Employee Details"].forEach((employee, employeeIndex) => {
          Object.entries(employee).forEach(([key, value]) => {
            if (value.trim() == "") {
              newErrors[`${designation}-${employeeIndex}-${key}`] = language
                ? `${key} आवश्यक है` 
                : `${key} is required`; 
            }else{
              if (key == 'Contact No.' && value.length != 10){
                newErrors[`${designation}-${employeeIndex}-${key}`] = language
                  ? `${key} आवश्यक है`
                  : `${key} is required`; 
              } else if (key == 'Staff Aadhaar No.' && value.length != 12) {
                newErrors[`${designation}-${employeeIndex}-${key}`] = language
                  ? `${key} आवश्यक है`
                  : `${key} is required`;
              } else if (key == 'Bank Account No.' && value.length < 9 && value.length < 11) {
                newErrors[`${designation}-${employeeIndex}-${key}`] = language
                  ? `${key} आवश्यक है`
                  : `${key} is required`;
              } else if (key == 'IFSC Code' && value.length != 11) {
                newErrors[`${designation}-${employeeIndex}-${key}`] = language
                  ? `${key} आवश्यक है`
                  : `${key} is required`;
              } else if (key == 'IFSC Code' && value.length == 11 && !/^[A-Z]{4}\d{7}$/.test(value)){
                newErrors[`${designation}-${employeeIndex}-${key}`] = language
                  ? `${key} आवश्यक है`
                  : `${key} is required`;
              }
            }
          });
        });

      });
    });

    setErrors(newErrors); // Update errors in state
    return Object.keys(newErrors).length == 0; // Return true if no errors
  };

  const handleBlur = (designation, index, key, value) => {
    const updatedErrors = { ...errors };
    if (value.trim() == "" ) {
      updatedErrors[`${designation}-${index}-${key}`] = `${key} is required`;
    }else{
      if (key == 'IFSC Code' && value.length == 11 && !/^[A-Z]{4}\d{7}$/.test(value)) {
        updatedErrors[`${designation}-${index}-${key}`] = language
          ? `${key} आवश्यक है`
          : `${key} is required`;
      }
    }
    setErrors(updatedErrors);
  };

  const updateEmployeeDetails = (designation, index, key, value) => {
   
    switch (key) {
      case "Employee Name":
        if (!/^[a-zA-Z\s]*$/.test(value)){
          return;
        }
        break;

      case "Contact No.":
        if (!/^\d*$/.test(value)) {
          return;
        }
        if (value.length === 1 && /^[0-5]$/.test(value)) {
          return;
        }
        break;
      case "Staff Aadhaar No.":
    
        if (!/^\d*$/.test(value)) {
          return;
        }
        break;
      case "Bank Account No.":
        if (!/^\d*$/.test(value)) {
          return;
        }
        break;
      case "Bank Name":
        if (!/^[a-zA-Z\s]*$/.test(value)) {
          return;
        }
        break;
    }
    const updatedFieldData = [...fieldData];
    updatedFieldData[0][designation]["Employee Details"][index][key] = value;
    setFieldData(updatedFieldData);
    updateNoOfPeople(
      designation,
      updatedFieldData?.[0]?.[designation]?.["Employee Details"]
    );
    const updatedErrors = { ...errors };
   delete updatedErrors[`${designation}-${index}-${key}`];
    setErrors(updatedErrors);
  };

  // This function will update 'No. of People' when the number of rows in 'Employee Details' changes.
  const updateNoOfPeople = (designation, employeeDetails) => {
    const noOfPeople = employeeDetails.length;
    setFieldData((prevFieldData) => {
      const updatedFieldData = [...prevFieldData];
      updatedFieldData[0][designation]["No. of People"] = noOfPeople;
      return updatedFieldData;
    });
  };

 
  const addRow = (designation) => {
    const updatedFieldData = [...fieldData];
    const newRow = {
      "Employee Name": "",
      "Date Of Joining": "",
      "Contact No.": "",
      "Staff Aadhaar No.": "",
      "Bank Account No.": "",
      "Bank Name": "",
      "IFSC Code": "",
      "Status": "",
    };
    updatedFieldData[0][designation]["Employee Details"].push(newRow);
    setFieldData(updatedFieldData);
    updateNoOfPeople(
      designation,
      updatedFieldData?.[0]?.[designation]?.["Employee Details"]
    );
  };

  const removeRow = (designation, index) => {
    const updatedFieldData = [...fieldData];
    if (updatedFieldData[0][designation]["Employee Details"].length > 1) {
      updatedFieldData[0][designation]["Employee Details"].splice(index, 1);

      // Find keys that include `${designation}-${index}`
      const matchingKeys = Object.keys(errors).filter((key) =>
        key.includes(`${designation}-${index}`)
      );

      // Delete the matching keys from the errors object
      matchingKeys.forEach((key) => {
        delete errors[key];
      });

      setFieldData(updatedFieldData);
      updateNoOfPeople(
        designation,
        updatedFieldData?.[0]?.[designation]?.["Employee Details"]
      );
    } else {
    }
  };

  return (
    <>
      {fieldData &&
        fieldData.length > 0 &&
        <div className="card mb-3">
          <div className="card-body bg-card user-tab-table">
            <div className="form-bar d-flex justify-content-between align-items-center p-3">
              <div className="bar-left-detail">
                <h5 className="p-0 text-dark mb-0">
                  {!language ? "Employee’s Details" : "कर्मचारी का विवरण"}
                </h5>
              </div>
              {isEditable ? (
                <div className="data-wrapper-edit-icon">
                  {showEditIcon ? (
                    <CgPen onClick={editToggle} />
                  ) : (
                    <>
                      <div onClick={handleSubmit}>
                        {!language ? "Save" : "सहेजें"}
                      </div>
                      <div onClick={editToggle}>
                        {!language ? "Cancel" : "रद्द करें"}
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <></>
              )}
            </div>

            {/* Render data for each level in fieldData */}
            {fieldData &&
              fieldData.length > 0 &&
              Object.entries(fieldData?.[0])?.map(([designation, details]) => (
                <div key={designation} className="designation-section">
                  <h5 className="designation-title">{designation}</h5>

                  <div className="row mb-3">
                    {/* Render No. of People */}
                    <div className="col-md-6 d-flex align-items-center">
                      <div className="preview-left-heading me-3">
                        <h6 className=" mb-0">
                          {language ? "लोगों की संख्या:" : "No. of People :"}
                        </h6>
                      </div>
                      {!showEditIcon ? (
                        <div>
                          <input
                            min={1}
                            type="number"
                            name="No. of People"
                            className="form-control w-auto"
                            style={{ maxWidth: "150px" }}
                            value={parseInt(details["No. of People"], 10) || ""}
                            onChange={(e) => {
                              let value = e.target.value < 0 ? '1' : e.target.value
                              if (value == "" || /^[1-9]+$/.test(value)) {
                                const updatedFieldData = [...fieldData];
                                updatedFieldData[0][designation]["No. of People"] =
                                  value;
                                // Add rows dynamically based on the No. of People value
                                const currentRowCount =
                                  details["Employee Details"].length;
                                const targetRowCount = parseInt(e.target.value, 10);
                                if (targetRowCount > currentRowCount) {
                                  // Add rows if needed
                                  const rowsToAdd = targetRowCount - currentRowCount;
                                  for (let i = 0; i < rowsToAdd; i++) {
                                    addRow(designation);
                                  }
                                } else if (targetRowCount < currentRowCount) {
                                  // Remove rows if needed
                                  const rowsToRemove =
                                    currentRowCount - targetRowCount;
                                  for (let i = 0; i < rowsToRemove; i++) {
                                    removeRow(designation, currentRowCount - 1 - i);
                                    // Find keys that include `${designation}-${index}`
                                    const matchingKeys = Object.keys(errors).filter((key) =>
                                      key.includes(`${designation}-${i}`)
                                    );

                                    // Delete the matching keys from the errors object
                                    matchingKeys.forEach((key) => {
                                      delete errors[key];
                                    });

                                  }

                                }
                                setFieldData(updatedFieldData);
                              }else{
                                return;
                              }
                              
                            }}
                            onBlur={(e) => {
                              let value = e.target.value
                              const updatedErrors = { ...errors };
                              if (!value || value.trim() == "") {
                                updatedErrors[`${designation}-noOfPeople`] = language ? "लोगों की संख्या आवश्यक है" : "No. of People is required";
                              } else {
                                delete updatedErrors[`${designation}-noOfPeople`];
                              }
                              setErrors(updatedErrors);
                            }}

                          />
                          {
                            errors[`${designation}-noOfPeople`] && (
                              <small className="text-danger">
                                {language ? "लोगों की संख्या आवश्यक है" : "No. of People is required"}
                              </small>
                            )
                          }

                      </div>
                        
                      ) : (
                        <h5 className="no-count mb-0">
                          {parseInt(details["No. of People"], 10) || ""}
                        </h5>
                      )}
                    </div>

                    {/* Render Average Experience */}
                    <div className="col-md-6 d-flex align-items-center">
                      <div className="preview-left-heading me-3">
                        <h6 className="mb-0">
                          {!language ? "Average Experience :" : "औसत अनुभव :"}
                        </h6>
                      </div>
                      {!showEditIcon ? (
                        <div>
                          <input
                            type="text"
                            name="Average Experience"
                            className={`form-control ${
                              errors[`${designation}-averageExperience`]
                                ? "is-invalid"
                                : ""
                            }`}
                            value={details["Average Experience"]}
                            onChange={(e) => {
                              const value = e?.target?.value;
                              if (value == "" || /^[1-9]+$/.test(value)) {
                                const updatedFieldData = [...fieldData];
                                updatedFieldData[0][designation][
                                  "Average Experience"
                                ] = e.target.value;
                                setFieldData(updatedFieldData);
                              }
                            }}
                            onBlur={(e) => {
                              const value = e.target.value;
                              const updatedErrors = { ...errors };
                              if (!value || value.trim() == "") {
                                updatedErrors[`${designation}-averageExperience`] =
                                  language ? "औसत अनुभव आवश्यक है" : "Average Experience is required";
                              } else {
                                delete updatedErrors[`${designation}-averageExperience`];
                              }
                              setErrors(updatedErrors);
                            }}

                          />
                          {errors[`${designation}-averageExperience`] && (
                            <small className="text-danger">
                              {language ? "औसत अनुभव आवश्यक है" : "Average Experience is required"}
                            </small>
                          )}

                        </div>
                      ) : (
                        <h5 className="no-count mb-0">
                          {parseInt(details["Average Experience"], 10) || "0"}
                        </h5>
                      )}
                    </div>
                  </div>

                  {/* Render Employee Details Table */}
                  <div className="table-responsive">
                    <table className="table bg-table mb-0">
                      {details?.["Employee Details"] && (
                        <>
                          <thead>
                            <tr>
                              {Object.keys(details?.["Employee Details"]?.[0]).map(
                                (header) => (
                                  <th scope="col" key={header}>
                                    {/* {header} */}
                                    <>
                                      {(() => {
                                        switch (header) {
                                          case "Employee Name": return language ? "कर्मचारी का नाम" : "Employee Name"
                                          case "Date Of Joining": return language ? "कर्मचारी का नाम" : "Date Of Joining"
                                          case "Contact No.": return language ? "संपर्क नंबर" : "Contact No."
                                          case "Staff Aadhaar No.": return language ? "स्टाफ आधार नंबर" : "Staff Aadhaar No."
                                          case "Bank Name": return language ? "बैंक का नाम" : "Bank Name"
                                          case "Bank Account No.": return language ? "बैंक खाता संख्या" : "Bank Account No."
                                          case "IFSC Code": return language ? "आईएफएससी कोड" : "IFSC Code"
                                          case "Status": return language ? "स्थिति" : "Status"
                                        }
                                      })()}
                                    </>
                                  </th>
                                )
                              )}
                              {!showEditIcon ? <th scope="col">Actions</th> : null}
                            </tr>
                          </thead>
                          <tbody>
                            {details["Employee Details"].map((employee, index) => (
                              <tr key={index}>
                                {Object.entries(employee).map(
                                  ([key, value], idx) => (
                                    <td key={idx}>
                                      {showEditIcon ? (
                                        key != 'Status' ?
                                          <span> { value }</span> :
                                          <span> {statusList?.find(option => option?.label == value ? option?.label == value : option?.value == value)?.label}</span>
                                      ) : (
                                          key != 'Status' ? (
                                            <input
                                              type={typeList.find((x) => x.field == key)?.type}
                                              className="form-control"
                                              value={value}
                                              maxlength={typeList.find((x) => x.field == key)?.maxlength}
                                              onChange={(e) => {
                                                let value = e.target.value
                                                if (key == 'IFSC Code'){
                                                  const input = e.target;
                                                  const cursorPosition = input.selectionStart;
                                                  value = value.toUpperCase()
                                                  window.requestAnimationFrame(() => {
                                                    input.setSelectionRange(cursorPosition, cursorPosition);
                                                  });
                                                }
                                                updateEmployeeDetails(
                                                  designation,
                                                  index,
                                                  key,
                                                  value
                                                );
                                              }}
                                              onBlur={(e) => handleBlur(designation, index, key, e.target.value)}
                                            />
                                          ):
                                            <div className="btn-group" role="group" aria-label="Status Toggle">
                                              <input
                                                type="range"
                                                id="status-slider"
                                                className="form-range custom-slider"
                                                min="0"
                                                max='1'
                                                step="1"
                                                defaultValue={value ? value === "Active" ? '1' : "0" : '1'}
                                                onChange={(e) => {
                                                let value = e?.target?.value === "1" ? "Active" : "Inactive"
                                                  updateEmployeeDetails(
                                                    designation,
                                                    index,
                                                    key,
                                                    value
                                                  );
                                                }}
                                              />
                                            </div>                                    
                                      )}
                                      {errors[`${designation}-${index}-${key}`] && (
                                        <small className="text-danger">
                                          <>
                                            {(() => {
                                              switch (key) {
                                                case "Employee Name": return language ? "कर्मचारी का नाम आवश्यक है" : "Employee Name is required"
                                                case "Date Of Joining": return language ? "कर्मचारी का नाम आवश्यक है" : "Date Of Joining is required"
                                                case "Contact No.": return language ? "संपर्क नंबर आवश्यक है. यह 10 अंक का होना चाहिए" : "Contact No. is required.  It must be 10 digit."
                                                case "Staff Aadhaar No.": return language ? "स्टाफ आधार नंबर आवश्यक है. यह 12 अंक का होना चाहिए" : "Staff Aadhaar No. is required.  It must be 12 digit"
                                                case "Bank Name": return language ? "बैंक का नाम आवश्यक है" : "Bank Name is required"
                                                case "Bank Account No.": return language ? "बैंक खाता संख्या आवश्यक है. यह 9 से 17 अंकों के बीच होना चाहिए" : "Bank Account No. is required. It must be between 9 to 17 digits"
                                                case "IFSC Code": return language ? "आईएफएससी कोड और आईएफएससी कोड आवश्यक है। यह इस प्रारूप में होना चाहिए: XXXX0YYYYYY" : "IFSC Code is required and IFSC code. It must be in the format: XXXX0YYYYYY"
                                                case "Status": return language ? "स्थिति आवश्यक है" : "Status is required"
                                              }
                                            })()}
                                          </>
                                        </small>
                                      )}
                                    </td>
                                  )
                                )}
                                {!showEditIcon && (
                                  <td>
                                    <button
                                      className="btn btn-danger btn-sm"
                                      onClick={() => removeRow(designation, index)}
                                      // disabled={designation["Employee Details"]?.length < 1}
                                    >
                                      <CgRemove />
                                    </button>
                                  </td>
                                )}
                              </tr>
                            ))}
                          </tbody>
                        </>
                      )}
                    </table>
                  </div>

                  {/* Add Row Button */}
                  {!showEditIcon && (
                    <div className="mt-2">
                      <button
                        className="btn btn-primary"
                        onClick={() => addRow(designation)}
                      >
                        <CgAdd />
                      </button>
                    </div>
                  )}
                </div>
              ))}
          </div>
        </div>
      }
    </>
  );
};

export default DesignationTable;
