import React, { useEffect, useState } from "react";
import AdminDashboard from "../AdminDashboard/AdminDashboard";
import { useLocation, useNavigate } from "react-router-dom";
import { Message } from "../../../components/message";
import { BsChevronLeft } from "react-icons/bs";
import { GetLocalStorage, GetLocalStorageParsed } from "../../../Utils/Utils";
import {
  addCertificateType,
  getRolesAllListData,
  getRolesListData,
  storeYearlyTarget,
  submitAdminUserData,
  updateAdminUserData,
  updateCertificateType,
} from "../../../services/api/admin";
import Select from "react-select";
import {
  checkPattern,
  checkPatternWithSpecialCharacter,
  checkValidEmail,
  checkValidMobile,
  customStylesAcc,
  selectTheme,
} from "../../../Utils/common";
import { UploadIcon } from "../AdminIcon";
import BeatLoader from "react-spinners/BeatLoader";
import { stateDistrict, checkEmailMobile } from "../../../services/api/auth";

const AddYearlytarget = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userDetail = GetLocalStorageParsed("userDetail");
  const token = GetLocalStorage("userInfo");
  const [apiMessage, setApiMessage] = useState({ type: "", message: "" });
  const [districtList, setDistrictList] = useState([]);
  const [districtId, setDistrictId] = useState("");
  const [userId, setUserId] = useState("");
  const [target, setTarget] = useState("");
  const [errorTarget, setErrorTarget] = useState("");
  const [districtError, setDistrictError] = useState("");

  useEffect(() => {
    getDistrictList();
  }, []);

  const getDistrictList = async () => {
    try {
      const districtData = await stateDistrict();
      const data = districtData?.data?.data?.map((district) => ({
        label: district?.name_en,
        value: district?.id,
      }));
      setDistrictList(data);
    } catch (err) {
      setApiMessage({ type: "error", message: err?.message });
    }
  };
  const validateDistrict = () => {
    if (!districtId) {
      setDistrictError("Please select district");
    } else {
      setDistrictError("");
    }
  };
  
  const handleSubmit = async (e) => {
   try{
     e.preventDefault();
     if (!districtId && target != '') {
       setErrorTarget("Please Enter the Yearly Target");
       setDistrictError("Please select district");
       return;
     } else {
       const date = new Date();
       const year = date.getFullYear();
       const formData = new FormData()
       formData.append('district_id', districtId)
       formData.append('assigned_by', userDetail?.id)
       formData.append('financial_year', `${year} - ${year + 1}`)
       formData.append('target_value', target)
       formData.append('target_achieved', 0)
       const response = await storeYearlyTarget(formData)
       if (response?.status == 200) {
         navigate("/admin/yearly-targets", { state: { message: response?.message } })
       } else {
         setApiMessage({ type: 'error', message: response.message })
       }
     }
   }catch(err){
     setApiMessage({ type: 'error', message: err.message })
   }
  };

  const updateFields = async (e) => {};

  return (
    <AdminDashboard>
      <div className="aod-outer">
        {apiMessage && (
          <Message
            apiMessage={apiMessage}
            setApiMessage={setApiMessage}
          ></Message>
        )}
        <div className="ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb">
          <div>
            <b onClick={() => navigate("/admin/")}>Home /</b>
            <span> Yearly Target/{userId ? "Update" : "Add"}</span>
          </div>
        </div>
        <div className="aod-inner">
          <div className={userId ? "aod-bottom" : "aod-bottom"}>
            <div className="aod-head text-center adm-head">
              <button onClick={() => navigate("/admin/yearly-targets")}>
                <BsChevronLeft /> Back
              </button>
              {userId ? "Update" : "Add"} Yearly Target
            </div>
            <form>
              <div className="row py-4">
                <div className="col-md-4 aod-resp-tab">
                  <div className="suf-input-box aod-form-input mb-2">
                    <label className="mb-1 w-100">District</label>
                    <Select
                      styles={customStylesAcc}
                      theme={selectTheme}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      className="my-select selectpicker"
                      classNamePrefix="ad-menu_open_select_class"
                      aria-label="Default select example"
                      name="role"
                      value={
                        districtList?.find(
                          (option) => option.value == districtId
                        ) || null
                      }
                      options={districtList}
                      onBlur={validateDistrict}
                      onChange={(selectedOption) => {
                        setDistrictId(selectedOption.value);
                        setDistrictError("");
                      }} // Use onChange for real-time validation
                      placeholder="Select District"
                    />
                    {districtError && (
                      <p className="validate-error">{districtError}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-4 aod-resp-tab">
                  <div className="suf-input-box aod-form-input mb-2">
                    <label className="mb-1 w-100">Target</label>
                    <input
                      className="w-100"
                      max={99999}
                      min={1}
                      name="target"
                      placeholder="Enter Target"
                      type="number"
                      onChange={(e) => {
                        let value = e?.target?.value
                        if (!/^\d*$/.test(value)) {
                          return;
                        }
                        if (value > 99999){
                          value = 99999
                          setTarget(value);
                        }else{
                          setTarget(value);
                        }
                        setErrorTarget("");
                      }}
                      onBlur={(e) => {
                        const value = e?.target?.value;
                        if (!value || value == "") {
                          setErrorTarget("Please Enter the Yearly Target");
                        }
                      }}
                      value={target}
                    />
                    {errorTarget && (
                      <p className="validate-error">{errorTarget}</p>
                    )}
                  </div>
                </div>
                <div className="row ad-cat-row">
                  <div className=" update-btns-block mt-3">
                    {userId ? (
                      <>
                        <button onClick={(e) => updateFields(e)}>Update</button>
                      </>
                    ) : (
                      <button onClick={(e) => handleSubmit(e)}>Submit</button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </AdminDashboard>
  );
};

export default AddYearlytarget;
