import { DeleteIcon, EditIcon } from "../AdminIcon";
import React, { useEffect, useState } from "react";
import AdminDashboard from "../AdminDashboard/AdminDashboard";
import {
    emailLogListCsv,
    emailLogListExcel,
    emailLogListPdf,
    getEmailLogsDataSort,
} from "../../../services/api/admin";
import { HomeIcon } from "../../../components/Application/ApplicationIcon";
import ReactPaginate from "react-paginate";
import NotFoundTable from "../../../components/notFound/NotFoundTable";
import { formatDateTimeWithSlash, formatDateWithSlash, printData, removeHTMLTags } from "../../../Utils/common";
import { useNavigate } from "react-router-dom";
import { Table, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Message } from "../../../components/message";

const EmailLog = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const navigate = useNavigate();
    const [emailLogList, setEmailLogList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [apiMessage, setApiMessage] = useState({ type: "", message: "" });
    const [tableDataLoaded, setTableDataLoaded] = useState(false);
    const [orderBy, setOrderBy] = useState("desc");
    const [searchTerm, setSearchTerm] = useState();
    const [sortBy, setSortBy] = useState("created_at");
    const [searchData, setSearchData] = useState("");

    useEffect(() => {
        emailLogsList("created_at","1", "desc", "");
    }, []);

    const emailLogsList = async (sortBy, pageNo, orderBy, searchBy) => {
        try {
            setSearchData(searchBy);
            setOrderBy(orderBy);
            setSortBy(sortBy);
            const usersListData = await getEmailLogsDataSort(sortBy, orderBy, searchBy, pageNo);
            if (usersListData.status === 200) {
                setCurrentPage(pageNo > 0 ? pageNo - 1 : pageNo);
                setEmailLogList(usersListData?.data?.data || []);
                setTotalPages(
                    Math.ceil(usersListData?.data?.total / usersListData?.data?.per_page)
                );
                // setCurrentPage(pageNo > 0 ? pageNo - 1 : pageNo);
                setTableDataLoaded(true);
            }
        } catch (err) {
            setTableDataLoaded(true);
            setApiMessage({ type: "error", message: err?.message });
        }
    };

    const handlePageClick = (data) => {
        emailLogsList(sortBy, data.selected + 1, orderBy, searchData);
    };

    const pdfDownload = async () => {
                try {
                  const response = await emailLogListPdf()
                  window.open(response?.file_path);
                } catch (err) {
                  setApiMessage({ type: "error", message: err?.message })
                }
              }
            
              const excelDownload = async () => {
                try {
                  const response = await emailLogListExcel()
                  window.open(response?.file_path);
                } catch (err) {
                  setApiMessage({ type: "error", message: err?.message })
                }
              }
            
              const csvDownload = async () => {
                try {
                  const response = await emailLogListCsv()
                  window.open(response?.file_path);
                } catch (err) {
                  setApiMessage({ type: "error", message: err?.message })
                }
              }
            
              const exportPrint = async () => {
                try {
                  // Fetch the PDF URL from the API
                  const response = await emailLogListPdf();
                  const pdfUrl = response?.file_path;
              
                  if (!pdfUrl) {
                    setApiMessage({ type: "error", message: "Error getting printing data"})
                    return
                  }
              
                  // Fetch the PDF file as a Blob
                  const res = await fetch(pdfUrl);
                  if (!res.ok) {
                    setApiMessage({ type: "error", message: "Error getting printing data"})
                    return
                  }
                  const pdfBlob = await res.blob();
              
                  printData(pdfBlob)
                  
                } catch (err) {
                  setApiMessage({ type: "error", message: err?.message });
                }
              }

    return (
        <AdminDashboard>
            <div className="aod-outer">
                <div className="ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb">
                    <div>
                        <HomeIcon /><b onClick={() => navigate("/admin/")}>Home  / </b>
                        <span>Email-Logs</span>
                    </div>
                    <div class="dropdown download_file_dropdown ms-auto d-flex gap-2 mb-3 flex-wrap align-items-center">
                        <a class=" px-2 btn btn-danger btn-sm" onClick={() => pdfDownload()} > <i class="fa fa-file-pdf me-1" ></i> PDF</a>
                        <a class=" px-2 btn btn-success btn-sm"  onClick={() => excelDownload()} ><i class="fa fa-file-excel  me-1"></i>
                                        Excel </a>
                                    {/* <a class=" px-2 btn btn-primary btn-sm" onClick={() => csvDownload()} ><i class="fa fa-file-csv  me-1"></i>
                                        CSV</a> */}
                            <a class=" px-2 btn btn-info  btn-sm" onClick={() => exportPrint()}><i class="fa fa-print me-1"></i>
                                Print</a>
                    </div> 
                </div>
                <div className="aod-inner pt-0">
                    <div className="aod-bottom">
                        <div className="adm-tabs-outer">
                            <h4 className="mb-0 w-100">Email Logs</h4>
                            <div className="position-relative w-25">
                                <input
                                    className="px-2 form-control"
                                    placeholder="Search"
                                    type="text"
                                    value={searchTerm} // Add a state for the input value
                                    onChange={(e) => {
                                    setSearchTerm(e.target.value); // Update the state
                                    emailLogsList(sortBy, 1, orderBy, e.target.value);
                                    }}
                                />
                                {searchTerm && ( // Show the clear button only when there's text
                                    <button
                                    className="btn btn-clear position-absolute"
                                    onClick={(e) => {
                                        setSearchTerm(""); // Clear the input value
                                        emailLogsList(sortBy, 1, orderBy, ""); // Fetch data with empty search term
                                    }}
                                    style={{
                                        top: "50%",
                                        right: "10px",
                                        transform: "translateY(-50%)",
                                        border: "none",
                                        background: "transparent",
                                        fontSize: "1rem",
                                        cursor: "pointer",
                                    }}
                                    >
                                    &times; {/* This is the "X" icon */}
                                    </button>
                                )}
                                </div>
                        </div>
                        <div className="aod-head"></div>
                        <div className="ad-reg-table">
                            <Table size="sm" className="ad-cat-table mt-4">
                                <thead>
                                    <tr>
                                        <th className="ad-sno">S. No</th>
                                        <th className="ad-sno" onClick={(e) => emailLogsList("from", currentPage + 1, orderBy === "asc" ? "desc" : "asc", '')}>From<span>
                                            <i className="fa-solid fa-sort"></i>
                                        </span></th>
                                        <th className="ad-sno" onClick={(e) => emailLogsList("to", currentPage + 1, orderBy === "asc" ? "desc" : "asc", '')}>To<span>
                                            <i className="fa-solid fa-sort"></i>
                                        </span> </th>
                                        <th className="ad-sno" onClick={(e) => emailLogsList("subject", currentPage + 1, orderBy === "asc" ? "desc" : "asc", '')}>Subject<span>
                                            <i className="fa-solid fa-sort"></i>
                                        </span></th>
                                        <th className="ad-long" onClick={(e) => emailLogsList("description", currentPage + 1, orderBy === "asc" ? "desc" : "asc", '')}>Description<span>
                                            <i className="fa-solid fa-sort"></i>
                                        </span></th>
                                        <th className="ad-sno">Status
                                        </th>
                                        <th className="ad-long" onClick={(e) => emailLogsList("created_at", currentPage + 1, orderBy === "asc" ? "desc" : "asc", '')}>Created At<span>
                                            <i className="fa-solid fa-sort"></i>
                                        </span></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {emailLogList.length > 0 ? (
                                        emailLogList.map((item, index) => (
                                            <tr
                                                    key={index}
                                                    className={index % 2 !== 0 ? "adm-even" : ""}
                                                >
                                                <td>{currentPage * 10 + (index + 1)}</td>
                                                <td>{item?.from}</td>
                                                <td>{item?.to}</td>
                                                <td>{item.subject}</td>
                                                {/* <td>{item.description}</td> */}
                                                <td>
                                                    {item?.description
                                                        ? item.description.length > 30
                                                            ? removeHTMLTags(item.description).substring(0, 30) +
                                                            "..."
                                                            : removeHTMLTags(item.description)
                                                        : "--"}
                                                </td>
                                                <td>
                                                    <span
                                                        className={
                                                            item?.status == "Success"
                                                                ? "badge rounded-pill text-bg-success"
                                                                : "badge rounded-pill text-bg-danger"
                                                        }
                                                    >
                                                        {item.status}
                                                    </span>
                                                </td>
                                                <td>{formatDateTimeWithSlash(item.created_at)}</td>
                                            </tr>
                                        ))
                                    ) : tableDataLoaded ? (
                                        <NotFoundTable colSpan="8"></NotFoundTable>
                                      ) : (
                                        <></>
                                      )}
                                </tbody>
                            </Table>
                            {totalPages > 1 && emailLogList?.length > 0 && (
                                <div className="d-flex w-100 justify-content-end">
                                <ReactPaginate
                                            previousLabel={"Previous"}
                                            nextLabel={"Next"}
                                            breakLabel={"..."}
                                            pageCount={totalPages} // Total number of pages from API
                                            marginPagesDisplayed={1}
                                            pageRangeDisplayed={1}
                                            onPageChange={handlePageClick} // Method to handle page click
                                            containerClassName={"pagination justify-content-center"} // Custom CSS classes
                                            pageClassName={"page-item"}
                                            pageLinkClassName={"page-link"}
                                            previousClassName={"page-item"}
                                            previousLinkClassName={"page-link"}
                                            nextClassName={"page-item"}
                                            nextLinkClassName={"page-link"}
                                            breakClassName={"page-item"}
                                            breakLinkClassName={"page-link"}
                                            activeClassName={"active"}
                                        />
                                        </div>
                                )}
                        </div>
                    </div>
                </div>
            </div>
        </AdminDashboard>
    );
};

export default EmailLog;
