import React, { useEffect, useState } from "react";
import { CgPen } from "react-icons/cg";
import { GetLocalStorage, GetLocalStorageParsed, SetLocalStorageStringify } from "../../../Utils/Utils";
import {
  createApplicationForm,
  getApplicationForm,
} from "../../../services/api/user";

const DesignationTableView = ({
  showEditIcon,
  mainPageApplicationData,
  tabsWholeData,
  // setEmpDetailsData,
  setShowEditIcon,
  designationKey,
  parentApplicationId
}) => {
  const [fieldData, setFieldData] = useState([]);
  const [errors, setErrors] = useState({});
  useEffect(() => {
    fetchApplicationFormData();
  }, [showEditIcon]);

  useEffect(() => {
    SetLocalStorageStringify('empDetails', fieldData)
  }, [fieldData]);

  const fetchApplicationFormData = async () => {
    try {
      const formData = new FormData();
      formData.append("sub_category_id", mainPageApplicationData?.category_id);
      //formData.append("application_detail_id", parentApplicationId && parentApplicationId > 0 ? parentApplicationId : mainPageApplicationData?.id);
      formData.append("application_detail_id", mainPageApplicationData?.id);
      const applicationFormResponse = await getApplicationForm(formData);
      if (applicationFormResponse.status === 200) {
        const designationEntry = Object.entries(
          applicationFormResponse?.data || {}
        ).find(([key]) => key === "f_" + designationKey);
        const newRow = {
          "Employee Name": "",
          "Date Of Joining": "",
          "Contact No.": "",
          "Staff Aadhaar No.": "",
          "Bank Account No.": "",
          "Bank Name": "",
          "IFSC Code": "",
          "Status": "",
        };

        const designationValue = designationEntry
          ? JSON.parse(designationEntry[1])
          : [];

        Object.keys(designationValue[0]).forEach((designation) => {
          // If 'Employee Details' does not exist, initialize it with newRow
          if (!designationValue[0][designation]["Employee Details"]) {
            designationValue[0][designation]["Employee Details"] = [newRow];
          } else {
            // If 'Employee Details' exists, check each entry for missing keys and add them
            designationValue[0][designation][
              "Employee Details"
            ] = designationValue[0][designation]["Employee Details"].map(
              (employee) => {
                return {
                  ...newRow,
                  ...employee, // Spread the existing employee details to override newRow defaults where present
                };
              }
            );
          }
        });
        setFieldData(designationValue);
      }
    } catch (err) {
      console.error("Error fetching application data:", err);
    } finally {
      const sectionData = Object.entries(tabsWholeData || {}).reduce(
        (acc, [key, value]) => {
          if (key.startsWith("f_") && !key.endsWith("json") && value) {
            acc[key] = value;
          }
          return acc;
        },
        {}
      );
      const updatedTabsWholeData = { ...sectionData };
      const empDesignationKey = Object.keys(updatedTabsWholeData).find((key) =>
        key === "f_" + designationKey
      );
      if (empDesignationKey) {
        updatedTabsWholeData[empDesignationKey] = JSON.stringify(fieldData);
      }
    }
  };

  const updateEmployeeDetails = (designation, index, key, value) => {
    const updatedFieldData = [...fieldData];
    updatedFieldData[0][designation]["Employee Details"][index][key] = value;
    setFieldData(updatedFieldData);
    updateNoOfPeople(
      designation,
      updatedFieldData?.[0]?.[designation]?.["Employee Details"]
    );
  };

  // This function will update 'No. of People' when the number of rows in 'Employee Details' changes.
  const updateNoOfPeople = (designation, employeeDetails) => {
    const noOfPeople = employeeDetails.length;
    setFieldData((prevFieldData) => {
      const updatedFieldData = [...prevFieldData];
      updatedFieldData[0][designation]["No. of People"] = noOfPeople;
      return updatedFieldData;
    });
  };


  return (
    <div className="card mb-3">
      <div className="card-body bg-card user-tab-table admin-view-table">
        <div className="form-bar d-flex justify-content-between align-items-center p-3">
          <div className="bar-left-detail">
            <h5 className="p-0 text-dark mb-0">Employee’s Details</h5>
          </div>
        </div>

        {/* Render data for each level in fieldData */}
        {
        fieldData &&
          fieldData.length > 0 &&
          Object.entries(fieldData?.[0])?.map(([designation, details]) => (
            <div key={designation} className="designation-section">
              <h5 className="designation-title">{designation}</h5>

              <div className="row mb-3">
                {/* Render No. of People */}
                <div className="col-md-6 d-flex align-items-center">
                  <div className="preview-left-heading me-3">
                    <h6 className=" mb-0">No. of People :</h6>
                  </div>
                  {!showEditIcon ? (
                    <div>
                      <input
                        type="number"
                        disabled
                        name="No. of People"
                        className="form-control w-auto"
                        style={{ maxWidth: "150px" }}
                        value={parseInt(details["No. of People"], 10) || ""}
                        onChange={(e) => {
                          const updatedFieldData = [...fieldData];
                          updatedFieldData[0][designation]["No. of People"] =
                            e.target.value;
                          // Add rows dynamically based on the No. of People value
                          const currentRowCount =
                            details["Employee Details"].length;
                          const targetRowCount = parseInt(e.target.value, 10);
                          if (targetRowCount > currentRowCount) {
                            // Add rows if needed
                            const rowsToAdd = targetRowCount - currentRowCount;
                            // for (let i = 0; i < rowsToAdd; i++) {
                            //     addRow(designation);
                            // }
                          } else if (targetRowCount < currentRowCount) {
                            // Remove rows if needed
                            const rowsToRemove =
                              currentRowCount - targetRowCount;
                            // for (let i = 0; i < rowsToRemove; i++) {
                            //     removeRow(designation, currentRowCount - 1 - i);
                            // }
                          }
                          setFieldData(updatedFieldData);
                        }}
                      />
                    </div>
                  ) : (
                    <h5 className="no-count mb-0">
                      {parseInt(details["No. of People"], 10) || ""}
                    </h5>
                  )}
                </div>

                {/* Render Average Experience */}
                <div className="col-md-6 d-flex align-items-center">
                  <div className="preview-left-heading me-3">
                    <h6 className="mb-0">Average Experience : </h6>
                  </div>
                  {!showEditIcon ? (
                    <div>
                      <input
                        type="text"
                        name="Average Experience"
                        className={`form-control ${errors[`${designation}-averageExperience`] ? "is-invalid" : ""}`}
                        value={details["Average Experience"]}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^\d*$/.test(value)) { // Check if the input contains only numbers
                            const updatedFieldData = [...fieldData];
                            updatedFieldData[0][designation]["Average Experience"] = value;
                            setFieldData(updatedFieldData);
                          }
                        }}
                        placeholder="Enter numbers only"
                      />

                    </div>
                  ) : (
                    <h5 className="no-count mb-0">
                      {parseInt(details["Average Experience"], 10) || "0"}
                    </h5>
                  )}
                </div>
              </div>

              {/* Render Employee Details Table */}
              <div className="table-responsive">
                <table className="table bg-table mb-0">
                  {details?.["Employee Details"] && (
                    <>
                      <thead>
                        <tr>
                          {Object.keys(details?.["Employee Details"]?.[0]).map(
                            (header) => (
                              <th scope="col" key={header}>
                                {header}
                              </th>
                            )
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {details["Employee Details"].map((employee, index) => (
                          <tr key={index}>
                            {Object.entries(employee).map(
                              ([key, value], idx) => (
                                <td key={idx}>
                                  {showEditIcon ? (
                                    <span>{value}</span>
                                  ) : (
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={value}
                                      onChange={(e) => {
                                        updateEmployeeDetails(
                                          designation,
                                          index,
                                          key,
                                          e.target.value
                                        );
                                      }}
                                    />
                                  )}
                                </td>
                              )
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </>
                  )}
                </table>
              </div>

              {/* Add Row Button */}
            </div>
          ))
          }
      </div>
    </div>
  );
};

export default DesignationTableView;
