import React, { useEffect, useState } from "react";
import AdminDashboard from "../AdminDashboard/AdminDashboard";
import { Message } from "../../../components/message";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal, Table } from "react-bootstrap";
import { HomeIcon } from "../../../components/Application/ApplicationIcon";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { DeleteIcon, EditIcon } from "../AdminIcon";
import {
    getTransactionList,
    transactionListCsv,
    transactionListExcel,
    transactionListPdf,
} from "../../../services/api/admin";
import ReactPaginate from "react-paginate";
import { formatDateWithSlash, printData } from "../../../Utils/common";
import NotFoundTable from "../../../components/notFound/NotFoundTable";
import { HiPlus } from "react-icons/hi";

const TransactionList = () => {
    const navigate = useNavigate();
    const [transactionList, setTransactionList] = useState([]);
    const [apiMessage, setApiMessage] = useState({ type: "", message: "" });
    const [currentPage, setCurrentPage] = useState(1);
    const [deleteData, setDeleteData] = useState();
    const [totalPages, setTotalPages] = useState(0); // Total number of pages
    const [searchTerm, setSearchTerm] = useState();
    const [orderBy, setOrderBy] = useState("desc");
    const [sortBy, setSortBy] = useState("created_at");
    const [searchData, setSearchData] = useState("");
    const [tableDataLoaded, setTableDataLoaded] = useState(false);
    const { state } = useLocation();

    const transactionStatusList = [
        { value: "1", label: "Success" },
        { value: "2", label: "Cancelled" },
        { value: "3", label: "Pending" }
    ];
  
    useEffect(() => {
      getTransactionListData("created_at", "1", "desc", "");
    }, []);
  
    useEffect(() => {
      if (state && state.message) {
        setApiMessage({ type: "success", message: state.message });
        // Clear the message once it is rendered
        window.scrollTo(0, 0);
        window.history.replaceState({}, document.title);
      }
    }, [state]);
  
    const getTransactionListData = async (sortBy, pageNo, orderBy, searchBy) => {
      try {
        setSearchData(searchBy);
        setOrderBy(orderBy);
        setSortBy(sortBy);
        const transactionListData = await getTransactionList(
          sortBy === "userName" ? sortBy : "transactions." + sortBy,
          orderBy,
          searchBy,
          pageNo
        );
        if (transactionListData?.status == 200) {
          const data = transactionListData?.data?.data;
          setCurrentPage(pageNo > 0 ? pageNo - 1 : pageNo);
          setTransactionList(data);
          setTotalPages(
            Math.ceil(transactionListData?.data?.total / transactionListData?.data?.per_page)
          ); // Calculate total pages
        } else {
          setTransactionList([]);
        }
        setTableDataLoaded(true);
      } catch (err) {
        setTableDataLoaded(true);
      }
    };
  
    // delete Category
    const handlePageChange = (selectedPage) => {
      getTransactionListData(sortBy, selectedPage.selected + 1, orderBy, searchData);
    };

    const pdfDownload = async () => {
              try {
                const response = await transactionListPdf()
                window.open(response?.file_path);
              } catch (err) {
                setApiMessage({ type: "error", message: err?.message })
              }
            }
          
            const excelDownload = async () => {
              try {
                const response = await transactionListExcel()
                window.open(response?.file_path);
              } catch (err) {
                setApiMessage({ type: "error", message: err?.message })
              }
            }
          
            const csvDownload = async () => {
              try {
                const response = await transactionListCsv()
                window.open(response?.file_path);
              } catch (err) {
                setApiMessage({ type: "error", message: err?.message })
              }
            }
          
            const exportPrint = async () => {
              try {
                // Fetch the PDF URL from the API
                const response = await transactionListPdf();
                const pdfUrl = response?.file_path;
            
                if (!pdfUrl) {
                  setApiMessage({ type: "error", message: "Error getting printing data"})
                  return
                }
            
                // Fetch the PDF file as a Blob
                const res = await fetch(pdfUrl);
                if (!res.ok) {
                  setApiMessage({ type: "error", message: "Error getting printing data"})
                  return
                }
                const pdfBlob = await res.blob();
            
                printData(pdfBlob)
                
              } catch (err) {
                setApiMessage({ type: "error", message: err?.message });
              }
            }
  
    return (
      <AdminDashboard>
        <div className="aod-outer">
          {apiMessage && (
            <Message
              apiMessage={apiMessage}
              setApiMessage={setApiMessage}
            ></Message>
          )}
          <div className="ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb">
            <div>
              <HomeIcon />
              <b onClick={() => navigate("/admin/")}>Home / </b>
              <span>Transaction List</span>
            </div>
            <div class="dropdown download_file_dropdown ms-auto d-flex gap-2 mb-3 flex-wrap align-items-center">
                <a class=" px-2 btn btn-danger btn-sm" onClick={() => pdfDownload()} > <i class="fa fa-file-pdf me-1" ></i> PDF</a>
                <a class=" px-2 btn btn-success btn-sm"  onClick={() => excelDownload()} ><i class="fa fa-file-excel  me-1"></i>
                                Excel </a>
                            {/* <a class=" px-2 btn btn-primary btn-sm" onClick={() => csvDownload()} ><i class="fa fa-file-csv  me-1"></i>
                                CSV</a> */}
                    <a class=" px-2 btn btn-info  btn-sm" onClick={() => exportPrint()}><i class="fa fa-print me-1"></i>
                        Print</a>
              </div> 
          </div>
          <div className="aod-inner pt-0">
            <div className="aod-bottom">
              <div className="adm-tabs-outer">
                <h4 className="mb-0 w-100">Transaction List</h4>
                <div className="position-relative w-25">
                  <input
                    className="px-2 form-control"
                    placeholder="Search"
                    type="text"
                    value={searchTerm} // Add a state for the input value
                    onChange={(e) => {
                      setSearchTerm(e.target.value); // Update the state
                      getTransactionListData(sortBy, 1, orderBy, e.target.value);
                    }}
                  />
                  {searchTerm && ( // Show the clear button only when there's text
                    <button
                      className="btn btn-clear position-absolute"
                      onClick={(e) => {
                        setSearchTerm(""); // Clear the input value
                        getTransactionListData(sortBy, 1, orderBy, ""); // Fetch data with empty search term
                      }}
                      style={{
                        top: "50%",
                        right: "10px",
                        transform: "translateY(-50%)",
                        border: "none",
                        background: "transparent",
                        fontSize: "1rem",
                        cursor: "pointer",
                      }}
                    >
                      &times; {/* This is the "X" icon */}
                    </button>
                  )}
                </div>
              </div>
              <div className="aod-head"></div>
              <div className="ad-reg-table">
                <div className="ad-reg-table">
                  <Table size="sm" className="ad-cat-table  mt-4">
                    <thead>
                      <tr>
                        <th className="ad-sno">S. No</th>
                        <th
                          className="ad-long cursor-pointer"
                          onClick={(e) =>
                            getTransactionListData(
                              "userName",
                              currentPage + 1,
                              orderBy === "asc" ? "desc" : "asc",
                              searchData
                            )
                          }
                        >
                          {" "}
                          User{" "}
                          <span>
                            <i className="fa-solid fa-sort"></i>
                          </span>
                        </th>
                        <th className="ad-long">Tracking Number</th>
                        <th
                          className="ad-long cursor-pointer"
                          onClick={(e) =>
                            getTransactionListData(
                              "transaction_id",
                              currentPage + 1,
                              orderBy === "asc" ? "desc" : "asc",
                              searchData
                            )
                          }
                        >
                          {" "}
                          Transaction Id{" "}
                          <span>
                            <i className="fa-solid fa-sort"></i>
                          </span>
                        </th>
                        <th className="ad-long">Phone</th>
                        <th
                          className="ad-long cursor-pointer"
                          onClick={(e) =>
                            getTransactionListData(
                              "easepayid",
                              currentPage + 1,
                              orderBy === "asc" ? "desc" : "asc",
                              searchData
                            )
                          }
                        >
                          {" "}
                          Easepay ID{" "}
                          <span>
                            <i className="fa-solid fa-sort"></i>
                          </span>
                        </th>
                        <th
                          className="ad-long cursor-pointer"
                          onClick={(e) =>
                            getTransactionListData(
                              "payment_mode",
                              currentPage + 1,
                              orderBy === "asc" ? "desc" : "asc",
                              searchData
                            )
                          }
                        >
                          {" "}
                          Payment Mode{" "}
                          <span>
                            <i className="fa-solid fa-sort"></i>
                          </span>
                        </th>
                        <th
                          className="ad-long cursor-pointer"
                          onClick={(e) =>
                            getTransactionListData(
                              "amount",
                              currentPage + 1,
                              orderBy === "asc" ? "desc" : "asc",
                              searchData
                            )
                          }
                        >
                          {" "}
                          Amount{" "}
                          <span>
                            <i className="fa-solid fa-sort"></i>
                          </span>
                        </th>
                        <th className="ad-long">Status</th>
                        <th
                          className="ad-long cursor-pointer"
                          onClick={(e) =>
                            getTransactionListData(
                              "created_at",
                              currentPage + 1,
                              orderBy === "asc" ? "desc" : "asc",
                              searchData
                            )
                          }
                        >
                          Created At{" "}
                          <span>
                            <i className="fa-solid fa-sort"></i>
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {transactionList?.length > 0 ? (
                        transactionList?.map((item, index) => (
                          <tr
                            key={index}
                            className={index % 2 !== 0 ? "adm-even" : ""}
                          >
                            <td>{currentPage * 10 + (index + 1)}</td>
                            <td>{item?.userName ? item?.userName : "--"}</td>
                            <td>{item?.tracking_number ? item?.tracking_number : "--"}</td>
                            <td>{item?.transaction_id ? item?.transaction_id : "--"}</td>
                            <td>{item?.phone ? item?.phone : "--"}</td>
                            <td>{item?.easepayid ? item?.easepayid : "--"}</td>
                            <td>{item?.payment_mode ? item?.payment_mode : "--"}</td>
                            <td>{item?.amount ? item?.amount : "--"}</td>
                            <td>{item?.status ? transactionStatusList?.find((t) => t?.value == item?.status)?.label : "--"}</td>
                            <td>
                              {item?.created_at
                                ? formatDateWithSlash(item?.created_at)
                                : "--"}
                            </td>
                          </tr>
                        ))
                      ) : tableDataLoaded ? (
                        <NotFoundTable colSpan="8"></NotFoundTable>
                      ) : (
                        <></>
                      )}
                    </tbody>
                  </Table>
                  {totalPages > 1 && transactionList?.length > 0 && (
                    <div className="d-flex w-100 justify-content-end">
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        pageCount={totalPages} // Total number of pages from API
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={1}
                        onPageChange={handlePageChange} // Method to handle page click
                        containerClassName={"pagination justify-content-center"} // Custom CSS classes
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminDashboard>
    );
}

export default TransactionList