import React, { useEffect, useState } from 'react'
import Banner from '../../components/Banner/Banner'
import CategoryInfoDetail from '../../components/CategoryInfo/CategoryInfoDetail'
import { useLanguage } from '../../components/LanguageProvider/LanguageProvider '
import MainLayout from '../../components/MainLayout/MainLayout'
import { getHomePageContent } from "../../services/api/outer"
import { ClearLocalStorage, GetLocalStorage, GetLocalStorageParsed } from "../../Utils/Utils"
import { Link } from 'react-router-dom'

const CategoryInfo = () => {
    const {language}=useLanguage();
    const [homePageData, setHomePageData] = useState(null);
    const [loading, setLoading] = useState(true);
    const getLoginSubCabData = GetLocalStorageParsed("subCategoryClick");

    useEffect(() => {
      window.scrollTo(0, 0);
     
      const fetchData = async () => {
        try {
          const [homePageDataResponse] = await Promise.all([
            getHomePageContent(),
          ]);
          setHomePageData(homePageDataResponse);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching data", error);
          setLoading(false);
        } finally {
          setLoading(false);
        }
      };
  
      fetchData();
    }, []);
  
    if (loading || homePageData === null) {
      return <></>;
    }
    return (
        <MainLayout homePageData={homePageData}>
              <section className="contact-inner sub-cat-section-padding">
              <section className="banner-section banner pt-5">
                <div className="banner-inner">
                  <div className="container">
                    <div className="row ">
                      <h2 className="banner-head">{language ? getLoginSubCabData?.category_name_hi ?? "श्रेणी जानकारी" : getLoginSubCabData?.category_name ?? "Category Info "}</h2>
                      <div className="banner-breadcrumb">
                        <h5>
                          <Link to="/" className="bread_crumb_f">
                            {language ? "होम": "Home"}{" "}
                          </Link>{" "}
                          <span> /{language ? "श्रेणी जानकारी":"Category Info "}</span>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            <CategoryInfoDetail/></section>
        </MainLayout>
    )
}

export default CategoryInfo
