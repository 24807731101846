import React, { useEffect, useState } from 'react';
import Select from 'react-select'; // Ensure you have installed react-select
import { dropDownsList, staticDropDownsList } from '../../services/api/user';
import { useLanguage } from '../LanguageProvider/LanguageProvider ';

const 
SelectType = ({ value, dropdownId, fieldKey, onChange, placeholder, styles, theme, ukStateId, fieldsDisabled = false }) => {
    const { language } = useLanguage();
    const [options, setOptions] = useState([]);

    useEffect(() => {
        const fetchOptions = async () => {
            try {
                const formData = new FormData();
                formData.append("dropdown_id", dropdownId);
                const response = await dropDownsList(formData);
                const ParsedDropdownValue = JSON.parse(response?.data?.dropdown_values);
                const formattedOptions = ParsedDropdownValue?.items?.map((option, index) => ({
                    value: String(option?.id ? option?.id : `${index + 1}`), // Concatenate id with index
                    label: language ? option.name_hi : option.name_en
                }));
                setOptions(formattedOptions);

                if (!response?.data?.dropdown_values || response?.data?.dropdown_values == "null") {
                    const staticFormData = new FormData();
                    staticFormData.append("title", response?.data?.name_en.toLowerCase());
                    const staticList = await staticDropDownsList(staticFormData);
                    let formattedStaticOptions = [];
                    if(response?.data?.name_en.toLowerCase() === "district"){
                        formattedStaticOptions = staticList?.data.filter((d) => d.state_id === ukStateId)?.map(option => ({
                            value: String(option.id),
                            label: language ? option?.name_hi : option?.name_en
                        }));
                    } else {
                        formattedStaticOptions = staticList?.data?.map(option => ({
                            value: String(option.id),
                            label: language ? option?.name_hi : option?.name_en
                        }));
                    }
                    setOptions(formattedStaticOptions);
                }
            } catch (error) {
                //console.error("Error fetching dropdown options:", error);
            }
        };

        fetchOptions();
    }, [dropdownId, language]);

    return (
       <>
            {/* <div>{JSON.stringify(value)}</div> */}
            <Select
                components={{
                    IndicatorSeparator: () => null,
                }}
                styles={styles}
                theme={theme}
                options={options}
                placeholder={placeholder}
                value={options?.find(option => option?.label === String(value) ? option?.label === String(value) : option?.value === String(value))}
                onChange={(selectedOption) => {
                    onChange({ target: { value: selectedOption, name: fieldKey } });
                }}
                isDisabled={fieldsDisabled}
            />
       </>
    );
};

export default SelectType;
