import React, { useEffect, useState } from "react";
import {
  CgAwards,
  CgClipboard,
  CgFileDocument,
  CgMicrosoft,
  CgPen,
  CgTranscript,
  CgLoadbarDoc,
} from "react-icons/cg";
import "./DasboardSidebar.css";

import { useLocation, useNavigate } from "react-router-dom";
import { useLanguage } from "../../LanguageProvider/LanguageProvider ";
import { GetLocalStorage, GetLocalStorageParsed } from "../../../Utils/Utils";
import { checkApplicationsData, getUserDashboardData } from "../../../services/api/user";

const DasboardSidebar = () => {
  const { language, setLanguage } = useLanguage();
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;
  const [applicationData, setApplicationData] = useState(null);
  const [userDashboardData, setUserDashboardData] = useState(null);
  const [showDraft, setShowDraft] = useState("false");
  const [showTrack, setShowTrack] = useState("false");
  const userDetail = GetLocalStorageParsed("userDetail");
  const getLoginSubCabData = GetLocalStorageParsed("LoginSubCatData");

  const getApplicationData = async () => {
    const formData = new FormData();
    formData.append("user_id", userDetail?.id);
    const data = await checkApplicationsData(formData);
    checkApplicationStatus(data?.data);
    setApplicationData(data?.data);
    try {
    } catch (err) {
      console.log(err);
    }
  };

  const getDashboardData = async () => {
    const data = await getUserDashboardData(userDetail?.id);
    setUserDashboardData(data?.data)
    try {
    } catch (err) {
      console.log(err);
    }
  };

  const checkApplicationStatus = async (data) => {
    const app_id = GetLocalStorage('application_id')
    if (data?.length > 0 && getLoginSubCabData) {
      const filterAppData = data.filter(
        (x) => x.category_id == getLoginSubCabData?.id
      );
      if (location.pathname == "/dashboard") {
        setShowDraft("false");
        setShowTrack("false");
      } else {
        if(app_id != null){
          switch (filterAppData[0]?.status){
            case '2' : {
              setShowTrack("true");
              setShowDraft("true");
            }
            break;
            case '3': {
              setShowTrack("false");
              setShowDraft("true");
            }
              break;
            case '4': {
              setShowTrack("true");
              setShowDraft("false");
            }
              break;
            case '5': {
              setShowTrack("true");
              setShowDraft("true");
            }
              break;
            case '7': {
              setShowTrack("true");
              setShowDraft("false");
            }
             break;
            case '8': {
              setShowTrack("true");
              setShowDraft("false");
            }
              break;
          }
        }else{
          setShowTrack("false");
          setShowDraft("false");
        }
      }
    } else {
      setShowDraft("false");
    }
  };

  useEffect(() => {
    getApplicationData();
    getDashboardData();
  }, []);

  return (
    <div className="hcat-second-das pb-5">
      <div className="ds-left mt-2">
        <ul className="ds-ul">
          <li onClick={() => navigate("/dashboard")}>
            {/* <button className={pathName==="/guidelines" ?'ds-green' : 'ds-white'} > <CgClipboard />Dashboard <span></span></button> */}
            <button
              className={
                pathName === "/dashboard" || pathName === "/guidelines"
                  ? "bg_image_green"
                  : "bg_image_white"
              }
            >
              {" "}
              <CgMicrosoft /> <span>{language ? "डैशबोर्ड" : "Dashboard"}</span>
            </button>
          </li>
          {/* <li onClick={() => navigate("/application/overview")}>
            <button
              className={
                pathName?.split("/")?.[1] === "application"
                  ? "bg_image_green"
                  : "bg_image_white"
              }
            >
              {" "}
              <CgClipboard />{" "}
              <span>{language ? "आवेदन फार्म" : "Application Form"}</span>
            </button>
          </li> */}
          {showDraft == "true" ? (
            <li onClick={() => navigate("/application-draft")}>
              {/* <button className={pathName==="/guidelines" ?'ds-green' : 'ds-white'} > <CgClipboard />Dashboard <span></span></button> && checkApplicationStatus(getLoginSubCabData) */}
              <button
                className={
                  pathName === "/application-draft"
                    ? "bg_image_green"
                    : "bg_image_white"
                }
              >
                {" "}
                <CgPen />
                <span>{language ? "आवेदन ड्राफ्ट" : "Application Draft"}</span>
              </button>
            </li>
          ) : (
            ""
          )}
          {applicationData?.length > 0 ? (
            <li onClick={() => navigate("/applications")}>
              {/* <button className={pathName==="/guidelines" ?'ds-green' : 'ds-white'} > <CgClipboard />Dashboard <span></span></button> */}
              <button
                className={
                  pathName === "/applications"
                    ? "bg_image_green"
                    : "bg_image_white"
                }
              >
                {" "}
                <CgLoadbarDoc />
                <span>{language ? "अनुप्रयोग" : "Applications"}</span>
              </button>
            </li>
          ) : (
            ""
          )}
          {userDashboardData?.certificates && userDashboardData?.certificates > 0 ? <li onClick={() => navigate("/certificate")}>
            {/* <button className={pathName==="/guidelines" ?'ds-green' : 'ds-white'} > <CgClipboard />Dashboard <span></span></button> */}
            <button
              className={
                pathName === "/certificate"
                  ? "bg_image_green"
                  : "bg_image_white"
              }
            >
              <CgAwards />{" "}
              <span>{language ? "प्रमाणपत्र" : "Certificates"}</span>
            </button>
          </li> : <></>}
          {showTrack == 'true' && 
            <li onClick={() => navigate("/track-application")}>
              <button
                className={
                  pathName === "/track-application"
                    ? "bg_image_green"
                    : "bg_image_white"
                }
              >
                {" "}
                <CgFileDocument />{" "}
                <span>{language ? "ट्रैक एप्लिकेशन" : "Track Application"}</span>
              </button>
            </li>
          }
          <li className="d-none" onClick={() => navigate("/payment-recipt")}>
            {/* <button className={pathName==="/guidelines" ?'ds-green' : 'ds-white'} > <CgClipboard />Dashboard <span></span></button> */}
            <button
              className={
                pathName === "/payment-recipt"
                  ? "bg_image_green"
                  : "bg_image_white"
              }
            >
              {" "}
              <CgFileDocument />{" "}
              <span>{language ? "भुगतान रसीद" : "Payment Receipt"}</span>
            </button>
          </li>
          {userDashboardData?.transactions && userDashboardData?.transactions > 0 ? <li onClick={() => navigate("/transactions")}>
            {/* <button className={pathName==="/guidelines" ?'ds-green' : 'ds-white'} > <CgClipboard />Dashboard <span></span></button> */}
            <button
              className={
                pathName === "/transactions"
                  ? "bg_image_green"
                  : "bg_image_white"
              }
            >
              {" "}
              <CgTranscript />{" "}
              <span>{language ? "लेनदेन" : "Transactions"}</span>
            </button>
          </li> : <></>}
          {/* <li onClick={() => navigate("/categories")}>
           
            <button
              className={
                pathName === "/categories" ? "bg_image_green" : "bg_image_white"
              }
            >
              {" "}
              <CgLayoutGridSmall />{" "}
              <span>{language ? "श्रेणियाँ" : "Categories"}</span>
            </button>
          </li>
          <li onClick={() => navigate("/forms")}>
          
            <button
              className={
                pathName === "/forms" ? "bg_image_green" : "bg_image_white"
              }
            >
              <CgFileDocument /> <span>{language ? "फार्म" : "Forms"}</span>
            </button>
          </li> */}
        </ul>
      </div>
    </div>
  );
};

export default DasboardSidebar;
