import Banner from "../../components/Banner/Banner";
import { useLanguage } from "../../components/LanguageProvider/LanguageProvider ";
import MainLayout from "../../components/MainLayout/MainLayout";
import React, { useEffect, useState } from "react";
import "../Home/Home.css";
import { ClearLocalStorage } from "../../Utils/Utils";
import { getHomePageContent } from "../../services/api/outer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { getContactUsData, getContactUsSectionList } from "../../services/api/user";

const ContactUs = () => {
  const { language, setLanguage } = useLanguage();
  const [homePageData, setHomePageData] = useState(null);
  const [contactUsData, setContactUsData] = useState(null);
  const [contactUsSectionData, setContactUsSectionData] = useState(null);
  const [loading, setLoading] = useState(true);

  const contactUsSectionTypes = [
    { value: "1", label: "Enquiry Section" },
    { value: "2", label: "DTDO Section" },
    { value: "3", label: "IAS Section" },
    { value: "4", label: "Content Information" },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      try {
        const [homePageDataResponse, contactUsResponse, contactUsSectionResponse] = await Promise.all([
          getHomePageContent(), getContactUsData(), getContactUsSectionList()
        ]);
        setHomePageData(homePageDataResponse);
        setContactUsData(contactUsResponse?.data)
        setContactUsSectionData(contactUsSectionResponse?.data)
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data", error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    ClearLocalStorage();
  }, []);

  if (loading || homePageData === null) {
    return <></>;
  }
  return (
    <>
      <div>
        <MainLayout homePageData={homePageData}>
          <section className="contact-inner section-padding">
            <div className="container">
              <div className="contact-info-main mt-0">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="contact-info bg-white">
                      <div className="contact-info-title text-center mb-5 px-5">
                        <p className="hex-p">
                          {language ? contactUsData?.title_hi : contactUsData?.title_en}
                        </p>
                        <div className="hex-explore">
                          {language ? contactUsData?.description_hi : contactUsData?.description_en}
                        </div>
                      </div>
                      {contactUsSectionData && contactUsSectionData?.filter((c) => c?.status == 1 && c.type === "4")?.length > 0 ? 
                      <div className="contact-info-content row mb-1">
                          {contactUsSectionData?.filter((c) => c?.status == 1 && c.type === "4")?.map((section, index) => { 
                            return (
                              <div key={index} className="col-lg-4 col-md-6 mb-4">
                                <div className="info-item bg-lgrey px-4 py-5 border-all text-center rounded">
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: language
                                      ? section?.content_hi
                                      : section?.content_en
                                  }}
                                ></span>
                                </div>
                              </div>
                            )     
                          })}
                      </div> : <></>} 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          
          {contactUsSectionData && contactUsSectionData?.filter((c) => c?.status == 1 && c.type === "1")?.length > 0  
            || contactUsSectionData && contactUsSectionData?.filter((c) => c?.status == 1 && c.type === "2")?.length > 0  
              || contactUsSectionData && contactUsSectionData?.filter((c) => c?.status == 1 && c.type === "3")?.length > 0 ? 
          <section className="contact-Details">
            <div className="container">
              {contactUsSectionData && contactUsSectionData?.filter((c) => c?.status == 1 && c.type === "3")?.length > 0 ?
              <div className="row mt-3">
              {/* <div className="contact-detail-headings">
                        <h4 class="contact-name text-start">DISTRICT TOURISM DEVELOPMENT OFFICERS</h4>
                    </div> */}
                {contactUsSectionData?.filter((c) => c?.status == 1 && c.type === "3")?.map((section, index) => { 
                  return (
                    <div className="col-12 col-lg-6 col-md-6 mb-4">
                      <div class=" green-box contact-box" >
                        <span
                          dangerouslySetInnerHTML={{
                            __html: language
                              ? section?.content_hi
                              : section?.content_en
                          }}
                        ></span>
                      </div>
                    </div>
                  )     
                })}
              </div> : <></>}

              {contactUsSectionData && contactUsSectionData?.filter((c) => c?.status == 1 && c.type === "2")?.length > 0 ?
                <div className="row mt-3">
                  <div className="col-12 col-lg-12">
                      <div className="contact-detail-headings">
                          <h4 className="contact-name text-start">DISTRICT TOURISM DEVELOPMENT OFFICERS</h4>
                      </div>
                  </div>
                  {contactUsSectionData?.filter((c) => c?.status == 1 && c.type === "2")?.map((section, index) => { 
                    return (
                      <div class="col-12 col-lg-6 col-md-6 mb-4">
                        <div class=" green-box contact-box">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: language
                                ? section?.content_hi
                                : section?.content_en
                            }}
                          ></span>
                        </div>
                      </div>
                    )     
                  })}
                </div> : <></> }

              {contactUsSectionData && contactUsSectionData?.filter((c) => c?.status == 1 && c.type === "1")?.length > 0 ?
                <div className="row mt-3">
                <div class="col-12 col-lg-12">
                      <div class="contact-detail-headings">
                          <h4 class="contact-name text-start"> FOR CHARDHAM YATRA PACKAGE INQUIRIES CONTACT</h4>
                      </div>
                  </div>
                  {contactUsSectionData?.filter((c) => c?.status == 1 && c.type === "1")?.map((section, index) => { 
                    return (
                      <div class="col-12 col-lg-6 col-md-6 mb-4">
                        <div class=" green-box contact-box">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: language
                                ? section?.content_hi
                                : section?.content_en
                            }}
                          ></span>
                        </div>
                      </div>
                    )     
                  })}
                </div> : <></>}
            </div>
          </section> : <></>}
        </MainLayout>
      </div>
    </>
  );
};
export default ContactUs;
