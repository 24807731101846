import React, { useEffect, useRef, useState } from "react";
import {
  AccomodationIcon,
  AdditionalIcon,
  EmployeeIcon,
  OrganizationIcon,
  PaymentIcon,
  UploadIcon,
  UploadIconPhoto,
} from "./ApplicationIcon";
import Dashboard from "../../pages/Dashboard/Dashboard";
import {
  Apiurl,
  GetLocalStorage,
  GetLocalStorageParsed,
  validateonSubmitHandle,
} from "../../Utils/Utils";
import axios from "axios";
import Select from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
import { BsXLg } from "react-icons/bs";
// import { useLanguage } from "../LanguageProvider/LanguageProvider ";
import {
  changeApplicationStatus,
  checkApplicationData,
  checkApplicationsData,
  createApplicationForm,
  downloadApplicationPDFAPI,
  getApplicationFields,
  getApplicationForm,
  getDropdownList,
  getSection,
  getSectionFields,
  getStates,
  sendNotificationDataAdmin,
  staticDropDownsList,
} from "../../services/api/user";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowUp } from "@fortawesome/free-solid-svg-icons";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import {
  customStylesAcc,
  customStylesAccUser,
  firstCharUpperCase,
  selectTheme,
  selectThemeUser,
} from "../../Utils/common";
import { useLanguage } from "../LanguageProvider/LanguageProvider ";
import InputTypeText from "../inputType/inputTypeText";
import SelectType from "../inputType/SelectType";
import SubSection from "../inputType/subSection";
import TableTab from "../inputType/tableTab";
import DocsUpload from "../inputType/DocsUpload";
import Payment from "../inputType/Payments";
import Geolocation from "../inputType/geoloaction";
import EmpDetails from "../inputType/EmpDetails";
import { set } from "react-hook-form";
import { CgCheckO, CgPen } from "react-icons/cg";
import InputTableData from "../ApplicationDraft/InputTableData";
import DesignationTable from "../ApplicationDraft/DesignationTable";
import DocsUploadDraft from "../ApplicationDraft/DocsUploadDraft";
import { getDistrictDropdown } from "../../services/api/admin";
import { getApplicationFees } from "../../services/api/auth";
import { Spinner } from "react-bootstrap";
import GeoLocationAddress from "../ApplicationDraft/GeoLocationAddress";

const OrganizationDetails = ({
  tabsData,
  tabActiveData,
  setTabActiveData,
  inputFormData,
  setInputFormData,
  getLoginSubCabData,
}) => {
  const { language, setLanguage } = useLanguage();
  const appFormRef = useRef(null);
  const navigate = useNavigate();
  const [isEmpDetailsTab, setIsEmpDetailsTab] = useState(false);
  const location = useLocation();
  const [tabActive, setTabActive] = useState(null);
  const [sectionData, setSectionData] = useState([]);
  const [applicationFromData, setApplicationFromData] = useState([]);
  const [initialLoaded, setInitialLoaded] = useState([]);
  const [sectionFormData, setSectionFormData] = useState([]);
  const [empDesignationTableData, setEmpDesignationTableData] = useState();
  const [formValues, setFormValues] = useState({});
  const [isLastTab, setIslastTab] = useState(false);
  const [isFirstTab, setIsFirstTab] = useState(false);
  const [isLocationTab, setIsLocationTab] = useState(false);
  const [sectionFieldsData, setSectionFieldsData] = useState({});
  const [isDocumnetTab, setIsDocumentTab] = useState(false);
  const [isPaymentTab, setIsPaymentTab] = useState(false);
  const [subcategoryId, setSubCategoryId] = useState();
  const applicationId = GetLocalStorage("application_id");
  const userDetail = GetLocalStorageParsed("userDetail");
  const loginCatInfo = GetLocalStorageParsed("LoginSubCatData");
  const [tableData, setTableData] = useState("");
  const [checkboxError, setCheckboxError] = useState(""); // State for checkbox error
  const [addressString, setAddressString] = useState("");

  const [showEmpDetailsTab, setShowEmpDetailsTab] = useState(false);
  const [empDesignationField, setEmpDesignationField] = useState(null);
  const [shouldShowEmpDetailsTab, setShouldShowEmpDetailsTab] = useState(false);
  const [isPaymentEnabled, setIsPaymentEnabled] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [empDetailIndex, setEmpDetailIndex] = useState(false);
  const [documentList, setDocumentList] = useState([]);

  const [showEditIcon, setShowEditIcon] = useState(true);
  const [isDraft, setIsDraft] = useState(false);
  const [geoLocationSection, setGeoLocationSection] = useState(null);
  const [geoLocationAddress, setGeoLocationAddress] = useState(null);
  const [trackingNumber, setTrackingNumber] = useState(null);
  const [isDraftSubmitted, setIsDraftSubmitted] = useState(false);
  const [paymentTabInfo, setPaymentTabInfo] = useState({})
  const [appliedForDistrict, setAppliedForDistrict] = useState({})
  const [pdfLoading, setPdfLoading] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [selectedTab, setSelectedTab] = useState(null);
  const [isEmpDataFilled, setIsEmpDataFilled] = useState(null);
  const [fieldsDisabled, setFieldsDisabled] = useState(false);
  const [isApplicationExpired, setIsApplicationExpired] = useState(false);
  const [htmlRendered, setHtmlRendered] = useState(false);
  const [totalRoomsPremesisesKey, setTotalRoomsPremesisesKey] = useState(null); 
  const [applicationNumber, setApplicationNumber] = useState(null); 
  const [districtDropdownId, setDistrictDropdownId] = useState(null); 
  const [ukStateId, setUkStateId] = useState(null); 
  const [designationKey, setDesignationKey] = useState(null); 
  const [parentApplicationId, setParentApplicationId] = useState(null); 
  const [applicationRejected, setApplicationRejected] = useState(null); 
  const [appStatus, setAppStatus] = useState(null); 
  const [applicantNumber, setApplicantNumber] = useState(null);
  const [applicantEmail, setApplicantEmail] = useState(null);

  useEffect(() => {
    if (appFormRef.current) {
      setTimeout(() => {
        setHtmlRendered(true);
      }, 500);
    }
  }, [appFormRef.current]);
  
  const editToggle = () => {
    setShowEditIcon(showEditIcon ? false : true);
  };

  const handleSubmitClick = async () => {
    if (isPaymentEnabled) {
      setIsDraft(false);
      setIsDocumentTab(false);
      setIsPaymentTab(true);
      setTabActive(combinedTabsData.find((t) => t.id === "make-payment"));
      setIsDraftSubmitted(true);
    } else {
      submitApplication();
    }
  };
  const downloadApplicationPDF = async () => {
    try {
      setPdfLoading(true)
      const formData = new FormData();
      formData.append("application_id", applicationId);
      const downloadApplicationPDF = await downloadApplicationPDFAPI(
        formData
      );
      if (downloadApplicationPDF.success === true) {
        window.open(downloadApplicationPDF?.data);
      }
      setPdfLoading(false)
    } catch {
      setPdfLoading(false)
    }

  };

  const combinedTabsData = [
    ...(tabsData || []), // Dynamic tabs
    {
      id: "doc-upload",
      name_en: "Document Uploads",
      name_hi: "दस्तावेज़ अपलोड",
      background_color: "#ffffff",
    },
    ...(tabsData?.[0]?.payment_status === "1" && !applicationRejected && (!fieldsDisabled || isApplicationExpired)
      ? [
        {
          id: "make-payment",
          name_en: "Make Payment",
          name_hi: "भुगतान करें",
          background_color: "#ffffff",
        },
      ]
      : []),
  ];

  combinedTabsData.splice(1, 0, {
    id: "geo-location",
    name_en: "Geo-Location",
    name_hi: "भू-स्थान",
    background_color: "#ffffff",
  });

  if (shouldShowEmpDetailsTab && empDetailIndex >= 0) {
    combinedTabsData.splice(empDetailIndex + 1, 0, {
      id: "emp-details",
      name_en: "Employee Details",
      name_hi: "कर्मचारी विवरण",
      background_color: "#ffffff",
    });
  }

  const [tabsWholeData, setTabsWholeData] = useState({
    section_fields: {},
    sub_section_fields: [],
  });

  const inputTypeList = [
    { label: "text", value: 1 },
    { label: "radio", value: 4 },
    { label: "password", value: 7 },
    { label: "file", value: 5 },
    { label: "dropdown", value: 2 },
    // { label: "Sub Section", value: 6 },
    { label: "checkbox", value: 3 },
    { label: "Designation Table", value: 8 },
    { label: "date", value: 9 },
    { label: "email", value: 11 },
    { label: "phone", value: 12 },
  ];

  const [errors, setErrors] = useState({});
  const [docErrors, setDocErrors] = useState({});

  useEffect(() => {
    if (tabsData && tabsData?.length) {
      getApplicationData()
    }
  }, [tabsData]);

  useEffect(() => {
    if (tabsData && tabsData?.length) {
      // setSubCategoryId(loginCatInfo?.id);
      fetchApplicationFormData(true);
      loadApplcationFees()
      loadDropdowns()
      getUkStateId()
      getEmpDesinationField()
      setIsPaymentEnabled(tabsData?.[0]?.payment_status === "1" && !applicationRejected && (!fieldsDisabled  || isApplicationExpired));
    }
    // const isAdditionalTabPresent = tabsData?.filter(item => item.type.includes("Additional Details"));
    // if (isAdditionalTabPresent && isAdditionalTabPresent.length > 0) {
    //   setShowEmpDetailsTab(true)
    // } else {
    //   setShowEmpDetailsTab(false)
    // }
  }, [appliedForDistrict > 0]);
  

  const getApplicationData = async (item) => {
    try {
      const formData = new FormData();
      formData.append("sub_category_id", tabsData[0]?.category_id);
      formData.append("user_id", userDetail?.id);
      const data = await checkApplicationData(formData);
      if (data?.status == 200) {
        setAppliedForDistrict(data?.data?.district_id);
        setApplicationNumber(data?.data?.application_number)
        setAppStatus(data?.data?.status ?? null)
        setApplicationRejected(data?.data?.status ? data?.data?.status === "5" : false)
        setIsApplicationExpired(data?.data?.status ? data?.data?.status === "6" : false)
        setFieldsDisabled(data?.data?.status ? data?.data?.status === "2" || data?.data?.status === "7" || data?.data?.status === "6" : false)
        setParentApplicationId(data?.data?.parent_application_id)
      } else {
        setIsApplicationExpired(false)
      }
    } catch (err) {
      setIsApplicationExpired(false)
    }
  };

  const getEmpDesinationField = async () => {
    try {
      const fieldsformData = new FormData();
      fieldsformData.append("category_id", tabsData[0]?.category_id);
      const fieldsResponse = await getApplicationFields(fieldsformData);
      const empDesignationKey = fieldsResponse?.data?.filter((f) => f.type === "8")?.[0]?.field_key ?? null
      setDesignationKey(empDesignationKey)
    } catch (err) {
    }
  };

  const loadDropdowns = async () => {
    try {
      const data = await getDropdownList();
      if (data?.status == 200) {
        setDistrictDropdownId(data?.data?.filter((d) => d.name_en.toLowerCase() === "district")?.[0]?.id);
      } 
    } catch (err) {
    }
  };

  const getUkStateId = async () => {
    try {
      const data = await getStates();
      if (data?.data?.length > 0) {
        setUkStateId(data?.data?.filter((d) => d.name_en.toLowerCase() === "uttarakhand")?.[0]?.id);
      } 
    } catch (err) {
    }
  };

  // Set state to show or hide the Employee Details tab
  useEffect(() => {
    setShowEmpDetailsTab(shouldShowEmpDetailsTab);
  }, [shouldShowEmpDetailsTab]);

  // useEffect(() => {
  //   // if (tabsData?.length) {
  //   //   setSubCategoryId(tabsData[0]?.category_id)
  //   //   fetchApplicationFormData()
  //   // }
  //   // Set the initial active tab and fetch data
  //   // if(isApplicationExpired) {
  //   //   if(isPaymentEnabled){
  //   //     setIsPaymentTab(true)
  //   //     setTabActive(combinedTabsData.find((t) => t.id === "make-payment"));
  //   //   } else {
  //   //     submitApplication();
  //   //   }
  //   // }
  //   // else {
  //     if (combinedTabsData && combinedTabsData?.length > 0) {
  //       const initialActiveTab = combinedTabsData[0];
  //       setTabActive(initialActiveTab);
  //       setIsFirstTab(true);
  //       fetchSectionData(initialActiveTab, false);
  //       //setSubCategoryData(initialActiveTab.category_id)
  //       //fetchSectionData(initialActiveTab, false);
  //     }
  //   //}
  // }, [initialLoaded]);

  useEffect(() => {
    const fetchAllSections = async () => {
      if (combinedTabsData && combinedTabsData.length > 0) {
        const initialActiveTab = combinedTabsData[0];
        setTabActive(initialActiveTab);
        setIsFirstTab(true);

        // Create an array of promises for fetching section data
        const fetchPromises = tabsData?.map((tab) => fetchSectionData(tab, true));
  
        try {
          // Wait for all fetchSectionData calls to complete
          await Promise.all(fetchPromises);
          
          // Call handleTabChange only after all sections are fetched
          handleTabChange(initialActiveTab);
        } catch (error) {
         // console.error("Error in fetching all section data:", error);
        }
      }
    };
  
    fetchAllSections();
  }, [initialLoaded]);
  

  const submitApplication = async () => {
    try{
      setSubmitting(true)
      const formData = new FormData();
        formData.append("status", "8");
        formData.append("application_id", applicationId);
        formData.append("remarks", "Application is submitted");
        const changeApplicationStatusResponse = await changeApplicationStatus(
          formData
        );
        if (
          changeApplicationStatusResponse.status === "200" ||
          changeApplicationStatusResponse.status === 200
        ) {
          setIsFormSubmitted(true);
          setIsDraft(false);
          setSubmitting(false)
          try {
            const remarkEn = {
              role: userDetail?.name,
              message: ` has submitted application. Check out the details of application to review and verify the information.`,
            }
            const notifcation = new FormData()
            notifcation.append("remark_en", JSON.stringify(remarkEn));
            notifcation.append("remark_hi", 'आवेदन पत्र जमा किया गया');
            notifcation.append('district_id', appliedForDistrict);
            const notificationSend = await sendNotificationDataAdmin(notifcation)
          } catch (err) {
            console.error("Error submitting the form:", err);
          }
      }
    } catch {
      setSubmitting(false)
    }
  }

  const fetchApplicationFormData = async (initialLoad) => {
    try {
      const paymentTabData = {}
      const formData = new FormData();
      formData.append("sub_category_id", tabsData[0]?.category_id);
      //formData.append("application_detail_id", parentApplicationId && parentApplicationId > 0 ? parentApplicationId : applicationId);
      formData.append("application_detail_id", applicationId);
      const applicationFormResponse = await getApplicationForm(formData);
      if (
        applicationFormResponse.status === "200" ||
        applicationFormResponse.status === 200
      ) {
        setApplicationFromData(applicationFormResponse?.data);
        if(geoLocationAddress === null || geoLocationAddress === ""){
          setGeoLocationAddress(applicationFormResponse?.data?.["geo_location_address"] ?? null)
        }
        if (applicationFormResponse?.data?.["tracking_number"]) {
          filterApplicationData(applicationFormResponse?.data?.application_id)
          setTrackingNumber(applicationFormResponse?.data?.["tracking_number"]);
          const accomadationUnitTitle = Object.entries(applicationFormResponse?.data || {}).find(
            ([key]) => key.startsWith("f_name_of_accommodation_unit")
          );
          const ownerEmail = Object.entries(applicationFormResponse?.data || {}).find(
            ([key]) => key.startsWith("f_owners_email")
          );
          const ownerName = Object.entries(applicationFormResponse?.data || {}).find(
            ([key]) => key.startsWith("f_name_of_the_ownerpartner")
          );
          const ownerMobile = Object.entries(applicationFormResponse?.data || {}).find(
            ([key]) => key.startsWith("f_owners_mobile_no")
          );
          paymentTabData.organizationName = accomadationUnitTitle && accomadationUnitTitle?.length > 0 ? accomadationUnitTitle[1] : "--"
          paymentTabData.ownerEmailId = ownerEmail && ownerEmail?.length > 0 ? ownerEmail[1] : "--"
          paymentTabData.ownerPartnerName = ownerName && ownerName?.length > 0 ? ownerName[1] : "--"
          paymentTabData.ownerMobileNumber = ownerMobile && ownerMobile?.length > 0 ? ownerMobile[1] : "--"
          setPaymentTabInfo((prevData) => ({
            ...prevData,
            ...paymentTabData
          }));


        }
      } else {
        setApplicationFromData(null);
      }
      if (initialLoad) {
        setInitialLoaded(true);
      }
    } catch (err) {
      console.error("Error fetching application data:", err);
    }
  };

  const filterApplicationData = async (id) => {

    const paymentTabData = {}
    const formData = new FormData();
    formData.append("user_id", userDetail?.id);
    const data = await checkApplicationsData(formData);
    const filterData = data?.data?.filter(x => x.id == id)
    const districtData = await getDistrictDropdown()
    const filterDistrict = districtData?.data?.filter(x => x.id == filterData?.[0]?.district_id)
    paymentTabData.district = filterDistrict
    setPaymentTabInfo((prevData) => ({
      ...prevData,
      ...paymentTabData
    }));
    try {
    } catch (err) {
      console.log(err);
    }
  };

  const loadApplcationFees = async () => {
    try {
      const paymentTabData = {}
      const formData = new FormData();
      formData.append("sub_category_id", loginCatInfo.id);
      const response = await getApplicationFees(formData);
      const data = response.data;
      paymentTabData.amount = response?.data?.fees
      setPaymentTabInfo((prevData) => ({
        ...prevData,
        ...paymentTabData
      }));
    } catch (error) {
      console.error("Error fetching districts:", error);
    }
  };


  const fetchSectionData = async (tab, isInitialLoad) => {
    try {
      if (parseInt(tab?.id) > 0) {
        const formData = new FormData();
        formData.append("category_id", tab.category_id);
        formData.append("tab_id", tab.id);
        const sectionResponse = await getSection(formData);
        if (sectionResponse.status === 200) {
          fetchSectionFormData(sectionResponse.data, tab.id);
          if(isInitialLoad){
            if(tab?.id === tabsData[0].id){
              setSectionData(sectionResponse.data);
            }
          } else {
            setSectionData(sectionResponse.data);
          }
        } else {
          setSectionData(null);
        }
      } else {
        setSectionData(null);
      }
    } catch (err) {
      console.error("Error fetching section data:", err);
    }
  };

  const cretaeApplicationFormData = async (sectionFields, subSectionFields) => {
    try {
      const payload = {
        sub_category_id: tabsData[0].category_id,
        user_id: userDetail?.id,
        application_id: applicationId,
        section_fields: sectionFields, // pass as an object
        sub_section_fields: subSectionFields, // pass as an object
        geo_location_address: geoLocationAddress,
        district_id: appliedForDistrict
      };

      const sectionResponse = await createApplicationForm(payload); // Send as JSON
      if (sectionResponse.status === "200" || sectionResponse.status === 200) {
        fetchApplicationFormData(false);

      } else {
      }
    } catch (err) {
      console.error("Error fetching section data:", err);
    }
  };

  const fetchSectionFormData = async (sectionObj, tabId) => {
    try {
      for (let i = 0; i < sectionObj.length; i++) {
        const formData = new FormData();
        formData.append("category_id", sectionObj[i].category_id);
        formData.append("tab_id", tabId);
        formData.append("section_id", sectionObj[i].id);

        const sectionFormResponse = await getSectionFields(formData);
        if (sectionFormResponse.status === 200) {
          setSectionFormData((prevData) => ({
            ...prevData,
            [sectionObj[i].id]: sectionFormResponse.data,
          }));

          if (
            sectionObj[i]?.is_geo_location_section &&
            sectionObj[i]?.is_geo_location_section === "1"
          ) {
            setGeoLocationSection(sectionObj[i]?.id);
          }

          

          if (showEmpDetailsTab === false) {
            setShouldShowEmpDetailsTab(
              sectionFormResponse.data.some((item) => item?.type === "8")
            );
            setEmpDesignationField(
              sectionFormResponse?.data?.find((item) => item?.type === "8")
            );
            setEmpDetailIndex(
              combinedTabsData.findIndex(
                (t) =>
                  t.id ===
                  sectionFormResponse?.data?.find((item) => item?.type === "8")
                    ?.tab_id
              )
            );
          }

          sectionFormResponse.data.forEach((item) => {
            const value =
              item?.type === "8" && applicationFromData?.["f_" + item.field_key]
                ? JSON.parse(applicationFromData?.["f_" + item.field_key])
                : applicationFromData?.["f_" + item.field_key] || ""; // Get initial value from applicationFormData if exists

            if(totalRoomsPremesisesKey === null) {
              if(item?.name_en.toLowerCase() === "total rooms in premises"){
                setTotalRoomsPremesisesKey(item?.field_key)
              }
            }

            if (item.sub_section_id) {
              // Populate tabsWholeData for sub-sections
              setTabsWholeData((prevData) => {
                const existingSubSection = Object.keys(
                  prevData?.sub_section_fields
                )?.find((sub) => Object.keys(sub)[0] === item.field_key);
                if (!existingSubSection) {
                  const fieldKeyJson = item.field_key + "_json"; // Create dynamic key like `fieldKey_json`
                  const jsonValue =
                    applicationFromData?.["f_" + item.field_key + "_json"] ||
                    null;

                  const expandedJsonValue = jsonValue
                    ? [
                      ...jsonValue,
                      ...Array(Math.max(value - jsonValue.length, 0))
                        .fill()
                        .map(() => {
                          // Create an object with the same keys as the first item, but values set to ""
                          return Object.keys(jsonValue[0])?.reduce(
                            (acc, key) => {
                              acc[key] = ""; // Set to empty string (or use `null` if preferred)
                              return acc;
                            },
                            {}
                          );
                        }),
                    ]
                    : null;

                  return {
                    ...prevData,
                    sub_section_fields: {
                      ...prevData.sub_section_fields,
                      [item.field_key]:
                        value === null || value === undefined || value === ""
                          ? "1"
                          : value, // Existing field key with value
                      [fieldKeyJson]: expandedJsonValue, // Dynamic key with value as an object
                    },
                  };
                }
                return prevData;
              });
            } else {
              if (!item.field_key.endsWith("_json")) {
                setTabsWholeData((prevData) => ({
                  ...prevData,
                  section_fields: {
                    ...prevData.section_fields,
                    [item.field_key]: item?.type === "10" ? appliedForDistrict : value,
                  },
                }));
                if(item?.type === "11"){
                  setApplicantEmail(value ?? "")
                }
                if(item?.type === "12") {
                  setApplicantNumber(value ?? "")
                }
              } else {
                console.log(item.field_key);
              }
              // Populate tabsWholeData for section fields
            }
          });
        }
      }
    } catch (err) {
      console.error("Error fetching section form data:", err);
    }
  };

  

  const handleInputChange = (e, field, sectionId, value) => {
    const name = e.target.name;
    const isRequired = field?.required === "1";
    let err = null;

    if (isRequired && !value) {
      err = {
        hi: `${field?.name_hi} आवश्यक है`,
        en: `${field?.name_en} is required`,
      };
    } else {
      // try{
      //   if (
      //     JSON.parse(JSON.parse(field?.validations)?.validation)?.onKeyUp &&
      //     JSON.parse(JSON.parse(field?.validations)?.validation)?.onKeyUp
      //       ?.length > 0
      //   ) {
      //     const validations = validateonSubmitHandle(field, value, null);
      //     err = validations === "" ? null : validations;
      //   } else {
      //     err = null;
      //   }
      // }
      // catch{}
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: err, // Include field name in the error message
    }));

    setSectionFieldsData({
      ...sectionFieldsData,
      [name]: value,
    });

    if (field?.type === "10") {
      setAppliedForDistrict(value)
    }

    setTabsWholeData((prevData) => {
      if (field.sub_section_id) {
        const fieldKeyJson = field.field_key + "_json"; // Create dynamic key

        return {
          ...prevData,
          sub_section_fields:
            prevData?.sub_section_id === field.sub_section_id
              ? {
                ...prevData.sub_section_fields,
                [fieldKeyJson]: {
                  ...prevData.sub_section_fields[fieldKeyJson],
                  [name]: value,
                },
              }
              : prevData.sub_section_fields,
        };
      } else {
        return {
          ...prevData,
          section_fields: { ...prevData.section_fields, [name]: value },
        };
      }
    });
  };

  const handleTabChange = async (tab) => {
    setTabActive(tab);
    fetchSectionData(tab, false);
    // console.log(tab?.id)
    staticsTab(tab);
    setErrors({});
    setDocErrors({});
  };

  const handleFormSubmit = async (e) => {
    const isAllValid = await handleApplicationSubmit(false);
    if (isAllValid) {
      const newDocErrors = {};
      documentList.forEach((doc, index) => {
        if (!doc.upload_documents || doc.upload_documents.length === 0) {
          newDocErrors[doc?.id] = {
            hi: `${doc?.name_hi} आवश्यक है`,
            en: `${doc?.name_en} is required`,
          };
        }
      });

      setDocErrors({ ...docErrors, ...newDocErrors });

      const hasDocErrors = Object.values(newDocErrors).some(
        (error) => error !== null
      );
      if (!hasDocErrors) {
        setIsDraft(true);
      }
    }
  };

  const handleDashboardClick = () => {
    navigate("/dashboard");
  };

  const handleFormBack = (e) => {
    const previousTab = combinedTabsData[combinedTabsData.findIndex(
      (tab) => tab.id === tabActive.id
    ) - 1]
    handleTabChange(previousTab)
  }

  const handleFormNext = (e) => {
    e.preventDefault(); // Prevent default form submission

    const newErrors = {};

    const currentTabSectionData = Object.entries(sectionFormData)
      .filter(([key, records]) =>
        records.every((record) => record?.tab_id === tabActive?.id)
      )
      ?.reduce((acc, [key, records]) => {
        acc[key] = records;
        return acc;
      }, {});

    Object.keys(currentTabSectionData).forEach((sectionId) => {
      sectionFormData[sectionId].forEach((field) => {
        if (
          tabsWholeData?.section_fields?.hasOwnProperty(field?.field_key)
        ) {
          const value = tabsWholeData.section_fields[field.field_key] || ""; // Get the value of the field
          const isRequired = field.required === "1"; // Check if the field is required

          if (isRequired && !value) {
            newErrors[field?.field_key] = {
              hi: `${field?.name_hi} आवश्यक है`,
              en: `${field?.name_en} is required`,
            };
            const errr = language
              ? newErrors?.[field?.field_key]?.hi
              : newErrors?.[field?.field_key]?.en;
          } else {
            if (
              JSON.parse(JSON.parse(field?.validations).validation)?.onKeyUp &&
              JSON.parse(JSON.parse(field?.validations).validation)?.onKeyUp
                ?.length > 0
            ) {
              const validations = validateonSubmitHandle(field, value, null);
              newErrors[field?.field_key] =
                validations === "" ? null : validations;
            } else {
              newErrors[field?.field_key] = null;
            }
          }
        }
      });
    });

    Object.keys(currentTabSectionData).forEach((sectionId) => {
      sectionFormData[sectionId].forEach((field) => {
        if (
          tabsWholeData?.sub_section_fields?.[field.field_key + "_json"] &&
          Array.isArray(
            tabsWholeData?.sub_section_fields?.[field.field_key + "_json"]
          )
        ) {
          tabsWholeData?.sub_section_fields?.[
            field.field_key + "_json"
          ]?.forEach((item, itemIndex) => {
            Object.keys(item).forEach((key) => {
              if (item[key] === null || item[key].trim() === "") {
                // Check if value is null or empty
                newErrors[`${field.field_key}_json_${itemIndex}_${key}`] = {
                  hi: `आवश्यक है`,
                  en: `Required`,
                };
              }
            });
          });
        }
      });
    });

    // Update the state with new errors



    // Find the index of the current active tab in the combined array
    const currentIndex = combinedTabsData.findIndex(
      (tab) => tab.id === tabActive.id
    );

    if (tabActive.id === "emp-details") {
      let firstErrorTab = "";
      Object.keys(sectionFormData)?.forEach((sectionId) => {
        sectionFormData[sectionId]?.forEach((field) => {
          if (field?.type === "8") { // Check for Employee Details field type
            const value = tabsWholeData?.section_fields?.[field.field_key] || "";

            if (Array.isArray(value)) {
              Object.keys(value[0])?.forEach((key) => {
                var empDetails = tabsWholeData?.section_fields?.[field.field_key]?.[0]?.[key]?.["Employee Details"];

                empDetails?.forEach((det, index) => {
                  Object.keys(empDetails?.[index])?.forEach((empKey) => {
                    var empDetailValue = empDetails?.[index]?.[empKey];

                    // Error messages
                    let requiredMessage = {
                      hi: `आवश्यक है`,
                      en: `Required`,
                    };
                    let regexMessage = {
                      hi: `मान्य प्रारूप में होना चाहिए`,
                      en: `Must be in a valid format`,
                    };

                    // Check for empty value
                    if (!empDetailValue) {
                      switch (empKey) {
                        case "Employee Name":
                          requiredMessage = {
                            hi: `कर्मचारी का नाम आवश्यक है`,
                            en: `Employee Name is required`,
                          };
                          break;

                        case "Contact No.":
                          requiredMessage = {
                            hi: `संपर्क नंबर आवश्यक है`,
                            en: `Contact Number is required`,
                          };
                          break;

                        case "Staff Aadhaar No.":
                          requiredMessage = {
                            hi: `स्टाफ आधार नंबर आवश्यक है`,
                            en: `Staff Aadhaar Number is required`,
                          };
                          break;

                        case "Bank Account No.":
                          requiredMessage = {
                            hi: `बैंक खाता संख्या आवश्यक है`,
                            en: `Bank Account Number is required`,
                          };
                          break;

                        case "IFSC Code":
                          requiredMessage = {
                            hi: `आईएफएससी कोड आवश्यक है`,
                            en: `IFSC Code is required`,
                          };
                          break;

                        case "Bank Name":
                          requiredMessage = {
                            hi: `बैंक का नाम आवश्यक है`,
                            en: `Bank Name is required`,
                          };
                          break;

                        case "Status":
                          requiredMessage = {
                            hi: `स्थिति आवश्यक है`,
                            en: `Status is required`,
                          };
                          break;

                        case "Date Of Joining":
                          requiredMessage = {
                            hi: `शामिल होने की तिथि आवश्यक है`,
                            en: `Date Of Joining is required`,
                          };
                          break;

                        default:
                          requiredMessage = {
                            hi: `आवश्यक है`,
                            en: `Required`,
                          };
                      }
                      // Set required field error
                      if(firstErrorTab === ""){
                        firstErrorTab = key;
                      }
                      newErrors[`${field?.field_key}_${key}_emp_${index}_${empKey}`] = requiredMessage;
                    }

                    // Regex validation for specific fields
                    if (empDetailValue) {
                      let isValid = true;

                      switch (empKey) {
                        case "Contact No.":
                          isValid = /^[6-9]\d{9}$/.test(empDetailValue); // Indian mobile number regex
                          regexMessage = {
                            hi: `संपर्क नंबर मान्य प्रारूप में होना चाहिए`,
                            en: `Contact Number must be valid`,
                          };
                          break;

                        case "Staff Aadhaar No.":
                          isValid = /^\d{12}$/.test(empDetailValue); // Aadhaar number regex
                          regexMessage = {
                            hi: `आधार नंबर 12 अंकों का होना चाहिए`,
                            en: `Aadhaar Number must be 12 digits`,
                          };
                          break;

                        case "Bank Account No.":
                          isValid = /^\d{9,18}$/.test(empDetailValue); // Bank account number regex
                          regexMessage = {
                            hi: `बैंक खाता संख्या मान्य प्रारूप में होनी चाहिए`,
                            en: `Bank Account Number must be valid`,
                          };
                          break;

                        case "IFSC Code":
                          isValid = /^[A-Z]{4}0[A-Z0-9]{6}$/.test(empDetailValue); // IFSC Code regex
                          regexMessage = {
                            hi: `आईएफएससी कोड मान्य प्रारूप में होना चाहिए`,
                            en: `IFSC Code must be valid`,
                          };
                          break;

                        default:
                          isValid = true; // No regex for other fields
                      }

                      if (!isValid) {
                        // Set regex validation error
                        if(firstErrorTab === ""){
                          firstErrorTab = key;
                        }
                        newErrors[`${field?.field_key}_${key}_emp_${index}_${empKey}`] = regexMessage;
                      }
                    }
                  });
                });
              });
            }
          }
        });
      });

      if(firstErrorTab !== ""){
        setSelectedTab(firstErrorTab)
      }

      const checkbox = document.getElementById("wp-comment-cookies-consent");
      if (!checkbox || !checkbox.checked) {
        newErrors["checkbox"] =
          language ? "आपको यह पुष्टि करनी होगी कि प्रदान की गई जानकारी सही है।" : "You must confirm that the information provided is correct.";
          setCheckboxError({
            hi: `आपको यह पुष्टि करनी होगी कि प्रदान की गई जानकारी सही है।"`,
            en: `You must confirm that the information provided is correct.`,
          }); // Set error state for checkbox
        return;
      } else {
        setCheckboxError(""); // Clear checkbox error if checked
      }
    }

    setErrors({ ...errors, ...newErrors });

    // Check if there are any errors before proceeding
    const hasErrors = Object.values(newErrors).some((error) => error !== null);
    if (hasErrors) {
      console.log("Validation Errors:", newErrors);
      return; // Stop submission if there are validation errors
    }

    const nextTab = combinedTabsData[currentIndex + 1];
    staticsTab(nextTab);

    const application_data = {
      category_id: tabsData[0].category_id,
      ...tabsWholeData,
    };

    const prefixedSectionFields = Object.fromEntries(
      Object.entries(tabsWholeData.section_fields).map(([key, value]) => [
        `f_${key}`,
        value,
      ])
    );

    // Prefix 'f_' to keys in mergedSubSectionFields
    const prefixedSubSectionFields = Object.fromEntries(
      Object.entries(tabsWholeData.sub_section_fields).map(([key, value]) => [
        `f_${key}`,
        value,
      ])
    );

    console.log("tabsWholeData", JSON.stringify(application_data, null, 2));

    cretaeApplicationFormData(prefixedSectionFields, prefixedSubSectionFields);

    const mapAddress = {};

    if (geoLocationSection) {
      sectionFormData[geoLocationSection]?.forEach((section) => {
        const dynamicKey = `${section.field_key}`;
        if (tabsWholeData.section_fields[dynamicKey] !== undefined) {
          mapAddress[section.name_en] =
            tabsWholeData.section_fields[dynamicKey];
        }
      });
    }

    setAddressString(
      Object.values(mapAddress || "Clock Tower ,Dehradun, Uttarakhand").join(
        ", "
      )
    );

    if (nextTab) {
      setTabActive(nextTab);
      fetchSectionData(nextTab, false);
    } else {
      console.log("Reached the last tab");
    }
  };
  const goBack = () => {
    const currentIndex = combinedTabsData.findIndex(
      (tab) => tab.id === tabActive.id
    );
    const prevTab = combinedTabsData[currentIndex - 1];

    if (prevTab) {
      setTabActive(prevTab);
      fetchSectionData(prevTab, false);
    } else {
      console.log("Reached the last tab");
    }
  };

  const staticsTab = (nextTab) => {
    // to show ststic active tabs content
    if (nextTab?.id == "doc-upload") {
      setIsDocumentTab(true);
    } else {
      setIsDocumentTab(false);
    }
    if (nextTab?.id === "make-payment") {
      setIsPaymentTab(true);
    } else {
      setIsPaymentTab(false);
    }
    if (
      nextTab?.name_en == combinedTabsData[combinedTabsData.length - 1]?.name_en
    ) {
      setIslastTab(true);
    } else {
      setIslastTab(false);
    }
    if (nextTab?.name_en == combinedTabsData[0]?.name_en) {
      setIsFirstTab(true);
    } else {
      setIsFirstTab(false);
    }

    if (nextTab?.id == "geo-location") {
      setIsLocationTab(true);
    } else {
      setIsLocationTab(false);
    }
    if (nextTab?.id == "emp-details") {
      setIsEmpDetailsTab(true);
    } else {
      setIsEmpDetailsTab(false);
    }
  };

  const handleEmpDesignationUpdate = (updatedValuesArray) => {
    setEmpDesignationTableData(updatedValuesArray);
    console.log(
      "Received updated values array from TableTab:",
      updatedValuesArray
    );
  };

  const handleApplicationSubmit = async (isPaymentTabClicked) => {
    const newErrors = {};
    let firstErrTab = null;

    Object.keys(sectionFormData).forEach((sectionId) => {
      sectionFormData[sectionId].forEach((field) => {
        if (
          tabsWholeData?.section_fields?.hasOwnProperty(field?.field_key)
        ) {
          const value = tabsWholeData.section_fields[field.field_key] || ""; // Get the value of the field
          const isRequired = field.required === "1"; // Check if the field is required

          if (isRequired && !value) {
            newErrors[field?.field_key] = {
              hi: `${field?.name_hi} आवश्यक है`,
              en: `${field?.name_en} is required`,
            };
          } else {
            if (
              JSON.parse(JSON.parse(field?.validations).validation)?.onKeyUp &&
              JSON.parse(JSON.parse(field?.validations).validation)?.onKeyUp
                ?.length > 0
            ) {
              const validations = validateonSubmitHandle(field, value, null);
              newErrors[field?.field_key] =
                validations === "" ? null : validations;
            } else {
              newErrors[field?.field_key] = null;
            }
          }
          firstErrTab = firstErrTab
            ? firstErrTab
            : newErrors[field?.field_key]
              ? combinedTabsData?.find(
                (t) => t.id === sectionFormData?.[sectionId]?.[0]?.tab_id
              )
              : null;
        }
      });
    });

    Object.keys(sectionFormData).forEach((sectionId) => {
      sectionFormData[sectionId].forEach((field) => {
        if (
          tabsWholeData?.sub_section_fields?.[field.field_key + "_json"] &&
          Array.isArray(
            tabsWholeData?.sub_section_fields?.[field.field_key + "_json"]
          )
        ) {
          tabsWholeData?.sub_section_fields?.[
            field.field_key + "_json"
          ]?.forEach((item, itemIndex) => {
            Object.keys(item).forEach((key) => {
              if (item[key] === null || item[key].trim() === "") {
                // Check if value is null or empty
                newErrors[`${field.field_key}_json_${itemIndex}_${key}`] = {
                  hi: `आवश्यक है`,
                  en: `Required`,
                };
                firstErrTab = firstErrTab
                  ? firstErrTab
                  : newErrors[`${field.field_key}_json_${itemIndex}_${key}`]
                    ? combinedTabsData?.find(
                      (t) => t.id === sectionFormData?.[sectionId]?.[0]?.tab_id
                    )
                    : null;
              }
            });
          });
        }
      });
    });

    Object.keys(sectionFormData)?.forEach((sectionId) => {
      sectionFormData[sectionId]?.forEach((field) => {
        if (field?.type === "8") { // Check for Employee Details field type
          const value = tabsWholeData?.section_fields?.[field.field_key] || "";
          
          if (Array.isArray(value)) {
            Object.keys(value[0])?.forEach((key) => {
              var empDetails = tabsWholeData?.section_fields?.[field.field_key]?.[0]?.[key]?.["Employee Details"];
              
              if(empDetails && Array.isArray(empDetails)) {
                empDetails?.forEach((det, index) => {
                  Object.keys(empDetails?.[index])?.forEach((empKey) => {
                    var empDetailValue = empDetails?.[index]?.[empKey];
      
                    // Error messages
                    let requiredMessage = {
                      hi: `आवश्यक है`,
                      en: `Required`,
                    };
                    let regexMessage = {
                      hi: `मान्य प्रारूप में होना चाहिए`,
                      en: `Must be in a valid format`,
                    };
      
                    // Check for empty value
                    if (!empDetailValue) {
                      switch (empKey) {
                        case "Employee Name":
                          requiredMessage = {
                            hi: `कर्मचारी का नाम आवश्यक है`,
                            en: `Employee Name is required`,
                          };
                          break;
      
                        case "Contact No.":
                          requiredMessage = {
                            hi: `संपर्क नंबर आवश्यक है`,
                            en: `Contact Number is required`,
                          };
                          break;
      
                        case "Staff Aadhaar No.":
                          requiredMessage = {
                            hi: `स्टाफ आधार नंबर आवश्यक है`,
                            en: `Staff Aadhaar Number is required`,
                          };
                          break;
      
                        case "Bank Account No.":
                          requiredMessage = {
                            hi: `बैंक खाता संख्या आवश्यक है`,
                            en: `Bank Account Number is required`,
                          };
                          break;
      
                        case "IFSC Code":
                          requiredMessage = {
                            hi: `आईएफएससी कोड आवश्यक है`,
                            en: `IFSC Code is required`,
                          };
                          break;
                        
                        case "Bank Name":
                          requiredMessage = {
                            hi: `बैंक का नाम आवश्यक है`,
                            en: `Bank Name is required`,
                          };
                          break;
                        
                        case "Status":
                          requiredMessage = {
                            hi: `स्थिति आवश्यक है`,
                            en: `Status is required`,
                          };
                          break;
  
                        case "Date Of Joining":
                            requiredMessage = {
                              hi: `शामिल होने की तिथि आवश्यक है`,
                              en: `Date Of Joining is required`,
                          };
                          break;
      
                        default:
                          requiredMessage = {
                            hi: `आवश्यक है`,
                            en: `Required`,
                          };
                      }
                      if(!isPaymentTabClicked && !firstErrTab) {
                        const tableTabIndex = combinedTabsData.findIndex(
                          (t) =>
                            t.id ===
                              field?.tab_id
                        )
                        combinedTabsData.splice(tableTabIndex + 1, 0, {
                          id: "emp-details",
                          name_en: "Employee Details",
                          name_hi: "कर्मचारी विवरण",
                          background_color: "#ffffff",
                        });
                        setIsEmpDetailsTab(true)
                        setEmpDesignationField(field)
                        setShouldShowEmpDetailsTab(true);
                        setEmpDetailIndex(tableTabIndex);
                      }
                      firstErrTab = firstErrTab
                          ? firstErrTab
                          : combinedTabsData?.find(
                              (t) => t.id === "emp-details"
                            );
                      newErrors[`${field?.field_key}_${key}_emp_${index}_${empKey}`] = requiredMessage;
                    }
      
                    // Regex validation for specific fields
                    if (empDetailValue) {
                      let isValid = true;
      
                      switch (empKey) {
                        case "Contact No.":
                          isValid = /^[6-9]\d{9}$/.test(empDetailValue); // Indian mobile number regex
                          regexMessage = {
                            hi: `संपर्क नंबर मान्य प्रारूप में होना चाहिए`,
                            en: `Contact Number must be valid`,
                          };
                          break;
      
                        case "Staff Aadhaar No.":
                          isValid = /^\d{12}$/.test(empDetailValue); // Aadhaar number regex
                          regexMessage = {
                            hi: `आधार नंबर 12 अंकों का होना चाहिए`,
                            en: `Aadhaar Number must be 12 digits`,
                          };
                          break;
      
                        case "Bank Account No.":
                          isValid = /^\d{9,18}$/.test(empDetailValue); // Bank account number regex
                          regexMessage = {
                            hi: `बैंक खाता संख्या मान्य प्रारूप में होनी चाहिए`,
                            en: `Bank Account Number must be valid`,
                          };
                          break;
      
                        case "IFSC Code":
                          isValid = /^[A-Z]{4}0[A-Z0-9]{6}$/.test(empDetailValue); // IFSC Code regex
                          regexMessage = {
                            hi: `आईएफएससी कोड मान्य प्रारूप में होना चाहिए`,
                            en: `IFSC Code must be valid`,
                          };
                          break;
      
                        default:
                          isValid = true; // No regex for other fields
                      }
      
                      if (!isValid) {
                        if(!isPaymentTabClicked && !firstErrTab) {
                          combinedTabsData.splice(empDetailIndex + 1, 0, {
                            id: "emp-details",
                            name_en: "Employee Details",
                            name_hi: "कर्मचारी विवरण",
                            background_color: "#ffffff",
                          });
                          const tableTabIndex = combinedTabsData.findIndex(
                            (t) =>
                              t.id ===
                                field?.tab_id
                          )
                          combinedTabsData.splice(tableTabIndex + 1, 0, {
                            id: "emp-details",
                            name_en: "Employee Details",
                            name_hi: "कर्मचारी विवरण",
                            background_color: "#ffffff",
                          });
                          setIsEmpDetailsTab(true)
                          setEmpDesignationField(field)
                          setShouldShowEmpDetailsTab(true);
                          setEmpDetailIndex(tableTabIndex);
                        }
                        firstErrTab = firstErrTab
                          ? firstErrTab
                          : combinedTabsData?.find(
                              (t) => t.id === "emp-details"
                            );
                        newErrors[`${field?.field_key}_${key}_emp_${index}_${empKey}`] = regexMessage;
                      }
                    }
                  });
                });
              } else {
                firstErrTab = firstErrTab
                  ? firstErrTab
                  : combinedTabsData?.find(
                    (t) => t.id === "emp-details"
                  );
              }
              
            });
          }
        }
      });
    });

    if(firstErrTab === null) {
      const newDocErrors = {};
      documentList.forEach((doc, index) => {
        if (!doc.upload_documents || doc.upload_documents.length === 0) {
          newDocErrors[doc?.id] = {
            hi: `${doc?.name_hi} आवश्यक है`,
            en: `${doc?.name_en} is required`,
          };
          firstErrTab = firstErrTab
                      ? firstErrTab
                      : combinedTabsData?.find(
                        (t) => t.id === "doc-upload"
                      );
        }
      });
      setDocErrors({ ...docErrors, ...newDocErrors });
    }

    // Update the state with new errors

    if (firstErrTab && !isPaymentTabClicked) {
      setTabActive(firstErrTab);
      fetchSectionData(firstErrTab, false);
      staticsTab(firstErrTab);
      setErrors({ ...errors, ...newErrors });
    }

    return firstErrTab === null;
  };

  const checkEmpDetailDataFilled = async (newErrors) => {
    try {
      let newErr = "";
      Object.keys(sectionFormData)?.forEach((sectionId) => {
        sectionFormData[sectionId]?.forEach((field) => {
          if (field?.type === "8") { // Check for Employee Details field type
            const value = tabsWholeData?.section_fields?.[field.field_key] || "";
            
            if (Array.isArray(value)) {
              Object.keys(value[0])?.forEach((key) => {
                var empDetails = tabsWholeData?.section_fields?.[field.field_key]?.[0]?.[key]?.["Employee Details"];
                
                if(empDetails && Array.isArray(empDetails)) {
                  empDetails?.forEach((det, index) => {
                    Object.keys(empDetails?.[index])?.forEach((empKey) => {
                      var empDetailValue = empDetails?.[index]?.[empKey];
        
                      // Error messages
                      let requiredMessage = {
                        hi: `आवश्यक है`,
                        en: `Required`,
                      };
                      let regexMessage = {
                        hi: `मान्य प्रारूप में होना चाहिए`,
                        en: `Must be in a valid format`,
                      };
        
                      // Check for empty value
                      if (!empDetailValue) {
                        newErr = "error"
                      }
        
                      // Regex validation for specific fields
                      if (empDetailValue) {
                        let isValid = true;
        
                        switch (empKey) {
                          case "Contact No.":
                            isValid = /^[6-9]\d{9}$/.test(empDetailValue); // Indian mobile number regex
                            regexMessage = {
                              hi: `संपर्क नंबर मान्य प्रारूप में होना चाहिए`,
                              en: `Contact Number must be valid`,
                            };
                            break;
        
                          case "Staff Aadhaar No.":
                            isValid = /^\d{12}$/.test(empDetailValue); // Aadhaar number regex
                            regexMessage = {
                              hi: `आधार नंबर 12 अंकों का होना चाहिए`,
                              en: `Aadhaar Number must be 12 digits`,
                            };
                            break;
        
                          case "Bank Account No.":
                            isValid = /^\d{9,18}$/.test(empDetailValue); // Bank account number regex
                            regexMessage = {
                              hi: `बैंक खाता संख्या मान्य प्रारूप में होनी चाहिए`,
                              en: `Bank Account Number must be valid`,
                            };
                            break;
        
                          case "IFSC Code":
                            isValid = /^[A-Z]{4}0[A-Z0-9]{6}$/.test(empDetailValue); // IFSC Code regex
                            regexMessage = {
                              hi: `आईएफएससी कोड मान्य प्रारूप में होना चाहिए`,
                              en: `IFSC Code must be valid`,
                            };
                            break;
        
                          default:
                            isValid = true; // No regex for other fields
                        }
        
                        if (!isValid) {
                          newErr = "error";
                        }
                      }
                    });
                  });
                } else {
                  newErr = "error";
                }
                
              });
            }
          }
        });
      });
      if(newErr === "") {
        return true;
      } else {
        return false;
      }
    } catch(ex) {
      console.error('Error', ex);
      return false;
    }
  }

  return (
    <Dashboard
      first={language ? "होम" : "Home"}
      second={
        language
          ? "आवेदन फार्म"
          : loginCatInfo?.category_name
            ? loginCatInfo?.category_name + "/Application Form"
            : loginCatInfo?.name + "/Application Form"
      }
      head={
        language
          ? "आवेदन फार्म"
          : loginCatInfo?.category_name
            ? loginCatInfo?.category_name + "/Application Form"
            : loginCatInfo?.name + "/Application Form"
      }
    >
      {applicationNumber ? <div className="d-flex justify-content-end mb-2 align-items-center"
        style={{
          height: "100%", // Match the height of the Dashboard component
        }}
      >
        <div className="card_feild_main_itm">
          <span className="fs-6 fw-bold me-1">{language ? "आवेदन संख्या" : "Application Number"} :</span>
        </div>
        <div className="card_feild_main_itm">
          <span className="fs-6">{applicationNumber}</span>
        </div>
      </div> : <></>}
      <div className="aod-outer" style={{ opacity: htmlRendered ? '1' : '0' }}>
        <div ref={appFormRef}>
          {tabsData?.length > 0 && !isFormSubmitted && !isDraft ? (
            <div className="aod-inner">
              <div className="aod-top justify-content-start">
                {combinedTabsData
                  // ?.filter((tab) => (!isApplicationExpired && (tab.id !== "emp-details" || showEmpDetailsTab)) 
                  //   || (isApplicationExpired && tab.id === "make-payment"))
                  ?.filter((tab) => ((tab.id !== "emp-details" || showEmpDetailsTab)))
                  .sort((a, b) => new Date(a.id) - new Date(b.id))
                  .map((tab) => (
                    <nav
                      key={tab.id}
                      className="nav nav-pills nav-fill"
                      style={{
                        background: tab?.background_color,
                      }}
                    >
                      <a
                        className={
                          tabActive?.id === tab?.id
                            ? "green"
                            : `${tab?.background_color} white`
                        }
                        style={{
                          fontSize: `${tab?.font_size}px`,
                          color:
                            tabActive?.id === tab?.id ? "white" : tab?.font_color,
                        }}
                        onClick={() => handleTabChange(tab)}
                      >
                        {language
                          ? tab?.name_hi
                          : firstCharUpperCase(tab?.name_en)}
                      </a>
                    </nav>
                  ))}
              </div>
            </div>
          ) : (
            <></>
          )}

          <div className="aod-bottom">
            {/* {tabsData?.length > 0  && !isApplicationExpired? ( */}
            {tabsData?.length > 0 ? (
              sectionData
                ?.sort((a, b) => new Date(a?.id) - new Date(b?.id))
                .map((section) => (
                  <div key={section.id}>
                    <div
                      className="m-wrapper-heading"
                      style={{
                        backgroundColor: section?.background_color,
                        color: section?.font_color,
                      }}
                    >
                      <div style={{ fontSize: `${section?.font_size}px` }}>
                        {language
                          ? section?.name_hi
                          : firstCharUpperCase(section?.name_en)}
                      </div>
                    </div>

                    <div className="m-form-wrapper">
                      <form>
                        <div className="row">
                          {sectionFormData[section?.id]?.map((field) => (
                            <div
                              className={
                                inputTypeList?.find(
                                  (x) => x?.value == field?.type
                                )?.label == "Designation Table" ||
                                  field?.sub_section_id != undefined ||
                                  field?.sub_section_id != null
                                  ? "col-lg-12"
                                  : "col-md-4"
                              }
                              key={field.id}
                            >
                              <div className="mb-3 f_fields">
                                {inputTypeList?.find(
                                  (x) => x.value == field?.type
                                )?.label != "Designation Table" ? (
                                  <label
                                    htmlFor={field.field_key}
                                    className={
                                      field?.name_en?.length < 40
                                        ? "form-label"
                                        : "select-label"
                                    }
                                  >
                                    <span
                                      className={`field-label${field?.required === "1" ? " required" : ""
                                        }`}
                                      style={{
                                        color: field?.font_color,
                                        fontSize: `${field?.font_size}px`,
                                        backgroundColor: field?.background_color,
                                      }}
                                    >
                                      {language ? field?.name_hi : field?.name_en}
                                    </span>
                                  </label>
                                ) : null}

                                {field?.sub_section_id ? (
                                  <SubSection
                                    fieldData={field}
                                    handleInputChange={handleInputChange}
                                    inputTypeList={inputTypeList}
                                    sectionId={field?.section_id}
                                    language={language} // or true for Hindi
                                    formValues={formValues}
                                    setFormValues={setFormValues}
                                    subSectionFieldKey={field.field_key}
                                    setTabsWholeData={setTabsWholeData}
                                    subSectionId={field?.sub_section_id}
                                    errors={errors}
                                    setErrors={setErrors}
                                    applicationFromData={applicationFromData}
                                    tabsWholeData={tabsWholeData}
                                    fieldsDisabled={fieldsDisabled}
                                    totalRoomsPremesisesKey={totalRoomsPremesisesKey}
                                  // subSectionsData={/* Your sub-section data */}
                                  />
                                ) : [
                                  "text",
                                  "checkbox",
                                  "password",
                                  "date",
                                  "file",
                                  "email",
                                  "phone"
                                ].includes(
                                  inputTypeList?.find(
                                    (x) => x.value == field?.type
                                  )?.label
                                ) ? (
                                  <div key={field.field_key}>
                                    <InputTypeText
                                      key={field.field_key}
                                      type={
                                        inputTypeList?.find(
                                          (x) => x.value == field?.type
                                        )?.label
                                      }
                                      placeholder={
                                        language
                                          ? field?.placeholder_hi
                                          : field?.placeholder_en
                                      }
                                      id={field.field_key}
                                      name={field.field_key}
                                      value={
                                        tabsWholeData.section_fields[
                                        field.field_key
                                        ] || ""
                                      } // Controlled value
                                      onChange={(e) =>
                                        handleInputChange(
                                          e,
                                          field,
                                          section.id,
                                          e?.target?.value || e?.target?.files
                                        )
                                      } // Change handler
                                      isSubSectionInput={false}
                                      fieldsDisabled={fieldsDisabled || (parentApplicationId && parentApplicationId > 0 && (field?.type === "11" || field?.type === "12"))}
                                      field={field}
                                      setErrors={setErrors}
                                    />
                                    {typeof errors[field.field_key] ===
                                      "object" &&
                                      errors[field.field_key] !== null && (
                                        <span className="validate-error">
                                          {language
                                            ? errors?.[field?.field_key]?.hi
                                            : errors?.[field?.field_key]?.en}
                                        </span>
                                      )}
                                  </div>
                                ) : inputTypeList?.find(
                                  (x) => x.value == field?.type
                                )?.label === "dropdown" || field?.type === "10" ? (
                                  <div key={field.field_key}>
                                    <SelectType
                                      key={field.field_key}
                                      dropdownId={field?.type === "10" ? districtDropdownId : field?.dropdown_id}
                                      fieldKey={field?.field_key}
                                      value={
                                        tabsWholeData.section_fields[
                                        field.field_key
                                        ] || ""
                                      } // Pass current value
                                      onChange={(e) =>
                                        handleInputChange(
                                          e,
                                          field,
                                          section.id,
                                          field?.type === "10" ? e?.target?.value?.value : e?.target?.value?.label
                                        )
                                      } // Change handler
                                      placeholder={
                                        language
                                          ? "विकल्प चुनें"
                                          : "Select Option"
                                      }
                                      styles={customStylesAccUser}
                                      theme={selectThemeUser}
                                      ukStateId={ukStateId}
                                      fieldsDisabled={fieldsDisabled || (parentApplicationId && parentApplicationId > 0 && field?.type === "10")}
                                    />
                                    {typeof errors[field.field_key] ===
                                      "object" &&
                                      errors[field.field_key] !== null && (
                                        <span className="validate-error">
                                          {language
                                            ? errors?.[field?.field_key]?.hi
                                            : errors?.[field?.field_key]?.en}
                                        </span>
                                      )}
                                  </div>
                                ) : inputTypeList?.find(
                                  (x) => x.value == field?.type
                                )?.label === "Designation Table" ? (
                                  <TableTab
                                    fieldData={field}
                                    inputTypeList={inputTypeList}
                                    subCatId={field?.category_id}
                                    language={language} // or true for Hindi
                                    formValues={
                                      tabsWholeData.section_fields[
                                      field.field_key
                                      ] || []
                                    }
                                    onChange={(e) =>
                                      handleInputChange(
                                        e,
                                        field,
                                        section.id,
                                        e?.target?.value
                                      )
                                    } // Change handler
                                    handleEmpDesignationUpdate={
                                      handleEmpDesignationUpdate
                                    } // Pass the callback to child
                                    tabsWholeData={tabsWholeData}
                                    setTabsWholeData={setTabsWholeData}
                                    applicationFromData={applicationFromData}
                                    fieldsDisabled={fieldsDisabled}
                                  />
                                ) : null}
                              </div>
                            </div>
                          ))}
                        </div>
                      </form>
                    </div>
                  </div>
                ))
            ) : (
              <>
                {/* {!isApplicationExpired ? <div className="text-center"> */}
                <div className="text-center">
                  {language
                    ? "इस श्रेणी के लिए कोई आवेदन पत्र उपलब्ध नहीं है"
                    : "No Application form is available for this category"}
                </div>
              </>
            )}
            {/* {isLocationTab && !isFormSubmitted && !isApplicationExpired && ( */}
            {isLocationTab && !isFormSubmitted && (
              <div className="m-form-wrapper">
                <form>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="mb-3 f_fields">
                        <Geolocation
                          initialAddress={userDetail?.address}
                          subCatId={loginCatInfo?.id}
                          mapLocation={addressString}
                          sectionFormDataField={sectionFormData}
                          tabsWholeDataField={tabsWholeData}
                          geoLocationSection={geoLocationSection}
                          geoLocationAddress={geoLocationAddress}
                          setGeoLocationAddress={setGeoLocationAddress}
                          fieldsDisabled={fieldsDisabled}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            )}
            {/* {isEmpDetailsTab && !isFormSubmitted && !isDraft && !isApplicationExpired && ( */}
            {isEmpDetailsTab && !isFormSubmitted && !isDraft && (
              <div className="m-form-wrapper">
                <form>
                  <div className="row">
                    <div className="col-lg-12">
                      <EmpDetails
                        empDesignationTableData={empDesignationTableData}
                        subCatId={loginCatInfo?.id}
                        language={language}
                        tableData={tableData} // Pass updated tableData to EmpDetails
                        tabsWholeData={tabsWholeData}
                        setTabsWholeData={setTabsWholeData}
                        applicationFromData={applicationFromData}
                        empDesignationField={empDesignationField}
                        errors={errors}
                        setErrors={setErrors}
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                        checkEmpDetailDataFilled={checkEmpDetailDataFilled}
                        fieldsDisabled={fieldsDisabled}
                      />
                    </div>
                  </div>
                </form>
                <div className="comment-form-cookies-consent d-flex align-items-center justify-content-start mt-4">
                  <input
                    id="wp-comment-cookies-consent"
                    className="mt-0"
                    name="wp-comment-cookies-consent"
                    type="checkbox"
                    value="yes"
                  />
                  <label htmlFor="wp-comment-cookies-consent" className="px-1">
                    {language
                      ? "मैं इसके द्वारा पुष्टि करता हूं कि मेरे द्वारा प्रदान की गई सभी जानकारी मेरी जानकारी के अनुसार सही है।"
                      : "I hereby confirm that all the information provided by me is correct to my knowledge."}
                  </label>
                </div>
                {checkboxError && (
                  <div className="text-danger mt-2">{language ? checkboxError?.hi : checkboxError.en}</div>
                )}
              </div>
            )}
            {/* {isDocumnetTab && !isFormSubmitted && !isDraft && !isApplicationExpired && ( */}
            {isDocumnetTab && !isFormSubmitted && !isDraft && (
              <div className="m-form-wrapper" style={{ opacity: isApplicationExpired ? 0.5 : 1, pointerEvents: isApplicationExpired ? "none" : "auto" }}>
                <form>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="mb-3 f_fields">
                        <DocsUpload
                          subCatId={loginCatInfo?.id}
                          setDocErrors={setDocErrors}
                          docErrors={docErrors}
                          documentList={documentList}
                          setDocumentList={setDocumentList}
                          parentApplicationId={parentApplicationId}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            )}
            {isPaymentEnabled && isPaymentTab && !isFormSubmitted && !isDraft && (
              <div className="m-form-wrapper">
                <form>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="mb-3 f_fields">
                        <Payment
                          district_id = {appliedForDistrict}
                          subCatId={loginCatInfo?.id}
                          handleApplicationSubmit={handleApplicationSubmit}
                          setIsFormSubmitted={setIsFormSubmitted}
                          applicationId={applicationId}
                          isDraftSubmitted={isDraftSubmitted}
                          paymentTabDetails={paymentTabInfo}
                          applicantEmail={applicantEmail}
                          applicantNumber={applicantNumber}
                          category={getLoginSubCabData}
                          handleFormBack={handleFormBack}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            )}
            {isFormSubmitted && (
              <div className="payment-successfull-block hide">
                <div className="row">
                  <div className="col-md-12">
                    <div className="payment-detail-blocks payment-success-inner-wrapprer">
                      <div className="paymnet-done-icon">
                        {" "}
                        <CgCheckO />
                      </div>
                      <div className="payment-done-text">
                        <p className="mb-2">
                          {trackingNumber
                            ? language
                              ? `ट्रैकिंग नंबर: ${trackingNumber}`
                              : `Tracking Number: ${trackingNumber}`
                            : ""}
                        </p>
                        <h5 className="">
                          {isPaymentEnabled
                            ? language
                              ? "भुगतान सफल!"
                              : "Payment Successful!"
                            : language
                              ? "सबमिशन सफल"
                              : "Submission Successful"}
                        </h5>
                        <p>
                          {isPaymentEnabled
                            ? language
                              ? "भुगतान हो चुका है और आवेदन सफलतापूर्वक जमा हो गया है! हमारे साथ बने रहने के लिए धन्यवाद."
                              : "The Payment has been done and Application submitted successfully! Thanks for being their with us."
                            : language
                              ? "आवेदन सफलतापूर्वक सबमिट कर दिया गया है! हमारे साथ बने रहने के लिए धन्यवाद."
                              : "The Application has been submitted successfully! Thanks for being their with us."}
                        </p>
                      </div>
                      <div className="dashboard-btn">
                        <button
                          className="user-theme-btn"
                          onClick={handleDashboardClick}
                        >
                          {" "}
                          {language ? "डैशबोर्ड" : "Dashboard"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!isDraft && !isPaymentTab && !isFormSubmitted && tabActive && tabsData && tabsData?.length > 0 && (<div className="d-flex" style={{ justifyContent: combinedTabsData?.findIndex((t) => t?.id === tabActive?.id) === 0 ? 'end' : 'space-between' }}>
               {combinedTabsData?.findIndex((t) => t?.id === tabActive?.id) > 0 && (<button
                type="submit"
                className="btn btn-primary user-theme-btn mt-3"
                onClick={handleFormBack}
              >
                {language ? "पीछे" : "Back"}
              </button>)}
              {!isLastTab &&
                !isDocumnetTab &&
                tabsData?.length > 0 &&
                !isPaymentTab &&
                !isFormSubmitted &&
                // !isDraft && !isApplicationExpired && (
                !isDraft && (
                    <button
                      type="submit"
                      className="btn btn-primary user-theme-btn mt-3 "
                      onClick={handleFormNext}
                    >
                      {language ? "अगला" : "Next"}
                    </button>
                )}
              {/* {isDocumnetTab && !isPaymentTab && !isFormSubmitted && !isDraft && !isApplicationExpired && ( */}
              {isDocumnetTab && !isPaymentTab && !isFormSubmitted && !isDraft && (
                  <button
                    type="submit"
                    className="btn btn-primary user-theme-btn mt-3 "
                    onClick={handleFormSubmit}
                  >
                    {language ? "अगला" : "Next"}
                  </button>
              )}
            </div>)}
            {/* {isDraft && !isApplicationExpired && ( */}
            {isDraft && (
              <>
                <div className="gc-apply-butt" style={{ justifyContent: "space-between", }}>
                  {pdfLoading ? <button style={{ justifyContent: "flex-start", marginBottom: "20px" }}>
                    <span>  {language ? "डाउनलोड हो रहा है..." : "Downloading..."}</span>
                    <Spinner animation="border" role="status" style={{ 'marginLeft': '5px' }}>
                      <span className="visually-hidden"></span>
                    </Spinner>
                  </button> : <button style={{ justifyContent: "flex-start", marginBottom: "20px" }} onClick={downloadApplicationPDF}>
                    {language
                      ? "पीडीएफ डाउनलोड करें"
                      : "Download PDF"}
                  </button>}
                  {submitting ? <button style={{ justifyContent: "flex-start", marginBottom: "20px" }}>
                    <span>  {language ? "सबमिट हो रहा है..." : "Submitting..."}</span>
                    <Spinner animation="border" role="status" style={{ 'marginLeft': '5px' }}>
                      <span className="visually-hidden"></span>
                    </Spinner>
                  </button> : <button style={{ justifyContent: "flex-end", marginBottom: "20px" }} onClick={handleSubmitClick}>
                  {isPaymentEnabled
                      ? language
                        ? "सबमिट करें और जारी रखें"
                        : "Submit & Continue"
                      : language
                        ? "सबमिट करें"
                        : "Submit"}
                  </button>}
                </div>
                <div className="">
                  <div className="preview-page-main-wrapper">
                    <div className="row">
                      <div className="col-md-12">
                        {tabsData && tabsData.length > 0
                          ? tabsData?.map((item) => (
                            <div key={item?.id}>
                              <InputTableData
                                tabsData={item}
                                isEditable={!fieldsDisabled}
                                parentApplicationId={parentApplicationId}
                              />
                            </div>
                          ))
                          : null}
                      </div>
                    </div>

                    {geoLocationAddress ? (
                      <div className="col-md-12 mt-4">
                        <div className="row">
                          <div className="mt-4">
                            <GeoLocationAddress subCatId={loginCatInfo?.id} isEditable={fieldsDisabled} mapLocation={geoLocationAddress} wholeFormData={applicationFromData} />
                          </div>
                        </div>
                      </div>
                    ): null
                    }

                    <div className="col-lg-12 mt-4">
                      {designationKey && (
                          <div>
                            <DesignationTable
                              tabsData={tabsData}
                              subCatId={loginCatInfo?.id}
                              isEditable={!fieldsDisabled}
                              designationKey={designationKey}
                              parentApplicationId={parentApplicationId}
                            />
                          </div>
                        )}
                    </div>

                    <div className="col-md-12 mt-4">
                      <div className="data-wrapper">
                        <div className="data-wrapper-heading-block d-flex justify-content-between align-items-center">
                          <div className="data-wrapper-heading">
                            <h5 className="mb-0">{language ? "दस्तावेज़ अपलोड करें" : "Upload Documents"}</h5>
                          </div>
                          <div
                            className="data-wrapper-edit-icon"
                            onClick={editToggle}
                          >
                            {showEditIcon ? (
                              <CgPen />
                            ) : (
                              <>
                                {/* <div>Save</div> */}
                                <div onClick={editToggle}>{language ? "रद्द करें" : "Cancel"}</div>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="mt-4">
                            <DocsUploadDraft
                              subCatId={loginCatInfo?.id}
                              showEditIcon={showEditIcon}
                              parentApplicationId={parentApplicationId}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </Dashboard>
  );
};

export default OrganizationDetails;
