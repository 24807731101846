import React, { useEffect, useState } from "react";
import AdminDashboard from "../AdminDashboard/AdminDashboard";
import { useLocation, useNavigate } from "react-router-dom";
import { GetLocalStorage } from "../../../Utils/Utils";
import { checkPattern } from "../../../Utils/common";
import { Message } from "../../../components/message";
import { BsChevronLeft } from "react-icons/bs";
import { addRegistrationStep, updateRegistrationStep } from "../../../services/api/admin";
import { UploadIcon } from "../AdminIcon";

const EditRegistrationStep = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const token = GetLocalStorage("userInfo");
    const [apiMessage, setApiMessage] = useState({ type: "", message: "" });
    const [nameHi, setNameHi] = useState("");
    const [nameEn, setNameEn] = useState("");
    const [descriptionHi, setDescriptionHi] = useState("");
    const [descriptionEn, setDescriptionEn] = useState("");
    const [id, setId] = useState("");
    const [nameHiErr, setNameHiErr] = useState("");
    const [nameEnErr, setNameEnErr] = useState("");
    const [descriptionHiErr, setDescriptionHiErr] = useState("");
    const [descriptionEnErr, setDescriptionEnErr] = useState("");
    const [sortOrder, setSortOrder] = useState("");
    const [sortOrderErr, setSortOrderErr] = useState();
    const [editData, setEditData] = useState();
    const [image, setImage] = useState();
    const [imageError, setImageError] = useState();
    const [file, setFile] = useState();
    const [color, setColor] = useState("#000000");
  
    useEffect(() => {
      if (!token) {
        navigate("/");
      } else {
        if (location?.state?.data) {
          editForm(location?.state?.data);
        }
      }
    }, [location?.state]);
  
    const editForm = (data) => {
      setEditData(data);
      setNameHi(data?.name_hi);
      setNameEn(data?.name_en);
      setDescriptionEn(data?.description_hi);
      setDescriptionHi(data?.description_en);
      setSortOrder(data?.sort);
      setColor(data?.font_color);
      setId(data?.id);
      setImage(data?.icon);
    };
  
    const updateStep = async (e) => {
      e.preventDefault();
      if (!validateForm("both")) return;
      try {
        if(typeof(image) === "object"){
          alert(typeof(image))
        }
        const formData = new FormData();
        formData.append("name_en", nameEn);
        formData.append("name_hi", nameHi);
        formData.append("description_en", descriptionEn);
        formData.append("description_hi", descriptionHi);
        formData.append("sort", sortOrder);
        formData.append("step_id", id);
        formData.append("font_color", color);
        if(typeof(image) === "object"){
          formData.append("image", image);
        }
        const updateData = await updateRegistrationStep(formData);
        if (updateData?.status == 200) {
          navigate(`/admin/registration-steps`, {
            state: { message: updateData?.message },
          });
        } else {
          setApiMessage({ type: "error", message: updateData?.message });
        }
      } catch (err) {
        setApiMessage({ type: "error", message: err?.message });
      }
    };
  
    const addStep = async (e) => {
      e.preventDefault();
      if (!validateForm("both")) return;
      try {
        const formData = new FormData();
        formData.append("name_en", nameEn);
        formData.append("name_hi", nameHi);
        formData.append("description_en", descriptionEn);
        formData.append("description_hi", descriptionHi);
        formData.append("sort", sortOrder);
        formData.append("image", image);
        formData.append("font_color", color);
        const updateData = await addRegistrationStep(formData);
        if (updateData?.status == 200) {
          navigate(`/admin/registration-steps`);
        } else {
          setApiMessage({ type: "error", message: updateData?.message });
        }
      } catch (err) {
        setApiMessage({ type: "error", message: err?.message });
      }
    };
  
    const validateForm = (item) => {
      let isValid = true;
  
      if (item === "nameEn" || item === "both") {
        if (!nameEn || nameEn.trim() === "") {
          setNameEnErr("Please Enter English Name");
          isValid = false;
        } else {
          setNameEnErr("");
        }
      }
      if (item === "nameHi" || item === "both") {
        if (!nameHi || nameHi.trim() === "") {
          setNameHiErr("Please Enter Hindi Name");
          isValid = false;
        } else {
          setNameHiErr("");
        }
      }

      if (item === "descriptionEn" || item === "both") {
        if (!descriptionEn || descriptionEn.trim() === "") {
          setDescriptionEnErr("Please Enter English Description");
          isValid = false;
        } else {
          setDescriptionEnErr("");
        }
      }
      if (item === "descriptionHi" || item === "both") {
        if (!nameHi || nameHi.trim() === "") {
          setDescriptionHiErr("Please Enter Hindi Description");
          isValid = false;
        } else {
          setDescriptionHiErr("");
        }
      }
  
      if (item === "sort" || item === "both") {
        if (!sortOrder || sortOrder === "") {
          setSortOrderErr("Please Enter Sort Order");
          isValid = false;
        } else {
          setSortOrderErr("");
        }
      }
  
      if (item === "icon" || item === "both") {
        if (!image) {
          setImageError("Please Select Icon Image");
          isValid = false;
        } else {
          setImageError("");
        }
      }
  
      return isValid;
    };
  
    const handleInputChange = async (e) => {
      const { name, value } = e.target;
      const cursorPosition = e.target.selectionStart;
  
      if (name == "nameEn") {
        if ((await checkPattern(value)) == false) {
          e.preventDefault();
          return;
        } else {
          setNameEn(value);
        }
      } else if (name == "nameHi") {
        if ((await checkPattern(value)) == false) {
          e.preventDefault();
          return;
        } else {
          setNameHi(value);
        }
      }
      setTimeout(() => {
        e.target.setSelectionRange(cursorPosition, cursorPosition);
      }, 0);
  
      validateOnChangeHandle(name, value);
    };
  
    const validateOnChangeHandle = async (name, value) => {
      switch (name) {
        case "nameEn":
          if (!value?.trim()) {
            setNameEnErr("Please Enter English Name");
          } else {
            setNameEnErr("");
          }
          break;
        case "nameHi":
          if (!value?.trim()) {
            setNameHiErr("Please Enter Hindi Name");
          } else {
            setNameHiErr("");
          }
          break;
        case "descriptionEn":
          if (!value?.trim()) {
            setDescriptionEnErr("Please Enter English Description");
          } else {
            setDescriptionEn(value)
            setDescriptionEnErr("");
          }
          break;
        case "descriptionHi":
          if (!value?.trim()) {
            setDescriptionHiErr("Please Enter Hindi Description");
          } else {
            setDescriptionHi(value)
            setDescriptionHiErr("");
          }
          break;
        case "sort":
          if (!value?.trim()) {
            setSortOrderErr("Please Enter Sort Order");
          }
          setSortOrder(value);
          setSortOrderErr("");
          break;
      }
    };
  
    const validateOnBlurHandle = async (e) => {
      const { name, value } = e?.target;
  
      if (name == "nameEn") {
        if (!value.trim()) {
          setNameEnErr("Please Enter English Name");
        } else {
          setNameEnErr("");
        }
      } else if (name == "nameHi") {
        if (!value.trim()) {
          setNameHiErr("Please Enter Hindi Name");
        } else {
          setNameHiErr("");
        }
      } else if (name == "descriptionEn") {
        if (!value.trim()) {
          setDescriptionEnErr("Please Enter English Description");
        } else {
          setDescriptionEnErr("");
        }
      } else if (name == "descriptionHi") {
        if (!value.trim()) {
          setDescriptionHiErr("Please Enter Hindi Description");
        } else {
          setDescriptionHiErr("");
        }
      } else if (name == "sort") {
        if (!value.trim()) {
          setSortOrderErr("Please Enter Sort Order");
        } else {
          setSortOrderErr("");
        }
      } 
    };
  
    const handleFileChange = (e) => {
      const file = e?.target?.files?.[0];
      if (file) {
        if (file.type.startsWith("image/")) {
          setImage(file);
          setFile(URL.createObjectURL(e.target.files[0]));
          setImageError("");
        } else {
          setImage(null);
          setImageError("Please Upload Icon.");
          setFile("");
          e.target.value = "";
        }
      }
    };
  
    return (
      <AdminDashboard>
        <div className="aod-outer">
          {apiMessage && (
            <Message
              apiMessage={apiMessage}
              setApiMessage={setApiMessage}
            ></Message>
          )}
          <div className="ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb">
            <div>
              <b onClick={() => navigate("/admin/")}>Home /</b>
              <span> Pages/ {editData?.id ? "Update" : "Add"}</span>
            </div>
          </div>
          <div className="aod-inner">
            <div className="aod-bottom">
              <div className="aod-head text-center adm-head">
                <button
                  onClick={() => navigate(`/admin/registration-steps`)}
                >
                  <BsChevronLeft /> Back
                </button>
                {editData?.id ? "Update Registration Step" : "Add Registration Step"}
              </div>
              <form>
                <div className="row py-4">
                  <div className="col-md-4 aod-resp-tab">
                    <div className="suf-input-box aod-form-input mb-2">
                      <label className="mb-1 w-100">Name in English</label>
                      <input
                        className="w-100"
                        maxLength={100}
                        name="nameEn"
                        placeholder="Enter name in english"
                        type="text"
                        onChange={(e) => handleInputChange(e)} // Use onChange for real-time validation
                        onBlur={(e) => validateOnBlurHandle(e)}
                        value={nameEn}
                      />
                      {nameEnErr && <p className="validate-error">{nameEnErr}</p>}
                    </div>
                  </div>
                  <div className="col-md-4 aod-resp-tab">
                    <div className="suf-input-box aod-form-input mb-2">
                      <label className="mb-1 w-100">Name in Hindi</label>
                      <input
                        className="w-100"
                        maxLength={100}
                        name="nameHi"
                        placeholder="Enter name in hindi"
                        type="text"
                        onChange={(e) => handleInputChange(e)}
                        onBlur={(e) => validateOnBlurHandle(e)} // Use onChange for real-time validation
                        value={nameHi}
                      />
                      {nameHiErr && <p className="validate-error">{nameHiErr}</p>}
                    </div>
                  </div>

                  <div className="col-md-4 aod-resp-tab">
                    <div className="suf-input-box aod-form-input mb-2">
                      <label className="mb-1 w-100">Description in English</label>
                      <input
                        className="w-100"
                        maxLength={100}
                        name="descriptionEn"
                        placeholder="Enter description in english"
                        type="text"
                        onChange={(e) => handleInputChange(e)} // Use onChange for real-time validation
                        onBlur={(e) => validateOnBlurHandle(e)}
                        value={descriptionEn}
                      />
                      {descriptionEnErr && <p className="validate-error">{descriptionEnErr}</p>}
                    </div>
                  </div>
                  <div className="col-md-4 aod-resp-tab">
                    <div className="suf-input-box aod-form-input mb-2">
                      <label className="mb-1 w-100">Description in Hindi</label>
                      <input
                        className="w-100"
                        maxLength={100}
                        name="descriptionHi"
                        placeholder="Enter description in hindi"
                        type="text"
                        onChange={(e) => handleInputChange(e)}
                        onBlur={(e) => validateOnBlurHandle(e)} // Use onChange for real-time validation
                        value={descriptionHi}
                      />
                      {descriptionHiErr && <p className="validate-error">{descriptionHiErr}</p>}
                    </div>
                  </div>
  
                  <div className="col-md-4 aod-resp-tab">
                    <div className="suf-input-box aod-form-input mb-2">
                      <label className="mb-1 w-100">Sort Order</label>
                      <input
                        className="w-100"
                        placeholder="Enter Sort Order"
                        type="number"
                        max={100}
                        min={1}
                        onInput={(e) => {
                          // Limit value to a maximum of 25 and minimum of 1
                          if (e.target.value > 100) e.target.value = 100;
                          if (e.target.value < 1) e.target.value = "";
                        }}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value > 0) {
                            setSortOrder(value);
                            setSortOrderErr("");
                          } else {
                            setSortOrder("");
                          }
                        }}
                        onPaste={(e) => {
                          e.preventDefault(); // Prevent pasting
                        }}
                        value={sortOrder}
                      />
                    </div>
                    {sortOrderErr && (
                      <p className="validate-error">{sortOrderErr}</p>
                    )}
                  </div>
                  <div className="col-md-4 aod-resp-tab">
                    <div className="suf-input-box aod-form-input mb-2">
                      <label className="mb-1 w-100">Color</label>
                      <label className="adm-label-color">
                        {color ? color : <span>Select Color</span>}
                        <input
                          className="adm-color"
                          placeholder="Enter Color"
                          type="color"
                          value={color}
                          onChange={(e) => setColor(e?.target?.value)}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4 aod-resp-tab d-flex  align-items-top">
                      <div className="suf-input-box aod-form-input mb-2 w-100">
                        <label className="mb-1 w-100">
                          Icon <span className="text-danger required_f">*</span>
                        </label>
                        <input
                          type="file"
                          name="partsuploadfile"
                          id="profileimg"
                          className="w-100 ad-cat-input-field-image"
                          accept="image/*"
                          onKeyUp={() => handleInputChange("img")}
                          onChange={(e) => handleFileChange(e)}
                        />
                        <label
                          htmlFor="profileimg"
                          className="ad-cat-input-upload-image p-0"
                        >
                          <p className="ad-cat-text-upload-image">
                            <span>
                              <UploadIcon />
                            </span>{" "}
                            {typeof image === "string" ? (
                              <p className="mb-0">
                                {image.split("\\").pop().split("/").pop()}
                              </p>
                            ) : image?.name ? (
                              <p className="mb-0 fw-semibold">{image?.name}</p>
                            ) : (
                              "Choose Category Icon"
                            )}
                          </p>
                        </label>
                        {imageError ? (
                          <p className="validate-error">{imageError}</p>
                        ) : (
                          ""
                        )}
                      </div>
                      {file && (
                        <div id="img_preview" className="pt-4">
                          <img
                            className="ms-1 uplaoed_img_preview"
                            src={file}
                            alt=""
                          />
                        </div>
                      )}
                  </div>
                  <div className="row ad-cat-row">
                    <div className=" update-btns-block mt-3">
                      {editData?.id ? (
                        <>
                          <button onClick={updateStep}>Update</button>
                        </>
                      ) : (
                        <button onClick={addStep}>Submit</button>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </AdminDashboard>
    );
}

export default EditRegistrationStep