import React, { useEffect, useState } from 'react'
import AdminDashboard from '../AdminDashboard/AdminDashboard'
import { useLocation, useNavigate } from 'react-router-dom'
import { GetLocalStorage } from '../../../Utils/Utils'
import { checkPattern, checkUrlPattern } from '../../../Utils/common'
import { Message } from '../../../components/message'
import { BsChevronLeft } from 'react-icons/bs'
import { UploadIcon } from '../AdminIcon'
import { addSocialMediaLinks, updateSocialMediaLinks } from '../../../services/api/admin'

const EditSocialMediaLink = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const token = GetLocalStorage("userInfo")
  const [apiMessage, setApiMessage] = useState({ type: '', message: '' });
  const [nameHi, setNameHi] = useState('')
  const [nameEn, setNameEn] = useState('')
  const [url, setUrl] = useState('')
  const [id, setId] = useState('')
  const [image, setImage] = useState('')
  const [nameHiErr, setNameHiErr] = useState('')
  const [nameEnErr, setNameEnErr] = useState('')
  const [urlErr, setUrlErr] = useState('')
  const [imageErr, setImageErr] = useState('')
  const [file, setFile] = useState();
  const [editData, setEditData] = useState();

  useEffect(() => {
    if (!token) {
      navigate("/")
    } else {
      if (location?.state) {
        editForm(location?.state)
      }
    }
  }, [location?.state])

  const editForm = (data) => {
    setEditData(data);
    setNameHi(data?.name_hi)
    setNameEn(data?.name)
    setImage(data?.logo)
    setId(data?.id)
    setUrl(data?.url)
  }

  const validateForm = (item) => {
    let isValid = true;

    if (item === "nameEn" || item === "both") {
      if (!nameEn || nameEn.trim() === "") {
        setNameEnErr("Please Enter English Name");
        isValid = false;
      } else {
        setNameEnErr("");
      }
    }
    if (item === "nameHi" || item === "both") {
      if (!nameHi || nameHi.trim() === "") {
        setNameHiErr("Please Enter Hindi Name");
        isValid = false;
      } else {
        setNameHiErr("");
      }
    }

    if (item === "url" || item === "both") {
      if (!url || url.trim() === "") {
        setUrlErr("Please Enter Url");
        isValid = false;
      } else {
        setUrlErr("");
      }
    }

    if (item === "icon" || item === "both") {
      if (!image) {
        setImageErr("Please Select Icon");
        isValid = false;
      } else {
        setImageErr("");
      }
    }

    return isValid;
  };

  const updateLink = async (e) => {
    e.preventDefault();
    if (!validateForm("both")) return;
    try {
        const formData = new FormData()
        formData.append('name', nameEn)
        formData.append('name_hi', nameHi)
        formData.append('logo', image)
        formData.append('link_id', id)
        formData.append('url', url)
        const updateData = await updateSocialMediaLinks(formData)
        if (updateData?.status == 200){
          navigate("/admin/social-media-links", { state: { message: updateData?.message } })
        }else{
          setApiMessage({ type: 'error', message: updateData?.message })
        }
      
    } catch (err) {
      setApiMessage({ type: 'error', message: err?.message })

    }
  }

  const addLink = async (e) => {
    e.preventDefault();
    if (!validateForm("both")) return;
    try {
        const formData = new FormData()
        formData.append('name', nameEn)
        formData.append('name_hi', nameHi)
        formData.append('image', image)
        formData.append('url', url)
        const updateData = await addSocialMediaLinks(formData)
        if (updateData?.status == 200){
          navigate("/admin/social-media-links", { state: { message: updateData?.message } })
        }else{
          setApiMessage({ type: 'error', message: updateData?.message })
        }
      
    } catch (err) {
      setApiMessage({ type: 'error', message: err?.message })

    }
  }

  const handleInputChange = async (e) => {
    const { name, value } = e.target
    const cursorPosition = e.target.selectionStart;

    if (name == 'nameEn') {
      if (await checkPattern(value) == false) {
        e.preventDefault()
        return;
      } else {
        setNameEn(value)
      }
    } else if (name == 'nameHi') {
      if (await checkPattern(value) == false) {
        e.preventDefault()
        return;
      } else {
        setNameHi(value)
      }
    }
    setTimeout(() => {
      e.target.setSelectionRange(cursorPosition, cursorPosition);
    }, 0);

    validateOnChangeHandle(name, value);
  }

  const validateOnChangeHandle = async (name, value) => {
    switch (name) {
      case 'nameEn':
        if (!value?.trim()) {
          setNameEnErr('Please enter name in english');
        } else {
          setNameEnErr('')
        }
        break;
      case 'nameHi':
        if (!value?.trim()) {
          setNameHiErr('Please enter name in hindi');
        } else {
          setNameHiErr('')
        }
        break;
      case 'url':
        if (!value?.trim()) {
          setUrlErr('Please enter url');
        }
        setUrl(value)
        setUrlErr('')
        break;
    }

  }


  const validateOnBlurHandle = async (e) => {
    const { name, value } = e?.target;

    if (name == 'name_en') {
      if (!value.trim()) {
        setNameEnErr('Please name in english');
      } else {
        setNameEnErr('')
      }
    } else if (name == 'name_hi') {
      if (!value.trim()) {
        setNameHiErr('Please name in hindi');
      } else {
        setNameHiErr('')
      }
    } else if (name == 'url') {
      if (!value?.trim()) {
        setUrlErr('Please enter url');
      } else {
        if (await checkUrlPattern(value) == false) {
          setUrlErr("Please Enter valid Url")
        }
        setUrlErr('')
      }
    }
  };


  const handleFileChange = (e) => {
    const file = e?.target?.files?.[0];
    if (file) {
      if (file.type.startsWith('image/')) {
        setImage(file);
        setFile(URL.createObjectURL(e.target.files[0]));
        setImageErr('');
      } else {
        setImage(null);
        setFile('')
        setImageErr('Please upload image.');
        e.target.value = '';
      }
    }
  }

  return (
    <AdminDashboard>
      <div className='aod-outer'>
        {apiMessage && (<Message apiMessage={apiMessage} setApiMessage={setApiMessage}></Message>)}
        <div className='ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb'>
          <div>
            <b onClick={() => navigate("/admin/")}>Home  /</b><span> Social Media Link/ {editData?.id ? "Update" : "Add"}</span>
          </div>
        </div>
        <div className="aod-inner">
          <div className="aod-bottom">
            <div className="aod-head text-center adm-head"><button onClick={() => navigate("/admin/social-media-links")}><BsChevronLeft /> Back</button>{editData?.id ? "Update" : "Add"} Social Media Link</div>
            <form>
              <div className="row py-4">
                <div className="col-md-4 aod-resp-tab">
                  <div className='suf-input-box aod-form-input mb-2'>

                    <label className='mb-1 w-100'>
                      Name in English
                    </label>
                    <input
                      className='w-100'
                      maxLength={100}
                      name='nameEn'
                      placeholder='Enter name in english'
                      type="text"
                      onChange={(e) => handleInputChange(e)} // Use onChange for real-time validation
                      onBlur={(e) => validateOnBlurHandle(e)}
                      value={nameEn}
                    />
                    {nameEnErr && <p className='validate-error'>{nameEnErr}</p>}

                  </div>
                </div>
                <div className="col-md-4 aod-resp-tab">
                  <div className='suf-input-box aod-form-input mb-2'>

                    <label className='mb-1 w-100'>
                      Name in Hindi
                    </label>
                    <input
                      className='w-100'
                      maxLength={100}
                      name='nameHi'
                      placeholder='Enter name in hindi'
                      type="text"
                      onChange={(e) => handleInputChange(e)}
                      onBlur={(e) => validateOnBlurHandle(e)} // Use onChange for real-time validation
                      value={nameHi}
                    />
                    {nameHiErr && <p className='validate-error'>{nameHiErr}</p>}

                  </div>
                </div>

                <div className="col-md-4 aod-resp-tab">
                  <div className='suf-input-box aod-form-input mb-2'>

                    <label className='mb-1 w-100'>
                      Url
                    </label>
                    <input
                      className='w-100'
                      maxLength={100}
                      name='url'
                      placeholder='Enter name in hindi'
                      type="text"
                      onChange={(e) => handleInputChange(e)}
                      onBlur={(e) => validateOnBlurHandle(e)} // Use onChange for real-time validation
                      value={url}
                    />
                    {urlErr && <p className='validate-error'>{urlErr}</p>}

                  </div>
                </div>

                <div className="col-md-4 aod-resp-tab d-flex  align-items-top">
                  <div className='suf-input-box aod-form-input mb-2 w-100'>
                    <label className='mb-1 w-100'>
                      Icon
                    </label>
                    <input type="file" name="partsuploadfile" id="profileimg" className="w-100 ad-cat-input-field-image" accept="image/*" onChange={(e) => handleFileChange(e)} />
                    <label htmlFor="profileimg" className="ad-cat-input-upload-image p-0">
                      <p className="ad-cat-text-upload-image">
                        <span><UploadIcon /></span> {
                          typeof image === 'string' ? (
                            <p className="mb-0">{image.split('\\').pop().split('/').pop()}</p>
                          ) : (
                            image?.name ? (
                              <p className="mb-0 fw-semibold">{image?.name}</p>
                            ) : (
                              "Choose Image"
                            )
                          )
                        }
                      </p>
                    </label>
                    {
                      imageErr ? <p className="validate-error">{imageErr}</p> : ""
                    }


                  </div>
                  {file &&
                    <div id="img_preview" className="pt-4">
                      <img className="ms-1 uplaoed_img_preview" src={file} alt="" />
                    </div>

                  }
                </div>
                <div className="row ad-cat-row">
                  <div className=" update-btns-block mt-3">
                  {editData?.id ? (
                    <>
                      <button onClick={updateLink}>Update</button>
                    </>
                  ) : (
                    <button onClick={addLink}>Submit</button>
                  )}
                  </div>

                </div>
              </div>
            </form>

          </div>
        </div>
      </div>
    </AdminDashboard>
  )
}

export default EditSocialMediaLink
