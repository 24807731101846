import React, { useEffect, useState } from "react";
import AdminDashboard from "../AdminDashboard/AdminDashboard";
import { useLocation, useNavigate } from "react-router-dom";
import { GetLocalStorage } from "../../../Utils/Utils";
import {
  checkPattern,
  customStylesAcc,
  selectTheme,
} from "../../../Utils/common";
import { Message } from "../../../components/message";
import { BsChevronLeft } from "react-icons/bs";
import {
  addRegistrationStep,
  deleteContactUsSection,
  getContactUsData,
  getContactUsSectionList,
  handleEnableDisableContactUsSection,
  updateContactUsData,
  updateRegistrationStep,
} from "../../../services/api/admin";
import { DeleteIcon, EditIcon, UploadIcon } from "../AdminIcon";
import { Modal, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import NotFoundTable from "../../../components/notFound/NotFoundTable";
import Select from "react-select";
import { HiPlus } from "react-icons/hi";
import {
  checkPermission,
  permissionsConfig,
} from "../AdminPermission/PermissionChecker";

const AdminContactUs = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = GetLocalStorage("userInfo");
  const [apiMessage, setApiMessage] = useState({ type: "", message: "" });
  const [titleHi, setTitleHi] = useState("");
  const [titleEn, setTitleEn] = useState("");
  const [descriptionHi, setDescriptionHi] = useState("");
  const [descriptionEn, setDescriptionEn] = useState("");
  const [id, setId] = useState("");
  const [titleHiErr, setTitleHiErr] = useState("");
  const [titleEnErr, setTitleEnErr] = useState("");
  const [descriptionHiErr, setDescriptionHiErr] = useState("");
  const [descriptionEnErr, setDescriptionEnErr] = useState("");
  const [contactUsSectionList, setContactUsSectionList] = useState([]);
  const [tableDataLoaded, setTableDataLoaded] = useState(false);
  const [sectionType, setSectionType] = useState();
  const [deleteData, setDeleteData] = useState();
  const { state } = useLocation();

  const grantPermissionForAddContactUsSection = checkPermission(
    permissionsConfig.addContactUsSection.role,
    permissionsConfig.addContactUsSection.action,
    permissionsConfig.addContactUsSection.type
  );

  const grantPermissionForEditContactUsSection = checkPermission(
    permissionsConfig.editContactUsSection.role,
    permissionsConfig.editContactUsSection.action,
    permissionsConfig.editContactUsSection.type
  );

  const contactUsSectionTypes = [
    { value: "1", label: "Enquiry Section" },
    { value: "2", label: "DTDO Section" },
    { value: "3", label: "IAS Section" },
    { value: "4", label: "Content Information" },
  ];

  useEffect(() => {
    if (state && state?.message) {
      setApiMessage({ type: "success", message: state?.message });
      // Clear the message once it is rendered
      window.scrollTo(0, 0);
      window.history.replaceState({}, document.title);
    }
  }, [state]);

  useEffect(() => {
    if (!token) {
      navigate("/");
    } else {
      getContactUs();
      setSectionType(location?.state?.type ? contactUsSectionTypes?.find((c) => c?.value == location?.state?.type) : contactUsSectionTypes?.[0]);
      getContactUsSectionData(location?.state?.type ? contactUsSectionTypes?.find((c) => c?.value == location?.state?.type) : contactUsSectionTypes?.[0]);
    }
  }, []);

  const getContactUs = async () => {
    try {
      const contactUsData = await getContactUsData();
      if (contactUsData?.status === 200) {
        const data = contactUsData?.data;
        setTitleHi(data?.title_hi);
        setTitleEn(data?.title_en);
        setDescriptionEn(data?.description_hi);
        setDescriptionHi(data?.description_en);
        setId(data?.id);
      }
    } catch {}
  };

  const updateContactUs = async (e) => {
    e.preventDefault();
    if (!validateForm("both")) return;
    try {
      if (typeof image === "object") {
        alert(typeof image);
      }
      const formData = new FormData();
      formData.append("id", id);
      formData.append("title_en", titleEn);
      formData.append("title_hi", titleHi);
      formData.append("description_en", descriptionEn);
      formData.append("description_hi", descriptionHi);
      const updateData = await updateContactUsData(formData);
      if (updateData?.status == 200) {
        setApiMessage({ type: "success", message: updateData?.message });
      } else {
        setApiMessage({ type: "error", message: updateData?.message });
      }
    } catch (err) {
      setApiMessage({ type: "error", message: err?.message });
    }
  };

  const validateForm = (item) => {
    let isValid = true;

    if (item === "titleEn" || item === "both") {
      if (!titleEn || titleEn.trim() === "") {
        setTitleEnErr("Please Enter English Title");
        isValid = false;
      } else {
        setTitleEnErr("");
      }
    }
    if (item === "titleHi" || item === "both") {
      if (!titleHi || titleHi.trim() === "") {
        setTitleHiErr("Please Enter Hindi Title");
        isValid = false;
      } else {
        setTitleHiErr("");
      }
    }

    if (item === "descriptionEn" || item === "both") {
      if (!descriptionEn || descriptionEn.trim() === "") {
        setDescriptionEnErr("Please Enter English Description");
        isValid = false;
      } else {
        setDescriptionEnErr("");
      }
    }
    if (item === "descriptionHi" || item === "both") {
      if (!descriptionHi || descriptionHi.trim() === "") {
        setDescriptionHiErr("Please Enter Hindi Description");
        isValid = false;
      } else {
        setDescriptionHiErr("");
      }
    }

    return isValid;
  };

  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    const cursorPosition = e.target.selectionStart;

    if (name == "titleEn") {
      if ((await checkPattern(value)) == false) {
        e.preventDefault();
        return;
      } else {
        setTitleEn(value);
      }
    } else if (name == "titleHi") {
      if ((await checkPattern(value)) == false) {
        e.preventDefault();
        return;
      } else {
        setTitleHi(value);
      }
    }
    setTimeout(() => {
      e.target.setSelectionRange(cursorPosition, cursorPosition);
    }, 0);

    validateOnChangeHandle(name, value);
  };

  const validateOnChangeHandle = async (name, value) => {
    switch (name) {
      case "titleEn":
        if (!value?.trim()) {
          setTitleEnErr("Please Enter English Name");
        } else {
          setTitleEnErr("");
        }
        break;
      case "titleHi":
        if (!value?.trim()) {
          setTitleHiErr("Please Enter Hindi Name");
        } else {
          setTitleHiErr("");
        }
        break;
      case "descriptionEn":
        if (!value?.trim()) {
          setDescriptionEnErr("Please Enter English Description");
        } else {
          setDescriptionEn(value);
          setDescriptionEnErr("");
        }
        break;
      case "descriptionHi":
        if (!value?.trim()) {
          setDescriptionHiErr("Please Enter Hindi Description");
        } else {
          setDescriptionHi(value);
          setDescriptionHiErr("");
        }
        break;
    }
  };

  const validateOnBlurHandle = async (e) => {
    const { name, value } = e?.target;

    if (name == "titleEn") {
      if (!value.trim()) {
        setTitleEnErr("Please Enter English Name");
      } else {
        setTitleEnErr("");
      }
    } else if (name == "titleHi") {
      if (!value.trim()) {
        setTitleHiErr("Please Enter Hindi Name");
      } else {
        setTitleHiErr("");
      }
    } else if (name == "descriptionEn") {
      if (!value.trim()) {
        setDescriptionEnErr("Please Enter English Description");
      } else {
        setDescriptionEnErr("");
      }
    } else if (name == "descriptionHi") {
      if (!value.trim()) {
        setDescriptionHiErr("Please Enter Hindi Description");
      } else {
        setDescriptionHiErr("");
      }
    }
  };

  const getContactUsSectionData = async (type) => {
    try {
      const formData = new FormData();
      formData.append("type", type?.value);
      const contactUsSectionData = await getContactUsSectionList(formData);
      if (contactUsSectionData?.status == 200) {
        const data = contactUsSectionData?.data;
        setContactUsSectionList(data);
      } else {
        setContactUsSectionList([]);
      }
      setTableDataLoaded(true);
    } catch (err) {
      setTableDataLoaded(true);
    }
  };

  const handleEnableDisable = async (e, item) => {
    try {
      const formData = new FormData();
      formData.append("id", item?.id);
      formData.append("status", e?.value);
      const data = await handleEnableDisableContactUsSection(formData);
      if (data?.status == 200) {
        getContactUsSectionData(sectionType);
      } else {
        setApiMessage({ type: "error", message: data?.message });
      }
    } catch (error) {
      setApiMessage({ type: "error", message: error.message });
    }
  };

  const deleteSection = async (obj) => {
        try {
          const formData = new FormData();
          formData.append("id", obj.id);
          await deleteContactUsSection(formData);
          getContactUsSectionData(sectionType)
          setDeleteData("");
        } catch (error) {
          console.error(error);
        }
      };

  return (
    <AdminDashboard>
      <div className="aod-outer">
        {apiMessage && (
          <Message
            apiMessage={apiMessage}
            setApiMessage={setApiMessage}
          ></Message>
        )}
        <div className="ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb">
          <div>
            <b onClick={() => navigate("/admin/")}>Home /</b>
            <span> Pages/ Contact Us</span>
          </div>
        </div>
        <div className="aod-inner">
          <div className="aod-bottom">
            <div className="aod-head text-center adm-head">Contact Us</div>
            <form>
              <div className="row py-4">
                <div className="col-md-4 aod-resp-tab">
                  <div className="suf-input-box aod-form-input mb-2">
                    <label className="mb-1 w-100">Title (English)</label>
                    <input
                      className="w-100"
                      maxLength={100}
                      name="titleEn"
                      placeholder="Enter title in english"
                      type="text"
                      onChange={(e) => handleInputChange(e)} // Use onChange for real-time validation
                      onBlur={(e) => validateOnBlurHandle(e)}
                      value={titleEn}
                    />
                    {titleEnErr && (
                      <p className="validate-error">{titleEnErr}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-4 aod-resp-tab">
                  <div className="suf-input-box aod-form-input mb-2">
                    <label className="mb-1 w-100">Title (Hindi)</label>
                    <input
                      className="w-100"
                      maxLength={100}
                      name="titleHi"
                      placeholder="Enter title in hindi"
                      type="text"
                      onChange={(e) => handleInputChange(e)}
                      onBlur={(e) => validateOnBlurHandle(e)} // Use onChange for real-time validation
                      value={titleHi}
                    />
                    {titleHiErr && (
                      <p className="validate-error">{titleHiErr}</p>
                    )}
                  </div>
                </div>

                <div className="col-md-4 aod-resp-tab">
                  <div className="suf-input-box aod-form-input mb-2">
                    <label className="mb-1 w-100">Description (English)</label>
                    <input
                      className="w-100"
                      maxLength={100}
                      name="descriptionEn"
                      placeholder="Enter description in english"
                      type="text"
                      onChange={(e) => handleInputChange(e)} // Use onChange for real-time validation
                      onBlur={(e) => validateOnBlurHandle(e)}
                      value={descriptionEn}
                    />
                    {descriptionEnErr && (
                      <p className="validate-error">{descriptionEnErr}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-4 aod-resp-tab">
                  <div className="suf-input-box aod-form-input mb-2">
                    <label className="mb-1 w-100">Description (Hindi)</label>
                    <input
                      className="w-100"
                      maxLength={100}
                      name="descriptionHi"
                      placeholder="Enter description in hindi"
                      type="text"
                      onChange={(e) => handleInputChange(e)}
                      onBlur={(e) => validateOnBlurHandle(e)} // Use onChange for real-time validation
                      value={descriptionHi}
                    />
                    {descriptionHiErr && (
                      <p className="validate-error">{descriptionHiErr}</p>
                    )}
                  </div>
                </div>
                <div className="row ad-cat-row">
                  <div className=" update-btns-block mt-3">
                    <button onClick={updateContactUs}>Submit</button>
                  </div>
                </div>
              </div>
            </form>
            <div className="row py-4">
              <div className="col-md-4 aod-resp-tab">
                <div className="suf-input-box aod-form-input mb-2">
                  <Select
                    styles={customStylesAcc}
                    theme={selectTheme}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    className="menu_open_select w-100"
                    classNamePrefix="ad-menu_open_select_class"
                    aria-label="Default select example"
                    value={sectionType}
                    options={contactUsSectionTypes}
                    onChange={(e) => {
                      setSectionType(e);
                      getContactUsSectionData(e);
                    }}
                    placeholder="Select Section"
                  />
                </div>
              </div>
            </div>
            <div className="adm-tabs-outer text-start align-items-end">
              <h5 className="mb-0 w-100">{sectionType?.label}</h5>
              <div className="admin-add-btn d-flex justify-content-end">
                {grantPermissionForAddContactUsSection ? (
                  <button
                    className="adm-tab-btn d-flex"
                    onClick={() => navigate("/admin/contactus/add",
                      {
                        state: { type: sectionType?.value , data: null },
                      })}
                  >
                    <span>
                      <HiPlus />
                    </span>{" "}
                    Add
                  </button>
                ) : null}
              </div>
            </div>
            <div className="aod-head"></div>
            <div className="ad-reg-table">
              <div className="ad-reg-table">
                <Table size="sm" className="ad-cat-table  mt-4">
                  <thead>
                    <tr>
                      <th className="ad-sno">S. No</th>
                      <th className="ad-long">Content English</th>
                      <th className="ad-long">Content Hindi</th>
                      <th className="ad-long">Status</th>
                      <th className="ad-long">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {contactUsSectionList?.length > 0 ? (
                      contactUsSectionList?.map((item, index) => (
                        <tr
                          key={index}
                          className={index % 2 !== 0 ? "adm-even" : ""}
                        >
                          <td>{index + 1}</td>
                          <td className="content-td">{item?.content_en ? 
                            <span
                              dangerouslySetInnerHTML={{
                                __html: item?.content_en
                              }}
                            ></span> : "--"}</td>
                          <td className="content-td">{item?.content_hi ? <span
                              dangerouslySetInnerHTML={{
                                __html: item?.content_hi
                              }}
                            ></span> : "--"}</td>
                          {grantPermissionForEditContactUsSection ? (
                              <> 
                          <td>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={item?.status == 1}
                                onChange={(e) =>
                                  handleEnableDisable(
                                    e.target.checked
                                      ? { value: "1" }
                                      : { value: "0" },
                                    item
                                  )
                                }
                              />
                              <span className="slider"></span>
                            </label>
                          </td>
                          <td>
                            <div className="action-btn d-flex">
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id={`edit-tooltip-${item.id}`}>
                                    Edit
                                  </Tooltip>
                                }
                              >
                                <span
                                  className="ad-cat-edit"
                                  onClick={() => {
                                    navigate(
                                      "/admin/contactus/update",
                                      {
                                        state: { type: sectionType?.value, data: item },
                                      }
                                    );
                                  }}
                                >
                                  <EditIcon />
                                </span>
                              </OverlayTrigger>
                              <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip id={`delete-tooltip-${item.id}`}>
                                          Delete
                                        </Tooltip>
                                      }
                                    >
                                      <span
                                        className="ad-cat-delete"
                                        onClick={() => setDeleteData(item)}
                                      >
                                        <DeleteIcon />
                                      </span>
                              </OverlayTrigger>
                            </div>
                          </td>
                          </>
                            ) : (
                              ""
                            )}
                        </tr>
                      ))
                    ) : tableDataLoaded ? (
                      <NotFoundTable colSpan="8"></NotFoundTable>
                    ) : (
                      <></>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={deleteData?.id} onHide={() => setDeleteData("")}>
          <Modal.Header closeButton>
            <Modal.Title>Do you really want to delete this Section Record ?</Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <div className="suf-btn p-2 d-flex flex-row">
              <button
                className="mx-2"
                onClick={() => {
                  deleteSection(deleteData);
                }}
              >
                Yes
              </button>
              <button
                className="suf-can-butt no-btn mx-2"
                onClick={() => setDeleteData("")}
              >
                No
              </button>
            </div>
          </Modal.Footer>
        </Modal>
    </AdminDashboard>
  );
};

export default AdminContactUs;
