import React, { useEffect, useState } from 'react'
import HeaderBottom from '../../components/Header/HeaderBottom'
import DashboardHeader from '../../components/Dashboard/DashboardHeader/DashboardHeader'
import DasboardSidebar from '../../components/Dashboard/DashboardSidebar/DasboardSidebar'
import './Dashboard.css'
import { HomeIcon } from '../../components/Application/ApplicationIcon'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { GetLocalStorage, GetLocalStorageParsed, SetLocalStorage, SetLocalStorageStringify } from '../../Utils/Utils'
import { useLanguage } from '../../components/LanguageProvider/LanguageProvider '
import { getAllFooterContentlListData, getRefreshToken } from '../../services/api/user'
import { getAllFooterContentlListDataforUser } from '../../services/api/admin'
const Dashboard = ({ children, first, head }) => {

    const { language, setLanguage } = useLanguage();
    const location = useLocation();
    const pathname = location.pathname;
    const userDetail = GetLocalStorageParsed("userDetail");
    const token = GetLocalStorage("userInfo");
    const [copyRightContentEn, setCopyRightContentEn] = useState(0);
    const [copyRightContentHi, setCopyRightContentHi] = useState(0);

    const navigate = useNavigate();


    useEffect(() => {
        if (!token) {
            navigate("/")
        }
    }, [])


    useEffect(() => {
        const checkViewportSize = () => {
            if (window.innerWidth <= 1199) {
                setSideBarCollapse(true);
            }
            else if (window.innerWidth > 1199) {
                setSideBarCollapse(false);
            }
        };

        const footerContentData = async () => {
              try {
                const dataList = await getAllFooterContentlListData();
                if(dataList?.status === 200) {
                  const data = dataList?.data.find(
                    (x) => x.type === "3"
                  );
                  setCopyRightContentEn(data?.content_en)
                  setCopyRightContentHi(data?.content_hi)
                }
              } catch (err) {}
            };

        checkViewportSize();
        footerContentData();

        window.addEventListener('resize', checkViewportSize);

        return () => window.removeEventListener('resize', checkViewportSize);
    }, []);

    // Remove the leading slash
    const cleanedPathname = pathname.startsWith('/') ? pathname.substring(1) : pathname;

    const [sidebarCollapse, setSideBarCollapse] = useState(false);
    return (
        <>
            <DashboardHeader sidebarCollapse={sidebarCollapse} setSideBarCollapse={setSideBarCollapse} userDetail={userDetail} />
            <div id="user-dashboard" className={sidebarCollapse === true ? ' dash-main-div dash-main-coll' : " dash-main-div"}>
                <div className={sidebarCollapse === true ? 'ds-cont ds-cont-coll' : "ds-cont"}>
                    <DasboardSidebar sidebarCollapse={sidebarCollapse} setSideBarCollapse={setSideBarCollapse} />
                </div>
                <div className="col-md-12 position-relative dahboard-inner-wrapper">
                    <div className='ap-breadcrumb banner-breadcrumb'>
                        <h5><HomeIcon />{<Link to="/dashboard" className='bread_crumb_f'>{first} </Link>}/<span> {head}</span></h5>
                    </div>
                    <div className='mb-5'>
                        {children}
                    </div>
                    <div className='ds-footer'><div style={sidebarCollapse ? {'marginLeft': '0px'} : {'marginLeft': '300px'}} className="footer-text">{language ? copyRightContentHi : copyRightContentEn}</div></div>
                </div>
            </div>
        </>
    )
}
export default Dashboard