import React, { useEffect, useState } from "react";
import AdminDashboard from "../AdminDashboard/AdminDashboard";
import { Message } from "../../../components/message";
import { useNavigate } from "react-router-dom";
import { Modal, Table } from "react-bootstrap";
import { HomeIcon } from "../../../components/Application/ApplicationIcon";
import dataicon from "../../../../src/assets/images/data-icon.png";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { DeleteIcon, EditIcon, EyeIcon } from "../AdminIcon";
import {
  getallApplicationList,
  getallApplicationListSort,
  getApplicationListBystatus,
} from "../../../services/api/admin";
import ReactPaginate from "react-paginate";
import { formatDateWithSlash } from "../../../Utils/common";
import { stateDistrict } from "../../../services/api/auth";
import NotFoundTable from "../../../components/notFound/NotFoundTable";
import StatusApplication from "./StatusApplication";

const RejectedApplications = () => {
  return (
    <StatusApplication appStatus="5" title="Rejected" subTitle="Rejected" application_type="0" pageFrom="rejected-applications"></StatusApplication>
  )
  // const navigate = useNavigate();
  // const [applicationList, setApplicationList] = useState([]);
  // const [apiMessage, setApiMessage] = useState({ type: "", message: "" });
  // const [currentPage, setCurrentPage] = useState(1);
  // const [deleteData, setDeleteData] = useState();
  // const [totalPages, setTotalPages] = useState(0); // Total number of pages
  // const [searchTerm, setSearchTerm] = useState();
  // const [orderBy, setOrderBy] = useState("asc");
  // const [districtList, setDistrictList] = useState([]);
  // const applicationStatusList = [
  //   { value: "0", label: "Inactive." },
  //   { value: "1", label: "Active." },
  //   { value: "2", label: "Pending" },
  //   { value: "3", label: "Draft" },
  //   { value: "4", label: "Approved" },
  //   { value: "5", label: "Rejected" },
  //   { value: "6", label: "Expired" },
  //   { value: "7", label: "Under Review" },
  //   { value: "8", label: "submitted" },
  // ];

  // useEffect(() => {
  //   getApplicationTableData(currentPage, "5");
  //   getDistrictList();
  // }, []);

  // const getApplicationTableData = async (pageNo, status) => {
  //   try {
  //     const totalapplicationData = await getApplicationListBystatus(pageNo, status);
  //     if (totalapplicationData?.status == 200) {
  //       const rejectedApplicationList = totalapplicationData?.data?.data
  //       setApplicationList(rejectedApplicationList);
  //       setTotalPages(
  //         Math.ceil(
  //           totalapplicationData?.data?.total /
  //           totalapplicationData?.data?.per_page
  //         )
  //       ); // Calculate total pages
  //       setCurrentPage(pageNo > 0 ? pageNo - 1 : pageNo);
  //     }
  //   } catch (err) { }
  // };

  // const getDistrictList = async () => {
  //   try {
  //     const districtData = await stateDistrict();
  //     const data = districtData?.data?.data?.map((district) => ({
  //       label: district?.name_en,
  //       value: district?.id,
  //     }));
  //     setDistrictList(data);
  //   } catch (err) {
  //     setApiMessage({ type: "error", message: err?.message });
  //   }
  // };

  // const searchSortFromList = async (e, sortBy) => {
  //   try {
  //     const searchBy = e.target.value ? e.target.value : "";
  //     setOrderBy(orderBy == "asc" ? "desc" : "asc");
  //     const tabList = await getallApplicationListSort(
  //       sortBy,
  //       orderBy,
  //       searchBy,
  //       "5"
  //     );
  //     const data = tabList?.data?.data;
  //     setApplicationList(data);
  //   } catch (err) {
  //     console.log("error on categoryList: ", err);
  //     setApiMessage({ type: "error", message: err.message });
  //   }
  // };

  // const handlePageChange = (selectedPage) => {
  //   setCurrentPage(selectedPage.selected);
  //   getApplicationTableData(selectedPage.selected + 1, "5"); // Increment by 1 if the API starts page numbers from 1
  // };

  // return (
  //   <AdminDashboard>
  //     <div className="aod-outer">
  //       {apiMessage && (
  //         <Message
  //           apiMessage={apiMessage}
  //           setApiMessage={setApiMessage}
  //         ></Message>
  //       )}
  //       <div className="ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb">
  //         <div>
  //           <HomeIcon />
  //           <b onClick={() => navigate("/admin/")}>Home /</b>
  //           <span>Rejected Application</span>
  //         </div>
  //       </div>
  //       <div className="aod-inner pt-0">
  //         <div className="aod-bottom">
  //           <div className="adm-tabs-outer">
  //             <h4 className="mb-0 w-100">Total Application</h4>
  //             <div className="position-relative w-25">
  //               <input
  //                 className="px-2 form-control"
  //                 placeholder="Search"
  //                 type="text"
  //                 value={searchTerm} // Add a state for the input value
  //                 onChange={(e) => {
  //                   setSearchTerm(e.target.value); // Update the state
  //                   searchSortFromList(e, 'tracking_number')
  //                 }}
  //               />
  //               {searchTerm && ( // Show the clear button only when there's text
  //                 <button
  //                   className="btn btn-clear position-absolute"
  //                   onClick={(e) => {
  //                     setSearchTerm(""); // Clear the input value
  //                     searchSortFromList(e, 'tracking_number') // Fetch data with empty search term
  //                   }}
  //                   style={{
  //                     top: "50%",
  //                     right: "10px",
  //                     transform: "translateY(-50%)",
  //                     border: "none",
  //                     background: "transparent",
  //                     fontSize: "1rem",
  //                     cursor: "pointer",
  //                   }}
  //                 >
  //                   &times; {/* This is the "X" icon */}
  //                 </button>
  //               )}
  //             </div>
  //           </div>
  //           <div className="aod-head"></div>
  //           <div className="ad-reg-table">
  //             <Table size="sm" className="ad-cat-table mt-4">
  //               <thead>
  //                 <tr>
  //                   <th className="ad-sno">S. No</th>
  //                   <th
  //                     className="ad-long"
  //                     onClick={(e) =>
  //                       searchSortFromList(e, "sub_category_name")
  //                     }
  //                   >
  //                     Sub category{" "}
  //                     <span>
  //                       <i className="fa-solid fa-sort"></i>
  //                     </span>
  //                   </th>
  //                   <th
  //                     className="ad-long"
  //                     onClick={(e) => searchSortFromList(e, "district_id")}
  //                   >
  //                     District{" "}
  //                     <span>
  //                       <i className="fa-solid fa-sort"></i>
  //                     </span>{" "}
  //                   </th>
  //                   <th
  //                     className="ad-long"
  //                     onClick={(e) => searchSortFromList(e, "tracking_number")}
  //                   >
  //                     Tracking Number{" "}
  //                     <span>
  //                       <i className="fa-solid fa-sort"></i>
  //                     </span>
  //                   </th>
  //                   <th
  //                     className="ad-long"
  //                     onClick={(e) => searchSortFromList(e, "customer_id")}
  //                   >
  //                     Customer Id{" "}
  //                     <span>
  //                       <i className="fa-solid fa-sort"></i>
  //                     </span>
  //                   </th>
  //                   <th
  //                     className="ad-long"
  //                     onClick={(e) => searchSortFromList(e, "created_at")}
  //                   >
  //                     Created At{" "}
  //                     <span>
  //                       <i className="fa-solid fa-sort"></i>
  //                     </span>
  //                   </th>
  //                   <th className="ad-long">Status</th>
  //                   <th className="ad-long">Actions</th>
  //                 </tr>
  //               </thead>
  //               <tbody>
  //                 {applicationList?.length > 0 ? (
  //                   applicationList?.map((item, index) => (
  //                     <tr
  //                       key={index}
  //                       className={index % 2 != 0 ? "adm-even" : ""}
  //                     >
  //                       <td>{currentPage * 10 + (index + 1)}</td>
  //                       <td>
  //                         {item?.sub_category_name
  //                           ? item?.sub_category_name
  //                           : "--"}
  //                       </td>
  //                       <td>
  //                         {item?.district_id
  //                           ? districtList?.find(
  //                             (option) => option.value == item?.district_id
  //                           )?.label
  //                           : "--"}
  //                       </td>
  //                       <td>
  //                         {item?.tracking_number ? item?.tracking_number : "--"}
  //                       </td>
  //                       <td>{item?.customer_id ? item?.customer_id : "--"}</td>
  //                       <td>
  //                         {item?.created_at
  //                           ? formatDateWithSlash(item?.created_at)
  //                           : "--"}
  //                       </td>
  //                       <td>
  //                         {item?.status
  //                           ? applicationStatusList?.find(
  //                             (option) => option.value == item?.status
  //                           )?.label
  //                           : "--"}
  //                       </td>

  //                       <td>
  //                         <div
  //                           className="action-btn d-flex">
  //                           <span onClick={() =>
  //                             navigate("/admin/application-view", {
  //                               state: {
  //                                 data: item,
  //                                 pageFrom: "rejectedApplication",
  //                               },
  //                             })
  //                           }>
  //                           <OverlayTrigger
  //                             placement="top"
  //                             overlay={<Tooltip>View</Tooltip>}
  //                           >
  //                             <span className="ad-cat-edit">
  //                               <EyeIcon />
  //                             </span>
  //                           </OverlayTrigger>
  //                           </span>
  //                           <OverlayTrigger
  //                             placement="top"
  //                             overlay={<Tooltip>Delete</Tooltip>}
  //                           >
  //                             <span className="ad-cat-delete">
  //                               <DeleteIcon />
  //                             </span>
  //                           </OverlayTrigger>
  //                         </div>
  //                       </td>
  //                     </tr>
  //                   ))
  //                 ) : (
  //                   <NotFoundTable colSpan="7" />
  //                 )}
  //               </tbody>
  //             </Table>
  //             {totalPages > 1 && applicationList?.length > 0 && (
  //               <div className="d-flex w-100 justify-content-end">
  //                 <ReactPaginate
  //                   previousLabel={"Previous"}
  //                   nextLabel={"Next"}
  //                   breakLabel={"..."}
  //                   pageCount={totalPages} // Total number of pages from API
  //                   marginPagesDisplayed={1}
  //                   pageRangeDisplayed={1}
  //                   onPageChange={handlePageChange} // Method to handle page click
  //                   containerClassName={"pagination justify-content-center"} // Custom CSS classes
  //                   pageClassName={"page-item"}
  //                   pageLinkClassName={"page-link"}
  //                   previousClassName={"page-item"}
  //                   previousLinkClassName={"page-link"}
  //                   nextClassName={"page-item"}
  //                   nextLinkClassName={"page-link"}
  //                   breakClassName={"page-item"}
  //                   breakLinkClassName={"page-link"}
  //                   activeClassName={"active"}
  //                 />
  //               </div>
  //             )}
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </AdminDashboard>
  // );
};

export default RejectedApplications;
