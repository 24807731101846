import { DeleteIcon, EditIcon } from "../AdminIcon";
import React, { useEffect, useState } from 'react'
import AdminDashboard from '../AdminDashboard/AdminDashboard'
import { getUserOTPLogs, otpLogListCsv, otpLogListExcel, otpLogListPdf } from '../../../services/api/admin';
import { HomeIcon } from '../../../components/Application/ApplicationIcon';
import ReactPaginate from 'react-paginate';
import NotFoundTable from '../../../components/notFound/NotFoundTable';
import { formatDateWithSlash, printData } from '../../../Utils/common';
import { useNavigate } from 'react-router-dom';
import { Modal, Table } from 'react-bootstrap';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Message } from "../../../components/message";

const OtpLogs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate()
  const [otpLogList, setOtpLogList] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0); // Total number of pages
  const [orderBy, setOrderBy] = useState("desc");
  const [apiMessage, setApiMessage] = useState({ type: "", message: "" });
  const [tableDataLoaded, setTableDataLoaded] = useState(false);
  const [searchTerm, setSearchTerm] = useState();
  const [sortBy, setSortBy] = useState("created_at");
  const [searchData, setSearchData] = useState("");

  useEffect(() => {
    userOtpLogList("created_at", "1", "desc", "");
  }, []);


const userOtpLogList = async (sortBy, pageNo, orderBy, searchBy) => {
    try {
        setSearchData(searchBy);
        setOrderBy(orderBy);
        setSortBy(sortBy);
        const otpListData = await getUserOTPLogs(sortBy, orderBy, searchBy, pageNo)
        if (otpListData.status == 200) {
            setCurrentPage(pageNo > 0 ? pageNo - 1 : pageNo);
            setOtpLogList(otpListData?.data?.data)
            setTotalPages(otpListData?.data?.last_page || 0); // Ensure the correct key for total pages
            setTableDataLoaded(true);
        }
    } catch (err) {
        setTableDataLoaded(true);
        setApiMessage({ type: 'error', message: err?.message })
    }
}


const handlePageChange = (selectedPage) => {
  userOtpLogList(sortBy, selectedPage.selected + 1, orderBy, searchData);
};

const pdfDownload = async () => {
          try {
            const response = await otpLogListPdf()
            window.open(response?.file_path);
          } catch (err) {
            setApiMessage({ type: "error", message: err?.message })
          }
        }
      
        const excelDownload = async () => {
          try {
            const response = await otpLogListExcel()
            window.open(response?.file_path);
          } catch (err) {
            setApiMessage({ type: "error", message: err?.message })
          }
        }
      
        const csvDownload = async () => {
          try {
            const response = await otpLogListCsv()
            window.open(response?.file_path);
          } catch (err) {
            setApiMessage({ type: "error", message: err?.message })
          }
        }
      
        const exportPrint = async () => {
          try {
            // Fetch the PDF URL from the API
            const response = await otpLogListPdf();
            const pdfUrl = response?.file_path;
        
            if (!pdfUrl) {
              setApiMessage({ type: "error", message: "Error getting printing data"})
              return
            }
        
            // Fetch the PDF file as a Blob
            const res = await fetch(pdfUrl);
            if (!res.ok) {
              setApiMessage({ type: "error", message: "Error getting printing data"})
              return
            }
            const pdfBlob = await res.blob();
        
            printData(pdfBlob)
            
          } catch (err) {
            setApiMessage({ type: "error", message: err?.message });
          }
        }

  return (
    <AdminDashboard>
    <div className="aod-outer">
      {apiMessage && (
        <Message
          apiMessage={apiMessage}
          setApiMessage={setApiMessage}
        ></Message>
      )}
      <div className="ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb">
        <div>
          <HomeIcon />
          <span>OTP-Logs</span>
        </div>
        <div class="dropdown download_file_dropdown ms-auto d-flex gap-2 mb-3 flex-wrap align-items-center">
                <a class=" px-2 btn btn-danger btn-sm" onClick={() => pdfDownload()} > <i class="fa fa-file-pdf me-1" ></i> PDF</a>
                <a class=" px-2 btn btn-success btn-sm"  onClick={() => excelDownload()} ><i class="fa fa-file-excel  me-1"></i>
                                Excel </a>
                            {/* <a class=" px-2 btn btn-primary btn-sm" onClick={() => csvDownload()} ><i class="fa fa-file-csv  me-1"></i>
                                CSV</a> */}
                    <a class=" px-2 btn btn-info  btn-sm" onClick={() => exportPrint()}><i class="fa fa-print me-1"></i>
                        Print</a>
              </div> 
      </div>
      <div className="aod-inner pt-0">
        <div className="aod-bottom">
          <div className="adm-tabs-outer">
            <h4 className="mb-0 w-100">OTP Logs</h4>
            <div className="position-relative w-25">
                  <input
                    className="px-2 form-control"
                    placeholder="Search"
                    type="text"
                    value={searchTerm} // Add a state for the input value
                    onChange={(e) => {
                      setSearchTerm(e.target.value); // Update the state
                      userOtpLogList(sortBy, 1, orderBy, e.target.value);
                    }}
                  />
                  {searchTerm && ( // Show the clear button only when there's text
                    <button
                      className="btn btn-clear position-absolute"
                      onClick={(e) => {
                        setSearchTerm(""); // Clear the input value
                        userOtpLogList(sortBy, 1, orderBy, ""); // Fetch data with empty search term
                      }}
                      style={{
                        top: "50%",
                        right: "10px",
                        transform: "translateY(-50%)",
                        border: "none",
                        background: "transparent",
                        fontSize: "1rem",
                        cursor: "pointer",
                      }}
                    >
                      &times; {/* This is the "X" icon */}
                    </button>
                  )}
                </div>
          </div>
          <div className="aod-head"></div>
          <div className="ad-reg-table">
            <Table size="sm" className="ad-cat-table mt-4">
              <thead>
                <tr>
                  <th className="ad-sno">S. No</th>
                  <th className="ad-long cursor-pointer"
                    onClick={(e) =>
                      userOtpLogList(
                        "email",
                        currentPage + 1,
                        orderBy === "asc" ? "desc" : "asc",
                        searchData
                      )
                    }>{" "}Email{" "}
                    <span>
                      <i className="fa-solid fa-sort"></i>
                    </span>
                  </th>
                  <th className="ad-long cursor-pointer"
                    onClick={(e) =>
                      userOtpLogList(
                        "mobile_number",
                        currentPage + 1,
                        orderBy === "asc" ? "desc" : "asc",
                        searchData
                      )
                    }>{" "}Mobile Number{" "}
                    <span>
                      <i className="fa-solid fa-sort"></i>
                    </span>
                  </th>
                  <th className="ad-long">OTP</th>
                  <th className="ad-long cursor-pointer"
                    onClick={(e) =>
                      userOtpLogList(
                        "created_at",
                        currentPage + 1,
                        orderBy === "asc" ? "desc" : "asc",
                        searchData
                      )
                    }>{" "}Created At{" "}
                    <span>
                      <i className="fa-solid fa-sort"></i>
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {otpLogList?.length > 0 ? (
                  otpLogList?.map((item, index) => (
                    <tr
                      key={index}
                      className={index % 2 !== 0 ? "adm-even" : ""}
                    >
                      <td>{currentPage * 10 + (index + 1)}</td>
                      <td>{item.email ? item.email : "--"}</td>
                      <td>{item.mobile_number ? item.mobile_number : "--"}</td>
                      <td>{item.otp}</td>                      
                      <td>{item.created_at ? formatDateWithSlash(item.created_at) : '-'}</td>
                    </tr>
                  ))
                ) : tableDataLoaded ? (
                  <NotFoundTable colSpan="8"></NotFoundTable>
                ) : (
                  <></>
                )}
              </tbody>
            </Table>
            {totalPages > 1 && otpLogList?.length > 0 && (
              <div className="d-flex w-100 justify-content-end">
              <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        pageCount={totalPages} // Total number of pages from API
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={1}
                        onPageChange={handlePageChange} // Method to handle page click
                        containerClassName={"pagination justify-content-center"} // Custom CSS classes
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
            )}
          </div>
        </div>
      </div>
    </div>
  </AdminDashboard>
  );
};

export default OtpLogs;
