import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { setNavigate } from '../../services/navigationService';

const NavigationProvider = ({ children }) => {
    const navigate = useNavigate();

    useEffect(() => {
        setNavigate(navigate);
    }, [navigate]);

    return children;
};

export default NavigationProvider;
