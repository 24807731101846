/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react'
import { BsChevronLeft } from 'react-icons/bs'
import { useLocation, useNavigate } from 'react-router-dom'
import Select from 'react-select'
import { HomeIcon } from '../../../components/Application/ApplicationIcon'
import { Message } from '../../../components/message'
import { addCategoryFees, getCategoriesDropdown, getSubCategoriesDropdown, updateCategoryFees } from '../../../services/api/admin'
import { customStylesAcc, selectTheme } from '../../../Utils/common'
import { GetLocalStorage, GetLocalStorageParsed } from '../../../Utils/Utils'
import "../Admin.css"
import AdminDashboard from '../AdminDashboard/AdminDashboard'

const CategoryFeesAdd = () => {

  const token = GetLocalStorage("userInfo")
  const navigate = useNavigate()
  const location = useLocation()
  const [subCategoryId, setSubCategoryId] = useState();
  const [name, setName] = useState('');
  const [fees, setFees] = useState('');
  const [timeline, setTimeline] = useState('');
  const [feesId, setFeesId] = useState('');
  const [tenure, setTenure] = useState('');
  const [feesError, setFeesError] = useState();
  const [timelineError, setTimelineError] = useState();
  const [tenureError, setTenureError] = useState();
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [subCategoryIdError, setSubCategoryIdError] = useState();
  const [apiMessage, setApiMessage] = useState({ type: '', message: '' });
  const [categoryId, setCategoryId] = useState()
  const [file, setFile] = useState();
  const userDetail = GetLocalStorageParsed("userDetail");
  const [categoryList, setCategoryList] = useState([])
  const [editData, setEditData] = useState()
  const [fontsList, setFontsList] = useState()
  useEffect(() => {
    if (location?.state && location?.pathname == "/admin/category/fees/update") {
      editSubCategory(location?.state)
      console.log(location.state)
    }
  }, [location?.state, categoryList])
  const handleKeyUpLoginDetails = (item) => {
    validateForm(item);
  };

  useEffect(() => {
    const getSubCategoryList = async () => {
      try {
        const subCategoryData = await getSubCategoriesDropdown();
        if (subCategoryData && subCategoryData.status === 200) {
          const data = subCategoryData?.data?.filter(i => i?.status === "1")?.map(i => ({
            label: i?.category_name,
            value: i?.id
          }));
          setSubCategoryList(data);
        }
      } catch (error) {
        console.log("error on sub category list: ", error);
        setApiMessage({ type: 'error', message: error?.message });
      }
    };
    getSubCategoryList();
  }, [subCategoryList]);


  //validations
  const validateForm = (item) => {
    let isValid = true;
    const specialCharPattern = /[!@#$%^&*(),.?":{}|<>]/;  // pattern to detect special characters
    const numberPattern = /^\d+$/;  // pattern to allow only numbers

    if (item === "fees" || item === "both") {
        if (!fees || fees === "") {
            setFeesError("Please Enter Fees");
            isValid = false;
        } else if (!numberPattern.test(fees)) {
            setFeesError("Fees must be a number");
            isValid = false;
        } else {
            setFeesError("");
        }
    }

    if (item === "tenure" || item === "both") {
        if (!tenure || tenure.trim() === "") {
            setTenureError("Please Enter Certificate Tenure");
            isValid = false;
        } else if (specialCharPattern.test(tenure)) {
            setTenureError("Special characters are not allowed in Certificate Tenure");
            isValid = false;
        } else {
            setTenureError("");
        }
    }
    if (item === "timeline" || item === "both") {
        if (!timeline || timeline.trim() === "") {
            setTimelineError("Please Enter Timeline");
            isValid = false;
        } else if (specialCharPattern.test(timeline)) {
            setTimelineError("Special characters are not allowed in Timeline");
            isValid = false;
        } else {
            setTimelineError("");
        }
    }
    if (item === 'subCategory' || item === "both" && !subCategoryId) {
      setSubCategoryIdError("Please Select Sub Category");
      isValid = false;
    } else if (specialCharPattern.test(subCategoryId)) {
      setSubCategoryIdError("Special characters are not allowed in Sub Category");
      isValid = false;
    } else {
      setSubCategoryIdError("");
    }

    return isValid;
};

  // add sub-Category
  const addSubCategory = async () => {
    if (!validateForm("both")) return
    try {
      const inputName = name.length > 20 ? name.substring(0, 20) : name
      const specialCharacterRemovedStr = inputName.toLowerCase()?.replaceAll(/[^a-zA-Z0-9 ]/g, "")
      const keyName = specialCharacterRemovedStr.replaceAll(" ", "_")

      const formData = new FormData();
      formData.append("category_id", subCategoryId)
      formData.append("fees", fees)
      formData.append("timeline", timeline)
      formData.append("certificate_tenure", tenure)
      formData.append("created_by",userDetail.id )
      
      const data = await addCategoryFees(formData)
      if (data?.status == 200) {
        navigate("/admin/category/fees", { state: { message: data?.message } })
      } else {
        setApiMessage({ type: 'error', message: data?.message });
      }
    } catch (error) {
      console.error(error);
      setApiMessage({ type: 'error', message: error?.message });
    }
  }



  //category list
  const getCategoryList = async () => {
    try {
      const categoryList = await getCategoriesDropdown()
      const data = categoryList?.data?.filter(i => i?.status === "1")?.map(item => ({
        value: item?.id,
        label: item?.category_name
      }))
      setCategoryList(data)
    } catch (err) {
      console.log("error on sub Category list: ", err)
    }
  };

  //get font families
  useEffect(() => {
    getCategoryList()
  }, [])

  //edit sub-category
  const editSubCategory = (item) => {
    setEditData(item)
    setSubCategoryId(item?.category_id);
    setTenure(item?.certificate_tenure)
    setFees(item?.fees)
    setTimeline(item?.timeline)
    setFeesId(item?.id)
    setCategoryId(subCategoryList?.find(i => i.value == item?.parent_category_id))
    window.scrollTo(0, 0);
  }

  const handlesubcategoryChange = async (selectedOption) => {
      setSubCategoryId(selectedOption.value);
      setSubCategoryIdError("");
  }

  const alphaNumericPattern = /^[a-zA-Z0-9\s]*$/;

  const handleEditorChange = (field, value) => {
    const inputValue = value.currentTarget.value;
    if (field === 'fees') {
      const numericOnlyValue = inputValue.replace(/[^0-9]/g, '');
      setFees(numericOnlyValue.substring(0, 20)); // restrict to numeric only and max length 20
    } else if (field === 'tenure') {
      setTenure(inputValue.substring(0, 20)); // max length 20
    } else if (field === 'timeline') {
      setTimeline(inputValue.substring(0, 20)); // max length 20
    }
    if (field === 'fees' && feesError) setFeesError('');
    if (field === 'tenure' && tenureError) setTenureError('');
    if (field === 'timeline' && timelineError) setTimelineError('');
  };
  
  //update sub-category
  const updateSubCategory = async () => {
    if (!validateForm("both")) return
    try {
      const formData = new FormData();
      formData.append("category_id", subCategoryId)
      formData.append("fees", fees)
      formData.append("timeline", timeline)
      formData.append("certificate_tenure", tenure)
      formData.append("fees_id",feesId )

      const data = await updateCategoryFees(formData)
      if (data?.status == 200) {
        navigate("/admin/category/fees", { state: { message: data.message } })
      } else {
        setApiMessage({ type: 'error', message: data.message });
      }
    } catch (error) {
      setApiMessage({ type: 'error', message: error.message });
    }
  }


  return (
    <AdminDashboard>
      <div className='aod-outer'>
        {apiMessage && (<Message apiMessage={apiMessage} setApiMessage={setApiMessage}></Message>)}
        <div className='ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb'>
          <div>
            <HomeIcon /><b onClick={() => navigate("/admin/")}>Home  /</b>  <span> Fees Form</span>
          </div>
        </div>
        <div className="aod-inner">
          <div className={editData?.id ? "aod-bottom" : "aod-bottom"}>
            <div className="aod-head text-center adm-head"><button onClick={() => navigate("/admin/category/fees")}><BsChevronLeft /> Back</button>{editData?.id ? "Update" : "Add"} </div>
            <div className="row py-4">
              <div className="col-md-4 aod-resp-tab">
                <div className='suf-input-box aod-form-input mb-2'>
                  <label className='lf-label w-100'>
                    Select Sub Category
                  </label>
                    <Select
                        styles={customStylesAcc}
                        theme={selectTheme}
                        components={{
                            IndicatorSeparator: () => null
                        }}
                        className="menu_open_select"
                        classNamePrefix="ad-menu_open_select_class"
                        aria-label="Default select example"
                        value={subCategoryList?.find(option => option.value === subCategoryId) || null}
                        options={subCategoryList}
                        onChange={(selectedOption) => {
                            handlesubcategoryChange(selectedOption)
                        }}
                        onKeyUp={(value) => handleKeyUpLoginDetails('subCategory', value)} 
                        placeholder="Select Sub Category"
                        isDisabled={!!editData} // Disable dropdown if editData exists
                    />
                  {subCategoryIdError && <p className='validate-error'>{subCategoryIdError}</p>}
                </div> 
              </div>
              <div className="col-md-4 aod-resp-tab">
                <div className='suf-input-box aod-form-input mb-2'>
                  <label className='mb-1 w-100'>
                    Fees
                  </label>
                  <input className='w-100' placeholder='Please Enter Fees' type="text" name="nameEn" value={fees} maxLength={100} onKeyUp={(value) => handleKeyUpLoginDetails('fees', value)}  onChange={(value) => handleEditorChange('fees', value)} />
                  {feesError && <p className='validate-error'>{feesError}</p>}
                </div>
              </div>
              <div className="col-md-4 aod-resp-tab">
                <div className='suf-input-box aod-form-input mb-2'>
                  <label className='mb-1 w-100'>
                    Timeline(In Days)
                  </label>
                  <input className='w-100' placeholder='Please Enter Timeline' type="text" name="nameEn" value={timeline} maxLength={100}  onKeyUp={(value) => handleKeyUpLoginDetails('timeline', value)} onChange={(value) => handleEditorChange('timeline', value)} />
                  {timelineError && <p className='validate-error'>{timelineError}</p>}
                </div>
              </div>
              <div className="col-md-4 aod-resp-tab">
                <div className='suf-input-box aod-form-input mb-2'>
                  <label className='mb-1 w-100'>
                    Certificate Tenure(In Years)
                  </label>
                  <input className='w-100' placeholder='Please Enter Certificate Tenure' type="text" name="nameEn" value={tenure} maxLength={100}  onKeyUp={(value) => handleKeyUpLoginDetails('tenure', value)}  onChange={(value) => handleEditorChange('tenure', value)} />
                  {tenureError && <p className='validate-error'>{tenureError}</p>}
                </div>
              </div>
            </div>
            <div className="row ad-cat-row">
              <div className=" update-btns-block">
                {
                  editData?.id ?
                    <>
                      <button onClick={() => updateSubCategory()}>
                        Update
                      </button> </> :
                    <button onClick={addSubCategory}>
                      Submit
                    </button>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminDashboard>
  )
}

export default CategoryFeesAdd
