import React, { useEffect, useState } from "react";
import "./User.css";
import { useLanguage } from '../LanguageProvider/LanguageProvider ';
import Dashboard from "../../pages/Dashboard/Dashboard";
import { city, district, states } from '../../services/api/auth';
import { useNavigate } from 'react-router-dom';
import { getUserById, updateUserProfileAPI } from '../../services/api/user';
import { Message } from '../../components/message'
import { GetLocalStorageParsed } from "../../Utils/Utils";
import { Spinner } from "react-bootstrap";

const EditProfile = () => {
  const { language, setLanguage } = useLanguage();
  const navigate = useNavigate()
  // State variables
  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [districtName, setDistrictName] = useState("");
  const [cityName, setCityName] = useState("");
  const [pincode, setPincode] = useState("");
  const [errors, setErrors] = useState({});
  const [statesData, setStatesData] = useState([]);
  const [districtsData, setDistrictsData] = useState([]);
  const [citiesData, setCitiesData] = useState([]);
  const [userId, setUserId] = useState("");
  const [userDetail, setUserDetail] = useState("");
  const [apiMessage, setApiMessage] = useState({ type: '', message: '' });
  const [stateLoaded, setStateLoaded] = useState(false);
  const [districtLoaded, setDistrictLoaded] = useState(false);
  const [cityLoaded, setCityLoaded] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    editUser()
  }, []);

  const editUser = async () => {
    var userData = GetLocalStorageParsed("userDetail") || {};
    const formData = new FormData();
    formData.append("user_id", userData.id);
    const user = await getUserById(formData)
    const userDetail = user?.data
    
    setUserDetail(userDetail);
    setName(userDetail.name || "");
    setGender(userDetail.gender || "");
    setPhoneNumber(userDetail.mobile_number || "");
    setEmail(userDetail.email || "");
    setAddress(userDetail.address || "");
    setState(userDetail.state || "");
    setStateLoaded(true)
    setDistrictName(userDetail.district || ""); // Ensure this is set correctly
    setCityName(userDetail.city || ""); // Ensure this is set correctly
    setPincode(userDetail.pincode || "");
    setUserId(userDetail.id || "");

    // Load states data
    const loadStates = async () => {
      try {
        const response = await states(); // Fetch states
        const data = response.data.data.map((state) => ({
          label: { hi : state?.name_hi , en: state.name_en },
          value: state.id
        }));
        setStatesData(data);
      } catch (error) {
        console.error("Error fetching states:", error);
      }
    };

    loadStates();
  }

  useEffect(() => {
    // Fetch districts based on selected state
    const loadDistricts = async () => {
      if (state) {
        try {
          const response = await district(state); // Fetch districts for selected state
          const filteredDistricts = response.data.data.filter(district => district.state_id === parseInt(state));
          const data = filteredDistricts.map((district) => ({
            label: { hi : district?.name_hi , en: district.name_en },
            value: district.id
          }));
          setDistrictsData(data);
          setDistrictName(!districtLoaded ? userDetail.district || "" : ""); // Set the district name from user detail
          setDistrictLoaded(true)
        } catch (error) {
          console.error("Error fetching districts:", error);
        }
      } else {
        setDistrictsData([]);
        setDistrictName(""); // Clear district and city when state is cleared
        setCitiesData([]);
      }
    };

    loadDistricts();
  }, [state]); // Listen for changes to state

  useEffect(() => {
    // Fetch cities based on selected district
    const loadCities = async () => {
      if (districtName) {
        try {
          const response = await city(); // Fetch all cities
          const filteredCities = response.data.data.filter(city => city.district_id === parseInt(districtName));
          const data = filteredCities.map((city) => ({
            label: { hi : city?.name_hi , en: city.name_en },
            value: city.id
          }));
          setCitiesData(data);
          setCityName(!cityLoaded ? userDetail.city || "" : "");
          setCityLoaded(true) // Set the city name from user detail
        } catch (error) {
          console.error("Error fetching cities:", error);
        }
      } else {
        setCitiesData([]);
        setCityName("");
      }
    };

    loadCities();
  }, [districtName]); // Listen for changes to districtName

  // Validation function
  const validateFields = () => {
    let formErrors = {};
   if (!name?.trim()) {formErrors.name = {hi : "नाम आवश्यक है" , en : "Name is required"}} else if(!/^[a-zA-Z ]*$/.test(name?.trim())) {formErrors.name = {hi : "केवल अक्षर की अनुमति है", en : "Only Alphabets are allowed"}} else if(name?.trim()?.length < 3) {formErrors.name = {hi : "नाम कम से कम 3 अक्षर का होना चाहिए" , en : "Name must be atleast 3 characters"}};
if (!gender) formErrors.gender = {hi : "लिंग आवश्यक है" , en : "Gender is required"};
if (!phoneNumber) formErrors.phoneNumber = { en : "फोन नंबर आवश्यक है" , en : "Phone number is required"};
if (!email) formErrors.email = { hi : "ईमेल आवश्यक है" , en : "Email is required"}; 
if (!address) {formErrors.address = {hi : "पता आवश्यक है" , en : "Address is required"}} else if (address?.length < 5) {formErrors.address = {hi : "पता कम से कम 5 अक्षर का होना चाहिए" , en : "Address must be atleast 5 characters"}};;
if (!state) formErrors.state = { hi:  "राज्य आवश्यक है", en : "State is required"};
if (!districtName) formErrors.district ={ hi : "जिला आवश्यक है" , en : "District is required"};
if (!cityName) formErrors.city = {hi : "शहर आवश्यक है", en : "City is required"};
if (!pincode || pincode.toString().trim()?.length < 6 || !/^[0-9\b]+$/.test(pincode)) formErrors.pincode = { hi : "मान्य 6 अंकों का पिनकोड आवश्यक है" , en : "Valid 6-digit pincode is required"};
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  // Submit handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateFields()) {
      const payload = {
        name,
        gender,
        phoneNumber,
        email,
        address,
        state,
        city: cityName, // Ensure cityName is used correctly
        district: districtName,
        pincode,
        user_id: userId
      };

      try {
        setSubmitting(true)
        const response = await updateUserProfileAPI(payload);
        if (response.status === 200) {
          sessionStorage.setItem("userDetail", JSON.stringify({ ...JSON.parse(sessionStorage.getItem("userDetail")), ...payload }));
        } else {
          setApiMessage({ type: 'error', message: "Unauthenticated" })
        }
        setSubmitting(false)
        setApiMessage({ type: 'success', message: response.message })
      } catch (error) {
        setSubmitting(false)
        setApiMessage({ type: 'error', message: error.message })
      }
    }
  };

  // Clear handler
  const handleClear = () => {
    setName("");
    setGender("");
    setPhoneNumber("");
    setEmail("");
    setAddress("");
    setState("");
    setDistrictName("");
    setCityName("");
    setPincode("");
    setErrors({});
  };

  const handlechanges = async (e) => {
    const { name, value } = e?.target
    let formErrors = {...errors};
    switch (name) {
      case 'UserName':
        if (!value?.trim()) {
          formErrors.name = { hi : "नाम आवश्यक है" , en : "Name is required"};
        } else {
          if(!/^[a-zA-Z ]*$/.test(value?.trim())) {
            formErrors.name = { hi : "केवल अक्षर की अनुमति है" , en : "Only Alphabets are allowed"}
          }
          else if(value?.trim()?.length < 3){
            formErrors.name = { hi : "नाम कम से कम 3 अक्षर का होना चाहिए" , en : "Name must be atleast 3 characters"};
          } else{
            formErrors.name = ''
          }
        }
        break;
      case 'gender':
        if (!value?.trim()) {
          formErrors.gender = { hi : "लिंग आवश्यक है" , en : "Gender is required"};
        } else {
          formErrors.gender = ''
        }
        break;
      case 'address':
        if (!value?.trim()) {
          formErrors.address = { hi : "पता आवश्यक है" , en : "Address is required"};
        } else {
          if(value?.trim()?.length < 5){
            formErrors.address = { hi :  "पता कम से कम 5 अक्षर का होना चाहिए" , en : "Address must be atleast 5 characters"};
          } else{
            formErrors.address = ''
          }
        }
        break;
      case 'pincode':
        if (!value || value.toString().length < 6 || !/^[0-9\b]+$/.test(value)) {
          formErrors.pincode = { hi :  "मान्य 6 अंकों का पिनकोड आवश्यक है" , en : "Valid 6-digit pincode is required"};
        } else {
          formErrors.pincode = ''
        }
        break;
      case 'state':
        if (!value?.trim()) {
          formErrors.state = { hi :  "राज्य आवश्यक है" , en : "State is required"};
        } else {
          formErrors.state = ''
        }
        break;
      case 'district':
        if (!value?.trim()) {
          formErrors.district = { hi :  "जिला आवश्यक है" , en : "District is required"};
        } else {
          formErrors.district = ''
        }
        break;
      case 'city':
        if (!value?.trim()) {
          formErrors.city = { hi :  "शहर आवश्यक है" , en : "City is required"};
        } else {
          formErrors.city = ''
        }
        break;
    }
    setErrors(formErrors);
  }

  return (
    <div>
      <Dashboard
        first={language ? "होम" : "Home"}
        second={language ? "प्रोफ़ाइल संपादित करें" : "Edit Profile"}
        head={language ? "प्रोफ़ाइल संपादित करें" : "Edit Profile"}
      >
        <div className="aod-bottom">
          {apiMessage && (<Message apiMessage={apiMessage} setApiMessage={setApiMessage}></Message>)}
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-4">
                <div className="mb-3 f_fields">
                  <label className="form-label">
                    <span className="en-label">{language ? 'नाम ' : 'Name'}</span>
                  </label>
                  <input
                    name="UserName"
                    type="text"
                    className="form-control"
                    placeholder="Enter Name"
                    value={name}
                    maxLength={50}
                    onChange={(e) => {
                      setName(e.target.value)
                      handlechanges(e)
                    } }
                  />
                  {errors.name && <span className="text-danger">{language ? errors?.name?.hi : errors?.name?.en}</span>}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3 f_fields">
                  <label className="form-label">
                    <span className="en-label">{language ? 'लिंग  ' : 'Gender'}</span>
                  </label>
                  <select
                  name="gender"
                    className="form-control"
                    value={gender}
                    onChange={(e) => { setGender(e.target.value); handlechanges(e) }}
                  >
                    <option value="">{language ? 'लिंग चुनें' : 'Select Gender'}</option>
                    <option value="1">{language ? 'पुरुष' : 'Male'}</option>
                    <option value="2">{language ? 'महिला' : 'Female'}</option>
                    <option value="0">{language ? 'अन्य' : 'Other'}</option>
                  </select>
                  {errors.gender && <span className="text-danger">{language ? errors?.gender?.hi : errors?.gender?.en}</span>}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3 f_fields">
                  <label className="form-label">
                    <span className="en-label">{language ? 'मोबाइल नंबर' : 'Mobile Number'}</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Phone Number"
                    value={phoneNumber}
                    maxLength={10}
                    disabled
                  />
                  {errors.phoneNumber && <span className="text-danger">{language ? errors?.phoneNumber?.hi : errors?.phoneNumber?.en}</span>}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3 f_fields">
                  <label className="form-label">
                    <span className="en-label">{language ? 'ईमेल' : 'Email'}</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Email"
                    value={email}
                    maxLength={100}
                    disabled
                  />
                  {errors.email && <span className="text-danger">{language ? errors?.email?.hi : errors?.email?.en}</span>}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3 f_fields">
                  <label className="form-label">
                    <span className="en-label">{language ? 'पता' : 'Address'}</span>
                  </label>
                  <input
                  name = "address"
                    type="text"
                    className="form-control"
                    placeholder="Enter Address"
                    maxLength={100}
                    value={address}
                    onChange={(e) => {setAddress(e.target.value);  handlechanges(e)}}
                  />
                  {errors.address && <span className="text-danger">{language ? errors?.address?.hi : errors?.address?.en}</span>}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3 f_fields">
                  <label className="form-label">
                    <span className="en-label">{language ? 'राज्य' : 'State'}</span>
                  </label>
                  <select
                  name='state'
                    className="form-control"
                    value={state}
                    onChange={(e) => {
                      setState(e.target.value);
                      setDistrictsData([])
                      setCitiesData([]);
                      setDistrictName(""); // Reset district and city when state changes
                      setCityName("");
                      handlechanges(e)
                    }}
                  >
                    <option value="">{language ? 'राज्य चुनें' : 'Select State'}</option>
                    {statesData.map((state) => (
                      <option key={state.value} value={state.value}>{language ? state.label?.hi : state.label?.en}</option>
                    ))}
                  </select>
                  {errors.state && <span className="text-danger">{language ? errors?.state?.hi : errors?.state?.en}</span>}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3 f_fields">
                  <label className="form-label">
                    <span className="en-label">{language ? 'ज़िला' : 'District'}</span>
                  </label>
                  <select
                    name='district'
                    className="form-control"
                    value={districtName}
                    onChange={(e) => {
                      setDistrictName(e.target.value);
                      handlechanges(e)
                      setCityName(""); // Reset city when district changes
                    }}
                  >
                    <option value="">{language ? 'ज़िला चुनें' : 'Select District'}</option>
                    {districtsData.map((district) => (
                      <option key={district.value} value={district.value}>{language ? district.label?.hi : district.label?.en}</option>
                    ))}
                  </select>
                  {errors.district && <span className="text-danger">{language ? errors?.district?.hi : errors?.district?.en}</span>}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3 f_fields">
                  <label className="form-label">
                    <span className="en-label">{language ? 'शहर' : 'City'}</span>
                  </label>
                  <select
                    name='city'
                    className="form-control"
                    value={cityName}
                    onChange={(e) => {setCityName(e.target.value); handlechanges(e)}}
                  >
                    <option value="">{language ? 'शहर चुनें' : 'Select City'}</option>
                    {citiesData.map((city) => (
                      <option key={city.value} value={city.value}>{language ? city.label?.hi : city.label?.en}</option>
                    ))}
                  </select>
                  {errors.city && <span className="text-danger">{language ? errors?.city?.hi : errors?.city?.en}</span>}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3 f_fields">
                  <label className="form-label">
                    <span className="en-label">{language ? 'पिनकोड' : 'Pincode'}</span>
                  </label>
                  <input
                    type="text"
                    name="pincode"
                    maxLength={6}
                    className="form-control"
                    placeholder={language ? 'पिनकोड दर्ज करें' : 'Enter Pincode'}
                    value={pincode}
                    onChange={(e) => {
                      const value = e.target.value;
                      const numericRegex = /^[0-9]*$/;
                      if (numericRegex.test(value)) {
                        setPincode(value);
                        handlechanges(e);
                      } 
                      handlechanges(e)
                    }}
                  />
                  {errors.pincode && <span className="text-danger">{language ? errors?.pincode?.hi : errors?.pincode?.en}</span>}
                </div>
              </div>
            </div>
            <div className="btn-group">
            {submitting ? <button className="btn btn-primary user-theme-btn mt-3">
                    <span>  {language ? "सबमिट हो रहा है..." : "Submitting..."}</span>
                    <Spinner animation="border" role="status" style={{ 'marginLeft': '5px' }}>
                      <span className="visually-hidden"></span>
                    </Spinner>
                  </button> : 
              <button type="submit" className="btn btn-primary user-theme-btn mt-3">{language ? 'जमा करना' : 'Submit'} </button>}
            </div>
          </form>
        </div>
      </Dashboard>
    </div>
  );
};

export default EditProfile;