import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faEye } from "@fortawesome/free-solid-svg-icons";
import {
    toUploadFile,
    uploadDocumentlistData,
    handleDeleteDocumentAPI,
} from "../../services/api/user";
import Upload from "../../assets/images/upload.svg";
import { formatDateWithdash } from "../../Utils/common";
import { Baseurl, GetLocalStorage } from "../../Utils/Utils";
import { SuccessModal } from "../Modal/SuccessModal";
import { useLanguage } from "../LanguageProvider/LanguageProvider ";


const DocsUploadDraft = ({ subCatId, showEditIcon, parentApplicationId }) => {
    const { language, setLanguage } = useLanguage();
    const [documentList, setDocumentList] = useState([]);
    const [baseUrl, setBaseUrl] = useState([]);
    const [modalShow, setModalShow] = useState(false);  // State to show/hide the modal 
    const [modalMessage, setModalMessage] = useState(""); // Modal message 
    const [modalHeaderMsg, setModalHeaderMsg] = useState('')
    const [fileId, setFileId] = useState()
    const [docDetails, setDocDetails] = useState()
    const applicationId = GetLocalStorage("application_id");
    const [fileErrors, setFileErrors] = useState({});
    const fileTypeList = [
        { label: "Docx", value: "1", accept: ".docx" },
        { label: "PDF", value: "2", accept: ".pdf" },
        { label: "Xlsx", value: "3", accept: ".xlsx" },
        { label: "Pptx", value: "4", accept: ".pptx" },
        { label: "Txt", value: "5", accept: ".txt" },
        { label: "Csv", value: "6", accept: ".csv" },
        { label: "Jpg/Jpeg", value: "7", accept: ".jpg,.jpeg" },
        { label: "Png", value: "8", accept: ".png" },
    ];

    useEffect(() => {
        docsList();
        setBaseUrl(Baseurl);
    }, []);

    useEffect(() => {
        setFileErrors([])
    }, [showEditIcon])

    const docsList = async () => {
        try {
            const formData = new FormData();
            formData.append("sub_category_id", subCatId?.subCatId || subCatId);
            //formData.append("application_id", parentApplicationId && parentApplicationId > 0 ? parentApplicationId : applicationId);
            formData.append("application_id", applicationId);
            const getList = await uploadDocumentlistData(formData);
            if (getList?.status === 200) {
                const filteredData = getList?.data?.filter(item => {
                    // Check if upload_documents exists and is non-empty
                    return item.upload_documents && Object.keys(item.upload_documents).length > 0;
                });

                setDocumentList(filteredData);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handleFileChange = (doc, index) => async (e) => {
        const uploadedFile = e?.target.files?.[0];
        const fileType = fileTypeList.find((x) => x.label === doc?.type);

        // Validate file type
        if (
            fileType &&
            fileType.accept &&
            !fileType.accept.includes(uploadedFile.type.split("/")[1])
        ) {
            setFileErrors((prevErrors) => ({
                ...prevErrors,
                [doc.id]: language ? `अमान्य फ़ाइल प्रकार.केवल ${doc?.type} फ़ाइलों की अनुमति है।` : `Invalid file type. Only ${doc?.type} files are allowed.`
            }));
            return;
        }

        const maxSizeInBytes = parseInt(doc?.size) * 1024;
        if (isNaN(maxSizeInBytes)) {
            return;
        }

        if (uploadedFile.size == 0) {
            setFileErrors((prevErrors) => ({
                ...prevErrors,
                [doc.id]: language ? `अमान्य फ़ाइल आकार। केवल 0 Kb फ़ाइलों की अनुमति है।` : `Invalid file size. Only 0 Kb files are allowed.`
            }));
            return;
        }

        if (uploadedFile.size > maxSizeInBytes) {
            setFileErrors((prevErrors) => ({
                ...prevErrors,
                [doc.id]: language ? `अमान्य फ़ाइल आकार. केवल ${doc?.size / 1000} एमबी फ़ाइलों की अनुमति है।` : `Invalid file size. Only ${doc?.size / 1000} MB files are allowed.`
            }));
            return;
        }

        try {
            const formData = new FormData();
            formData.append("upload_doc", uploadedFile);
            formData.append("upload_date", formatDateWithdash(new Date()));
            formData.append("category_id", subCatId?.subCatId);
            formData.append("document_type_id", doc?.id);
            formData.append("application_id", applicationId);
            formData.append(
                "upload_document_id",
                doc?.upload_documents?.[0] && doc?.upload_documents?.[0]?.id
                    ? doc?.upload_documents?.[0]?.id
                    : ""
            );
            const data = await toUploadFile(formData);
            if (data?.success === true) {
                docsList();
                setModalMessage(`Document uploaded`);
                setModalHeaderMsg("false")
                setModalShow(true);
                setFileErrors((prevErrors) => {
                    const updatedErrors = { ...prevErrors };
                    delete updatedErrors[doc.id];
                    return updatedErrors;
                });
            }
        } catch (error) {
            console.error(error.message);
            setModalMessage(error.message);
            setModalHeaderMsg("")
            setModalShow(true);
        }
    };

    const handleDeleteDocument = async () => {
        try {
            const payload = {
                upload_document_id:
                    docDetails?.upload_documents?.[0] && docDetails?.upload_documents?.[0]?.id
                        ? docDetails?.upload_documents?.[0]?.id
                        : "",
            };
            const data = await handleDeleteDocumentAPI(payload);
            if (data?.status === 200) {
                // setModalShow(true);
                // setModalMessage("Document Deleted");
                // setModalHeaderMsg("true")
                docsList();
            }
        } catch (error) {
            console.error(error.message);
        }
    };

    return (
        <div className="">
            <div className="row mt-2">
                {documentList?.map((doc, index) => (
                    <div className="col-lg-6 col-md-12 col-sm-12" key={index}>
                        <div className="documents_block">
                            <div className="documents_detail w-100">
                                <h5 className="mb-0">
                                    <span className="en-label">
                                        {index + 1}. {language ? doc?.name_hi : doc?.name_en}
                                    </span>
                                </h5>
                                <div className="upload-document-text">
                                    {doc?.upload_documents?.[0] && doc?.upload_documents?.[0]?.filename
                                        ? doc?.upload_documents?.[0]?.filename
                                        : ""}
                                </div>
                                {fileErrors[doc.id] && (
                                    <div className="validate-error">
                                        {fileErrors[doc.id]}
                                    </div>
                                )}
                            </div>

                            <div className="upload-icons d-flex align-items-end flex-column justify-content-end w-100">
                                <div className="u_inner_icons d-flex mb-2">
                                    <label
                                        htmlFor={`general_certificate_${index}`}
                                        className="upload-label"
                                    >
                                        {!showEditIcon ?
                                            <>
                                                <div className="doc-icon upload file_need_to_be_upload">
                                                    <div className="doc-icon upload-icon preview">
                                                        <img src={Upload} alt="Upload Icon" />
                                                    </div>
                                                </div>
                                                <input
                                                    id={`general_certificate_${index}`}
                                                    type="file"
                                                    className="upload_document"
                                                    accept={
                                                        fileTypeList.find((x) => x.label === doc?.type)
                                                            ?.accept || ""
                                                    }
                                                    required
                                                    onChange={(e) => {
                                                        handleFileChange(doc, index)(e);
                                                        e.target.value = null; // Reset input value to allow re-selection of the same file
                                                    }}
                                                    style={{ display: "none" }}
                                                />
                                            </> : null
                                        }
                                    </label>

                                    <a
                                        href="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (
                                                doc?.upload_documents[0] &&
                                                doc?.upload_documents[0]?.path
                                            ) {
                                                window.open(
                                                    baseUrl + "/" + doc?.upload_documents[0]?.path,
                                                    "_blank"
                                                );
                                            } else {
                                                alert("No preview available.");
                                            }
                                        }}
                                    >
                                        <div className="doc-icon preview-icon delete">
                                            <FontAwesomeIcon icon={faEye} />
                                        </div>
                                    </a>
                                </div>

                                <div className="instructions">
                                    <p className="mb-0 pdf-support">{language ? `(केवल ${doc?.type} अपलोड करें)` : `(Only ${doc?.type} upload)`}</p>
                                    <p className="mb-0 file-size">{language ? `फाइल साइज मैक्सिमम ${doc?.size / 1000} मब` : ` File Size मैक्सिमम ${doc?.size / 1000} MB`}

                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {modalShow && (
                <SuccessModal
                    className="modalClass"
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    message={modalMessage}
                    handleDelete={handleDeleteDocument}
                    headerMsg={modalHeaderMsg}
                />
            )}
        </div>
    );
};

export default DocsUploadDraft;
