import React, { useEffect, useState } from 'react'
import AdminDashboard from '../AdminDashboard/AdminDashboard'
import { Message } from "../../../components/message";
import { useLocation, useNavigate } from "react-router-dom";
import { Table } from "react-bootstrap";
import { HomeIcon } from "../../../components/Application/ApplicationIcon";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { EyeIcon } from "../AdminIcon";
import { allAdminList, allAdminUserList, getAdminPostingDistrictLog, getYearlyTarget, getYearlyTargetDistrictLog } from '../../../services/api/admin';
import NotFoundTable from '../../../components/notFound/NotFoundTable';
import { BsChevronLeft } from 'react-icons/bs';
import { formatDateTimeWithSlash, formatDateWithdash } from '../../../Utils/common';
import { GetLocalStorage } from '../../../Utils/Utils';
import { stateDistrict } from '../../../services/api/auth';

const AdminPosting = () => {
    const navigate = useNavigate();
    const token = GetLocalStorage("userInfo");
    const location = useLocation()
    const [apiMessage, setApiMessage] = useState({ type: "", message: "" });
    const [list, setList] = useState([]);
    const [userList, setuserList] = useState([]);
    const [logData, setLogData] = useState()

    useEffect(() => {
        if (!token) {
            navigate("/")
        } else {
            if (location?.state) {
                setLogData(location?.state)
                fetchData(location?.state);
                getAdminUserList()
            }
        }
    }, [location?.state])

    const fetchData = async (data) => {
        try {
            const formData = new FormData()
            formData.append("admin_id", data?.id)
            const response = await getAdminPostingDistrictLog(formData)
            if (response?.status == 200) {
                setList(response?.data)
            } else {
                setList([])
                // setApiMessage({ type: 'error', message: response?.message })
            }
        } catch (err) {
            setApiMessage({ type: 'error', message: err.message })
        }
    }

    const getAdminUserList = async () => {
        try {
            const districtData = await stateDistrict();
            const data = districtData?.data?.data?.map((district) => ({
                label: district?.name_en,
                value: district?.id,
            }));
            console.log(data)
            setuserList(data)
        } catch (err) {
            setApiMessage({ type: "error", message: err?.message });
        }
    };

    return (
        <AdminDashboard>
            <div className="aod-outer">
                {apiMessage && (<Message apiMessage={apiMessage} setApiMessage={setApiMessage}></Message>)}
                <div className="ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb">
                    <div>
                        <HomeIcon />
                        <b onClick={() => navigate("/admin/")}>Home / </b>
                        <span>Previous Posting</span>
                    </div>
                </div>
                <div className="aod-inner pt-0">
                    <div className="aod-bottom">
                        <div className="aod-head text-center adm-head">
                            <button onClick={() => navigate("/admin/view", { state: logData })}>
                                <BsChevronLeft /> Back
                            </button>
                            Previous Posting
                        </div>
                        <div className="ad-reg-table">
                            <Table size="sm" className="ad-cat-table mt-4">
                                <thead>
                                    <tr>
                                        <th className="ad-sno">S. No</th>
                                        <th className="ad-long">Name</th>
                                        <th className="ad-long">District</th>
                                        <th className="ad-long">Joining Date</th>
                                        <th className="ad-long">Relieving Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {list?.length > 0 ? list?.map((item, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{item?.admin_details?.name || "NA"}</td>
                                            <td>{item?.district_id ? userList?.find(x => x?.value == item?.district_id)?.label : '--'}</td>
                                            <td>{formatDateWithdash(item?.created_at) || "NA"}</td>
                                            <td>{formatDateWithdash(item?.relieving_date) || "NA"}</td>
                                        </tr>
                                    )) : <NotFoundTable colSpan="7"></NotFoundTable>}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </AdminDashboard>
    )
}

export default AdminPosting;
