import React, { useEffect, useState } from "react";
import { Modal, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { HomeIcon } from "../../../components/Application/ApplicationIcon";
import NotFoundTable from "../../../components/notFound/NotFoundTable";
import {
  deleteSocialMediaLinks,
  getSocialMediaLinks,
  handleEnableDisableSocialMediaLinks,
} from "../../../services/api/admin";
import { formatDateWithSlash } from "../../../Utils/common";
import { Baseurl } from "../../../Utils/Utils";
import AdminDashboard from "../AdminDashboard/AdminDashboard";
import { DeleteIcon, EditIcon } from "../AdminIcon";
import { Message } from "../../../components/message";
import { HiPlus } from "react-icons/hi";

const SocialMedialLinksUpdate = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const [userList, setUserList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [apiMessage, setApiMessage] = useState({ type: "", message: "" });
  const [tableDataLoaded, setTableDataLoaded] = useState(false);
  const [deleteData, setDeleteData] = useState('')
  const { state } = useLocation();

  useEffect(() => {
    socialMediaLinks();
  }, []);

  useEffect(() => {
    if (state && state.message) {
      setApiMessage({ type: "success", message: state.message });
      // Clear the message once it is rendered
      window.scrollTo(0, 0);
      window.history.replaceState({}, document.title);
    }
  }, [state]);

  const socialMediaLinks = async () => {
    try {
      const usersListData = await getSocialMediaLinks();
      if (usersListData.status === 200) {
        setUserList(usersListData?.data || []);
        setTableDataLoaded(true);
      }
    } catch (err) {
      setApiMessage({ type: "error", message: err?.message });
    }
  };

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
    socialMediaLinks();
  };

  const handleEnableDisable = async (e, item) => {
    try {
      const formData = new FormData();
      formData.append("link_id", item?.id);
      formData.append("status", e?.value);
      const data = await handleEnableDisableSocialMediaLinks(formData);
      if (data?.status == 200) {
        socialMediaLinks();
      } else {
        setApiMessage({ type: "error", message: data?.message });
      }
    } catch (error) {
      console.error(error);
      setApiMessage({ type: "error", message: error?.message });
    }
  };

  const deleteLink = async (item) => {
      try {
        const payload = {
          link_id: item.id,
        };
        const res = await deleteSocialMediaLinks(payload);
        setDeleteData("");
        socialMediaLinks()
      } catch (e) {}
  };

  return (
    <AdminDashboard>
      <div className="aod-outer">
        {apiMessage && (
          <Message
            apiMessage={apiMessage}
            setApiMessage={setApiMessage}
          ></Message>
        )}
        <div className="ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb">
          <div>
            <HomeIcon />
            <b onClick={() => navigate("/admin/")}>Home /</b>
            <span> Social Media Links</span>
          </div>
        </div>
        <div className="admin-add-btn mb-3 d-flex justify-content-end">
          <button
            className="adm-tab-btn"
            onClick={() =>
              navigate("/admin/social-media-links/add")
            }
          >
            <span>
              <HiPlus />
            </span>{" "}
            Add
          </button>
        </div>
        <div className="aod-inner pt-0">
          <div className="aod-bottom">
            <div className="adm-tabs-outer">
              <h4 className="mb-0 w-100">Social Media Links List</h4>
            </div>
            <div className="aod-head"></div>
            <div className="ad-reg-table">
              <Table size="sm" className="ad-cat-table mt-4">
                <thead>
                  <tr>
                    <th className="ad-sno">S. No</th>
                    <th className="ad-long">English Name</th>
                    <th className="ad-long">Hindi Name</th>
                    <th className="ad-long">Icon</th>
                    <th className="ad-long">Created At</th>
                    <th className="ad-long">Status</th>
                    {userList.length > 1 && <th className="ad-long">Edit</th>}
                  </tr>
                </thead>
                <tbody>
                  {userList.length > 0 ? (
                    userList.map((item, index) => (
                      <tr key={item.id}>
                        <td>{(currentPage - 1) * 10 + index + 1}</td>
                        <td>{item.name}</td>
                        <td>{item.name_hi}</td>
                        <td>
                          <img
                            src={`${Baseurl()}/${item.logo}`}
                            alt="Banner"
                            style={{ width: "30px", height: "auto" }}
                          />
                        </td>
                        <td>{formatDateWithSlash(item.created_at)}</td>
                        <td>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={item?.status == 1}
                              onChange={(e) =>
                                handleEnableDisable(
                                  e.target.checked
                                    ? { value: "1" }
                                    : { value: "0" },
                                  item
                                )
                              }
                            />
                            <span className="slider"></span>
                          </label>
                        </td>
                        <td>
                          <div className="action-btn d-flex">
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip id={`edit-tooltip-${item.id}`}>Edit</Tooltip>}
                            >
                              <span
                                className="ad-cat-edit"
                                onClick={() => { navigate("/admin/social-media-links/update", { state: item }) }}
                              >
                                <EditIcon />
                              </span>
                            </OverlayTrigger>
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip id={`delete-tooltip-${item.id}`}>Delete</Tooltip>}
                            >
                              <span
                                className="ad-cat-delete"
                                onClick={() => setDeleteData(item)}
                              >
                                <DeleteIcon />
                              </span>
                            </OverlayTrigger>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <NotFoundTable colSpan="7"></NotFoundTable>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
      <Modal show={deleteData?.id} onHide={() => setDeleteData("")}>
        <Modal.Header closeButton>
          <Modal.Title>
            Do you really want to delete this Link ?
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <div className="suf-btn p-2">
            <button onClick={() => deleteLink(deleteData)}>Yes</button>
            <button
              className="suf-can-butt"
              onClick={() => setDeleteData(false)}
            >
              No
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </AdminDashboard>
  );
};

export default SocialMedialLinksUpdate;
