import React from "react";
import { useLanguage } from "../LanguageProvider/LanguageProvider ";
import Dashboard from "../../pages/Dashboard/Dashboard";
import css from "../../components/Payment/Payment.css";

const PaymentRecipt = () => {
  const { language, setLanguage } = useLanguage();
  return (
    <Dashboard
      first={language ? "होम" : "Home"}
      second={language ? "भुगतान रसीद" : "Payment Receipt"}
      head={language ? "भुगतान रसीद" : "Payment Receipt"}
    >
      <>
        <div className="aod-bottom">
          <div className="page_heading d-flex align-items-center justify-content-between">
            <h5 className="mb-0">{language ? "भुगतान रसीद" : "Payment Receipt"}</h5>
            <button className="btn reciept-cta">{language ? "डाउनलोड" : "Download"}</button>
          </div>
          <div className="form-block" id="reciept-form">
            <div className="form-bar">
              <div className="bar-right-detail">
                <div className="download-btn"></div>
              </div>
            </div>
            <div className="form-inner-wrapper">
              <div className="card-body">
                <div className="tab">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="receipt-block">
                        <div className="Payment">
                          <h4>₹ 500.00</h4>
                          <p>
                            <span>
                              <i className="fas fa-check-circle"></i>
                            </span>
                            <span className="px-2">{language ? "सफलतापूर्वक भुगतान" : "Paid Successfully"} </span>
                          </p>
                        </div>
                        <div className="receipt-table-outer">
                          <div className="receipt-table-inner">
                            <table className="receipt-data">
                              <tr>
                                <th>{language ? "नाम" : "Name"}</th>
                                <td>Tajinder Singh</td>
                              </tr>
                              <tr>
                                <th>{language ? "ईमेल" : "Email"}</th>
                                <td>tajinder@gmail.com</td>
                              </tr>
                              <tr>
                                <th>{language ? "मोबाइल नंबर" : "Mobile No."}</th>
                                <td>9877682593</td>
                              </tr>
                              <tr>
                                <th>{language ? "भुगतान आईडी" : "Payment Id"}</th>
                                <td>HS89768905678056</td>
                              </tr>
                              <tr>
                                <th>{language ? "भुगतान तिथि" : "Paid On"}</th>
                                <td>5 March, 2024</td>
                              </tr>
                              <tr>
                                <th>{language ? "तरीका" : "Method"}</th>
                                <td>
                                  HDFC bank <span>(Pending)</span>
                                </td>
                              </tr>
                              <tr>
                                <th>{language ? "वैकल्पिक मोबाइल नंबर" : "Alternate Mobile No."}</th>
                                <td>9877687865</td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </Dashboard>
  );
};

export default PaymentRecipt;
