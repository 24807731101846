/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { BsChevronLeft } from 'react-icons/bs';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { HomeIcon } from '../../../components/Application/ApplicationIcon';
import { Message } from '../../../components/message';
import { addPageDocument, getPageListData, updatePageDocument } from '../../../services/api/admin';
import { checkPattern, customStylesAcc, selectTheme } from '../../../Utils/common';
import { GetLocalStorage } from '../../../Utils/Utils';
import "../Admin.css";
import AdminDashboard from '../AdminDashboard/AdminDashboard';
import { UploadIcon } from '../AdminIcon';

const AddPageDocument = () => {
    const token = GetLocalStorage("userInfo");
    const navigate = useNavigate();
    const location = useLocation();

    const [pageDocumentId, setPageDocumentId] = useState();
    const [pageId, setPageId] = useState();
    const [document, setDocument] = useState('');
    const [apiMessage, setApiMessage] = useState({ type: '', message: '' });
    const [PageDocumentErr, setPageDocumentErr] = useState('');
    const [PageDocumentIdError, setPageDocumentIdError] = useState('');
    const [editData, setEditData] = useState();
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState();

    const [nameHi, setNameHi] = useState("");
    const [nameHiError, setNameHiError] = useState();
    const [pageIdOptions, setPageIdOptions] = useState();

    // const pageIdOptions = [
    //     { value: 1, label: "Privacy Policy" },
    //     { value: 2, label: "Terms and Conditions" },
    //     { value: 3, label: "Disclaimer" },
    //     { value: 4, label: "Rules" },
    // ];

    useEffect(() => {
        getPagesList()
        if (location?.state && location?.pathname === "/admin/page-document/update") {
            editPageDocument(location?.state);
        }
    }, [location?.state]);

    const getPagesList = async () => {
        try {
          const pagesList = await getPageListData();
          if (pagesList?.status == 200) {
            const data = pagesList?.data?.map((item) => ({
                value: item?.id,
                label: item?.name_en,
              }));
            setPageIdOptions(data);
          }
        } catch (err) {
          console.log("error on sub Category list: ", err);
        }
      };

    const validateForm = (item) => {
        let isValid = true;
        if (!name) {
            if (!name || name.trim() === "") {
                setNameError("Please Enter English Name");
                isValid = false;
            } else {
                setNameError("");
            }
        }
        if (!nameHi) {
            if (!nameHi || nameHi.trim() === "") {
                setNameHiError("Please Enter Hindi Name");
                isValid = false;
            } else {
                setNameHiError("");
            }
        }
        if (!document) {
            setPageDocumentErr("Please upload a document.");
            isValid = false;
        } else {
            setPageDocumentErr("");
        }
        if (!pageId) {
            setPageDocumentIdError("Please select a page.");
            isValid = false;
        } else {
            setPageDocumentIdError("");
        }
        return isValid;
    };



    const handlePageIdChange = (selectedOption) => {
        setPageId(selectedOption.value);
    };

    const addPageDocumentAPI = async () => {
        if (!validateForm()) return;
        try {
            const formData = new FormData();
            formData.append("page_id", pageId);
            formData.append("name_en", name);
            formData.append("name_hi", nameHi);
            formData.append("document", document);

            const data = await addPageDocument(formData);
            if (data?.status === 200) {
                navigate("/admin/page-document", { state: { message: data?.message } });
            } else {
                setApiMessage({ type: 'error', message: data?.message });
            }
        } catch (error) {
            setApiMessage({ type: 'error', message: error?.message });
        }
    };

    const updatePageDocumentAPI = async () => {
        if (!validateForm()) return;
        try {
            const formData = new FormData();
            formData.append("document_id", pageDocumentId);
            formData.append("name_en", name);
            formData.append("page_id",pageId);
            formData.append("name_hi", nameHi);
            if (typeof document != 'string') {
                formData.append("document", document);
            }
            const data = await updatePageDocument(formData);
            if (data?.status === 200) {
                navigate("/admin/page-document", { state: { message: data?.message } });
            } else {
                setApiMessage({ type: 'error', message: data?.message });
            }
        } catch (error) {
            setApiMessage({ type: 'error', message: error?.message });
        }
    };

    const editPageDocument = (item) => {
        setEditData(item);
        setPageDocumentId(item.id);
        setName(item.name_en);
        setNameHi(item.name_hi);
        setPageId(item.page_id)
        setDocument(item.document); // If editing, set document as the string
        window.scrollTo(0, 0);
    };

    const handleDocumentChange = (e) => {
        const file = e?.target?.files?.[0];
        if (file && file.type === "application/pdf") { // Accept only PDF
            setDocument(file);
            setPageDocumentErr('');
        } else {
            setDocument(null);
            setPageDocumentErr('Please upload a valid PDF document.');
            e.target.value = ''; // Reset file input
        }
    };

    const handleInputChange = async (e) => {
        const { name, value } = e.target;
        const pattern = /^[a-zA-Z\s]*$/;
        const cursorPosition = e.target.selectionStart;
         // Check for "nameEn" field
        if (name === "nameEn") {
            if (await checkPattern(value)) {
                setNameError("")
                setName(value);
            } else {
                e.preventDefault()
                return;
            }
        }
        // Check for "nameHi" field
        if (name === "nameHi") {
            if (await checkPattern(value)) {
                setNameHiError("")
                setNameHi(value);
            } else {
                e.preventDefault()
                return;
            }
        }
        setTimeout(() => {
            e.target.setSelectionRange(cursorPosition, cursorPosition);
        }, 0);
    };

    return (
        <AdminDashboard>
            <div className='aod-outer'>
                {apiMessage && (<Message apiMessage={apiMessage} setApiMessage={setApiMessage}></Message>)}
                <div className='ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb'>
                    <div>
                        <HomeIcon /><b onClick={() => navigate("/admin/")}>Home  /</b>  <span> Page Document</span>
                    </div>
                </div>
                <div className="aod-inner">
                    <div className="aod-bottom">
                        <div className="aod-head text-center adm-head">
                            <button onClick={() => navigate("/admin/page-document")}><BsChevronLeft /> Back</button>
                            {editData?.id ? "Update" : "Add"}
                        </div>
                        <div className="row py-4">
                        <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>

                                    <label className='mb-1 w-100'>
                                        English Name <span className="text-danger required_f">*</span>
                                    </label>
                                    <input
                                        className='w-100'
                                        maxLength={100}
                                        name='nameEn'
                                        placeholder='Enter English Name'
                                        type="text"
                                        onChange={handleInputChange} // Use onChange for real-time validation
                                        value={name}
                                    />
                                    {/* <input className='w-100' maxLength={100} name='name' placeholder='Enter Category English Name' type="text" onChange={(e) => handleKeyUpLoginDetails(e)} value={name} /> */}

                                    {nameError && <p className='validate-error'>{nameError}</p>}

                                </div>
                            </div>
                            <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>

                                    <label className='mb-1 w-100'>
                                        Hindi Name <span className="text-danger required_f">*</span>
                                    </label>
                                    <input maxLength={100} className='w-100' placeholder='Enter Hindi Name' type="text" name='nameHi' value={nameHi} onChange={handleInputChange} />

                                    {nameHiError && <p className='validate-error'>{nameHiError}</p>}

                                </div>
                            </div>
                            <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>
                                    <label className='lf-label w-100'>
                                        Select Page  <span className="text-danger required_f">*</span>
                                    </label>
                                    <Select
                                        styles={customStylesAcc}
                                        theme={selectTheme}
                                        components={{
                                            IndicatorSeparator: () => null
                                        }}
                                        className="menu_open_select"
                                        classNamePrefix="ad-menu_open_select_class"
                                        aria-label="Default select example"
                                        options={pageIdOptions}
                                        onChange={handlePageIdChange}
                                        value={pageIdOptions?.find(option => option.value === pageId)}
                                        placeholder="Please Select Page"
                                    />
                                    {PageDocumentIdError && <p className='validate-error'>{PageDocumentIdError}</p>}
                                </div>
                            </div>
                            <div className="col-md-4 aod-resp-tab d-flex align-items-top">
                                <div className='suf-input-box aod-form-input mb-2 w-100'>
                                    <label className='mb-1 w-100'>
                                        Document <span className="text-danger required_f">*</span>
                                    </label>
                                    <input
                                        type="file"
                                        name="partsuploadfile"
                                        id="profileimg"
                                        className="w-100 ad-cat-input-field-image"
                                        accept="application/pdf" // Only PDF files
                                        onChange={handleDocumentChange}
                                    />
                                    <label htmlFor="profileimg" className="ad-cat-input-upload-image p-0">
                                        <p className="ad-cat-text-upload-image">
                                            <span><UploadIcon /></span>
                                            {editData?.id && typeof document === 'string' ? (
                                                <p className="mb-0 fw-semibold">{document.split("/").pop()}</p>
                                            ) : (
                                                document?.name ? (
                                                    <p className="mb-0 fw-semibold">{document.name}</p>
                                                ) : (
                                                    <p className="mb-0 fw-semibold">Please upload document</p>
                                                )
                                            )}
                                        </p>
                                    </label>
                                    {PageDocumentErr && <p className='validate-error'>{PageDocumentErr}</p>}
                                </div>
                            </div>
                        </div>
                        <div className="row ad-cat-row">
                            <div className="update-btns-block">
                                {editData?.id ? (
                                    <button onClick={updatePageDocumentAPI}>Update</button>
                                ) : (
                                    <button onClick={addPageDocumentAPI}>Submit</button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AdminDashboard>
    );

};

export default AddPageDocument;
