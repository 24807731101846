import React, { useEffect, useState } from 'react'
import AdminDashboard from '../AdminDashboard/AdminDashboard';
import { Message } from '../../../components/message'
import { useLocation, useNavigate } from 'react-router-dom';
import { HomeIcon } from '../../../components/Application/ApplicationIcon';
import { Modal, Table } from 'react-bootstrap';
import sortByIcon from '../../../assets/images/sort.png';
import { allAdminUserDataSort, allAdminUserList, allCertificateTypeList, deleteAdminUserData, getEnableDisableAdminUserData, getEnableDisableCertificateType } from '../../../services/api/admin';
import { DeleteIcon, EditIcon, EyeIcon, PermissionIcon } from '../AdminIcon';
import ReactPaginate from 'react-paginate';
import { HiPlus } from 'react-icons/hi';
import { formatDateWithSlash } from '../../../Utils/common';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { checkPermission, permissionsConfig } from '../AdminPermission/PermissionChecker';
import NotFoundTable from '../../../components/notFound/NotFoundTable';

const CertificateCategory = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [apiMessage, setApiMessage] = useState({ type: '', message: '' });
  const [certificateList, setCertificateList] = useState([])
  const [orderBy, setOrderBy] = useState("desc");
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteData, setDeleteData] = useState();
  const [totalPages, setTotalPages] = useState(0); // Total number of pages
  const [searchTerm, setSearchTerm] = useState()
  const [sortBy, setSortBy] = useState("created_at");
  const [tableDataLoaded, setTableDataLoaded] = useState(false);

  useEffect(() => {
    if (state && state.message) {
      setApiMessage({ type: 'success', message: state.message });
      // Clear the message once it is rendered
      window.history.replaceState({}, document.title);
    }
  }, [state])

  const grantPermissionForAddCertificateType = checkPermission(permissionsConfig.addCertType.role, permissionsConfig.addCertType.action, permissionsConfig.addCertType.type);
  const grantPermissionForEditCertificateType = checkPermission(permissionsConfig.editCertType.role, permissionsConfig.editCertType.action, permissionsConfig.editCertType.type);

  useEffect(() => {
    getCertificateList("created_at", "1", "desc");
  }, [])

  const getCertificateList = async (sortBy, pageNo, orderBy) => {
    try {
      setOrderBy(orderBy);
      setSortBy(sortBy);
      const list = await allCertificateTypeList(sortBy, orderBy, pageNo)
      if (list.status == 200) {
        setCertificateList(list?.data)
        setCurrentPage(pageNo > 0 ? pageNo - 1 : pageNo);
        setTotalPages(Math.ceil(list?.data?.total / list?.data?.per_page)); // Calculate total pages
        // setCurrentPage(pageNo > 0 ? pageNo - 1 : pageNo);
      } else {
        setCertificateList([]);
        setApiMessage({ type: "error", message: list?.message });
      }
      setTableDataLoaded(true);
    } catch (err) {
      setApiMessage({ type: 'err', message: err.message });
    }
  }

  const handleEnableDisable = async (e, item) => {
    try {
      const formData = new FormData();
      formData.append("status", e?.value)
      formData.append("certificate_type_id", item?.id)
      const res = await getEnableDisableCertificateType(formData)
      if (res?.status === 200) {
        getCertificateList(sortBy, currentPage + 1, orderBy);
      } else {
        setApiMessage({ type: "error", message: res?.message });
      }
    } catch (err) {
      setApiMessage({ type: 'err', message: err.message });
    }
  }

  const searchSortFromList = async (e, sortBy) => {
    // try {
    //   const searchBy = e.target.value ? e.target.value : '';
    //   setOrderBy(orderBy == 'asc' ? 'desc' : 'asc')
    //   const tabList = await allAdminUserDataSort(sortBy, orderBy, searchBy)
    //   const data = tabList?.data?.data
    //   setuserList(data)
    // } catch (err) {
    //   console.log("error on categoryList: ", err)
    //   setApiMessage({ type: 'err', message: err.message });
    // }
  }

  const handlePageChange = (selectedPage) => {
    getCertificateList(sortBy, selectedPage.selected + 1, orderBy); 
  };

  const deleteCategory = async (obj) => {
    try {
      const data = await deleteAdminUserData(obj.id)
      if (data?.status == 200) {
        getCertificateList(sortBy, currentPage + 1, orderBy);
        setDeleteData("");
        setApiMessage({ type: 'success', message: data.message });
      } else {
        setDeleteData("");
        setApiMessage({ type: 'err', message: data.message });
      }
    } catch (error) {
      console.error(error);
    }
  }


  return (
    <AdminDashboard>
      <div className='aod-outer'>
        {apiMessage && (<Message apiMessage={apiMessage} setApiMessage={setApiMessage}></Message>)}
        <div className='ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb'>
          <div>
            <HomeIcon /><b onClick={() => navigate("/admin/")}>Home  /</b>   <span>Certificate Type</span>

          </div>
          {/* {(grantPermissionForAddCertificateType) ? ( */}
          <button className='adm-tab-btn' onClick={() => navigate("/admin/certificate-type/add")}><span><HiPlus />
            </span> Add</button>
          {/* ) : ''} */}
        </div>
        <div className="aod-inner pt-0">
          <div className="aod-bottom">
            <div className="adm-tabs-outer d-flex justify-content-between">
              <h4 className='mb-0'>Certificate Type </h4>
              {/* <input className='w-25 px-2 form-control' placeholder='search' type="text" onChange={(e) => searchSortFromList(e, 'name')} /> */}
              {/* <div className="position-relative  w-md-50 w-25">
                <input
                  className="px-2 form-control"
                  placeholder="Search"
                  type="text"
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    searchSortFromList(e, 'name')
                  }}
                />
                {searchTerm && ( 
                  <button
                    className="btn btn-clear position-absolute"
                    onClick={(e) => {
                      setSearchTerm(""); 
                      searchSortFromList(e, 'name') 
                    }}
                    style={{
                      top: "50%",
                      right: "10px",
                      transform: "translateY(-50%)",
                      border: "none",
                      background: "transparent",
                      fontSize: "1rem",
                      cursor: "pointer",
                    }}
                  >
                    &times; 
                  </button>
                )}
              </div> */}
            </div>
            <div className="aod-head"></div>
            <div className="ad-reg-table">
              <Table size="sm" className='ad-cat-table mt-4'>
                <thead>
                  <tr>
                    <th className='ad-sno'>S. No</th>
                    <th className='ad-long' onClick={(e) =>  getCertificateList(
                          "name_en",
                          currentPage + 1,
                          orderBy === "asc" ? "desc" : "asc"
                        )}>Name English</th>
                    <th className='ad-long ' onClick={(e) => getCertificateList(
                          "name_hi",
                          currentPage + 1,
                          orderBy === "asc" ? "desc" : "asc"
                        )}>Name Hindi</th>
                    <th className='ad-long' onClick={(e) => getCertificateList(
                          "created_at",
                          currentPage + 1,
                          orderBy === "asc" ? "desc" : "asc"
                        )}>Created At</th>
                    {/* {(grantPermissionForEditCertificateType) ? (<> */}
                      <th className='ad-long'>Status</th>
                      <th className='ad-long' style={{ paddingLeft: '30px' }}>Actions</th>
                    {/* </>) : ''} */}
                  </tr>
                </thead>
                <tbody>
                  {
                    certificateList?.length > 0 ? certificateList?.map((item, index) => (
                      <tr key={index}>
                        <td>{currentPage * 10 + (index + 1)}</td>
                        <td>{item?.name_en ? item?.name_en : '--'}</td>
                        <td>{item?.name_hi ? item?.name_hi : '--'}</td>
                        <td>{item?.created_at ? formatDateWithSlash(item?.created_at) : '--'}</td>
                        {/* {(grantPermissionForEditCertificateType) ? (<> */}
                          <td>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={item?.status == 1}
                                onChange={(e) => handleEnableDisable(e.target.checked ? { value: "1" } : { value: "0" }, item)}
                              />
                              <span className="slider"></span>
                            </label>
                          </td>
                          <td>
                            <div className='icon-col'>

                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id={`edit-tooltip-${item.id}`}>Edit</Tooltip>}
                              >
                                <span
                                  className="ad-cat-edit"
                                  onClick={() => { navigate("/admin/certificate-type/update", { state: item }) }}
                                >
                                  <EditIcon />
                                </span>
                              </OverlayTrigger>

                            </div>
                          </td>
                        {/* </>) : ''} */}
                      </tr>
                    )) : tableDataLoaded ? (
                      <NotFoundTable colSpan="8"></NotFoundTable>
                    ) : (
                      <></>
                    )}
                </tbody>
              </Table>
              {totalPages > 1 && certificateList?.length > 0 &&
                <div className="d-flex w-100 justify-content-end">
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    pageCount={totalPages} // Total number of pages from API
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={1}
                    onPageChange={handlePageChange} // Method to handle page click
                    containerClassName={"pagination justify-content-center"} // Custom CSS classes
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </AdminDashboard>
  )
}

export default CertificateCategory
