import React, { useEffect, useState } from 'react'
import "./Footer.css"
import { BiLogoFacebook } from "react-icons/bi";
import { BsTwitterX } from "react-icons/bs";
import { AiOutlineYoutube } from "react-icons/ai";
import { AiOutlineInstagram } from "react-icons/ai";
import { RiLinkedinLine } from "react-icons/ri";
import octicongraph from "../../assets/images/octicon-graph.png"
import trainingprogram from "../../assets/images/training-program.png"
import photography from "../../assets/images/photography.png"
import List from "../../assets/images/list.png"
import transferpolicy from "../../assets/images/transfer-policy.png"
import register from "../../assets/images/register.png"
import phone from "../../assets/images/phone.png"
import login from "../../assets/images/login.png"
import { useLocation, useNavigate } from 'react-router-dom';
import { useLanguage } from '../LanguageProvider/LanguageProvider ';
import { Baseurl } from "../../Utils/Utils";
import { getVisitorCount } from '../../services/api/outer';
const Footer = ({ homePageContent, getUserInfo }) => {
    const { language, setLanguage } = useLanguage()
    const navigate = useNavigate()
    const location = useLocation();
    const [visitorCountData, setVisitorCountData] = useState(null);
    const iconMap = {
        Facebook: <BiLogoFacebook />,
        "Twitter(X)": <BsTwitterX />,
        Youtube: <AiOutlineYoutube />,
        Instagram: <AiOutlineInstagram />,
        LinkedIn: <RiLinkedinLine />,
      };

    
    useEffect(() => {
        getVisitorCountData()
    }, [])
  
    const getVisitorCountData = async () => {
        try {
            const visitorCountResponse = await getVisitorCount();
            setVisitorCountData(visitorCountResponse?.data);
        } catch (err) { }
    };

      
    const activeHome = location.pathname == "/" || location.pathname == "/login" || location.pathname == "/signup" || location.pathname == "/reset-password" || location.pathname == "/forgot-password"
    return (
        <> { homePageContent?.footer_desc && homePageContent?.footer_desc ? <div className='ft-main m-0' style={{ display: getUserInfo && !activeHome ? "none" : "block" }}>
            <div className="container">
                <div className='ft-upper row'>
                    <div className='upper-box1 col-md-5'>
                        <p className='box2-pera-heading'>{language ? homePageContent?.footer_desc.find((d) => d.type === "1")?.title_hi ?? '' : homePageContent?.footer_desc.find((d) => d.type === "1")?.title_en ?? ''}</p>
                        <p className='box2-pera-line'></p>
                        <p className='box1-pera'>{language ? homePageContent?.footer_desc.find((d) => d.type === "1")?.content_hi ?? '' : homePageContent?.footer_desc.find((d) => d.type === "1")?.content_en ?? ''} </p>
                        <div className="box1-icons">
                            {homePageContent?.social_media_link?.filter((item) => item.status === "1")?.map((link) => (
                                <div key={link.id} className="b1-icons-inner">
                                    <a
                                        href={link?.url} 
                                        target="_blank" 
                                        rel="noopener noreferrer"
                                        className="social-link"
                                    >
                                        <img
                                        src={Baseurl() + "/" + link?.logo}
                                        alt={link.name || "social link"}
                                        />
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-md-1"></div>
                    <div className='upper-box2 col-md-3'>
                        <p className='box2-pera-heading'>{language ? "ट्रैवल ट्रेड" : "Travel Trade"}</p>
                        <p className='box2-pera-line'></p>
                        <div className='box2-pera-content'>
                        {homePageContent?.footer_link.map(link => (
                            <div key={link.id} className="ft-box2-lines">
                                <img src={Baseurl() + "/" + link.image || 'default-icon.png'} alt={link.name} />
                                <a href={link?.link} target="_blank" style={{ color: 'inherit', textDecoration: 'none' }} >
                                    <p>{language ? link.name_hi : link.name_en}</p>
                                </a>
                            </div>
                        ))}
                        </div>
                    </div>
                    <div className='upper-box3 col-md-2'>
                        <p className='box3-pera-heading'>{language ? "महत्वपूर्ण लिंक" : "Important Links"}</p>
                        <p className='box3-pera-line'></p>
                        <div className='box3-pera-content'>
                            <div className='ft-box2-lines' onClick={() => navigate("/signup")}> <img src={login} alt="" />  <p> {language ? "रेजिस्ट्रेशन" : "Registration"}</p></div>
                            <div className='ft-box2-lines' onClick={() => navigate("/login")}> <img src={register} alt="" /><p> {language ? "लॉग इन " : "Login"}</p></div>
                            {
                                homePageContent?.new_header_menu
                                ?.filter((item) => item.status === "1") // Filter items with status 1
                                .map((item) =>
                                    item.url_type === "2" ? ( // Check if it's an external link
                                        <a
                                            key={item.id}
                                            href={item.url}
                                            target="_blank"
                                            className='ft-box2-lines'
                                            rel="noopener noreferrer"
                                            style={{
                                                textDecoration: "none",
                                            }}
                                        >
                                            <img src={`${Baseurl()}/${item?.image}`} alt="" />
                                            <p>{language ? item?.name_hi : item?.name_en}</p>
                                        </a>
                                    ) : (
                                        <div
                                            className='ft-box2-lines'
                                            key={item.id}
                                            onClick={() =>
                                                navigate(`/${item?.url}`, {
                                                    state: {
                                                        content_en: item?.content_en,
                                                        content_hi: item?.content_hi,
                                                        page_id: item?.id,
                                                        title_en: item?.name_en,
                                                        title_hi: item?.name_hi,
                                                    },
                                                })
                                            }
                                        >
                                            <img src={`${Baseurl()}/${item?.image}`} alt="" />
                                            <p>{language ? item?.name_hi : item?.name_en}</p>
                                        </div>
                                    )
                                )
                            }
                            {
                                homePageContent?.top_footer_link
                                ?.filter((item) => item.status === "1") // Filter items with status 1
                                .map((item) =>
                                    item.url_type === "2" ? ( // Check if it's an external link
                                        <a
                                            key={item.id}
                                            href={item.url}
                                            target="_blank"
                                            className='ft-box2-lines'
                                            rel="noopener noreferrer"
                                            style={{
                                                textDecoration: "none",
                                            }}
                                        >
                                            <img src={`${Baseurl()}/${item?.image}`} alt="" />
                                            <p>{language ? item?.name_hi : item?.name_en}</p>
                                        </a>
                                    ) : (
                                        <div
                                            className='ft-box2-lines'
                                            key={item.id}
                                            onClick={() =>
                                                navigate(`/${item?.url}`, {
                                                    state: {
                                                        content_en: item?.content_en,
                                                        content_hi: item?.content_hi,
                                                        page_id: item?.id,
                                                        title_en: item?.name_en,
                                                        title_hi: item?.name_hi,
                                                    },
                                                })
                                            }
                                        >
                                            <img src={`${Baseurl()}/${item?.image}`} alt="" />
                                            <p>{language ? item?.name_hi : item?.name_en}</p>
                                        </div>
                                    )
                                )
                            }
                            {/* <div className='ft-box2-lines' onClick={() => navigate("/privacy-policy")}><img src={transferpolicy} alt="" /><p>{language ? "गोपनीयता नीति" : "Privacy Policy"}</p></div> */}
                            <div className='ft-box2-lines' onClick={() => navigate("/contact-us")}><img src={phone} alt="" /><p>{language ? "संपर्क करें" : "Contact Us"}</p></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ft-lower">
                <div className='container'>
                    <div className='ft-lower-box1'>
                        <p>{language ? homePageContent?.footer_desc.find((d) => d.type === "2")?.content_hi ?? '' : homePageContent?.footer_desc.find((d) => d.type === "2")?.content_en ?? ''}</p>
                    </div>
                    <div className='ft-lower-box2-p3'>
                        <div className='total-visitors'>
                            <div className='visitor-heading'>
                            <span>{language ? "कुल आगंतुक" : "Total Visitors"}</span>
                            </div>
                            <div className='visitors-count'>
                                {visitorCountData?.visitor_count ?? 0}
                            </div>
                        </div>
                        {visitorCountData?.ip_address ? <div className='ip-tracking'>
                            <div className='ip-heading'>
                                <span>{language ? "आईपी ​​ट्रैकिंग" : "IP Tracking"}</span>
                            </div>
                            <div className='visitors-count'>
                              {visitorCountData?.ip_address}
                            </div>
                        </div> : <></>}
                       </div>
                    <div className='ft-lower-box2'>
                        <div className='ft-lower-box2-p1'><p>{language ? homePageContent?.footer_desc.find((d) => d.type === "3")?.content_hi ?? '' : homePageContent?.footer_desc.find((d) => d.type === "3")?.content_en ?? ''}</p></div>
                
                       
                        <div className='ft-lower-box2-p2'>
                            {
                                homePageContent?.bottom_footer_link
                                ?.filter((item) => item.status === "1") // Filter items with status 1
                                .map((item) =>
                                    item?.url_type === "2" ? ( // Check if it's an external link
                                        <a
                                            key={item.id}
                                            href={item.url}
                                            target="_blank"
                                            style={{
                                                textDecoration: "none",
                                            }}
                                        >
                                            {language ? item?.name_hi : item?.name_en}
                                        </a>
                                    ) : (
                                        <p
                                            key={item.id}
                                            onClick={() =>
                                                navigate(`/${item?.url}`, {
                                                    state: {
                                                        content_en: item?.content_en,
                                                        content_hi: item?.content_hi,
                                                        page_id: item?.id,
                                                        title_en: item?.name_en,
                                                        title_hi: item?.name_hi,
                                                    },
                                                })
                                            }
                                        >
                                            {language ? item?.name_hi : item?.name_en}
                                        </p>
                                    )
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div> : '' } </>
    )
}
export default Footer