/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import "../Admin.css";
import { DeleteIcon, EditIcon } from "../AdminIcon";
import { Modal, Table } from "react-bootstrap";
import { Imageurl } from "../../../Utils/Utils";
import AdminDashboard from "../AdminDashboard/AdminDashboard";
import { useLocation, useNavigate } from "react-router-dom";
import { HiPlus } from "react-icons/hi";
import { HomeIcon } from "../../../components/Application/ApplicationIcon";
import {
  deleteCategoryData,
  getCategoriesDropdown,
  getSubCategory,
  getSubCategoryListDataSort,
  handleEnablecategoryDisableData,
  subCategoriesListCsv,
  subCategoriesListExcel,
  subCategoriesListPdf,
  subCategoryDataEnableDisable,
} from "../../../services/api/admin";
import ReactPaginate from "react-paginate";
import { Message } from "../../../components/message";
import {
  customStylesAcc,
  formatDateWithSlash,
  printData,
  selectTheme,
} from "../../../Utils/common";
import Select from "react-select";
import NotFoundTable from "../../../components/notFound/NotFoundTable";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  checkPermission,
  permissionsConfig,
} from "../AdminPermission/PermissionChecker";

const SubCategory = () => {
  const navigate = useNavigate();
  const [searchCategory, setSearchCategory] = useState();
  const [searchData, setSearchData] = useState("");
  const [sortBy, setSortBy] = useState("created_at");
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [tableDataLoaded, setTableDataLoaded] = useState(false);
  const [apiMessage, setApiMessage] = useState({ type: "", message: "" });
  const [deleteData, setDeleteData] = useState();
  const [orderBy, setOrderBy] = useState("desc");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const { state } = useLocation();
  const [searchTerm, setSearchTerm] = useState()

  useEffect(() => {
    if (state && state.message) {
      setApiMessage({ type: "success", message: state.message });
      window.history.replaceState({}, document.title);
    }
  }, [state]);

  const grantPermissionForAddSubCategories = checkPermission(
    permissionsConfig.addSubCategory.role,
    permissionsConfig.addSubCategory.action,
    permissionsConfig.addSubCategory.type
  );
  const grantPermissionForEditSubCategories = checkPermission(
    permissionsConfig.editSubCategory.role,
    permissionsConfig.editSubCategory.action,
    permissionsConfig.editSubCategory.type
  );

  //category list
  const getCategoryList = async () => {
    try {
      const categoryList = await getCategoriesDropdown();
      const data = categoryList?.data?.map((item) => ({
        value: item?.id,
        label: item?.category_name,
      }));
      if (categoryList?.status == 200) {
        setCategoryList(data);
      }
    } catch (err) {
      console.log("error on sub Category list: ", err);
    }
  };

  const resetFilter = () => {
    setSearchCategory("");
    getSubCategoryData("", sortBy, 1, orderBy, searchData);
  };

  // sub category list
  const getSubCategoryData = async (
    searchedCategory,
    sortBy,
    pageNo,
    orderBy,
    searchBy
  ) => {
    try {
      setSearchData(searchBy);
      setOrderBy(orderBy);
      setSortBy(sortBy);
      const subCategoryList = await getSubCategoryListDataSort(
        sortBy,
        orderBy,
        searchBy,
        pageNo,
        searchedCategory?.value ? searchedCategory?.value : ""
      );
      //const subCategoryList = await getSubCategory(currentPage)
      if (subCategoryList?.status == 200) {
        setTotalPages(
          Math.ceil(
            subCategoryList?.data?.total / subCategoryList?.data?.per_page
          )
        );
        setCurrentPage(pageNo > 0 ? pageNo - 1 : pageNo);
        const data = subCategoryList?.data?.data;
        setSubCategoryList(data);
      } else {
        setSubCategoryList([]);
        setApiMessage({ type: "error", message: subCategoryList?.message });
      }
      setTableDataLoaded(true);
    } catch (err) {
      console.log("Error on subCategorylist: ", err);
    }
  };

  const handlePageChange = (selectedPage) => {
    getSubCategoryData(
      searchCategory,
      sortBy,
      selectedPage.selected + 1,
      orderBy,
      searchData
    ); // Increment by 1 if the API starts page numbers from 1
  };

  //get font families
  useEffect(() => {
    getCategoryList();
    getSubCategoryData("", "created_at", 1, "desc", "");
  }, []);

  // delete sub-Category
  const deleteSubCategory = async (item) => {
    try {
      const formData = new FormData();
      formData.append("parent_category_id", item?.parent_category_id);
      formData.append("category_id", item?.id);
      await deleteCategoryData(formData);
      getSubCategoryData(
        searchCategory,
        sortBy,
        currentPage,
        orderBy,
        searchData
      );
      setDeleteData("");
    } catch (error) {
      console.error(error);
    }
  };

  const handleEnableDisable = async (e, item) => {
    try {
      const formData = new FormData();
      formData.append("category_id", item?.id);
      formData.append("status", e?.value);
      const data = await subCategoryDataEnableDisable(formData); // api same for cat and sub cat
      if (data?.status == 200) {
        getSubCategoryData(
          searchCategory,
          sortBy,
          currentPage + 1,
          orderBy,
          searchData
        );
      } else {
        setApiMessage({ type: "error", message: data?.message });
      }
    } catch (error) {
      console.error(error);
      setApiMessage({ type: "error", message: error?.message });
    }
  };

  const pdfDownload = async () => {
        try {
            const response = await subCategoriesListPdf()
            window.open(response?.file_path);
        } catch (err) {
            setApiMessage({ type: "error", message: err?.message })
        }
    }

  const excelDownload = async () => {
      try {
        const response = await subCategoriesListExcel()
        window.open(response?.file_path);
      } catch (err) {
        setApiMessage({ type: "error", message: err?.message })
      }
    }
  
    const csvDownload = async () => {
      try {
        const response = await subCategoriesListCsv()
        window.open(response?.file_path);
      } catch (err) {
        setApiMessage({ type: "error", message: err?.message })
      }
    }
  
  const exportPrint = async () => {
      try {
        // Fetch the PDF URL from the API
        const response = await subCategoriesListPdf();
        const pdfUrl = response?.file_path;
          
              if (!pdfUrl) {
                setApiMessage({ type: "error", message: "Error getting printing data"})
                return
              }
          
              // Fetch the PDF file as a Blob
              const res = await fetch(pdfUrl);
              if (!res.ok) {
                setApiMessage({ type: "error", message: "Error getting printing data"})
                return
              }
              const pdfBlob = await res.blob();
          
              printData(pdfBlob)
      } catch (err) {
        setApiMessage({ type: "error", message: err?.message });
      }
    }

  return (
    <AdminDashboard>
      <div className="aod-outer">
        {apiMessage && (
          <Message
            apiMessage={apiMessage}
            setApiMessage={setApiMessage}
          ></Message>
        )}
        <div className="ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb">
          <div>
            <HomeIcon />
            <b onClick={() => navigate("/admin/")}>Home /</b>{" "}
            <span>Sub Categories</span>
          </div>
          <div class="dropdown download_file_dropdown ms-auto d-flex gap-2 mb-3 flex-wrap align-items-center">
            <a class=" px-2 btn btn-danger btn-sm" onClick={() => pdfDownload()}> <i class="fa fa-file-pdf me-1 "></i> PDF</a>
            <a class=" px-2 btn btn-success btn-sm" onClick={() => excelDownload()}><i class="fa fa-file-excel  me-1"></i>
              Excel </a>
            {/* <a class=" px-2 btn btn-primary btn-sm" onClick={() => csvDownload()}><i class="fa fa-file-csv  me-1"></i>
              CSV</a> */}
            <a class=" px-2 btn btn-info  btn-sm" onClick={() => exportPrint()}><i class="fa fa-print me-1"></i>
              Print</a>
          </div>
        </div>
        <div className='admin-add-btn mb-3 d-flex justify-content-end'>
          {grantPermissionForAddSubCategories ? (
            <button
              className="adm-tab-btn"
              onClick={() => navigate("/admin/sub-categories/add")}
            >
              <span>
                <HiPlus />
              </span>{" "}
              Add
            </button>
          ) : (
            ""
          )}
        </div>
        <div className="aod-inner pt-0">
          <div className="aod-bottom">
            <div className="adm-tabs-outer d-flex justify-content-between">
              <h4 className="mb-0">Sub Categories</h4>{" "}
              <div className="position-relative  w-md-50 w-25">
                <input
                  className="px-2 form-control"
                  placeholder="Search"
                  type="text"
                  value={searchTerm} // Add a state for the input value
                  onChange={(e) => {
                    setSearchTerm(e.target.value); // Update the state
                    getSubCategoryData(searchCategory, sortBy, 1, orderBy, e.target.value)
                  }}
                />
                {searchTerm && ( // Show the clear button only when there's text
                  <button
                    className="btn btn-clear position-absolute"
                    onClick={(e) => {
                      setSearchTerm(""); // Clear the input value
                      getSubCategoryData(searchCategory, sortBy, 1, orderBy, e.target.value); // Fetch data with empty search term
                    }}
                    style={{
                      top: "50%",
                      right: "10px",
                      transform: "translateY(-50%)",
                      border: "none",
                      background: "transparent",
                      fontSize: "1rem",
                      cursor: "pointer",
                    }}
                  >
                    &times; {/* This is the "X" icon */}
                  </button>
                )}
              </div>
            </div>
            <div className="aod-head"></div>
            <div className="row py-4">
              <div className="col-md-4 aod-resp-tab">
                <div className="suf-input-box aod-form-input mb-2">
                  <Select
                    styles={customStylesAcc}
                    theme={selectTheme}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    className="menu_open_select w-100"
                    classNamePrefix="ad-menu_open_select_class"
                    aria-label="Default select example"
                    value={searchCategory}
                    options={categoryList}
                    onChange={(e) => {
                      setSearchCategory(e);
                      getSubCategoryData(e, sortBy, 1, orderBy, searchData);
                    }}
                    placeholder="Select Category"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <button
                  onClick={resetFilter}
                  className="ad-reset-btn w-auto px-4"
                >
                  Reset
                </button>
              </div>
            </div>
            <div className="ad-reg-table">
              <Table size="sm" className="ad-cat-table">
                <thead>
                  <tr>
                    <th className="ad-sno">S. No</th>
                    <th className="ad-long">Image</th>
                    <th
                      className="ad-long cursor-pointer"
                      onClick={(e) =>
                        getSubCategoryData(
                          searchCategory,
                          "category_name",
                          currentPage + 1,
                          orderBy == "asc" ? "desc" : "asc",
                          searchData
                        )
                      }
                    >
                      English Name{" "}
                      <span>
                        <i className="fa-solid fa-sort"></i>
                      </span>
                    </th>
                    <th
                      className="ad-long cursor-pointer"
                      onClick={(e) =>
                        getSubCategoryData(
                          searchCategory,
                          "category_name_hi",
                          currentPage + 1,
                          orderBy == "asc" ? "desc" : "asc",
                          searchData
                        )
                      }
                    >
                      Hindi Name{" "}
                      <span>
                        <i className="fa-solid fa-sort"></i>
                      </span>
                    </th>
                    <th
                      className="ad-long cursor-pointer"
                      onClick={(e) =>
                        getSubCategoryData(
                          searchCategory,
                          "parent_category_id",
                          currentPage + 1,
                          orderBy == "asc" ? "desc" : "asc",
                          searchData
                        )
                      }
                    >
                      Category{" "}
                      <span>
                        <i className="fa-solid fa-sort"></i>
                      </span>
                    </th>
                    <th
                      className="ad-long cursor-pointer"
                      onClick={(e) =>
                        getSubCategoryData(
                          searchCategory,
                          "category_description",
                          currentPage + 1,
                          orderBy == "asc" ? "desc" : "asc",
                          searchData
                        )
                      }
                    >
                      Description{" "}
                      <span>
                        <i className="fa-solid fa-sort"></i>
                      </span>
                    </th>
                    <th
                      className="ad-long cursor-pointer"
                      onClick={(e) =>
                        getSubCategoryData(
                          searchCategory,
                          "created_at",
                          currentPage + 1,
                          orderBy == "asc" ? "desc" : "asc",
                          searchData
                        )
                      }
                    >
                      Created At{" "}
                      <span>
                        <i className="fa-solid fa-sort"></i>
                      </span>
                    </th>
                    {grantPermissionForEditSubCategories ? (
                      <>
                        <th className="ad-long">Status</th>
                        <th className="ad-long">Actions</th>
                      </>
                    ) : (
                      ""
                    )}
                  </tr>
                </thead>
                <tbody>
                  {subCategoryList?.length > 0 ? (
                    subCategoryList?.map((item, index) => (
                      <tr
                        key={index}
                        className={index % 2 != 0 ? "adm-even" : ""}
                      >
                        <td>{currentPage * 10 + (index + 1)}</td>
                        <td>
                          {item?.category_image ? (
                            <img
                              className="ad-cat-td-img sub-cat-img"
                              src={`${Imageurl()}/${item?.category_image}`}
                              alt=""
                            />
                          ) : (
                            "--"
                          )}
                        </td>
                        <td>
                          {item?.category_name ? item?.category_name : "--"}
                        </td>
                        <td>
                          {item?.category_name_hi
                            ? item?.category_name_hi
                            : "--"}
                        </td>
                        <td>
                          {
                            categoryList?.find(
                              (i) => i.value == item?.parent_category_id
                            )?.label
                          }
                        </td>
                        {/* <td>{item?.category_description ? item?.category_description : "--"}</td> */}
                        <td>
                          {item?.category_description
                            ? item.category_description.length > 5
                              ? item.category_description.substring(0, 18) +
                                "..."
                              : item.category_description
                            : "--"}
                        </td>
                        <td>
                          {item?.created_at
                            ? formatDateWithSlash(item?.created_at)
                            : "--"}
                        </td>
                        {grantPermissionForEditSubCategories ? (
                          <>
                            <td>
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  checked={item?.status == 1}
                                  onChange={(e) =>
                                    handleEnableDisable(
                                      e.target.checked
                                        ? { value: "1" }
                                        : { value: "0" },
                                      item
                                    )
                                  }
                                />
                                <span className="slider"></span>
                              </label>
                            </td>

                            <td>
                              <div className="action-btn d-flex">
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`edit-tooltip-${item.id}`}>
                                      Edit
                                    </Tooltip>
                                  }
                                >
                                  <span
                                    className="ad-cat-edit"
                                    onClick={() => {
                                      navigate("/admin/sub-categories/update", {
                                        state: item,
                                      });
                                    }}
                                  >
                                    <EditIcon />
                                  </span>
                                </OverlayTrigger>

                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`delete-tooltip-${item.id}`}>
                                      Delete
                                    </Tooltip>
                                  }
                                >
                                  <span
                                    className="ad-cat-delete d-none"
                                    onClick={() => setDeleteData(item)}
                                  >
                                    <DeleteIcon />
                                  </span>
                                </OverlayTrigger>
                              </div>
                            </td>
                          </>
                        ) : (
                          ""
                        )}
                      </tr>
                    ))
                  ) : tableDataLoaded ? (
                    <NotFoundTable colSpan="9"></NotFoundTable>
                  ) : (
                    <></>
                  )}
                </tbody>
              </Table>
              {totalPages > 1 && subCategoryList?.length > 0 &&
                <div className="d-flex w-100 justify-content-end">
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    pageCount={totalPages} // Total number of pages from API
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={1}
                    onPageChange={handlePageChange} // Method to handle page click
                    forcePage={currentPage}
                    containerClassName={"pagination justify-content-center"} // Custom CSS classes
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={`page-item${!subCategoryList?.length > 0 ? ' disabled' : ''}`}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>

              }
            </div>
          </div>
        </div>
      </div>
      <Modal show={deleteData?.id} onHide={() => setDeleteData("")}>
        <Modal.Header closeButton>
          <Modal.Title>
            Do you really want to delete sub this Category ?
          </Modal.Title>
        </Modal.Header>
        {/* <Modal.Body></Modal.Body> */}
        <Modal.Footer>
          <div className="suf-btn p-2 d-flex flex-row">
            <button
              className="mx-2"
              onClick={() => {
                deleteSubCategory(deleteData);
              }}
            >
              Yes
            </button>
            <button
              className="suf-can-butt no-btn mx-2"
              onClick={() => setDeleteData("")}
            >
              No
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </AdminDashboard>
  );
};

export default SubCategory;
