import apiInstance from "./config";

export const getTabs = async (payload) => {
  return await apiInstance
    .post("/categories/tabs/list", payload)
    .then((response) => response)
    .catch((err) => err);
};

export const getSection = async (payload) => {
  return await apiInstance
    .post("/categories/tabs/sections", payload)
    .then((response) => response)
    .catch((err) => err);
};

export const changeAdminPasswordAPI = async (payload) => {
  return await apiInstance
    .post("/admin/change-password", payload)
    .then((response) => response)
    .catch((err) => err);
};

export const getApplicationForm = async (payload) => {
  return await apiInstance
    .post("/sub-categories/district/application/details", payload)
    .then((response) => response)
    .catch((err) => err);
};

export const createApplicationForm = async (payload) => {
  return await apiInstance
    .post("/sub-categories/district/application/create", payload)
    .then((response) => response)
    .catch((err) => err);
};

export const changeApplicationStatus = async (payload) => {
  return await apiInstance
    .post("/sub-categories/applications/status/change", payload)
    .then((response) => response)
    .catch((err) => err);
};
export const downloadApplicationPDFAPI = async (payload) => {
  return await apiInstance
    .post("/application/pdf", payload)
    .then((response) => response)
    .catch((err) => err);
};

export const getSectionFields = async (payload) => {
  return await apiInstance
    .post("/categories/tabs/sections/fields", payload)
    .then((response) => response)
    .catch((err) => err);
};

export const getRefreshToken = async () => {
  return await apiInstance
    .get("/refresh-token")
    .then((response) => response)
    .catch((err) => err);
};

export const userLogout = async () => {
  return await apiInstance
    .post("/logout")
    .then((response) => response)
    .catch((err) => err);
};

export const staticDropDownsList = async (payload) => {
  return await apiInstance
    .post("/dropdown/static-values", payload)
    .then((response) => response)
    .catch((err) => err);
};

export const dropDownsList = async (payload) => {
  return await apiInstance
    .post("/application/dropdown/get-details", payload)
    .then((response) => response)
    .catch((err) => err);
};

export const subSectionData = async (payload) => {
  return await apiInstance
    .post("/application/sub-sections/details", payload)
    .then((response) => response)
    .catch((err) => err);
};

export const empDesignationData = async (payload) => {
  return await apiInstance
    .post(`/employee/designations/list`, payload)
    .then((response) => response)
    .catch((err) => err);
};

export const uploadDocumentlistData = async (payload) => {
  return await apiInstance
    .post(`/sub-categories/documents`, payload)
    .then((response) => response)
    .catch((err) => err);
};

export const handleDeleteDocumentAPI = async (payload) => {
  return await apiInstance
    .post(`/application/document/delete`, payload)
    .then((response) => response)
    .catch((err) => err);
};

export const toUploadFile = async (payload) => {
  return await apiInstance
    .post(`/application/document/upload`, payload)
    .then((response) => response)
    .catch((err) => err);
};

export const changePasswordAPI = async (payload) => {
  return await apiInstance
    .post("/change-password", payload)
    .then((response) => response)
    .catch((err) => err);
};

export const updateUserProfileAPI = async (payload) => {
  return await apiInstance
    .post("/users/profile/update", payload)
    .then((response) => response)
    .catch((err) => err);
};

export const getUserById = async (payload) => {
  return await apiInstance
    .post("users/get-details", payload)
    .then((response) => response)
    .catch((err) => err);
};

export const updateUserEmailMobileNumberAPI = async (payload) => {
  return await apiInstance
    .post("/users/update-phone-email-request", payload)
    .then((response) => response)
    .catch((err) => err);
};

export const getUserEmailMobileNumberAPI = async (payload) => {
  return await apiInstance
    .post("/users/update-phone-email-requests", payload)
    .then((response) => response)
    .catch((err) => err);
};

export const applicationGenerate = async (payload) => {
  return await apiInstance
    .post("/applications/generate", payload)
    .then((response) => response)
    .catch((err) => err);
};

export const checkApplicationData = async (payload) => {
  return await apiInstance
    .post("/applications/details", payload)
    .then((response) => response)
    .catch((err) => err);
};

export const checkApplicationsData = async (payload) => {
  return await apiInstance
    .post("/application/details", payload)
    .then((response) => response)
    .catch((err) => err);
};

export const applicationStatusChange = async (payload) => {
  return await apiInstance
    .post("sub-categories/applications/status/change", payload)
    .then((response) => response)
    .catch((err) => err);
};

export const trackApplicationData = async (payload) => {
  return await apiInstance
    .post("user/application/track", payload)
    .then((response) => response)
    .catch((err) => err);
};

export const toUploadFileAdmin = async (payload) => {
  return await apiInstance
    .post(`/admin/application/document/upload`, payload)
    .then((response) => response)
    .catch((err) => err);
}

export const getUserDashboardData = async (userId) => {
  return await apiInstance
    .get(`users/dashboard/data?&user_id=${userId}`)
    .then((response) => response)
    .catch((err) => err);
};

export const notificationDataUser = async (payload) => {
  return await apiInstance.post(`/user/notifications`, payload)
    .then(res => res)
    .catch(err => err);
}

export const sendNotificationDataUser = async (payload) => {
  return await apiInstance.post(`/user/notifications/store`, payload)
    .then(res => res)
    .catch(err => err);
}

export const notificationDataReadUser = async (payload) => {
  return await apiInstance.post(`/user/notifications/update-seen-time`, payload)
    .then(res => res)
    .catch(err => err);
}

export const latestNotificationCountUser = async (payload) => {
  return await apiInstance.post(`/user/notifications/latest`, payload)
    .then(res => res)
    .catch(err => err);
}

export const getApplicationFields = async (payload) => {
  return await apiInstance
    .post("/application-form/fields", payload)
    .then((response) => response)
    .catch((err) => err);
};


export const notificationDataAdmin = async (payload) => {
  return await apiInstance.post(`/admin/notifications`, payload)
    .then(res => res)
    .catch(err => err);
}

export const sendNotificationDataAdmin = async (payload) => {
  return await apiInstance.post(`/admin/notifications/store`, payload)
    .then(res => res)
    .catch(err => err);
}

export const notificationDataReadAdmin = async (payload) => {
  return await apiInstance.post(`/admin/notifications/update-seen-time`, payload)
    .then(res => res)
    .catch(err => err);
}

export const latestNotificationCountAdmin = async (payload) => {
  return await apiInstance.post(`/admin/notifications/latest`, payload)
    .then(res => res)
    .catch(err => err);
}

export const getDropdownList = async () => {
  return await apiInstance.get(`/fields/dropdown/all`)
    .then(res => res)
    .catch(err => err);
}

export const getStates = async () => {
  return await apiInstance.get('/states/all')
      .then(res => res)
      .catch(err => err);
}

export const getAllFooterContentlListData = async () => {
  return await apiInstance.get('/footer/content')
      .then(res => res)
      .catch(err => err);
}

export const getConfigurationData = async () => {
  return await apiInstance.get('/settings/configuration')
      .then(res => res)
      .catch(err => err);
}

export const getContactUsData =  async () => {
  return await apiInstance.get(`/contact-us/get-details`)
  .then(res => res)
  .catch(err => err);
}

export const getContactUsSectionList =  async () => {
  return await apiInstance.get(`/contact-us/sections/get-details`)
  .then(res => res)
  .catch(err => err);
}