import apiInstance from "./config";

export const login = async (payload) => {
    return await apiInstance.post('/login', payload)
        .then(response => response)
        .catch(err => err);
}

export const forgotPassword = async (updatedData) => {
    return await apiInstance.get('/forgot-password-form', updatedData)
        .then(res => res)
        .catch(err => err);
}

export const checkEmailMobile = async (formData) => {
    return await apiInstance.post('/is-email-mobileno-exists', formData)
        .then(reponse => reponse)
        .catch(err => err);
};

export const sendOtp = async (formData) => {
    return await apiInstance.post(`/forgot-password`, formData)
        .then(reponse => reponse)
        .catch(err => err);
};

export const sendMobileOtp = async (formData) => {
    return await apiInstance.post(`/sms-otp`, formData)
        .then(reponse => reponse)
        .catch(err => err);
};

export const sendSMSOtp = async (formData) => {
    return await apiInstance.post(`/sms-send`, formData)
        .then(reponse => reponse)
        .catch(err => err);
};

export const verifyOtp = async (data) => {
    return await apiInstance.post(`/verify-otp`, data)
        .then(reponse => reponse)
        .catch(err => err);
};

export const verifyBannerOtp = async (data) => {
    return await apiInstance.post(`/banner/verify-otp`, data)
        .then(reponse => reponse)
        .catch(err => err);
};

export const resetPassword = async (updatedData) => {
    return await apiInstance.get('reset-password-form', updatedData)
        .then(res => res)
        .catch(err => err);
}

export const reset_Password = async (data) => {
    return await apiInstance.post(`/reset-password`, data)
        .then(response => response)
        .catch(err => err);
};

export const registartionForm = async (updatedData) => {
    return await apiInstance.get('/register-form-fields', updatedData)
        .then(res => { return { data: res } })
        .catch(err => err);
}

export const states = async (data) => {
    return await apiInstance.get('/states', data)
        .then(res => { return { data: res } })
        .catch(err => err);
}

export const district = async (data) => {
    return await apiInstance.get('/districts', data)
        .then(res => { return { data: res } })
        .catch(err => err);
}

export const stateDistrict = async (data) => {
    return await apiInstance.get('/states/uttarakhand/districts', data)
        .then(res => { return { data: res } })
        .catch(err => err);
}

export const city = async (data) => {
    return await apiInstance.get('/cities', data)
        .then(res => { return { data: res } })
        .catch(err => err);
}

export const verifyMobile= async (data) => {
    return await apiInstance.post(`/verify-mobile`, data)
        .then(reponse => reponse)
        .catch(err => err);
};

export const verifyEmail= async (data) => {
    return await apiInstance.post(`/verify-email`, data)
        .then(reponse => reponse)
        .catch(err => err);
};

export const registerUser = async (formData) => {
    return await apiInstance.post(`/register`, formData)
        .then(response => {console.log(response); return response})
        .catch(err => err);
};


export const getLoginFormField = async () => {
    return await apiInstance.get('/login-form')
        .then(res => res)
        .catch(err => err);
}

export const getMobileExistence = async (payload) => {
    return await apiInstance.post('/is-email-mobileno-exists', payload)
        .then(res => res.data)
        .catch(err => err);
}

export const clearCache = async () => {
    return await apiInstance.get('/clear-cache')
        .then(res => res)
        .catch(err => err);
}
export const getApplicationFees = async (payload) => {
    return await apiInstance.post(`/categories/fees/get-details`, payload)
        .then(res => res)
        .catch(err => err);
  }
  
export const ApplicationStatus = async (payload) => {
    return await apiInstance.post('/application/status', payload)
        .then(response => response)
        .catch(err => err);
}

export const certificateView = async (payload) => {
    return await apiInstance.post('/certificate/by-application-number', payload)
        .then(response => response)
        .catch(err => err);
}

