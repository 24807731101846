import React, { useEffect, useState } from "react";
import AdminDashboard from "../AdminDashboard/AdminDashboard";
import { useNavigate } from "react-router-dom";
import { Message } from "../../../components/message";
import {
  getConfigurationData,
  providerList,
  updateConfigurationData,
} from "../../../services/api/admin";
import {
  customStylesAcc,
  selectTheme,
} from "../../../Utils/common";
import Select from "react-select";
import { CgComment, CgMail } from "react-icons/cg";
import { BsEye, BsEyeSlash } from "react-icons/bs";

const Configuration = () => {
  const navigate = useNavigate();
  const [apiMessage, setApiMessage] = useState({ type: "", message: "" });
  const [selectedModuleIndex, setSelectedModuleIndex] = useState("1");
  const [templateList, setTemplateList] = useState([
    { label: "SMS Configuration", type: "sms", value: "1" },
    { label: "Email Configuration", type: "email", value: "2" },
  ]);
  const [smsformData, setSMSFormData] = useState({
    sms_provider_id: null,
    sms_from: null,
    sms_api_key: null,
    sms_api_secret: null,
  });
  const [emailformData, setEmailFormData] = useState({
    mail_type: null,
    mail_driver: null,
    mail_host: null,
    mail_port: null,
    mail_username: null,
    mail_password: null,
    mail_encryption: null,
    mail_from: null,
  });
  const [smsProviderListData, setSmsProviderListData] = useState();
  const [smsProviderSelectErr, setSmsProviderSelectErr] = useState();
  const [configData, setConfigData] = useState();
  const [showHideSMSOtp, setShowHideSMSOtp] = useState(false);
  const [mailPasswordVisible, setMailPasswordVisible] = useState(false);

  const [err, setErr] = useState({});

  useEffect(() => {
    smsDataByLabel(templateList[0]);
    smsProviderList();
  }, []);

  const smsDataByLabel = async (item) => {
    try {
      const filterType = item != undefined ? item : templateList[0];
      const dataList = await getConfigurationData();
      const labelData = dataList?.data?.find((x) => x.type == filterType?.type);
      setConfigData(labelData);
      if (filterType?.type === "sms") {
        setSMSFormData({
          sms_provider_id: labelData?.config_data?.sms_provider_id,
          sms_from: labelData?.config_data?.sms_from,
          sms_api_key: labelData?.config_data?.sms_api_key,
          sms_api_secret: labelData?.config_data?.sms_api_secret,
        });
        setShowHideSMSOtp(labelData?.status == 1 ? true : false);
      } else if (filterType?.type === "email") {
        setEmailFormData({
          mail_type: labelData?.config_data?.mail_type,
          mail_driver: labelData?.config_data?.mail_driver,
          mail_host: labelData?.config_data?.mail_host,
          mail_port: labelData?.config_data?.mail_port,
          mail_username: labelData?.config_data?.mail_username,
          mail_password: labelData?.config_data?.mail_password,
          mail_encryption: labelData?.config_data?.mail_encryption,
          mail_from: labelData?.config_data?.mail_from,
        });
      }
    } catch (err) {
      setApiMessage({ type: "error", message: err?.message });
    }
  };

  function formatKey(key) {
    return key
      .replace(/_/g, ' ') // Replace underscores with spaces
      .toLowerCase() // Convert to lowercase for uniformity
      .replace(/\b\w/g, (char) => char.toUpperCase()) // Capitalize first letter of each word
      .trim(); // Remove extra spaces if any
  }

  const smsProviderList = async () => {
    try {
      const data = await providerList();
      const editData = data?.data?.map((i) => ({
        label: i?.name,
        value: i?.id,
      }));
      setSmsProviderListData(editData);
    } catch (err) {
      setApiMessage({ type: "error", message: err?.message });
    }
  };

  const handleInput = async (e) => {
    const { name, value } = e.target;
    const cursorPosition = e.target.selectionStart;

    // Update form data
    if (configData.type === "sms") {
      setSMSFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else if (configData.type === "email") {
      setEmailFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }

    // Restore cursor position after state update
    setTimeout(() => {
      if (e.target) {
        e.target.setSelectionRange(cursorPosition, cursorPosition);
      }
    }, 0);

    // Validate input
    validateForm(name, value);
  };

  const validateForm = (name, value) => {
    let formErr = { ...err };

    if (!value) {
      formErr[name] = `${formatKey(name)} is required`; // Assign error to the specific field
      setErr(formErr); // Update the error state
      return false;
    }

    // Clear the error for the specific field if validation passes
    formErr[name] = "";
    setErr(formErr); // Update the error state
    return true;
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const newErrors = {};
      if (configData.type === "sms") {
        Object.keys(smsformData).forEach((key) => {
          const value = smsformData[key] || ""; // Get the value of the field
          if (!value) {
            newErrors[key] = `${formatKey(key)} is required`;
          }
        });
      } else if (configData.type === "email") {
        Object.keys(emailformData).forEach((key) => {
          const value = emailformData[key] || ""; // Get the value of the field
          if (!value) {
            newErrors[key] = `${formatKey(key)} is required`;
          }
        });
      }

      setErr({ ...err, ...newErrors });
      const hasErrors = Object.values(newErrors).some(
        (error) => error !== null
      );
      if (hasErrors) {
        return;
      }

      const formData = new FormData();
      formData.append("id", configData?.id);
      formData.append("type", configData?.type);
      if (configData.type === "sms") {
        formData.append("config_data", JSON.stringify(smsformData));
        formData.append("status", showHideSMSOtp ? 1 : 0);
      } else if (configData.type === "email") {
        formData.append("config_data", JSON.stringify(emailformData));
        formData.append("status", 1);
      }

      const dataUpdate = await updateConfigurationData(formData);
      if (dataUpdate?.success) {
        smsDataByLabel(templateList.find((t) => t.type === configData.type));
        setApiMessage({ type: "success", message: dataUpdate?.data });
      } else {
        setApiMessage({ type: "error", message: dataUpdate?.message });
      }
    } catch (err) {
      setApiMessage({ type: "error", message: err?.message });
    }
  };

  return (
    <AdminDashboard>
      <div className="aod-outer">
        <div className="ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb">
          <div>
            <b onClick={() => navigate("/admin/")}>Home /</b>
            <span> Configuration</span>
          </div>
        </div>
        {apiMessage && (
          <Message
            apiMessage={apiMessage}
            setApiMessage={setApiMessage}
          ></Message>
        )}
        <div className="aod-inner">
          <div className="content-wrapper" id="sms-page">
            <div className="flex-grow-1 container-p-y">
              <h4 className="mb-4">Configuration</h4>
              <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-4">
                  <div className="card mb-4 border-0">
                    <h5 className="card-header">List</h5>
                    <ul className="template-list">
                      {templateList?.map((item, index) => (
                        <a>
                          <li
                            className={`odd ${
                              selectedModuleIndex === item.value ? "active" : ""
                            }`}
                            id={`v-pills-${item.label}-tab`}
                            aria-selected={selectedModuleIndex == item.value}
                            onClick={() => {
                              setSelectedModuleIndex(item.value);
                              smsDataByLabel(item);
                            }}
                          >
                            {item?.type === "sms" ? (
                              <CgComment className="me-2 fs-6"></CgComment>
                            ) : (
                              <CgMail className="me-2 fs-5"></CgMail>
                            )}{" "}
                            {item.label}
                          </li>
                        </a>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="col-xl-8 col-lg-8 col-md-8">
                  <div className="card mb-4 border-0">
                    {selectedModuleIndex == "1" ? (
                      <form className="card-body admin-role-form">
                        <div class="opt-main-wrapper">
                          <p class="form-top-subtext">
                            View and Update your SMS Gateway and It's API Keys
                          </p>
                          <div class="otp-inner-block mb-3">
                            <div class=" d-flex justify-content-between">
                              <div class="room-service">
                                <span class="toggle-lable">Show/Hide OTP</span>
                              </div>
                              <div class="toggle-block form-check form-switch ps-5">
                                <input
                                  class="form-check-input flexSwitchCheckChecked"
                                  id="showhideotp"
                                  type="checkbox"
                                  role="switch"
                                  name="showhideotp"
                                  checked={showHideSMSOtp}
                                  onChange={() => {
                                    setShowHideSMSOtp(!showHideSMSOtp);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <label className="form-label" for="Map-provider">
                            SMS Provider
                          </label>
                          <Select
                            styles={customStylesAcc}
                            theme={selectTheme}
                            components={{
                              IndicatorSeparator: () => null,
                            }}
                            className="menu_open_select"
                            classNamePrefix="ad-menu_open_select_class"
                            aria-label="Default select example"
                            value={smsProviderListData?.find(
                              (x) => x.value == smsformData?.sms_provider_id
                            )}
                            options={smsProviderListData}
                            onChange={(select) => {
                              setSMSFormData((prevData) => ({
                                ...prevData,
                                ["sms_provider_id"]: select?.value,
                              }));
                              setSmsProviderSelectErr("");
                            }}
                            placeholder="Select Sub Category"
                            onMenuClose={(select) => {
                              if (!smsformData?.sms_provider_id) {
                                setSmsProviderSelectErr("Select the provider");
                              } else {
                                setSmsProviderSelectErr("");
                              }
                            }}
                          />
                          {smsProviderSelectErr && (
                            <p className="validate-error">
                              {smsProviderSelectErr}
                            </p>
                          )}
                        </div>
                        <div className="col-md-12">
                          <label className="form-label" for="Map-provider">
                            SMS From
                          </label>
                          <input
                            type="text"
                            id="map-provider"
                            className="form-control"
                            maxLength={60}
                            placeholder=""
                            name="sms_from"
                            value={smsformData?.sms_from}
                            onChange={handleInput}
                          />
                          {err.sms_from && (
                            <p className="validate-error">{err.sms_from}</p>
                          )}
                        </div>
                        <div className="col-md-12">
                          <label className="form-label" for="Map-provider">
                            API Auth Key
                          </label>
                          <input
                            type="text"
                            id="map-provider"
                            className="form-control"
                            maxLength={60}
                            placeholder=""
                            name="sms_api_key"
                            value={smsformData?.sms_api_key}
                            onChange={handleInput}
                          />
                          {err.sms_api_key && (
                            <p className="validate-error">{err.sms_api_key}</p>
                          )}
                        </div>
                        <div className="col-md-12">
                          <label className="form-label" for="Map-provider">
                            API Secret
                          </label>
                          <input
                            type="text"
                            id="map-provider"
                            className="form-control"
                            maxLength={60}
                            placeholder=""
                            name="sms_api_secret"
                            value={smsformData?.sms_api_secret}
                            onChange={handleInput}
                          />
                          {err.sms_api_secret && (
                            <p className="validate-error">{err.sms_api_secret}</p>
                          )}
                        </div>
                        <div className="row ad-cat-row mt-3">
                          <div className=" update-btns-block">
                            <button
                              onClick={(e) => handleSubmit(e)}
                              className="me-3"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </form>
                    ) : (
                      <form className="card-body admin-role-form">
                        <div class="opt-main-wrapper">
                          <p class="form-top-subtext">
                            View and Update your Email Configurations
                          </p>
                        </div>
                        <div className="row col-md-12">
                          <div className="col-md-5">
                            <label className="form-label" for="Map-provider">
                              Mail Type
                            </label>
                            <input
                              type="text"
                              id="map-provider"
                              className="form-control"
                              maxLength={60}
                              placeholder=""
                              name="mail_type"
                              value={emailformData?.mail_type}
                              onChange={handleInput}
                            />
                            {err.mail_type && (
                              <p className="validate-error">{err.mail_type}</p>
                            )}
                          </div>
                          <div className="col-md-5">
                            <label className="form-label" for="Map-provider">
                              Mail Driver
                            </label>
                            <input
                              type="text"
                              id="map-provider"
                              className="form-control"
                              maxLength={60}
                              placeholder=""
                              name="mail_driver"
                              value={emailformData?.mail_driver}
                              onChange={handleInput}
                            />
                            {err.mail_driver && (
                              <p className="validate-error">{err.mail_driver}</p>
                            )}
                          </div>
                        </div>
                        <div className=" row col-md-12">
                          <div className="col-md-5">
                            <label className="form-label" for="Map-provider">
                              Mail Host
                            </label>
                            <input
                              type="text"
                              id="map-provider"
                              className="form-control"
                              maxLength={60}
                              placeholder=""
                              name="mail_host"
                              value={emailformData?.mail_host}
                              onChange={handleInput}
                            />
                            {err.mail_host && (
                              <p className="validate-error">{err.mail_host}</p>
                            )}
                          </div>
                          <div className="col-md-5">
                            <label className="form-label" for="Map-provider">
                              Mail Port
                            </label>
                            <input
                              type="number"
                              id="map-provider"
                              className="form-control"
                              maxLength={60}
                              placeholder=""
                              name="mail_port"
                              value={emailformData?.mail_port}
                              onChange={(e) => {
                                const { name, value } = e.target;
                                setEmailFormData((prevData) => ({
                                  ...prevData,
                                  [name]: value,
                                }));
                                validateForm(name, value);
                              }}
                            />
                            {err.mail_port && (
                              <p className="validate-error">{err.mail_port}</p>
                            )}
                          </div>
                        </div>
                        <div className="row col-md-12">
                          <div className="col-md-5">
                            <label className="form-label" for="Map-provider">
                              Mail Username
                            </label>
                            <input
                              type="text"
                              id="map-provider"
                              className="form-control"
                              maxLength={60}
                              placeholder=""
                              name="mail_username"
                              value={emailformData?.mail_username}
                              onChange={handleInput}
                            />
                            {err.mail_username && (
                              <p className="validate-error">
                                {err.mail_username}
                              </p>
                            )}
                          </div>

                          <div className="mb-3 col-md-5 f_fields pwd-fields">
                            <label className="form-label" for="Map-provider">
                              Mail Password
                            </label>
                            <input
                              type={mailPasswordVisible ? "text" : "password"}
                              id="map-provider"
                              className="form-control"
                              maxLength={60}
                              placeholder=""
                              name="mail_password"
                              value={emailformData?.mail_password}
                              onChange={handleInput}
                            />
                            {
                              mailPasswordVisible ?
                                <BsEye className="me-3" onClick={() => setMailPasswordVisible(!mailPasswordVisible)} /> :
                                <BsEyeSlash className="me-3" onClick={() => setMailPasswordVisible(!mailPasswordVisible)} />
                            }
                            {err.mail_password && (
                              <p className="validate-error">
                                {err.mail_password}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="row col-md-12">
                          <div className="col-md-5">
                            <label className="form-label" for="Map-provider">
                              Mail Encryption
                            </label>
                            <input
                              type="text"
                              id="map-provider"
                              className="form-control"
                              maxLength={60}
                              placeholder=""
                              name="mail_encryption"
                              value={emailformData?.mail_encryption}
                              onChange={handleInput}
                            />
                            {err.mail_encryption && (
                              <p className="validate-error">
                                {err.mail_encryption}
                              </p>
                            )}
                          </div>
                          <div className="col-md-5">
                            <label className="form-label" for="Map-provider">
                              Mail From
                            </label>
                            <input
                              type="text"
                              id="map-provider"
                              className="form-control"
                              maxLength={60}
                              placeholder=""
                              name="mail_from"
                              value={emailformData?.mail_from}
                              onChange={handleInput}
                            />
                            {err.mail_from && (
                              <p className="validate-error">{err.mail_from}</p>
                            )}
                          </div>
                        </div>
                        <div className="row ad-cat-row mt-3">
                          <div className=" update-btns-block">
                            <button
                              onClick={(e) => handleSubmit(e)}
                              className="me-3"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="content-backdrop fade"></div>
        </div>
      </div>
    </AdminDashboard>
  );
};

export default Configuration;
