import "./App.css";
import Home from "./pages/OuterPages/Home/Home";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Login from "./pages/Auth/Login/Login";
import SignUp from "./pages/Auth/SignUp/SignUp";
import ResetPassword from "./pages/Auth/ResetPassword/ResetPassword";
import ForgotPassword from "./pages/Auth/ForgotPassword/ForgotPassword";

import Guidelines from "./pages/Guidelines/Guidelines";
import Application from "./pages/Application/Application";
import DashboardCategory from "./pages/DashboardCategory/DashboardCategory";
import { useEffect } from "react";
import {
  Apiurl,
  GetLocalStorage,
  GetLocalStorageParsed,
  SetLocalStorage,
  SetLocalStorageStringify,
} from "./Utils/Utils";
import axios from "axios";
import CategoryInfo from "./pages/CategoryInfo/CategoryInfo";
import Category from "./pages/Admin/Category/Category";
import SubCategory from "./pages/Admin/SubCategory/SubCategory";
import DashboardLayout from "./pages/Admin/DashboardLayout/DashboardLayout";
import RegistrationForm from "./pages/Admin/RegistrationForm/RegistrationForm";
import ApplicationTabs from "./pages/Admin/ApplicationTabs/ApplicationTabs";
import ApplicationSections from "./pages/Admin/ApplicationSections/ApplicationSections";
import FormFields from "./pages/Admin/FormFields/FormFields";
import Users from "./pages/Admin/Users/Users";
import { LanguageProvider } from "./components/LanguageProvider/LanguageProvider ";
import AdminLogin from "./pages/Admin/AdminLogin/AdminLogin";
import AdminResetPassword from "./pages/Admin/AdminResetPassword/AdminResetPassword";
import AdminForgotPassword from "./pages/Admin/AdminForgotPassword/AdminForgotPassword";
import RegistrationFormAdd from "./pages/Admin/RegistrationForm/RegistrationFormAdd";
import AdminLoginAdd from "./pages/Admin/AdminLogin/AdminLoginAdd";
import AdminResetPasswordAdd from "./pages/Admin/AdminResetPassword/AdminResetPasswordAdd";
import AdminForgotPasswordAdd from "./pages/Admin/AdminForgotPassword/AdminForgotPasswordAdd";
import CategoryAdd from "./pages/Admin/Category/CategoryAdd";
import SubCategoryAdd from "./pages/Admin/SubCategory/SubCategoryAdd";
import AdminGuidelines from "./pages/Admin/AdminGuidelines/AdminGuidelines";
import AdminGuidelineslist from "./pages/Admin/AdminGuidelines/AdminGuidelineslist";
import AddRoles from "./pages/Admin/userRoles/AddRoles";
import RolesList from "./pages/Admin/userRoles/RolesList";
import UserPermissions from "./pages/Admin/userRoles/user-permissions";
import AdminUsers from "./pages/Admin/AdminUsers/AdminUsers";
import UserPermissionList from "./pages/Admin/userRoles/userPermissionList";
import ViewData from "./components/viewData/viewData";
import States from "./pages/Admin/SettingsTabs/States";
import Districts from "./pages/Admin/SettingsTabs/Districts";
import Cities from "./pages/Admin/SettingsTabs/Cities";
import AddAdminUser from "./pages/Admin/AdminUsers/AddAdminUser";
import AdminCategoryFaq from "./pages/Admin/AdminCategoryFaq/AdminCategoryFaq";
import AddAdminCategoryFaqs from "./pages/Admin/AdminCategoryFaq/AddAdminCategoryFaqs";
import EmployeeDesignation from "./pages/Admin/Employee Designation/EmployeeDesignation";
import AddEmployeeDesignation from "./pages/Admin/Employee Designation/AddEmployeeDesignation";
import TotalApplication from "./pages/Admin/Application/TotalApplication";
import PendingApplication from "./pages/Admin/Application/PendingApplication";
import RejectedApplications from "./pages/Admin/Application/RejectedApplications";
import ApprovedApplications from "./pages/Admin/Application/ApprovedApplications";
import RenewalApplications from "./pages/Admin/Application/RenewalApplications";
import AddDropDown from "./pages/Admin/dropDown/AddDropDown";
import DropDownList from "./pages/Admin/dropDown/DropDownList";
import SmsSettingPage from "./pages/Admin/setting Page/SmsSettingPage";
import EmailSettingPage from "./pages/Admin/setting Page/EmailSettingPage";
// import UserLog from "./pages/Admin/user-log/UserLog";
import AddAdminSubSection from "./pages/Admin/AdminSubSection/AddAdminSubSection";
import AdminSubSectionList from "./pages/Admin/AdminSubSection/AdminSubSectionList";
import NotFound from "./components/notFound/notFound";
import PermissionDenied from "./components/notFound/PermissionDenied";
import UploadDocument from "./pages/Admin/uploadDocument/UploadDocument";
import AddUploadDocument from "./pages/Admin/uploadDocument/AddUploadDocument";
import ChangePassword from "./components/User/ChangePassword";
import EditProfile from "./components/User/EditProfile";
import ChangeRequest from "./components/User/ChangeRequest";
import { getRefreshToken } from "./services/api/user";
import {
  checkPermission,
  permissionsConfig,
} from "./pages/Admin/AdminPermission/PermissionChecker";
import MobileEmailVerification from "./pages/Admin/SettingsTabs/MobileEmailVerfication";
import Preview from "./components/Application/preview";
import ComingSoon from "./components/ComingSoon/ComingSoon";
import ApplicationDraft from "./components/Application/ApplicationDraft";
import Applications from "./components/Application/Applications";
import Certificate from "./components/Certificate/Certificate";
import PaymentRecipt from "./components/Payment/PaymentRecipt";
import Transactions from "./components/Payment/Transactions";
import Categories from "./components/CategoryInfo/Categories";
import Forms from "./components/Application/Forms";
import AdminCertificates from "./pages/Admin/AdminCertificates/AdminCertificates";
import AdminActiveCertificates from "./pages/Admin/AdminCertificates/AdminActiveCertificates";
import AdminExpiredCertificates from "./pages/Admin/AdminCertificates/AdminExpiredCertificates";
import FrontendUserList from "./pages/Admin/UsersList/FrontendUserList";
import UserLogs from "./pages/Admin/Users/UserLogs";
import OtpLogs from "./pages/Admin/Users/OtpLogs";
import BannerPage from "./pages/Admin/Pages/BannerPage";
import BannerLinks from "./pages/Admin/Pages/BannerLinks";
import CategoryFees from "./pages/Admin/Category/CategoryFees";
import CategoryFeesAdd from "./pages/Admin/Category/CategoryFeesAdd";
import ContactUs from "./components/Home/ContactUs";
import PrivacyPolicy from "./components/Home/PrivacyPolicy";
import BannerPageUpdate from "./pages/Admin/Pages/BannerPageUpdate";
import BannerLinksUpdate from "./pages/Admin/Pages/BannerLinksUpdate";
import ApplicationView from "./pages/Admin/ApplicationView/ApplicationView";
import HomepageHeader from "./pages/Admin/Pages/HomepageHeader";
import HomepageLogo from "./pages/Admin/Pages/HomepageLogo";
import HomepageLogoUpdate from "./pages/Admin/Pages/HomepageLogoUpdate";
import EditPrivacyPolicy from "./pages/Admin/Pages/EditPrivacyPolicy";
import EditTermsConditions from "./pages/Admin/Pages/EditTermsConditions";
import EditDisclaimer from "./pages/Admin/Pages/EditDisclaimer";
import EditNiyamvali from "./pages/Admin/Pages/EditNiyamvali";
import PrivacyPolicyGuidelinesUpdate from "./pages/Admin/Pages/PrivacyPolicyGuidelinesUpdate";
import SocialMedialLinksUpdate from "./pages/Admin/Pages/SocialMediaLinksUpdate";
import PaymentUpdatePage from "./pages/Admin/SettingsTabs/PaymentUpdatePage";
import UpdatePaymentStatus from "./pages/Admin/SettingsTabs/UpdatePaymentStatus";
import TermsAndConditions from "./components/Home/TermsAndConditions";
import Disclaimer from "./components/Home/Disclaimer";
import Rules from "./pages/Admin/Pages/Rules";
import PageDocument from "./pages/Admin/Pages/PageDocument";
import AddPageDocument from "./pages/Admin/Pages/AddPageDocument";
import HomepageRules from "./components/Home/HomepageRules";
import TrackApplication from "./components/Payment/TrackApplication";
import PrivateRoute from "./Utils/PrivateRoute";
import RecoverFrontendUsers from "./pages/Admin/UsersList/RecoverFrontendUsers";
import RecoverAdminUsers from "./pages/Admin/AdminUsers/RecoverAdminUsers";
import AllApplications from "./pages/Admin/AdminReports/AllApplications";
import ActiveApplications from "./pages/Admin/AdminReports/ActiveApplications";
import ApprovedApplicationsReport from "./pages/Admin/AdminReports/ApprovedApplications";
import DraftApplications from "./pages/Admin/AdminReports/DraftApplications";
import InActiveApplications from "./pages/Admin/AdminReports/InActiveApplications";
import PendingApplications from "./pages/Admin/AdminReports/PendingApplications";
import RejectsApplications from "./pages/Admin/AdminReports/RejectsApplications";
import UnderReviewApplications from "./pages/Admin/AdminReports/UnderReviewApplications";
import { FontSizeProvider } from "./components/FontSizeProvider/FontSizeProvider";
import Notification from "./components/Notification/Notification";
import WithdrawalApplications from "./pages/Admin/Application/WithdrawalApplications";
import DraftApplicationList from "./pages/Admin/Application/DraftApplications";
import UserDetails from "./pages/Admin/Users/UserDetails";
import UnderReviewApplication from "./pages/Admin/Application/UnderRviewApplications";
import ChangeUserPassword from "./pages/Admin/AdminUsers/ChangeUserPassword";
import AddYearlytarget from "./pages/Admin/AdminUsers/AddYearlytarget";
import YearlyTarget from "./pages/Admin/AdminUsers/YearlyTarget";
import CertificateCategory from "./pages/Admin/Certificate Category/CertificateCategory";
import CertificateCategoryAdd from "./pages/Admin/Certificate Category/CertificateCategoryAdd";
import YearlyTagetLogs from "./pages/Admin/AdminUsers/YearlyTagetLogs";
import NewRenewApplications from "./pages/Admin/Application/NewRenewApplications";
import UnderReviewRenewApplications from "./pages/Admin/Application/UnderReviewRenewApplications";
import DocumentPendingRenewApplications from "./pages/Admin/Application/DocumentPendingRenewApplications";
import AcceptedRenewApplications from "./pages/Admin/Application/AcceptedRenewApplications";
import RejectedRenewApplications from "./pages/Admin/Application/RejectedRenewApplications";
import ExpiredRenewApplications from "./pages/Admin/Application/ExpiredRenewApplications";
import AllRenewApplications from "./pages/Admin/Application/AllRenewApplications";
import NewApplications from "./pages/Admin/Application/NewApplications";
import ExpiredApplications from "./pages/Admin/Application/ExpiredApplications";
import DisableApplications from "./pages/Admin/Application/DisableApplications";
import DisableRenewApplications from "./pages/Admin/Application/DisableRenewApplications";
import EditSocialMediaLink from "./pages/Admin/Pages/EditSocialMediaLink";
import AdminPosting from "./pages/Admin/AdminUsers/AdminPosting";
import RegularDisableApplications from "./pages/Admin/Application/RegularDisableApplications";
import NavigationProvider from "./components/NavigationProvider/NavigationProvider";
import DynamicPage from "./pages/Dynamic/DynamicPage";
import HeaderPages from "./pages/Admin/Pages/HeaderPages";
import TopFooterPages from "./pages/Admin/Pages/TopFooterPages";
import BootomFooterPages from "./pages/Admin/Pages/BootomFooterPages";
import AddPages from "./pages/Admin/Pages/AddPages";
import EmailLog from "./pages/Admin/email-log/EmailLog";
import TravelTradeLinks from "./pages/Admin/Pages/TravelTradeLinks";
import AddTravelTradeLink from "./pages/Admin/Pages/AddTravelTradeLink";
import FooterContent from "./pages/Admin/Pages/FooterContent";
import RegistrationSteps from "./pages/Admin/Pages/RegistrationSteps";
import EditRegistrationStep from "./pages/Admin/Pages/EditRegistrationStep";
import HeaderContent from "./pages/Admin/Pages/HeaderContent";
import Configuration from "./pages/Admin/setting Page/Configuration";
import AdminYearlyTarget from "./pages/Admin/AdminUsers/AdminYearlyTarget";
import YearlyTargetReport from "./pages/Admin/AdminReports/YearlyTargetReport";
import TransactionList from "./pages/Admin/Application/TransactionList";
import AdminLogs from "./pages/Admin/AdminUsers/AdminLogs";
import SuperAdminLogs from "./pages/Admin/AdminUsers/SuperAdminLogs";
import AdminContactUs from "./pages/Admin/Pages/AdminContactUs";
import EditAdminContactUs from "./pages/Admin/Pages/EditAdminContactUs";

function App() {
  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     const tokenExpiry = GetLocalStorageParsed("tokenExpiry"); // Fetch the latest token expiry
  //     if (tokenExpiry) {
  //       const expiryDate = new Date(tokenExpiry);
  //       const currentDate = new Date();
  //       const timeDifference = expiryDate - currentDate;
  //       // If token is about to expire (1 minutes left)
  //       if (timeDifference > 0 && timeDifference <= 600000) {
  //         refToken(); // Refresh token
  //       } else if (timeDifference <= 0) {
  //         refToken(); // Token has already expired
  //       }
  //     }
  //   }, 1000); // Check every second (1000 ms)
  //   // Clean up the interval on component unmount
  //   return () => clearInterval(intervalId);
  // }, []);

  // const refToken = async () => {
  //   const refreshToken = await getRefreshToken();
  //   if (refreshToken) {
  //     SetLocalStorage("userInfo", refreshToken["refresh-token"]);
  //     SetLocalStorageStringify("tokenExpiry", refreshToken["expire_at"]);
  //   }
  // };
  // Simplified permission checks using the permissions config
  const grantPermissionForViewCategories = checkPermission(
    permissionsConfig.viewCategories.role,
    permissionsConfig.viewCategories.action,
    permissionsConfig.viewCategories.type
  );
  const grantPermissionForViewSubCategories = checkPermission(
    permissionsConfig.viewSubCategories.role,
    permissionsConfig.viewSubCategories.action,
    permissionsConfig.viewSubCategories.type
  );
  const grantPermissionForViewGuidelines = checkPermission(
    permissionsConfig.viewGuidelines.role,
    permissionsConfig.viewGuidelines.action,
    permissionsConfig.viewGuidelines.type
  );
  const grantPermissionForViewCategoryFAQList = checkPermission(
    permissionsConfig.viewCategoryFAQList.role,
    permissionsConfig.viewCategoryFAQList.action,
    permissionsConfig.viewCategoryFAQList.type
  );
  const grantPermissionForViewDocumentList = checkPermission(
    permissionsConfig.viewDocumentList.role,
    permissionsConfig.viewDocumentList.action,
    permissionsConfig.viewDocumentList.type
  );

  const grantPermissionForViewFormTabs = checkPermission(
    permissionsConfig.viewFormTabs.role,
    permissionsConfig.viewFormTabs.action,
    permissionsConfig.viewFormTabs.type
  );
  const grantPermissionForViewFormSection = checkPermission(
    permissionsConfig.viewFormSection.role,
    permissionsConfig.viewFormSection.action,
    permissionsConfig.viewFormSection.type
  );
  const grantPermissionForViewFormSubSection = checkPermission(
    permissionsConfig.viewFormSubSection.role,
    permissionsConfig.viewFormSubSection.action,
    permissionsConfig.viewFormSubSection.type
  );
  const grantPermissionForViewFormFields = checkPermission(
    permissionsConfig.viewFormFields.role,
    permissionsConfig.viewFormFields.action,
    permissionsConfig.viewFormFields.type
  );
  const grantPermissionForViewFormDropdowns = checkPermission(
    permissionsConfig.viewFormDropdowns.role,
    permissionsConfig.viewFormDropdowns.action,
    permissionsConfig.viewFormDropdowns.type
  );
  const grantPermissionForViewEmployeeDesignation = checkPermission(
    permissionsConfig.viewEmployeeDesignation.role,
    permissionsConfig.viewEmployeeDesignation.action,
    permissionsConfig.viewEmployeeDesignation.type
  );

  // const grantPermissionForTotalApplications = checkPermission(permissionsConfig.totalApplications.role, permissionsConfig.totalApplications.action, permissionsConfig.totalApplications.type);
  // const grantPermissionForViewPending = checkPermission(permissionsConfig.viewPending.role, permissionsConfig.viewPending.action, permissionsConfig.viewPending.type);
  // const grantPermissionForViewApproved = checkPermission(permissionsConfig.viewApproved.role, permissionsConfig.viewApproved.action, permissionsConfig.viewApproved.type);
  // const grantPermissionForViewRejected = checkPermission(permissionsConfig.viewRejected.role, permissionsConfig.viewRejected.action, permissionsConfig.viewRejected.type);

  // const grantPermissionForCertificates = checkPermission(permissionsConfig.certificates.role, permissionsConfig.certificates.action, permissionsConfig.certificates.type);
  // const grantPermissionForExpired = checkPermission(permissionsConfig.expired.role, permissionsConfig.expired.action, permissionsConfig.expired.type);

  const grantPermissionForViewLogin = checkPermission(
    permissionsConfig.viewLogin.role,
    permissionsConfig.viewLogin.action,
    permissionsConfig.viewLogin.type
  );
  const grantPermissionForViewResetPassword = checkPermission(
    permissionsConfig.viewResetPassword.role,
    permissionsConfig.viewResetPassword.action,
    permissionsConfig.viewResetPassword.type
  );
  const grantPermissionForViewForgotPassword = checkPermission(
    permissionsConfig.viewForgotPassword.role,
    permissionsConfig.viewForgotPassword.action,
    permissionsConfig.viewForgotPassword.type
  );
  const grantPermissionForViewRegistration = checkPermission(
    permissionsConfig.viewRegistration.role,
    permissionsConfig.viewRegistration.action,
    permissionsConfig.viewRegistration.type
  );

  const grantPermissionForRolesAndPermissions = checkPermission(
    permissionsConfig.rolesAndPermissions.role,
    permissionsConfig.rolesAndPermissions.action,
    permissionsConfig.rolesAndPermissions.type
  );
  const grantPermissionForViewAdminUser = checkPermission(
    permissionsConfig.viewAdminUser.role,
    permissionsConfig.viewAdminUser.action,
    permissionsConfig.viewAdminUser.type
  );

  const grantPermissionForViewManageStates = checkPermission(
    permissionsConfig.manageStates.role,
    permissionsConfig.manageStates.action,
    permissionsConfig.manageStates.type
  );
  const grantPermissionForViewManageDistricts = checkPermission(
    permissionsConfig.manageDistricts.role,
    permissionsConfig.manageDistricts.action,
    permissionsConfig.manageDistricts.type
  );
  const grantPermissionForViewManageCities = checkPermission(
    permissionsConfig.manageCities.role,
    permissionsConfig.manageCities.action,
    permissionsConfig.manageCities.type
  );
  const grantPermissionForViewSMSSetting = checkPermission(
    permissionsConfig.viewSMSSetting.role,
    permissionsConfig.viewSMSSetting.action,
    permissionsConfig.viewSMSSetting.type
  );
  const grantPermissionForViewEmailSetting = checkPermission(
    permissionsConfig.viewEmailSetting.role,
    permissionsConfig.viewEmailSetting.action,
    permissionsConfig.viewEmailSetting.type
  );

  const grantPermissionForViewPages = checkPermission(
      permissionsConfig.viewPages.role,
      permissionsConfig.viewPages.action,
      permissionsConfig.viewPages.type
  );

  const grantPermissionForViewTravelTradeLink = checkPermission(
    permissionsConfig.viewTravelTradeLink.role,
    permissionsConfig.viewTravelTradeLink.action,
    permissionsConfig.viewTravelTradeLink.type
  );

  const grantPermissionForViewFooterContent = checkPermission(
    permissionsConfig.viewFooterContent.role,
    permissionsConfig.viewFooterContent.action,
    permissionsConfig.viewFooterContent.type
  );

  const grantPermissionForViewRegistrationStep = checkPermission(
    permissionsConfig.viewRegistrationStep.role,
    permissionsConfig.viewRegistrationStep.action,
    permissionsConfig.viewRegistrationStep.type
  );

  const grantPermissionForViewHeaderContent = checkPermission(
      permissionsConfig.viewHeaderContent.role,
      permissionsConfig.viewHeaderContent.action,
      permissionsConfig.viewHeaderContent.type
  );

  const grantPermissionForViewContactUs = checkPermission(
    permissionsConfig.viewContactUs.role,
    permissionsConfig.viewContactUs.action,
    permissionsConfig.viewContactUs.type
);

  const ClearCache = () => {
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
      },
      url: `${Apiurl()}/clear-cache`,
    };
    axios(option)
      .then((e) => { })
      .catch((error) => { });
  };
  useEffect(() => {
    ClearCache();
  }, []);

  return (
    <FontSizeProvider>
    <LanguageProvider>
      <BrowserRouter>
      <NavigationProvider>
        <PrivateRoute roles={["superadmin", "admin", "user"]}>
          <Routes>
            <Route path="/notFound" element={<NotFound />} />
            <Route path="/notification" element={<Notification />} />
            <Route path="/" element={<Home />} />
            <Route path="/:name" element={<DynamicPage />} />
            <Route path="/categoryInfo" element={<CategoryInfo />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/application-preview" element={<Preview />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/guidelines" element={<Guidelines />} />
            <Route path="/dashboard" element={<DashboardCategory />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path="/edit-profile" element={<EditProfile />} />
            <Route path="/change-request" element={<ChangeRequest />} />
            <Route path="/coming-soon" element={<ComingSoon />} />
            <Route path="/application-draft" element={<ApplicationDraft />} />
            <Route path="/applications" element={<Applications />} />
            <Route path="/certificate" element={<Certificate />} />
            <Route path="/payment-recipt" element={<PaymentRecipt />} />
            <Route path="/transactions" element={<Transactions />} />
            <Route path="/track-application" element={<TrackApplication />} />
            <Route path="/categories" element={<Categories />} />
            <Route path="/forms" element={<Forms />} />
            <Route path="/application/*" element={<Application />} />
            {/* Admin Routes */}
            <Route path="/admin/view" element={<ViewData />} />
            <Route
              path="/admin/categories/"
              element={
                grantPermissionForViewCategories ? (
                  <Category />
                ) : (
                  <PermissionDenied />
                )
              }
            />
            <Route
              path="/admin/categories/:name"
              element={
                grantPermissionForViewCategories ? (
                  <CategoryAdd />
                ) : (
                  <PermissionDenied />
                )
              }
            />
            <Route
              path="/admin/sub-categories"
              element={
                grantPermissionForViewSubCategories ? (
                  <SubCategory />
                ) : (
                  <PermissionDenied />
                )
              }
            />
            <Route
              path="/admin/sub-categories/:name"
              element={
                grantPermissionForViewSubCategories ? (
                  <SubCategoryAdd />
                ) : (
                  <PermissionDenied />
                )
              }
            />
            <Route
              path="/admin"
              element={
                <PrivateRoute roles={["superadmin", "admin"]}>
                  <DashboardLayout />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/registration"
              element={
                grantPermissionForViewRegistration ? (
                  <RegistrationForm />
                ) : (
                  <PermissionDenied />
                )
              }
            />
            <Route
              path="/admin/registration/:name"
              element={
                grantPermissionForViewRegistration ? (
                  <RegistrationFormAdd />
                ) : (
                  <PermissionDenied />
                )
              }
            />
            <Route
              path="/admin/form/tabs"
              element={
                grantPermissionForViewFormTabs ? (
                  <ApplicationTabs />
                ) : (
                  <PermissionDenied />
                )
              }
            />
            <Route
              path="/admin/form/sections"
              element={
                grantPermissionForViewFormSection ? (
                  <ApplicationSections />
                ) : (
                  <PermissionDenied />
                )
              }
            />
            <Route path="/admin/change-password" element={<ChangePassword />} />
            <Route
              path="/admin/form/fields"
              element={
                grantPermissionForViewFormFields ? (
                  <FormFields />
                ) : (
                  <PermissionDenied />
                )
              }
            />
            <Route path="/admin/users" element={<Users />} />
            <Route
              path="/admin/login"
              element={
                grantPermissionForViewLogin ? (
                  <AdminLogin />
                ) : (
                  <PermissionDenied />
                )
              }
            />
            <Route
              path="/admin/login/:name"
              element={
                grantPermissionForViewLogin ? (
                  <AdminLoginAdd />
                ) : (
                  <PermissionDenied />
                )
              }
            />
            <Route
              path="/admin/reset-password"
              element={
                grantPermissionForViewResetPassword ? (
                  <AdminResetPassword />
                ) : (
                  <PermissionDenied />
                )
              }
            />
            <Route
              path="/admin/reset-password/:name"
              element={
                grantPermissionForViewResetPassword ? (
                  <AdminResetPasswordAdd />
                ) : (
                  <PermissionDenied />
                )
              }
            />
            <Route
              path="/admin/forgot-password"
              element={
                grantPermissionForViewForgotPassword ? (
                  <AdminForgotPassword />
                ) : (
                  <PermissionDenied />
                )
              }
            />
            <Route
              path="/admin/forgot-password/:name"
              element={
                grantPermissionForViewForgotPassword ? (
                  <AdminForgotPasswordAdd />
                ) : (
                  <PermissionDenied />
                )
              }
            />
            <Route
              path="/admin/guidelines/:name"
              element={
                grantPermissionForViewGuidelines ? (
                  <AdminGuidelines />
                ) : (
                  <PermissionDenied />
                )
              }
            />
            <Route
              path="/admin/guidelines"
              element={
                grantPermissionForViewGuidelines ? (
                  <AdminGuidelineslist />
                ) : (
                  <PermissionDenied />
                )
              }
            />
            <Route
              path="/admin/roles/:name"
              element={
                grantPermissionForRolesAndPermissions ? (
                  <AddRoles />
                ) : (
                  <PermissionDenied />
                )
              }
            />
            <Route
              path="/admin/roles"
              element={
                grantPermissionForRolesAndPermissions ? (
                  <RolesList />
                ) : (
                  <PermissionDenied />
                )
              }
            />
            <Route
              path="/admin/roles/permissions/:name"
              element={
                grantPermissionForRolesAndPermissions ? (
                  <UserPermissions />
                ) : (
                  <PermissionDenied />
                )
              }
            />
            <Route
              path="/admin/admin-users/user-permissions/edit"
              element={
                grantPermissionForViewAdminUser ? (
                  <UserPermissions />
                ) : (
                  <PermissionDenied />
                )
              }
            />
            <Route
              path="/admin/admin-users/change-passwword"
              element={
                grantPermissionForViewAdminUser ? (
                  <ChangeUserPassword />
                ) : (
                  <PermissionDenied />
                )
              }
            />
            <Route
              path="/admin/roles/permissions"
              element={
                grantPermissionForRolesAndPermissions ? (
                  <UserPermissionList />
                ) : (
                  <PermissionDenied />
                )
              }
            />
            <Route
              path="/admin/admin-users"
              element={
                grantPermissionForViewAdminUser ? (
                  <AdminUsers />
                ) : (
                  <PermissionDenied />
                )
              }
            />
            <Route
              path="/admin/category/documents"
              element={
                grantPermissionForViewDocumentList ? (
                  <UploadDocument />
                ) : (
                  <PermissionDenied />
                )
              }
            />
            <Route path="/admin/category/fees" element={<CategoryFees />} />
            <Route
              path="/admin/category/fees/:name"
              element={<CategoryFeesAdd />}
            />
            <Route
              path="/admin/category/documents/:name"
              element={
                grantPermissionForViewDocumentList ? (
                  <AddUploadDocument />
                ) : (
                  <PermissionDenied />
                )
              }
            />
            <Route path="/admin/permissions" element={<UserPermissions />} />
            <Route
              path="/admin/states"
              element={
                grantPermissionForViewManageStates ? (
                  <States />
                ) : (
                  <PermissionDenied />
                )
              }
            />
            <Route
              path="/admin/districts"
              element={
                grantPermissionForViewManageDistricts ? (
                  <Districts />
                ) : (
                  <PermissionDenied />
                )
              }
            />
            <Route
              path="/admin/cities"
              element={
                grantPermissionForViewManageCities ? (
                  <Cities />
                ) : (
                  <PermissionDenied />
                )
              }
            />
            <Route
              path="/admin/admin-users/:name"
              element={
                grantPermissionForViewAdminUser ? (
                  <AddAdminUser />
                ) : (
                  <PermissionDenied />
                )
              }
            />

            <Route
              path="/admin/faqs"
              element={
                grantPermissionForViewCategoryFAQList ? (
                  <AdminCategoryFaq />
                ) : (
                  <PermissionDenied />
                )
              }
            />
            <Route
              path="/admin/FAQs/:name"
              element={
                grantPermissionForViewCategoryFAQList ? (
                  <AddAdminCategoryFaqs />
                ) : (
                  <PermissionDenied />
                )
              }
            />
            <Route
              path="/admin/employee-designations"
              element={
                grantPermissionForViewEmployeeDesignation ? (
                  <EmployeeDesignation />
                ) : (
                  <PermissionDenied />
                )
              }
            />
            <Route
              path="/admin/employee-designations/:name"
              element={
                grantPermissionForViewEmployeeDesignation ? (
                  <AddEmployeeDesignation />
                ) : (
                  <PermissionDenied />
                )
              }
            />
            <Route path="/admin/new-applications" element={<NewApplications />} />
            <Route path="/admin/expired-applications" element={<ExpiredApplications />} />
            <Route
              path="/admin/total-applications"
              element={<TotalApplication />}
            />
            <Route
              path="/admin/pending-applications"
              element={<PendingApplication />}
            />
            <Route
              path="/admin/rejected-applications"
              element={<RejectedApplications />}
            />
            <Route
              path="/admin/approved-applications"
              element={<ApprovedApplications />}
            />
            <Route
              path="/admin/renewal-applications"
              element={<RenewalApplications />}
            />
            <Route
                path="/admin/under-review-applications"
              element={<UnderReviewApplication />}
            />
            <Route
                path="/admin/disable-applications"
              element={<RegularDisableApplications />}
            />
            <Route
                path="/admin/transaction-list"
              element={<TransactionList />}
            />
            <Route
              path="/admin/form/dropdowns/:name"
              element={
                grantPermissionForViewFormDropdowns ? (
                  <AddDropDown />
                ) : (
                  <PermissionDenied />
                )
              }
            />
            <Route
              path="/admin/form/dropdowns"
              element={
                grantPermissionForViewFormDropdowns ? (
                  <DropDownList />
                ) : (
                  <PermissionDenied />
                )
              }
            />
            <Route
              path="/admin/sms-setting"
              element={
                grantPermissionForViewSMSSetting ? (
                  <SmsSettingPage />
                ) : (
                  <PermissionDenied />
                )
              }
            />
                <Route
                  path="/admin/configurations"
                  element={ <Configuration />}/>
            <Route
              path="/admin/email-setting"
              element={
                grantPermissionForViewEmailSetting ? (
                  <EmailSettingPage />
                ) : (
                  <PermissionDenied />
                )
              }
            />
            <Route path="/admin/email-log" element={<EmailLog />} />
            <Route
              path="/admin/form/sub-sections/:name"
              element={
                grantPermissionForViewFormSubSection ? (
                  <AddAdminSubSection />
                ) : (
                  <PermissionDenied />
                )
              }
            />
            <Route
              path="/admin/form/sub-sections"
              element={
                grantPermissionForViewFormSubSection ? (
                  <AdminSubSectionList />
                ) : (
                  <PermissionDenied />
                )
              }
            />
            <Route
              path="/admin/request/mobile-email"
              element={<MobileEmailVerification />}
            />
            <Route path="/admin/certificates" element={<AdminCertificates />} />
            <Route
              path="/admin/active-certificates"
              element={<AdminActiveCertificates />}
            />
            <Route
              path="/admin/expired-certificates"
              element={<AdminExpiredCertificates />}
            />
            <Route path="/admin/user" element={<FrontendUserList />} />
            <Route path="/admin/user-logs-list" element={<UserLogs />} />
            <Route path="/admin/admin-logs-list" element={<AdminLogs />} />
            <Route path="/admin/superadmin-logs-list" element={<SuperAdminLogs />} />
            <Route path="/admin/otp-logs" element={<OtpLogs />} />
            <Route path="/admin/banner-page" element={<BannerPage />} />
            <Route path="/admin/banner-link" element={<BannerLinks />} />
            <Route path="/contact-us" element={<ContactUs />} />
            {/* <Route path="/privacy-policy" element={<PrivacyPolicy />} /> */}
            <Route
              path="/admin/header-content"
              element={
                grantPermissionForViewHeaderContent ? (
                  <HeaderContent />
                ) : (
                  <PermissionDenied />
                )
              }
            />
            <Route
              path="/admin/contactus/:name"
              element={
                grantPermissionForViewContactUs ? (
                  <EditAdminContactUs />
                ) : (
                  <PermissionDenied />
                )
              }
            />
            <Route
              path="/admin/contactus"
              element={
                grantPermissionForViewContactUs ? (
                  <AdminContactUs />
                ) : (
                  <PermissionDenied />
                )
              }
            />
            <Route
              path="/admin/footer-content"
              element={
                grantPermissionForViewFooterContent ? (
                  <FooterContent />
                ) : (
                  <PermissionDenied />
                )
              }
            />
            <Route
              path="/admin/registration-steps"
              element={
                grantPermissionForViewRegistrationStep ? (
                  <RegistrationSteps />
                ) : (
                  <PermissionDenied />
                )
              }
            />
            <Route
              path="/admin/registration-steps/:name"
              element={
                grantPermissionForViewRegistrationStep ? (
                  <EditRegistrationStep />
                ) : (
                  <PermissionDenied />
                )
              }
            />
            <Route
              path="/admin/travel-trade-links"
              element={
                grantPermissionForViewTravelTradeLink ? (
                  <TravelTradeLinks />
                ) : (
                  <PermissionDenied />
                )
              }
            />
            <Route
              path="/admin/travel-trade-links/:name"
              element={
                grantPermissionForViewTravelTradeLink ? (
                  <AddTravelTradeLink />
                ) : (
                  <PermissionDenied />
                )
              }
            />
            <Route
              path="/admin/header-pages"
              element={
                grantPermissionForViewPages ? (
                  <HeaderPages />
                ) : (
                  <PermissionDenied />
                )
              }
            />
            <Route
              path="/admin/top-footer-pages"
              element={
                grantPermissionForViewPages ? (
                  <TopFooterPages />
                ) : (
                  <PermissionDenied />
                )
              }
            />
            <Route
              path="/admin/bottom-footer-pages"
              element={
                grantPermissionForViewPages ? (
                  <BootomFooterPages />
                ) : (
                  <PermissionDenied />
                )
              }
            />
            <Route
              path="/admin/pages/:name"
              element={
                grantPermissionForViewPages ? (
                  <AddPages />
                ) : (
                  <PermissionDenied />
                )
              }
            />
            {/* <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            /> */}
            <Route
              path="/admin/rules"
              element={<Rules />}
            />
            <Route
              path="/admin/page-document"
              element={<PageDocument />}
            />
            <Route
              path="/admin/page-document/add"
              element={<AddPageDocument />}
            />
            <Route
              path="/admin/page-document/update"
              element={<AddPageDocument />}
            />
            {/* <Route
              path="/disclaimer"
              element={<Disclaimer />}
            /> */}
            <Route
              path="/admin/banner-page/:name"
              element={<BannerPageUpdate />}
            />
            <Route
              path="/admin/banner-link/update"
              element={<BannerLinksUpdate />}
            />
            {/* <Route
              path="/rules"
              element={<HomepageRules />}
            /> */}
            <Route path="/admin/application-view" element={<ApplicationView />} />
            <Route path="/admin/homepage-header" element={<HomepageHeader />} />
            <Route path="/admin/homepage-logo" element={<HomepageLogo />} />
            <Route path="admin/certificate-type" element={<CertificateCategory />} />
              <Route path="admin/certificate-type/:name" element={<CertificateCategoryAdd />} />
            <Route
              path="/admin/homepage-logo/update"
              element={<HomepageLogoUpdate />}
            />
            <Route
              path="/admin/privacy-policy/update"
              element={<EditPrivacyPolicy />}
            />
            <Route
              path="/admin/terms-conditions/update"
              element={<EditTermsConditions />}
            />
            <Route path="/admin/disclaimer/update" element={<EditDisclaimer />} />

            <Route path="/admin/niyamvali/update" element={<EditNiyamvali />} />
            <Route
              path="/admin/privacy-policy/update/guidelines"
              element={<PrivacyPolicyGuidelinesUpdate />}
            />
            <Route
              path="/admin/social-media-links/:name"
                element={<EditSocialMediaLink />}
            />
              <Route
                path="/admin/social-media-links"
                element={<SocialMedialLinksUpdate />}
              />
              <Route
                path="/admin/district-posting-log"
                element={<AdminPosting />}
              />
                <Route path="/admin/configuration" element={<Configuration />} />
            <Route path="/admin/notification" element={<Notification />} />
            <Route path="/admin/yearly-target-logs" element={<YearlyTagetLogs />} />
            <Route path="/admin/user-details" element={<UserDetails />} />
            <Route path="/admin/yearly-targets" element={<AdminYearlyTarget/>} />
            <Route path="/admin/add-yearly-targets" element={<AddYearlytarget/>} />
            <Route path="/admin/payment/tab" element={<PaymentUpdatePage />} />
            <Route path="/admin/frontend-users" element={<RecoverFrontendUsers />} />
            <Route path="/admin/recover-users" element={<RecoverAdminUsers />} />
            <Route path="/admin/withdrawal-applications" element={<WithdrawalApplications />} />
            <Route path="/admin/draft-applications" element={<DraftApplicationList />} />
            <Route path="/admin/renew/new" element={<NewRenewApplications />} />
            <Route path="/admin/renew/under-review" element={<UnderReviewRenewApplications />} />
            <Route path="/admin/renew/document-pending" element={<DocumentPendingRenewApplications />} />
            <Route path="/admin/renew/accepted" element={<AcceptedRenewApplications />} />
            <Route path="/admin/renew/rejected" element={<RejectedRenewApplications />} />
            <Route path="/admin/renew/expired" element={<ExpiredRenewApplications />} />
            <Route path="/admin/renew/disable" element={<DisableRenewApplications />} />
            <Route path="/admin/renew/all" element={<AllRenewApplications />} />
            <Route path="/admin/all-applications-report" element={<AllApplications />} />
            <Route path="/admin/all-active-report" element={<ActiveApplications />} />
            <Route path="/admin/all-inactive-report" element={<InActiveApplications />} />
            <Route path="/admin/all-pending-report" element={<PendingApplications />} />
            <Route path="/admin/all-draft-report" element={<DraftApplications />} />
            <Route path="/admin/all-approved-report" element={<ApprovedApplicationsReport />} />
            <Route path="/admin/all-rejected-report" element={<RejectsApplications />} />
            <Route path="/admin/all-under-review-report" element={<UnderReviewApplications />} />
            <Route path="/admin/yearly-target-report" element={<YearlyTargetReport />} />
          </Routes>
        </PrivateRoute>
        </NavigationProvider>
      </BrowserRouter>
    </LanguageProvider>
    </FontSizeProvider>
  );
}
export default App;
