export const formatDateWithSlash = (dateTimeString) => {
    if (dateTimeString) {
        const date = new Date(dateTimeString);

        if (dateTimeString.includes('T') || dateTimeString.includes(' ')) {
            // Handle both "2024-07-31T05:18:15.000000Z" and "2024-07-26 00:00:00"
            const options = {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                // hour: '2-digit',
                // minute: '2-digit',
                // second: '2-digit',
                // hour12: true
            };
            return date.toLocaleString('en-GB', options).replace(',', '');
        } else {
            // If the date format is "2024-07-30"
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();

            return `${day}/${month}/${year}`;
        }
    } else {
        return;
    }
}

export const formatDateWithdash = (dateTimeString) => {
    if (dateTimeString) {
        // const dateTimeString = "Sat Oct 26 2024 14:46:33 GMT+0530 (India Standard Time)";
        const dateObj = new Date(dateTimeString);

        // Extract day, month, and year from the date object
        const day = String(dateObj.getDate()).padStart(2, '0');
        const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const year = dateObj.getFullYear();

        // Format as dd-mm-yyyy
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
    } else {
        return;
    }
}

export const checkPattern = async (value) => {
    const pattern = /^[a-zA-Z\u0900-\u097F\s\W]*$/;
    return pattern.test(value)
}

export const checkUrlPattern = async (value) => {
    const urlPattern = /^(https?:\/\/)?([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,})(:[0-9]{1,5})?(\/[^\s]*)?$/;
    return urlPattern.test(value)
}

export const checkPatternWithSpecialCharacter = async (value) => {
    const pattern = /^[a-zA-Z\u0900-\u097F\s\W\d]*$/;
    return pattern.test(value)
}

export const checkPatternFontSize = async (value) => {
    const pattern = /^\d{1,2}(\.\d{1,2})?$/;
    return pattern.test(value)
}

export const checkPatternFileSize = async (value) => {
    const pattern = /^(?:[0-9]|[1-9][0-9]{1,2}|1[0-9]{3}|2000)$/;
    return pattern.test(value)
}


export const checkValidEmail = async (value) => {
    const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return pattern.test(value)
}

export const checkValidMobile = async (value) => {
    const pattern = /^\+?[0-9]{10}$/;
    return pattern.test(value)
}

export const customStyles = {
    control: (provided, state) => ({
        ...provided,
        borderColor: state.isFocused ? '#00aeef' : 'var(--dark-gray)',
        minHeight: '38px',
        height: '38px',
        // border: state.isFocused ? 'var(--bs-border-width) solid var(--bs-border-color)' : 'var(--bs-border-width) solid var(--dark-gray)',
        alignItems: 'center', // Align content vertically
        padding: '0',
    }),
    input: (provided) => ({
        ...provided,
        margin: 0,              // Remove margin to avoid positioning issues
        padding: 0,             // Remove padding for correct alignment
        height: 'auto',         // Auto height adjustment
        lineHeight: 'normal',   // Reset line height to prevent issues with text
    }),
    valueContainer: (provided) => ({
        ...provided,
        height: '38px',        // Ensure the height is consistent
        display: 'flex',
        alignItems: 'center',  // Center the text vertically
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#00aeef' : 'white',
        color: state.isSelected ? 'white' : 'black',
        '&:active': {
            backgroundColor: 'white',
        },
        '&:hover': {
            backgroundColor: '#00aeef',
        },
    }),
};

export const customStylesAcc = {
    ...customStyles,
    placeholder: (provided) => ({
        ...provided,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        color: 'black'
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: '0',
        paddingLeft: '8px'
    }),
};

export const selectTheme = (theme) => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary: '#00aeef',
    },
})

export const firstCharUpperCase = (str) => {
    const capitalizedString = str.replace(/^\w/, (c) => c.toUpperCase());
    return capitalizedString;
}

export const customStylesUser = {
    control: (provided, state) => ({
        ...provided,
        borderColor: state.isFocused ? '#00684a' : 'var(--dark-gray)',
        minHeight: '38px',
        height: '38px',
        // border: state.isFocused ? 'var(--bs-border-width) solid var(--bs-border-color)' : 'var(--bs-border-width) solid var(--dark-gray)',
        alignItems: 'center', // Align content vertically
        padding: '0',
    }),
    input: (provided) => ({
        ...provided,
        margin: 0,              // Remove margin to avoid positioning issues
        padding: 0,             // Remove padding for correct alignment
        height: 'auto',         // Auto height adjustment
        lineHeight: 'normal',   // Reset line height to prevent issues with text
    }),
    valueContainer: (provided) => ({
        ...provided,
        height: '38px',        // Ensure the height is consistent
        display: 'flex',
        alignItems: 'center',  // Center the text vertically
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#00684a' : 'white',
        color: state.isSelected ? 'white' : 'black',
        '&:active': {
            backgroundColor: 'white',
        },
        '&:hover': {
            backgroundColor: '#00684a',
            color: "white"
        },
    }),
};

export const customStylesAccUser = {
    ...customStylesUser,
    placeholder: (provided) => ({
        ...provided,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        color: 'black'
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: '0',
        paddingLeft: '8px'
    }),
};

export const selectThemeUser = (theme) => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary: '#00684a',
    },
})

export const formatDateTime = (dateTimeString) => {
    if (dateTimeString) {
        const date = new Date(dateTimeString);
        // Format date to dd-mm-yyyy
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        // Format time to 12-hour clock with AM/PM
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;
        const formattedDate = `${day}-${month}-${year}`;
        const formattedTime = `${hours}:${minutes} ${ampm}`;

        return `${formattedDate} ${formattedTime}`;
    } else {
        return;
    }
}

export const removeHTMLTags = (str) =>{
    return str.replace(/<[^>]*>/g, '').replace(/&nbsp;/g, ' ')
}

export const formatDateTimeWithSlash = (dateTimeString) => {
    if (dateTimeString) {
        const date = new Date(dateTimeString);

        if (dateTimeString.includes('T') || dateTimeString.includes(' ')) {
            // Handle both "2024-07-31T05:18:15.000000Z" and "2024-07-26 00:00:00"
            const options = {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: true
            };
            return date.toLocaleString('en-GB', options).replace(',', '');
        } else {
            // If the date format is "2024-07-30"
            let hours = date.getHours();
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');
            hours = hours % 12 || 12;
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            const ampm = hours >= 12 ? 'PM' : 'AM';
            return `${day}/${month}/${year} ${hours}:${minutes}:${seconds} ${ampm} `;
        }
    } else {
        return;
    }
}

export const  capitalizeFirstLetter = (input) => {
    if (input && input !='' && typeof input == 'string'){
        return input.replace(/^\w/, (c) => c.toUpperCase());
    }else{
        return '';
    }
    return input
    
}

export const printData = (pdfBlob) => {
    const blobUrl = URL.createObjectURL(pdfBlob);
  
    // Create an iframe to load the PDF
    const iframe = document.createElement("iframe");
    iframe.style.position = "fixed";
    iframe.style.top = "0";
    iframe.style.left = "0";
    iframe.style.width = "100%";
    iframe.style.height = "100%";
    iframe.style.zIndex = "-1"; // Ensure it's on top
    iframe.style.border = "none";
    iframe.src = blobUrl;
  
    // Append iframe to the DOM
    document.body.appendChild(iframe);
  
    // Wait for the iframe to load fully
    iframe.onload = () => {
      try {
        const iframeWindow = iframe.contentWindow;
        const iframeDoc = iframeWindow.document;
  
        // Add custom styles to scale the content
        const style = iframeDoc.createElement("style");
        style.textContent = `
              @page {
                size: A4; /* Use A4 as default paper size */
                margin: 10mm; /* Adjust margins */
              }
              body {
                transform: scale(0.95); /* Scale content slightly */
                transform-origin: top left;
                margin: 0;
                padding: 0;
              }
              iframe, embed {
                width: 100%;
                height: 100%;
              }
            `;
        iframeDoc.head.appendChild(style);
  
        // Focus and print the iframe content
        iframeWindow.focus();
        iframeWindow.print();
  
        // Optionally: remove iframe after printing
        // document.body.removeChild(iframe);
      } catch (error) {
        console.error("Error during printing:", error);
      }
    };
};
