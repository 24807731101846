import React, { useEffect, useState } from 'react'
import AdminDashboard from '../AdminDashboard/AdminDashboard'
import { useNavigate } from 'react-router-dom';
import { Message } from '../../../components/message';
import { getAllFooterContentlListData, getAllSMSData, getAllSMSlabelListData, updateFooterContent, updateSMSlabelData } from '../../../services/api/admin';
import { checkPatternWithSpecialCharacter } from '../../../Utils/common';

const FooterContent = () => {
  const navigate = useNavigate();
  const [apiMessage, setApiMessage] = useState({ type: "", message: "" });
  const [selectedModuleIndex, setSelectedModuleIndex] = useState(1);
  const [footerContentList, setFooterContentList] = useState([])
  const [footerContentData, setFooterContentData] = useState({})
  const [titleEn, setTitleEn] = useState("");
  const [titleHi, setTitleHi] = useState("");
  const [contentEn, setContentEn] = useState("");
  const [contentHi, setContentHi] = useState("");
  const [err, setErr] = useState({
    titleEn: "",
    titleHi: "",
    contentEn: "",
    contentHi: "",
  });
  
  useEffect(() => {
    getFooterContentList()
  }, [])

  const footerContentDataBySection = async (item) => {
    try {
      let formErr = { ...err };
      formErr.titleEn = "";
      formErr.titleHi = "";
      formErr.contentEn = "";
      formErr.contentHi = "";
      setErr(formErr);
      const dataList = await getAllFooterContentlListData();
      const labelData = dataList?.data.find(
        (x) => x.id == item?.id
      );
      if (labelData) {
        setFooterContentData(labelData);
        setTitleEn(labelData?.title_en);
        setTitleHi(labelData?.title_hi);
        setContentEn(labelData?.content_en);
        setContentHi(labelData?.content_hi)
      } else {
        setTitleEn("");
        setTitleHi("");
        setContentEn("");
        setContentHi("");
      }
    } catch (err) {}
  };

  const getFooterContentList = async () => {
    try {
      const listData = await getAllFooterContentlListData();
      if (listData?.status == 200) {
        setFooterContentList(listData?.data);
        const firstContentData = listData?.data?.[0];
        if (firstContentData) {
            footerContentDataBySection(firstContentData);
        }
      }
    } catch (err) {}
  };

  const handleInput = async (e) => {
    const { name, value } = e?.target;
    const cursorPosition = e.target.selectionStart;
    if (name == "titleEn") {
      if ((await checkPatternWithSpecialCharacter(value)) == false) {
        return;
      } else {
        setTitleEn(value);
      }
    }else if (name == "titleHi") {
      if ((await checkPatternWithSpecialCharacter(value)) == false) {
        return;
      } else {
        setTitleHi(value);
      }
    } else if (name == "contentEn") {
      setContentEn(value);
    } else if (name == "contentHi") {
      setContentHi(value);
    }
    setTimeout(() => {
      e.target.setSelectionRange(cursorPosition, cursorPosition);
    }, 0);
    validateForm(name, value);
  };

  const validateForm = (name, value) => {
    console.log(name, value);
    let formErr = { ...err };
    if (name == "titleEn") {
      if (!value?.trim()) {
        formErr.titleEn = "Please Enter English Title";
        setErr(formErr);
        return false;
      } else {
        formErr.titleEn = "";
      }
    } else if (name == "titleHi") {
      if (!value?.trim()) {
        formErr.titleHi = "Please Enter Hindi Title";
        setErr(formErr);
        return false;
      } else {
        formErr.titleHi = "";
      }
    } else if (name == "contentEn") {
      if (!value?.trim()) {
        formErr.contentEn = "Please Enter English Content";
        setErr(formErr);
        return false;
      } else {
        formErr.contentEn = "";
      }
    } else if (name == "contentHi") {
      if (!value?.trim()) {
        formErr.contentHi = "Please Enter Hindi Content";
        setErr(formErr);
        return false;
      } else {
        formErr.contentHi = "";
      }
    }
    setErr(formErr);
    return true;
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (
        !validateForm("titleEn", titleEn) ||
        !validateForm("titleHi", titleHi) ||
        !validateForm("contentEn", contentEn) ||
        !validateForm("contentHi", contentHi)
      ) {
        return;
      }

      const formData = new FormData();
      formData.append("content_id", footerContentData?.id);
      formData.append("title_en", titleEn);
      formData.append("title_hi", titleHi);
      formData.append("content_en", contentEn);
      formData.append("content_hi", contentHi);

      const dataUpdate = await updateFooterContent(formData);
      if (dataUpdate?.status === 200) {
        await footerContentDataBySection(footerContentData);
        setApiMessage({ type: "success", message: dataUpdate?.message });
      } else {
        setApiMessage({ type: "error", message: dataUpdate?.message });
      }
    } catch (err) {
      setApiMessage({ type: "error", message: err?.message });
    }
  };

  return (
    <AdminDashboard>
      <div className="aod-outer">
        <div className="ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb">
          <div>
            <b onClick={() => navigate("/admin/")}>Home /</b>
            <span> Footer Content</span>
          </div>
        </div>
        {apiMessage && (
          <Message
            apiMessage={apiMessage}
            setApiMessage={setApiMessage}
          ></Message>
        )}
        <div className="aod-inner">
          <div className="content-wrapper" id="sms-page">
            <div className="flex-grow-1 container-p-y">
              <h4 className="mb-4">Footer Contents</h4>
              <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-4">
                  <div className="card mb-4 border-0">
                    <h5 className="card-header">Sections</h5>
                    <ul className="template-list">
                      {footerContentList?.map((item, index) => (
                        <a>
                          <li
                            className={`odd ${
                              selectedModuleIndex === item.id ? "active" : ""
                            }`}
                            id={`v-pills-${item.label}-tab`}
                            aria-selected={selectedModuleIndex === item.id}
                            onClick={() => {
                              setSelectedModuleIndex(item.id);
                              footerContentDataBySection(item);
                            }}
                          >
                            {item.title_en}
                          </li>
                        </a>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="col-xl-8 col-lg-8 col-md-8">
                  <div className="card mb-4 border-0">
                    <form className="card-body admin-role-form">
                      <div className="col-md-12">
                        <label className="form-label" for="Map-provider">
                          English Title
                        </label>
                        <input
                          type="text"
                          id="map-provider"
                          className="form-control"
                          maxLength={40}
                          placeholder="Enter English Title"
                          name="titleEn"
                          value={titleEn}
                          onChange={handleInput}
                        />
                        {err.titleEn && (
                          <p className="validate-error">{err.titleEn}</p>
                        )}
                      </div>
                      <div className="col-md-12 mt-4">
                        <label className="form-label" for="Map-provider">
                          Hindi Title
                        </label>
                        <input
                          type="text"
                          id="map-provider"
                          className="form-control"
                          placeholder="Enter hindi Title"
                          name="titleHi"
                          value={titleHi}
                          onChange={handleInput}
                        />
                        {err.titleHi && (
                          <p className="validate-error">{err.titleHi}</p>
                        )}
                      </div>
                      <div className="col-md-12 mt-4">
                        <label
                          for="exampleFormControlTextarea1"
                          className="form-label"
                        >
                          Content
                        </label>
                        <textarea
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          placeholder="Add here"
                          rows="3"
                          spellcheck="false"
                          name="contentEn"
                          value={contentEn}
                          onChange={handleInput}
                        ></textarea>
                        {err.contentEn && (
                          <p className="validate-error">{err.contentEn}</p>
                        )}
                      </div>
                      <div className="col-md-12 mt-4">
                        <label
                          for="exampleFormControlTextarea1"
                          className="form-label"
                        >
                          Hindi Content
                        </label>
                        <textarea
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          placeholder="Add here"
                          rows="3"
                          spellcheck="false"
                          name="contentHi"
                          value={contentHi}
                          onChange={handleInput}
                        ></textarea>
                        {err.contentHi && (
                          <p className="validate-error">{err.contentHi}</p>
                        )}
                      </div>

                      <div className="pt-4">
                        <button
                          type="button"
                          className="btn btn-primary me-sm-3 me-1 theme-cta"
                          onClick={handleSubmit}
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="content-backdrop fade"></div>
        </div>
      </div>
    </AdminDashboard>
  );
};

export default FooterContent;
