import React, { useEffect, useState } from 'react';
import { Modal, Table } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { HomeIcon } from '../../../components/Application/ApplicationIcon';
import NotFoundTable from '../../../components/notFound/NotFoundTable';
import { deleteBannerImage, getHomepageBanners, handleEnableDisableHomepageBanner } from '../../../services/api/admin';
import { formatDateWithSlash } from '../../../Utils/common';
import { Baseurl } from '../../../Utils/Utils';
import AdminDashboard from '../AdminDashboard/AdminDashboard';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { DeleteIcon, EditIcon } from "../AdminIcon";
import { Message } from '../../../components/message'
import { HiPlus } from 'react-icons/hi';

const BannerPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const navigate = useNavigate();
  const [userList, setUserList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [apiMessage, setApiMessage] = useState({ type: "", message: "" });
  const [tableDataLoaded, setTableDataLoaded] = useState(false);
  const [deleteData, setDeleteData] = useState('')

  useEffect(() => {
    bannerDataList(currentPage);
  }, [currentPage]);

  const bannerDataList = async (pageNo) => {
    try {
      const usersListData = await getHomepageBanners(pageNo);
      if (usersListData.status === 200) {
        setUserList(usersListData?.data || []);
        setTableDataLoaded(true);
      }
    } catch (err) {
      setApiMessage({ type: 'error', message: err?.message });
    }
  };

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
    bannerDataList(currentPage);
  };

  const handleEnableDisable = async (e, item) => {
    try {
      const formData = new FormData();
      formData.append("banner_id", item?.id);
      formData.append("status", e?.value);
      const data = await handleEnableDisableHomepageBanner(formData);
      if (data?.status == 200) {
        bannerDataList();
      } else {
        setApiMessage({ type: "error", message: data?.message });
      }
    } catch (error) {
      console.error(error);
      setApiMessage({ type: "error", message: error?.message });
    }
  };

  const deleteBanner = async (item) => {
    try {
      const payload = {
        banner_id: item.id,
      };
      const res = await deleteBannerImage(payload);
      setDeleteData("");
      bannerDataList();
    } catch (e) {}
  };

  return (
    <AdminDashboard>
      <div className="aod-outer">
      {apiMessage && (<Message apiMessage={apiMessage} setApiMessage={setApiMessage}></Message>)}
        <div className="ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb">
        <div>
            <HomeIcon />
            <b onClick={() => navigate("/admin/")}>Home /</b>
            <span> Homepage-Banner</span>
          </div>
        </div>
        <div className="admin-add-btn mb-3 d-flex justify-content-end">
            <button
              className="adm-tab-btn"
              onClick={() =>
                navigate("/admin/banner-page/add")
              }
            >
            <span>
              <HiPlus />
            </span>{" "}
              Add
          </button>
        </div>
        <div className="aod-inner pt-0">
          <div className="aod-bottom">
            <div className="adm-tabs-outer">
              <h4 className="mb-0 w-100">Homepage Banners List</h4>
            </div>
            <div className="aod-head"></div>
            <div className="ad-reg-table">
              <Table size="sm" className="ad-cat-table mt-4">
                <thead>
                  <tr>
                    <th className="ad-sno">S. No</th>
                    <th className="ad-long">Image</th>
                    <th className="ad-long">Created At</th>
                    {userList.length > 1 && <th className="ad-long">Status</th>}
                    {userList.length > 1 && <th className="ad-long">Edit</th>}
                  </tr>
                </thead>
                <tbody>
                  {userList.length > 0 ? (
                    userList.map((item, index) => (
                      <tr key={item.id}>
                        <td>{(currentPage - 1) * 10 + index + 1}</td>
                        <td>
                          <img
                            src={`${Baseurl()}/${item.image_path}`}
                            alt="Banner"
                            style={{ width: "220px", height: "auto" }}
                          />
                        </td>
                        <td>{formatDateWithSlash(item.created_at)}</td>
                        {userList.length > 1 && ( <>
                          <td>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={item?.status == 1}
                                onChange={(e) =>
                                  handleEnableDisable(
                                    e.target.checked
                                      ? { value: "1" }
                                      : { value: "0" },
                                    item
                                  )
                                }
                              />
                              <span className="slider"></span>
                            </label>
                          </td>
                        <td>
                          <div className="action-btn d-flex">
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip id={`edit-tooltip-${item.id}`}>Edit</Tooltip>}
                            >
                              <span
                                className="ad-cat-edit"
                                onClick={() => {
                                  navigate("/admin/banner-page/update", {
                                    state: item,
                                  });
                                }}
                              >
                                <EditIcon />
                              </span>
                            </OverlayTrigger>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id={`delete-tooltip-${item.id}`}>
                                  Delete
                                </Tooltip>
                              }
                            >
                              <span
                                className="ad-cat-delete"
                                onClick={() => setDeleteData(item)}
                              >
                              <DeleteIcon />
                              </span>
                            </OverlayTrigger>
                          </div>
                        </td></>
                                 )}
                      </tr>
                    ))
                  ) : (
                    <NotFoundTable colSpan="9"></NotFoundTable>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
      <Modal show={deleteData?.id} onHide={() => setDeleteData("")}>
        <Modal.Header closeButton>
          <Modal.Title>
            Do you really want to delete this Banner ?
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <div className="suf-btn p-2">
            <button onClick={() => deleteBanner(deleteData)}>Yes</button>
            <button
              className="suf-can-butt"
              onClick={() => setDeleteData(false)}
            >
              No
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </AdminDashboard>
  );
};

export default BannerPage;
