import { useLocation, useNavigate } from "react-router-dom";
import AdminDashboard from "../../pages/Admin/AdminDashboard/AdminDashboard";
import { HomeIcon } from "../Application/ApplicationIcon";
import "../viewData/viewData.css";
import { useEffect, useState } from "react";
import { GetLocalStorage } from "../../Utils/Utils";
import { BsChevronLeft } from "react-icons/bs";
import { getRolesAllListData } from "../../services/api/admin";
import { Message } from "../message";
import { stateDistrict } from "../../services/api/auth";
import { formatDateTime } from "../../Utils/common";

const ViewData = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = GetLocalStorage("userInfo");
  const [userDetails, setUserDetails] = useState();
  const [roleList, setRoleList] = useState([]);
  const [apiMessage, setApiMessage] = useState({ type: "", message: "" });
  const [districtList, setDistrictList] = useState([]);

  const listOfTitle = [
    { value: "1", label: "Mr." },
    { value: "2", label: "Mrs." },
    { value: "3", label: "Miss" },
    { value: "4", label: "Ms" },
    { value: "5", label: "Dr" },
  ];
  useEffect(() => {
    if (!token) {
      navigate("/");
    } else {
      if (location?.state) {
        setUserDetails(location?.state);
        getRolesList();
        getDistrictList();
      }
    }
  }, [location?.state]);

  const getRolesList = async () => {
    try {
      const RolesData = await getRolesAllListData();
      const data = RolesData?.data?.map((i) => ({
        label: i?.title,
        value: i?.id,
      }));
      setRoleList(data);
    } catch (err) {
      setApiMessage({ type: "error", message: err?.message });
    }
  };

  const getDistrictList = async () => {
    try {
      const districtData = await stateDistrict();
      const data = districtData?.data?.data?.map((district) => ({
        label: district?.name_en,
        value: district?.id,
      }));
      setDistrictList(data);
    } catch (err) {
      setApiMessage({ type: "error", message: err?.message });
    }
  };

  return (
    <AdminDashboard>
      <div className="aod-outer">
        {apiMessage && (
          <Message
            apiMessage={apiMessage}
            setApiMessage={setApiMessage}
          ></Message>
        )}

        <div className="ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb">
          <div>
            <HomeIcon />
            <b onClick={() => navigate("/admin/")}>Home /</b>{" "}
            <span> Admin User Details</span>
          </div>
        </div>
        <div className='admin-add-btn mb-3 d-flex justify-content-end'>
          <button className="adm-tab-btn" onClick={() => navigate("/admin/district-posting-log", { state: userDetails })}>
            Previous Posting
          </button>
        </div>
        <div className="aod-inner pt-0">
          <div className="aod-bottom">
            <div className="row">
              <div className="detail_card_header ps-0 col-md-12">
                <div className=" text-center adm-head">
                  <button onClick={() => navigate("/admin/admin-users")}>
                    <BsChevronLeft /> Back
                  </button>
                  <h5 className="mb-0">
                    <strong>View Details</strong>
                  </h5>
                </div>
              </div>
              <div className="aod-head mb-4"></div>
              <div className="col-md-2 ps-0">
                <div className=" mt-3 view-detail-card-c user_card_h">
                  <div className="view-detail-c">
                    <img
                      className="mb-4"
                      src="https://utdbhomestayapi.dilonline.in/assets/backend/img/dummy_user.svg"
                      alt=""
                      srcset=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-10 col-sm-12 mt-0">
                <div className="row g-2 mt-2">
                  <div className="col-lg-4 col-md-6">
                    <div className="detail_card_desc left-card-desc mt-0">
                      <p className="mb-0">Admin ID</p>
                      <div className="view-card-d">
                        <small>{userDetails?.id || "--"}</small>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="detail_card_desc left-card-desc mt-0">
                      <p className="mb-0">Name</p>
                      <div className="view-card-d">
                        <small>
                          {userDetails?.title
                            ? listOfTitle.find(
                                (x) => x.value == userDetails?.title
                              )?.label
                            : ""}{" "}
                          {userDetails?.name || "--"}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="detail_card_desc left-card-desc mt-0">
                      <p className="mb-0">User Name</p>
                      <div className="view-card-d">
                        <small>{userDetails?.username || "--"}</small>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="detail_card_desc left-card-desc mt-0">
                      <p className="mb-0"> Email </p>
                      <div className="view-card-d">
                        <small>{userDetails?.email || "--"}</small>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="detail_card_desc left-card-desc mt-0">
                      <p className="mb-0"> Mobile No. </p>
                      <div className="view-card-d">
                        <small>{userDetails?.mobile_number || "--"}</small>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="detail_card_desc left-card-desc mt-0">
                      <p className="mb-0"> Address </p>
                      <div className="view-card-d">
                        <small>{userDetails?.address || "--"}</small>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="detail_card_desc left-card-desc mt-0">
                      <p className="mb-0"> Role </p>
                      <div className="view-card-d">
                        <small>
                          {userDetails?.role_id
                            ? roleList?.find(
                                (x) => x.value == userDetails?.role_id
                              )?.label
                            : "--"}
                        </small>
                      </div>
                    </div>
                  </div>
                  {userDetails?.district && (
                    <div className="col-lg-4 col-md-6 ">
                      <div className="detail_card_desc left-card-desc mt-0">
                        <p className="mb-0"> District </p>
                        <div className="view-card-d">
                          <small>
                            {userDetails?.district
                              ? districtList?.find(
                                  (x) => x.value == userDetails?.district
                                )?.label
                              : "--"}
                          </small>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col-lg-4 col-md-6">
                    <div className="detail_card_desc left-card-desc mt-0">
                      <p className="mb-0"> Joined </p>
                      <div className="view-card-d">
                        <small>
                          {formatDateTime(userDetails?.created_at) || "--"}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 ">
                    <div className="detail_card_desc left-card-desc mt-0">
                      <p className="mb-0">Status </p>
                      <div className="view-card-d">
                        <span
                          className={
                            userDetails?.status == "1"
                              ? "badge rounded-pill text-bg-success"
                              : "badge rounded-pill text-bg-danger"
                          }
                        >
                          {userDetails?.status == "1" ? "Active" : "Inactive"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminDashboard>
  );
};

export default ViewData;
