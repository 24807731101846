import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useLanguage } from "../../components/LanguageProvider/LanguageProvider ";
import MainLayout from "../../components/MainLayout/MainLayout";
import Banner from "../../components/Banner/Banner";
import { getHomePageContent } from "../../services/api/outer";
import { useEffect, useState } from "react";
import { Baseurl, ClearLocalStorage } from "../../Utils/Utils";
import { getPageDetails } from "../../services/api/admin";

function DynamicPage() {
  const { pageUrl } = useParams(); // Extract the dynamic URL part
  const location = useLocation(); // Access passed state
  const content_en = location.state?.content_en; // Extract content from state
  const content_hi = location.state?.content_hi; // Extract content from state
  const pageId = location.state?.page_id; // Extract content from state
  const titleEn = location.state?.title_en; // Extract content from state
  const titleHi = location.state?.title_hi; // Extract content from state
  const { language, setLanguage } = useLanguage();
  const [loading, setLoading] = useState(true);
  const [homePageData, setHomePageData] = useState(null);
  const navigate = useNavigate();
  const [pageDocumentData, setPageDocumentData] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      try {
        const [homePageDataResponse] = await Promise.all([
          getHomePageContent(),
        ]);
        setHomePageData(homePageDataResponse);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data", error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    getPageDocumentData();
    ClearLocalStorage();
  }, [location]);

  const getPageDocumentData = async () => {
    try {
      const formData = new FormData();
      formData.append("page_id", pageId);
      const pageListData = await getPageDetails(formData);
      if (pageListData.status === 200) {
        setPageDocumentData(pageListData?.data?.documents?.filter((d) => d.deleted_at === null && d.status === "1"));
      }
    } catch (err) {
      //setApiMessage({ type: 'error', message: err?.message });
    }
  };

  // If content is not passed via state, you may fetch it as a fallback
  if (!content_en || !content_hi || !pageId) {
    navigate("/");
  }

  if (loading || homePageData === null) {
    return <></>;
  }

  return (
    <>
      <MainLayout homePageData={homePageData}>
        <section className="privacy-policy section-padding">
          <div className="container">
            <div className="privacy-wrapper">
              <div className="privacy-heading">
                <div className="text-center pt-2 pb-3 hcat-main">
                  <p className="hex-p">
                    {" "}
                    {language ? titleHi : titleEn}{" "}
                  </p>
                  {/* <div className="hex-explore">
                    {language
                      ? "यात्रा व्यापार अस्वीकरण का अन्वेषण करें"
                      : "Explore Travel Trade Disclaimer"}
                  </div> */}
                </div>
              </div>
              <p>
                <span className="ql-editor"
                  dangerouslySetInnerHTML={{
                    __html: language ? content_hi : content_en,
                  }}
                ></span>
              </p>
              <p>&nbsp;</p>
              {pageDocumentData?.length > 0 ? (
                <>
                  <div className="pdf-link-block mt-5">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>{language ? "क्रमांक" : "Sr. no"}</th>
                          <th>{language ? "फ़ाइल" : "File"}</th>
                          <th>{language ? "कार्रवाई" : "Action"}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {pageDocumentData?.map((item, index) => (
                          <tr className="align-middle" key={index}>
                            <td>{index + 1}</td>
                            <td>{language ? item.name_hi : item.name_en}</td>
                            <td>
                              <a
                                className="btn btn-green desktopBtn d-block"
                                href={Baseurl() + "/" + item.document} // Assuming item.path holds the document URL
                                target="_blank" // Opens the document in a new tab
                                rel="noopener noreferrer" // Adds security for external links
                              >
                                <i className="bi bi-download bi-icon-font me-1"></i>
                                {language ? "डाउनलोड" : "Download"}
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </section>
      </MainLayout>
    </>
  );
}

export default DynamicPage;
