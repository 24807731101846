import React, { useEffect, useState } from "react";
import "../Admin.css";
import { DeleteIcon, EditIcon, AcceptRequest } from "../AdminIcon";
import { Modal, Table } from "react-bootstrap";
import { GetLocalStorageParsed, RemoveSession } from "../../../Utils/Utils";
import Select from "react-select";
import AdminDashboard from "../AdminDashboard/AdminDashboard";
import { useNavigate } from "react-router-dom";
import { checkPattern } from "../../../Utils/common";
import { CgCheck, CgClose } from "react-icons/cg";
import {
  getStateDropdown,
  getDistrictDropdown,
  addCityData,
  getCityListDataSort,
  activeInactiveCity,
  deleteCityData,
  updateCityData,
  getMobileEmailDataList,
  updateEmailMobileVerify,
} from "../../../services/api/admin";
import { BsChevronLeft } from "react-icons/bs";
import "../AdminGuidelines/AdminGuildlines.css";
import { HiPlus } from "react-icons/hi";
import ReactPaginate from "react-paginate";
import { HomeIcon } from "../../../components/Application/ApplicationIcon";
import { Message } from "../../../components/message";
import { formatDateWithSlash } from "../../../Utils/common";
import NotFoundTable from "../../../components/notFound/NotFoundTable";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { sendNotificationDataUser } from "../../../services/api/user";

const MobileEmailVerification = () => {
  const navigate = useNavigate(); 
  const userDetail = GetLocalStorageParsed("userDetail");
  const [searchData, setSearchData] = useState("");
  const [sortBy, setSortBy] = useState("created_at");
  const [searchState, setSearchState] = useState();
  const [editData, setEditData] = useState();
  const [requestList, setRequestList] = useState();
  const [tableDataLoaded, setTableDataLoaded] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [editContainer, setEditContainer] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0); // Total number of pages
  const [orderBy, setOrderBy] = useState("desc");
  const [searchTerm, setSearchTerm] = useState('')
  const [apiMessage, setApiMessage] = useState({ type: "", message: "" });


  const handlePageChange = (selectedPage) => {
    getEmailMobileDataList(
      sortBy,
      selectedPage.selected + 1,
      orderBy,
      searchData
    );
  };

  const handleAccept = async (item) => {
    console.log(item)
    try {
      const payload = {
        user_id: item?.user_id,
        new_mobile: item?.new_mobile,
        new_email: item?.new_email,
        request_id: item?.id,
        status: 1,
        type: item?.type || '1'
      }
      const response = await updateEmailMobileVerify(payload);
      if (response?.status === 200) {
        setApiMessage({ type: "success", message: response?.message });
        getEmailMobileDataList(
          sortBy,
          currentPage + 1,
          orderBy,  
          searchData
        );
        try {
          const remarkEn = {
            role: userDetail?.roles,
            message: `updated your data. `,
          }
          const remarkHi = {
            role: userDetail?.roles,
            message: `ने आपके डेटा को अपडेट कर दिया है |`,
          }
          const notifcation = new FormData()
          notifcation.append("user_id", item?.user_id);
          notifcation.append("remark_en", JSON.stringify(remarkEn));
          notifcation.append("remark_hi", JSON.stringify(remarkHi));
          notifcation.append('district_id', userDetail?.district)
          const notificationSend = await sendNotificationDataUser(notifcation)
        } catch (err) {
          console.error("Error submitting the form:", err);
        }
      } else {
        setApiMessage({ type: "error", message: response?.message });
      }
    } catch (error) {
      setApiMessage({ type: "error", message: error?.message });
    }
  };

  const handleReject = async (item) => {
    console.log(item)
    try {
      const payload = {
        user_id: item?.user_id,
        request_id: item?.id,
        new_mobile: item?.new_mobile,
        new_email: item?.new_email,
        status: 2,
        type: item?.type || 2

      }
      // const payload = { status: 2, request_id: item?.user_id }; // 2 for rejected
      const response = await updateEmailMobileVerify(payload);
      if (response?.status === 200) {
        setApiMessage({ type: "success", message: response?.message });
        getEmailMobileDataList(
          sortBy,
          currentPage + 1,
          orderBy,
          searchData
        );
        try {
          const remarkEn = {
            role: userDetail?.roles,
            message: `rejected your change request. `,
          }
          const remarkHi = {
            role: userDetail?.roles,
            message: `ने आपके परिवर्तन अनुरोध को अस्वीकृत कर दिया है |`,
          }
          const notifcation = new FormData()
          notifcation.append("user_id", item?.user_id);
          notifcation.append("remark_en", JSON.stringify(remarkEn));
          notifcation.append("remark_hi", JSON.stringify(remarkHi));
          notifcation.append('district_id', userDetail?.district)
          const notificationSend = await sendNotificationDataUser(notifcation)
        } catch (err) {
          console.error("Error submitting the form:", err);
        }
      } else {
        setApiMessage({ type: "error", message: response?.message });
      }
    } catch (error) {
      setApiMessage({ type: "error", message: error?.message });
    }
  };

  // City list
  const getEmailMobileDataList = async (
    sortBy,
    pageNo,
    orderBy,
    searchBy
  ) => {
    try {
      setSearchData(searchBy);
      setOrderBy(orderBy);
      setSortBy(sortBy);
      const requestList = await getMobileEmailDataList(sortBy, orderBy, searchBy, pageNo);
      setTotalPages(
        Math.ceil(requestList?.data?.total / requestList?.data?.per_page)
      );
      setCurrentPage(pageNo > 0 ? pageNo - 1 : pageNo);
      const data = requestList?.data?.data;
      setRequestList(data);
      setTableDataLoaded(true);
    } catch (err) {
      console.log("error on requestList: ", err);
      setTableDataLoaded(true);
    }
  };

  useEffect(() => {
    //getFontFamily();
    getEmailMobileDataList("created_at", 1, "desc", "");
  }, []);


  return (
    <AdminDashboard>
      <div className="aod-outer">
        {apiMessage && (
          <Message
            apiMessage={apiMessage}
            setApiMessage={setApiMessage}
          ></Message>
        )}
        
          <>
            <div className="ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb">
              <div>
                <HomeIcon />
                <b onClick={() => navigate("/admin/")}>Home /</b>{" "}
                <span> Update-Details-Requests</span>
              </div>
            </div>
            <div className="aod-inner pt-0">
              <div className="aod-bottom">
                <div className="adm-tabs-outer d-flex justify-content-between d-md-flex justify-content-between">
                  <h4 className="mb-0 w-100">Update Details Requests</h4>
                  <div className="position-relative w-25">
                      <input
                        className="px-2 form-control"
                        placeholder="Search"
                        type="text"
                        value={searchTerm}
                        onChange={(e) => {
                          setSearchTerm(e.target.value);
                          getEmailMobileDataList(sortBy, 1, orderBy, e.target.value);
                        }}
                      />
                      {searchTerm && (
                        <button
                          className="btn btn-clear position-absolute"
                          onClick={(e) => {
                            setSearchTerm("");
                            getEmailMobileDataList(sortBy, 1, orderBy, e.target.value);
                          }}
                          style={{
                            top: "50%",
                            right: "10px",
                            transform: "translateY(-50%)",
                            border: "none",
                            background: "transparent",
                            fontSize: "1rem",
                            cursor: "pointer",
                          }}
                        >
                          &times;
                        </button>
                      )}
                    </div> 
                </div>
                <div className="aod-head"></div>
                <div className="row py-4"></div>
                <div className="ad-reg-table">
                  <Table size="sm" className="ad-cat-table">
                    <thead>
                      <tr>
                        <th className="ad-sno">S No</th>
                        <th className="ad-long">Customer Id</th>
                        <th className="ad-long cursor-pointer"
                          onClick={(e) =>
                            getEmailMobileDataList(
                              "name",
                              currentPage + 1,
                              orderBy == "asc" ? "desc" : "asc",
                              searchData
                            )
                          }
                        >Name<span>
                        <i className="fa-solid fa-sort"></i>
                        </span></th>
                        <th
                          className="ad-long cursor-pointer"
                          onClick={(e) =>
                            getEmailMobileDataList(
                              "type",
                              currentPage + 1,
                              orderBy == "asc" ? "desc" : "asc",
                              searchData
                            )
                          }
                        >
                          Request Type{" "}
                          <span>
                            <i className="fa-solid fa-sort"></i>
                          </span>
                        </th>
                        <th
                          className="ad-long">
                          Existing{" "}
                        </th>
                        <th
                          className="ad-long"
                        >
                          Requested{" "}
                        </th>
                        <th
                          className="ad-long cursor-pointer"
                          onClick={(e) => getEmailMobileDataList(
                            "created_at",
                            currentPage + 1,
                            orderBy === "asc" ? "desc" : "asc",
                            searchData
                          )}
                        >
                          Created At{" "}
                          <span>
                            <i className="fa-solid fa-sort"></i>
                          </span>
                        </th>
                        <th className="ad-long">Status</th>
                        <th className="ad-long">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {requestList?.length > 0 ? (
                        requestList?.map((item, index) => (
                          <tr key={index}>
                            <td>{currentPage * 10 + (index + 1)}</td>
                            <td>{item?.unique_id ? item?.unique_id : "--"}</td>
                            <td>{item?.name ? item?.name : "--"}</td>
                            <td>
                              {/* Display request type based on the 'type' field */}
                              {item.type == "1"
                                ? "Email"
                                : item.type == "2"
                                  ? "Mobile"
                                  : item.type == "3" ? "Mobile | Email" : '--'}
                            </td>
                            <td>
                              {/* Show old and new mobile numbers based on the type */}
                              {item.type === "1" ? (
                                <>
                                  <div>{item.old_email}</div>
                                </>
                              ) : item.type === "3" ? (
                                <>
                                  <div>
                                    <strong>Mobile:</strong>{" "}
                                    {item.old_mobile_number} <br></br>{" "}
                                    <strong>Email:</strong> {item.old_email}
                                  </div>
                                </>
                              ) : item.type === "2" ? (
                                <>
                                  <div>{item.old_mobile_number}</div>
                                </>
                              ) : (
                                <div>--</div>
                              )}
                            </td>
                            <td>
                              {/* Show old and new emails based on the type */}
                              {item.type === "1" ? (
                                <>
                                  <div> {item.new_email || "--"}</div>
                                </>
                              ) : item.type === "3" ? (
                                <>
                                  <strong>Mobile:</strong> {item.new_mobile}{" "}
                                  <br></br> <strong>Email:</strong>{" "}
                                  {item.new_email}
                                </>
                              ) : item.type === "2" ? (
                                <>
                                  <div>{item.new_mobile}</div>
                                </>
                              ) : (
                                <div>--</div>
                              )}
                            </td>
                            <td>
                              {item?.created_at
                                  ? formatDateWithSlash(item?.created_at)
                                  : "--"}
                            </td>
                            <td>
                              {item.status === "0"
                                ? "Pending"
                                : item.status === "1"
                                  ? "Accepted"
                                  : "Rejected"}
                            </td>
                            <td>
                              <div className="action-btn d-flex">
                                {item.status === "0" ? 
                              <>
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip id={`edit-tooltip-${item.id}`}>
                                          Accept
                                        </Tooltip>
                                      }
                                    >
                                      <span
                                        className="ad-cat-edit edit-check-icon"
                                        onClick={() => handleAccept(item)}
                                      >
                                        <CgCheck />
                                      </span>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip id={`delete-tooltip-${item.id}`}>
                                          Reject
                                        </Tooltip>
                                      }
                                    >
                                      <span
                                        className="ad-cat-delete"
                                        onClick={() => handleReject(item)}
                                      >
                                        <CgClose />
                                      </span>
                                    </OverlayTrigger>
                              </>  : null
                              }
                               
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : tableDataLoaded ? (
                        <NotFoundTable colSpan="8"></NotFoundTable>
                      ) : (
                        <></>
                      )}
                    </tbody>
                  </Table>
                  {requestList?.length > 0 && totalPages > 1 && (
                    <div className="d-flex w-100 justify-content-end">
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        pageCount={totalPages} // Total number of pages from API
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={1}
                        onPageChange={handlePageChange} // Method to handle page click
                        forcePage={currentPage}
                        containerClassName={"pagination justify-content-center"} // Custom CSS classes
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={`page-item${!requestList?.length > 0 ? " disabled" : ""
                          }`}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
      </div>
      <Modal show={deleteData?.id} onHide={() => setDeleteData("")}>
        <Modal.Header closeButton>
          <Modal.Title>Do you really want to delete this City ?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <div className="suf-btn p-2">
            <button>Yes</button>
            <button className="suf-can-butt" onClick={() => setDeleteData("")}>
              No
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </AdminDashboard>
  );
};

export default MobileEmailVerification;
