import React, { useEffect, useState } from "react";
import AdminDashboard from "../AdminDashboard/AdminDashboard";
import { Message } from "../../../components/message";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal, Table } from "react-bootstrap";
import { HomeIcon } from "../../../components/Application/ApplicationIcon";
import dataicon from "../../../../src/assets/images/data-icon.png";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { DeleteIcon, EditIcon, EyeIcon } from "../AdminIcon";
import {
  deletePage,
  getPageList,
  handleEnableDisablePage,
} from "../../../services/api/admin";
import ReactPaginate from "react-paginate";
import { formatDateWithSlash, printData } from "../../../Utils/common";
import { stateDistrict } from "../../../services/api/auth";
import NotFoundTable from "../../../components/notFound/NotFoundTable";
import {
  checkPermission,
  permissionsConfig,
} from "../AdminPermission/PermissionChecker";
import { HiPlus } from "react-icons/hi";

const MenuPages = ({ type, title, pageFrom }) => {
  const navigate = useNavigate();
  const [menuLinkList, setMenuLinkList] = useState([]);
  const [apiMessage, setApiMessage] = useState({ type: "", message: "" });
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteData, setDeleteData] = useState();
  const [totalPages, setTotalPages] = useState(0); // Total number of pages
  const [searchTerm, setSearchTerm] = useState();
  const [orderBy, setOrderBy] = useState("desc");
  const [sortBy, setSortBy] = useState("created_at");
  const [searchData, setSearchData] = useState("");
  const [tableDataLoaded, setTableDataLoaded] = useState(false);
  const { state } = useLocation();

  const grantPermissionForAddPages = checkPermission(
    permissionsConfig.addPages.role,
    permissionsConfig.addPages.action,
    permissionsConfig.addPages.type
  );
  const grantPermissionForEditPages = checkPermission(
    permissionsConfig.editPages.role,
    permissionsConfig.editPages.action,
    permissionsConfig.editPages.type
  );

  useEffect(() => {
    getMenuLinkData("created_at", "1", "desc", "");
  }, []);

  useEffect(() => {
      if (state && state.message) {
        setApiMessage({ type: "success", message: state.message });
        // Clear the message once it is rendered
        window.scrollTo(0, 0);
        window.history.replaceState({}, document.title);
      }
    }, [state]);

  const getMenuLinkData = async (sortBy, pageNo, orderBy, searchBy) => {
    try {
      setSearchData(searchBy);
      setOrderBy(orderBy);
      setSortBy(sortBy);
      const menuLinkData = await getPageList(
        sortBy,
        orderBy,
        searchBy,
        pageNo,
        type
      );
      if (menuLinkData?.status == 200) {
        const data = menuLinkData?.data?.data;
        setCurrentPage(pageNo > 0 ? pageNo - 1 : pageNo);
        setMenuLinkList(data);
        setTotalPages(
          Math.ceil(menuLinkData?.data?.total / menuLinkData?.data?.per_page)
        ); // Calculate total pages
      } else {
        setMenuLinkList([]);
      }
      setTableDataLoaded(true);
    } catch (err) {}
  };

  // delete Category
  const deleteLink = async (obj) => {
    try {
      const formData = new FormData();
      formData.append("page_id", obj.id);
      await deletePage(formData);
      getMenuLinkData(sortBy, currentPage, orderBy, searchData);
      setDeleteData("");
    } catch (error) {
      console.error(error);
    }
  };

  const handlePageChange = (selectedPage) => {
    getMenuLinkData(sortBy, selectedPage.selected + 1, orderBy, searchData);
  };

  const handleEnableDisable = async (e, item) => {
    try {
      const formData = new FormData();
      formData.append("page_id", item?.id);
      formData.append("status", e?.value);
      const data = await handleEnableDisablePage(formData);
      if (data?.status == 200) {
        getMenuLinkData(sortBy, currentPage + 1, orderBy, searchData);
      } else {
        setApiMessage({ type: "error", message: data?.message });
      }
    } catch (error) {
      setApiMessage({ type: "error", message: error.message });
    }
  };

  return (
    <AdminDashboard>
      <div className="aod-outer">
        {apiMessage && (
          <Message
            apiMessage={apiMessage}
            setApiMessage={setApiMessage}
          ></Message>
        )}
        <div className="ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb">
          <div>
            <HomeIcon />
            <b onClick={() => navigate("/admin/")}>Home / </b>
            <span>{title}</span>
          </div>
        </div>
        <div className="admin-add-btn mb-3 d-flex justify-content-end">
          {grantPermissionForAddPages ? (
            <button
              className="adm-tab-btn"
              onClick={() => navigate("/admin/pages/add", {
                state: { data: null, pageFrom: pageFrom, type: type }
              })}
            >
              <span>
                <HiPlus />
              </span>{" "}
              Add
            </button>
          ) : null}
        </div>
        <div className="aod-inner pt-0">
          <div className="aod-bottom">
            <div className="adm-tabs-outer">
              <h4 className="mb-0 w-100">{title}</h4>
              <div className="position-relative w-25">
                <input
                  className="px-2 form-control"
                  placeholder="Search"
                  type="text"
                  value={searchTerm} // Add a state for the input value
                  onChange={(e) => {
                    setSearchTerm(e.target.value); // Update the state
                    getMenuLinkData(sortBy, 1, orderBy, e.target.value);
                  }}
                />
                {searchTerm && ( // Show the clear button only when there's text
                  <button
                    className="btn btn-clear position-absolute"
                    onClick={(e) => {
                      setSearchTerm(""); // Clear the input value
                      getMenuLinkData(sortBy, 1, orderBy, ""); // Fetch data with empty search term
                    }}
                    style={{
                      top: "50%",
                      right: "10px",
                      transform: "translateY(-50%)",
                      border: "none",
                      background: "transparent",
                      fontSize: "1rem",
                      cursor: "pointer",
                    }}
                  >
                    &times; {/* This is the "X" icon */}
                  </button>
                )}
              </div>
            </div>
            <div className="aod-head"></div>
            <div className="ad-reg-table">
              <div className="ad-reg-table">
                <Table size="sm" className="ad-cat-table  mt-4">
                  <thead>
                    <tr>
                      <th className="ad-sno">S. No</th>
                      <th
                        className="ad-long cursor-pointer"
                        onClick={(e) =>
                          getMenuLinkData(
                            "name_en",
                            currentPage + 1,
                            orderBy === "asc" ? "desc" : "asc",
                            searchData
                          )
                        }
                      >
                        {" "}
                        English Name{" "}
                        <span>
                          <i className="fa-solid fa-sort"></i>
                        </span>
                      </th>
                      <th
                        className="ad-long cursor-pointer"
                        onClick={(e) =>
                            getMenuLinkData(
                            "name_hi",
                            currentPage + 1,
                            orderBy === "asc" ? "desc" : "asc",
                            searchData
                          )
                        }
                      >
                        {" "}
                        Hindi Name{" "}
                        <span>
                          <i className="fa-solid fa-sort"></i>
                        </span>
                      </th>
                      <th
                        className="ad-long cursor-pointer"
                        onClick={(e) =>
                            getMenuLinkData(
                            "created_at",
                            currentPage + 1,
                            orderBy === "asc" ? "desc" : "asc",
                            searchData
                          )
                        }
                      >
                        Created At{" "}
                        <span>
                          <i className="fa-solid fa-sort"></i>
                        </span>
                      </th>
                      {grantPermissionForEditPages ? (
                        <>
                          <th className="ad-long">Status</th>
                          <th className="ad-long">Actions</th>
                        </>
                      ) : (
                        ""
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {menuLinkList?.length > 0 ? (
                      menuLinkList?.map((item, index) => (
                        <tr
                          key={index}
                          className={index % 2 !== 0 ? "adm-even" : ""}
                        >
                          <td>{currentPage * 10 + (index + 1)}</td>
                          <td>
                            {item?.name_en ? item?.name_en : "--"}
                          </td>
                          <td>
                            {item?.name_hi
                              ? item?.name_hi
                              : "--"}
                          </td>
                          <td>
                            {item?.created_at
                              ? formatDateWithSlash(item?.created_at)
                              : "--"}
                          </td>
                          {/* <td>
                                                    <div className='action-btn d-flex'><span className='ad-cat-edit' onClick={() => { navigate("/admin/categories/update", { state: item }) }}><EditIcon /></span><span className='d-none ad-cat-delete' onClick={() => setDeleteData(item)}><DeleteIcon /></span></div></td> */}
                          {grantPermissionForEditPages ? (
                            <>
                              <td>
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    checked={item?.status == 1}
                                    onChange={(e) =>
                                      handleEnableDisable(
                                        e.target.checked
                                          ? { value: "1" }
                                          : { value: "0" },
                                        item
                                      )
                                    }
                                  />
                                  <span className="slider"></span>
                                </label>
                              </td>
                              <td>
                                <div className="action-btn d-flex">
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip id={`edit-tooltip-${item.id}`}>
                                        Edit
                                      </Tooltip>
                                    }
                                  >
                                    <span
                                      className="ad-cat-edit"
                                      onClick={() => {
                                        navigate("/admin/pages/update", {
                                          state: { data: item, pageFrom: pageFrom, type: type } 
                                        });
                                      }}
                                    >
                                      <EditIcon />
                                    </span>
                                  </OverlayTrigger>
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip id={`delete-tooltip-${item.id}`}>
                                        Delete
                                      </Tooltip>
                                    }
                                  >
                                    <span
                                      className="ad-cat-delete"
                                      onClick={() => setDeleteData(item)}
                                    >
                                      <DeleteIcon />
                                    </span>
                                  </OverlayTrigger>
                                </div>
                              </td>
                            </>
                          ) : (
                            ""
                          )}
                        </tr>
                      ))
                    ) : tableDataLoaded ? (
                      <NotFoundTable colSpan="8"></NotFoundTable>
                    ) : (
                      <></>
                    )}
                  </tbody>
                </Table>
                {totalPages > 1 && menuLinkList?.length > 0 && (
                  <div className="d-flex w-100 justify-content-end">
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      pageCount={totalPages} // Total number of pages from API
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={1}
                      onPageChange={handlePageChange} // Method to handle page click
                      containerClassName={"pagination justify-content-center"} // Custom CSS classes
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={deleteData?.id} onHide={() => setDeleteData("")}>
        <Modal.Header closeButton>
          <Modal.Title>
            Do you really want to delete this Page ?
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <div className="suf-btn p-2 d-flex flex-row">
            <button
              className="mx-2"
              onClick={() => {
                deleteLink(deleteData);
              }}
            >
              Yes
            </button>
            <button
              className="suf-can-butt no-btn mx-2"
              onClick={() => setDeleteData("")}
            >
              No
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </AdminDashboard>
  );
};

export default MenuPages;
