import React, { useEffect, useState } from "react";
import AdminDashboard from "../AdminDashboard/AdminDashboard";
import {
  getEnableDisableUser,
  getAllApplicationsReportByStatus,
  deleteUserFromUserId,
  getActiveApplicationsSort,
} from "../../../services/api/admin";
import { HomeIcon } from "../../../components/Application/ApplicationIcon";
import ReactPaginate from "react-paginate";
import NotFoundTable from "../../../components/notFound/NotFoundTable";
import { formatDateWithSlash } from "../../../Utils/common";
import { useNavigate } from "react-router-dom";
import { Modal, Table } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { DeleteIcon } from "../AdminIcon";
import { Message } from "../../../components/message";

const ActiveApplications = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const [userList, setUserList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0); // Total number of pages
  const [orderBy, setOrderBy] = useState("asc");
  const [deleteData, setDeleteData] = useState();
  const [apiMessage, setApiMessage] = useState({ type: "", message: "" });
  const [tableDataLoaded, setTableDataLoaded] = useState(false);
  const [searchTerm, setSearchTerm] = useState();
  useEffect(() => {
    userDataList(currentPage);
  }, []);

  const userDataList = async (pageNo) => {
    try {
      const formData = new FormData();
      formData.append("status", 1);
      const usersListData = await getAllApplicationsReportByStatus(formData);
      if (usersListData.status == 200) {
        setTotalPages(
          Math.ceil(usersListData?.data?.total / usersListData?.data?.per_page)
        );
        setCurrentPage(pageNo > 0 ? pageNo - 1 : pageNo);
        setUserList(usersListData?.data);
      }
      setTableDataLoaded(true)
    } catch (err) {
      setApiMessage({ type: "error", message: err?.message });
      setTableDataLoaded(true)
    }
  };

  const searchSortFromList = async (e, sortBy) => {
    try {
      const searchBy = e.target.value ? e.target.value : "";
      const validInput = /^[A-Za-z\s]*$/;
      if (!validInput.test(searchBy)) {
        e.target.value = searchBy.replace(/[^A-Za-z\s]/g, "");
        return;
      }
      let tabList = [];
      let data = [] ;
      if(!searchBy){
        tabList = await getAllApplicationsReportByStatus(1);
        data = tabList?.data?.data;
      } else {
        setOrderBy(orderBy === "asc" ? "desc" : "asc");
        tabList = await getAllApplicationsReportByStatus();
        data = tabList?.data?.data;
      }
      setUserList(data);
    } catch (err) {
      console.log("error on categoryList: ", err);
    }
  };
  const handlePageChange = (selectedPage) => {
    userDataList(selectedPage.selected + 1); // Increment by 1 if the API starts page numbers from 1
  };

  return (
    <AdminDashboard>
      <div className="aod-outer">
        {apiMessage && (
          <Message
            apiMessage={apiMessage}
            setApiMessage={setApiMessage}
          ></Message>
        )}
        <div className="ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb">
          <div>
            <HomeIcon />
            <b onClick={() => navigate("/admin/")}>Home /</b> <span>Active Applications Report</span>
          </div>
        </div>
        <div className="aod-inner pt-0">
          <div className="aod-inner pt-0">
            <div className="aod-bottom">
              <div className="adm-tabs-outer d-flex justify-content-between">
                <h4 className="mb-0">Active Applications Report</h4>
                <div className="position-relative w-25">
                  <input
                    className="px-2 form-control"
                    placeholder="Search"
                    type="text"
                    value={searchTerm}
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                      searchSortFromList(e, 'title')
                    }}
                  />
                  {searchTerm && (
                    <button
                      className="btn btn-clear position-absolute"
                      onClick={(e) => {
                        setSearchTerm("");
                        searchSortFromList(e, 'title')
                      }}
                      style={{
                        top: "50%",
                        right: "10px",
                        transform: "translateY(-50%)",
                        border: "none",
                        background: "transparent",
                        fontSize: "1rem",
                        cursor: "pointer",
                      }}
                    >
                      &times;
                    </button>
                  )}
                </div> 
              </div>
              <div className="aod-head"></div>
              <div className="ad-reg-table less-width-table">
                <Table size="sm" className="ad-cat-table mt-4">
                  <thead>
                    <tr>
                      <th className="ad-sno">S. No</th>
                      <th
                        className="ad-long "
                        onClick={(e) => searchSortFromList(e, "unique_id")}
                      >
                        District Name
                        <span>
                          <i className="fa-solid fa-sort"></i>
                        </span>
                      </th>
                      <th
                        className="ad-long cursor-pointer"
                        onClick={(e) => searchSortFromList(e, "title")}
                      >
                        Total Applications
                        <span>
                          <i className="fa-solid fa-sort"></i>
                        </span>{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {userList?.length > 0 ? (
                      userList?.map((item, index) => (
                        <tr key={index}>
                          <td>{currentPage * 10 + (index + 1)}</td>
                          <td>{item?.district_name ? item?.district_name : "--"}</td>
                          <td>{item?.total_applications ? item?.total_applications : "--"}</td>
                        </tr>
                      ))
                    ) : tableDataLoaded ? (
                      <NotFoundTable colSpan="8"></NotFoundTable>
                    ) : (
                      <></>
                    )}
                  </tbody>
                </Table>
                {totalPages > 1 && userList?.length > 0 && (
                  <div className="d-flex w-100 justify-content-end">
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      pageCount={totalPages} // Total number of pages from API
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={1}
                      onPageChange={handlePageChange} // Method to handle page click
                      containerClassName={"pagination justify-content-center"} // Custom CSS classes
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminDashboard>
  );
};

export default ActiveApplications;
