import React, { useEffect, useState } from 'react'
import AdminDashboard from '../AdminDashboard/AdminDashboard'
import { Message } from "../../../components/message";
import { useLocation, useNavigate } from "react-router-dom";
import { Table } from "react-bootstrap";
import { HomeIcon } from "../../../components/Application/ApplicationIcon";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { EyeIcon } from "../AdminIcon";
import { allAdminList, allAdminUserList, getYearlyTarget, yearlyTargetReportListCsv, yearlyTargetReportListExcel, yearlyTargetReportListPdf } from '../../../services/api/admin';
import NotFoundTable from '../../../components/notFound/NotFoundTable';
import ReactPaginate from 'react-paginate';
import { printData } from '../../../Utils/common';

const YearlyTarget = ({ title, showAction }) => {
  const navigate = useNavigate();
   const { state } = useLocation();
  const [apiMessage, setApiMessage] = useState({ type: "", message: "" });
  const [yearlyTargetList, setYearlyTargetList] = useState([]);
  const [orderBy, setOrderBy] = useState("desc");
  const [sortBy, setSortBy] = useState("created_at");
  const [searchData, setSearchData] = useState("");
  const [tableDataLoaded, setTableDataLoaded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0); // Total number of pages
  const [searchTerm, setSearchTerm] = useState();

  useEffect(()=>{
    getYearlyTargetList("created_at", "1", "desc", "");
  },[])

  useEffect(() => {
    if (state && state.message) {
      setApiMessage({ type: 'success', message: state.message });
      window.history.replaceState({}, document.title);
    }
  }, [state])

  const getYearlyTargetList = async (sortBy, pageNo, orderBy, searchBy) => {
    try{
      setSearchData(searchBy);
      setOrderBy(orderBy);
      setSortBy(sortBy);
      const response = await getYearlyTarget(sortBy, orderBy, searchBy, pageNo)
      if(response?.status == 200){
        const data = response?.data?.data;
        setCurrentPage(pageNo > 0 ? pageNo - 1 : pageNo);
        setYearlyTargetList(data);
        setTotalPages(
            Math.ceil(response?.data?.total / response?.data?.per_page)
        ); // Calculate total pages
      }else{
        setYearlyTargetList([])
      }
      setTableDataLoaded(true);
    }catch(err){
      setTableDataLoaded(true);
      setApiMessage({ type: 'error', message: err.message })
    }
  }

  const pdfDownload = async () => {
            try {
              const response = await yearlyTargetReportListPdf()
              window.open(response?.file_path);
            } catch (err) {
              setApiMessage({ type: "error", message: err?.message })
            }
          }
        
          const excelDownload = async () => {
            try {
              const response = await yearlyTargetReportListExcel()
              window.open(response?.file_path);
            } catch (err) {
              setApiMessage({ type: "error", message: err?.message })
            }
          }
        
          const csvDownload = async () => {
            try {
              const response = await yearlyTargetReportListCsv()
              window.open(response?.file_path);
            } catch (err) {
              setApiMessage({ type: "error", message: err?.message })
            }
          }
        
          const exportPrint = async () => {
            try {
              // Fetch the PDF URL from the API
              const response = await yearlyTargetReportListPdf();
              const pdfUrl = response?.file_path;
          
              if (!pdfUrl) {
                setApiMessage({ type: "error", message: "Error getting printing data"})
                return
              }
          
              // Fetch the PDF file as a Blob
              const res = await fetch(pdfUrl);
              if (!res.ok) {
                setApiMessage({ type: "error", message: "Error getting printing data"})
                return
              }
              const pdfBlob = await res.blob();
          
              printData(pdfBlob)
              
            } catch (err) {
              setApiMessage({ type: "error", message: err?.message });
            }
          }

  const handlePageChange = (selectedPage) => {
    getYearlyTargetList(sortBy, selectedPage.selected + 1, orderBy, searchData);
  };

  return (
    <AdminDashboard>
         <div className="aod-outer">
        {apiMessage && (<Message apiMessage={apiMessage} setApiMessage={setApiMessage}></Message>)}
        <div className="ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb">
          <div>
            <HomeIcon />
            <b onClick={() => navigate("/admin/")}>Home / </b>
            <span>{title}</span>
          </div>
          <div class="dropdown download_file_dropdown ms-auto d-flex gap-2 mb-3 flex-wrap align-items-center me-4">
                <a class=" px-2 btn btn-danger btn-sm" onClick={() => pdfDownload()} > <i class="fa fa-file-pdf me-1" ></i> PDF</a>
                <a class=" px-2 btn btn-success btn-sm"  onClick={() => excelDownload()} ><i class="fa fa-file-excel  me-1"></i>
                                Excel </a>
                            {/* <a class=" px-2 btn btn-primary btn-sm" onClick={() => csvDownload()} ><i class="fa fa-file-csv  me-1"></i>
                                CSV</a> */}
                    <a class=" px-2 btn btn-info  btn-sm" onClick={() => exportPrint()}><i class="fa fa-print me-1"></i>
                        Print</a>
              </div>
              {showAction ? <button class="adm-tab-btn" onClick={()=> navigate("/admin/add-yearly-targets")}><span><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 20 20" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd"></path></svg></span> Add</button> : <></>}
        </div>
        <div className="aod-inner pt-0">
          <div className="aod-bottom">
            <div className="adm-tabs-outer">
              <h4 className="mb-0 w-100">{title}</h4>
              <div className="position-relative w-25">
                  <input
                    className="px-2 form-control"
                    placeholder="Search"
                    type="text"
                    value={searchTerm} // Add a state for the input value
                    onChange={(e) => {
                      setSearchTerm(e.target.value); // Update the state
                      getYearlyTargetList(sortBy, 1, orderBy, e.target.value);
                    }}
                  />
                  {searchTerm && ( // Show the clear button only when there's text
                    <button
                      className="btn btn-clear position-absolute"
                      onClick={(e) => {
                        setSearchTerm(""); // Clear the input value
                        getYearlyTargetList(sortBy, 1, orderBy, ""); // Fetch data with empty search term
                      }}
                      style={{
                        top: "50%",
                        right: "10px",
                        transform: "translateY(-50%)",
                        border: "none",
                        background: "transparent",
                        fontSize: "1rem",
                        cursor: "pointer",
                      }}
                    >
                      &times; {/* This is the "X" icon */}
                    </button>
                  )}
                </div>
            </div>
            <div className="aod-head"></div>
            <div className="ad-reg-table">
              <Table size="sm" className="ad-cat-table mt-4">
                <thead>
                  <tr>
                    <th className="ad-sno">S. No</th>
                    <th className="ad-long cursor-pointer" 
                      onClick={(e) =>
                        getYearlyTargetList(
                          "assigned_user",
                          currentPage + 1,
                          orderBy === "asc" ? "desc" : "asc",
                          searchData
                        )
                      }
                    >{" "}Assigned By{" "}<span><i className="fa-solid fa-sort"></i></span></th>
                    <th className="ad-long cursor-pointer"
                      onClick={(e) =>
                        getYearlyTargetList(
                          "district_name",
                          currentPage + 1,
                          orderBy === "asc" ? "desc" : "asc",
                          searchData
                        )
                      }
                    >{" "}District{" "}<span><i className="fa-solid fa-sort"></i></span></th>
                    <th className="ad-long cursor-pointer"
                      onClick={(e) =>
                        getYearlyTargetList(
                          "target_value",
                          currentPage + 1,
                          orderBy === "asc" ? "desc" : "asc",
                          searchData
                        )
                      }
                    >{" "}Target{" "}<span><i className="fa-solid fa-sort"></i></span></th>
                    <th className="ad-long cursor-pointer"
                      onClick={(e) =>
                        getYearlyTargetList(
                          "target_achieved",
                          currentPage + 1,
                          orderBy === "asc" ? "desc" : "asc",
                          searchData
                        )
                      }
                    >{" "}Pending{" "}<span><i className="fa-solid fa-sort"></i></span></th>
                    <th className="ad-long">{" "}Achieved{" "}</th>
                    <th className="ad-long cursor-pointer"
                      onClick={(e) =>
                        getYearlyTargetList(
                          "financial_year",
                          currentPage + 1,
                          orderBy === "asc" ? "desc" : "asc",
                          searchData
                        )
                      }
                    >{" "}Financial Year{" "}<span><i className="fa-solid fa-sort"></i></span></th>
                    {showAction ? <th className="ad-long">Action</th> : <></>}
                  </tr>
                </thead>
                <tbody>
                  {yearlyTargetList?.length > 0 ? yearlyTargetList?.map((item,index)=>(
                    <tr
                        key={index}
                        className={index % 2 !== 0 ? "adm-even" : ""}
                      >
                      <td>{currentPage * 10 + (index + 1)}</td>
                      <td>{item?.assigned_user || '--'}</td>
                      <td>{item?.district_name || '--'}</td>
                      <td>{item?.target_value || '--'}</td>
                      <td>{item?.target_achieved}</td>
                      <td>{item?.target_value - item?.target_achieved }</td>
                      <td>{item?.financial_year}</td>
                      {showAction ? <td>
                        <div className="action-btn d-flex" onClick={() => navigate("/admin/yearly-target-logs", { state: item })}>
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>View</Tooltip>}
                          >
                            <span className="ad-cat-edit">
                              <EyeIcon />
                            </span>
                          </OverlayTrigger>
                        </div>
                      </td> : <></>}
                    </tr>
                  )) : tableDataLoaded ? (
                    <NotFoundTable colSpan="8"></NotFoundTable>
                  ) : (
                    <></>
                  )}
                </tbody>
              </Table>
              {totalPages > 1 && yearlyTargetList?.length > 0 && (
                    <div className="d-flex w-100 justify-content-end">
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        pageCount={totalPages} // Total number of pages from API
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={1}
                        onPageChange={handlePageChange} // Method to handle page click
                        containerClassName={"pagination justify-content-center"} // Custom CSS classes
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  )}
            </div>
          </div>
        </div>
      </div>
    </AdminDashboard>
  )
}

export default YearlyTarget;
