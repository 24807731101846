import React, { useEffect, useState } from "react";
import AdminDashboard from "../AdminDashboard/AdminDashboard";
import {
  applicationReportListCsv,
  applicationReportListExcel,
  applicationReportListPdf,
  getAllApplicationsReportByStatus,
} from "../../../services/api/admin";
import { HomeIcon } from "../../../components/Application/ApplicationIcon";
import ReactPaginate from "react-paginate";
import NotFoundTable from "../../../components/notFound/NotFoundTable";
import { customStylesAcc, printData, selectTheme } from "../../../Utils/common";
import { useNavigate } from "react-router-dom";
import { Table } from "react-bootstrap";
import { Message } from "../../../components/message";
import Select from 'react-select';

const ApplicationReports = ({ status, title, heading }) => {
      useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      const navigate = useNavigate();
      const [applicationReportList, setApplicationReportList] = useState([]);
      const [currentPage, setCurrentPage] = useState(1);
      const [totalPages, setTotalPages] = useState(0); // Total number of pages
      const [orderBy, setOrderBy] = useState("asc");
      const [apiMessage, setApiMessage] = useState({ type: "", message: "" });
      const [tableDataLoaded, setTableDataLoaded] = useState(false);
      const [districtList, setDistrictList] = useState([]);
      const [searchTerm, setSearchTerm] = useState('')
      const [districtId, setDistrictId] = useState('');
      const [sortBy, setSortBy] = useState("district_name");
      const [searchData, setSearchData] = useState("");
    
      useEffect(() => {
        getApplicationReportList("", "district_name", "1", "asc", "", true)
      }, []);
    
      const getApplicationReportList = async(searchedDistrict, sortBy, pageNo, orderBy, searchBy, isInitial = false) => {
        try {
          setSearchData(searchBy);
          setOrderBy(orderBy);
          setSortBy(sortBy);
          const applicationReportData = await getAllApplicationsReportByStatus(sortBy, orderBy, searchedDistrict?.value ? searchedDistrict?.value : "", searchBy, pageNo, status);
          if (applicationReportData.status == 200) {
            if(isInitial) {
              const data = applicationReportData?.data?.data?.map((district) => ({
                label: district?.district_name,
                value: district?.district_id,
              }));
              setDistrictList(data)
            }
            setTotalPages(
              Math.ceil(applicationReportData?.data?.total / applicationReportData?.data?.per_page)
            );
            setCurrentPage(pageNo > 0 ? pageNo - 1 : pageNo);
            setApplicationReportList(applicationReportData?.data?.data);
          }
          setTableDataLoaded(true)
        } catch (err) {
          setApiMessage({ type: "error", message: err?.message });
          setTableDataLoaded(true)
        }
      };
    
      const handlePageChange = (selectedPage) => {
        getApplicationReportList(districtId, sortBy, selectedPage.selected + 1, orderBy, searchData) // Increment by 1 if the API starts page numbers from 1
      };
    
      const resetFilter = () => {
        setDistrictId("");
        getApplicationReportList("", sortBy, 1, orderBy, searchData)
      };

      const pdfDownload = async () => {
          try {
            const response = await applicationReportListPdf(status)
            window.open(response?.file_path);
          } catch (err) {
            setApiMessage({ type: "error", message: err?.message })
          }
        }
      
        const excelDownload = async () => {
          try {
            const response = await applicationReportListExcel()
            window.open(response?.file_path);
          } catch (err) {
            setApiMessage({ type: "error", message: err?.message })
          }
        }
      
        const csvDownload = async () => {
          try {
            const response = await applicationReportListCsv()
            window.open(response?.file_path);
          } catch (err) {
            setApiMessage({ type: "error", message: err?.message })
          }
        }
      
        const exportPrint = async () => {
          try {
            // Fetch the PDF URL from the API
            const response = await applicationReportListPdf(status);
            const pdfUrl = response?.file_path;
        
            if (!pdfUrl) {
              setApiMessage({ type: "error", message: "Error getting printing data"})
              return
            }
        
            // Fetch the PDF file as a Blob
            const res = await fetch(pdfUrl);
            if (!res.ok) {
              setApiMessage({ type: "error", message: "Error getting printing data"})
              return
            }
            const pdfBlob = await res.blob();
        
            printData(pdfBlob)
            
          } catch (err) {
            setApiMessage({ type: "error", message: err?.message });
          }
        }
    
      return (
        <AdminDashboard>
          <div className="aod-outer">
            {apiMessage && (
              <Message
                apiMessage={apiMessage}
                setApiMessage={setApiMessage}
              ></Message>
            )}
            <div className="ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb">
              <div>
                <HomeIcon />
                <b onClick={() => navigate("/admin/")}>Home /</b> <span>{title}</span>
              </div>
              <div class="dropdown download_file_dropdown ms-auto d-flex gap-2 mb-3 flex-wrap align-items-center">
                <a class=" px-2 btn btn-danger btn-sm" onClick={() => pdfDownload()} > <i class="fa fa-file-pdf me-1" ></i> PDF</a>
                <a class=" px-2 btn btn-success btn-sm"  onClick={() => excelDownload()} ><i class="fa fa-file-excel  me-1"></i>
                                Excel </a>
                            {/* <a class=" px-2 btn btn-primary btn-sm" onClick={() => csvDownload()} ><i class="fa fa-file-csv  me-1"></i>
                                CSV</a> */}
                    <a class=" px-2 btn btn-info  btn-sm" onClick={() => exportPrint()}><i class="fa fa-print me-1"></i>
                        Print</a>
              </div> 
            </div>
            <div className="aod-inner pt-0">
              <div className="aod-inner pt-0">
                <div className="aod-bottom">
                  <div className="adm-tabs-outer d-flex justify-content-between">
                    <h4 className="mb-0">{title}</h4>
                    <div className="position-relative w-25">
                      <input
                        className="px-2 form-control"
                        placeholder="Search"
                        type="text"
                        value={searchTerm}
                        onChange={(e) => {
                          setSearchTerm(e.target.value);
                          getApplicationReportList(districtId, sortBy, 1, orderBy, e.target.value);
                        }}
                      />
                      {searchTerm && (
                        <button
                          className="btn btn-clear position-absolute"
                          onClick={(e) => {
                            setSearchTerm("");
                            getApplicationReportList(districtId, sortBy, 1, orderBy, e.target.value);
                          }}
                          style={{
                            top: "50%",
                            right: "10px",
                            transform: "translateY(-50%)",
                            border: "none",
                            background: "transparent",
                            fontSize: "1rem",
                            cursor: "pointer",
                          }}
                        >
                          &times;
                        </button>
                      )}
                    </div> 
                  </div>
                  <div className="aod-head"></div>
                  <div className="row py-4">
                    <div className="col-md-4 aod-resp-tab">
                      <div className="suf-input-box aod-form-input mb-2">
                        <Select
                          styles={customStylesAcc}
                          theme={selectTheme}
                          components={{
                            IndicatorSeparator: () => null
                          }}
                          className="my-select selectpicker"
                          classNamePrefix="ad-menu_open_select_class"
                          aria-label="Default select example"
                          name="role"
                          value={districtId}
                          options={districtList}
                          onChange={(selectedOption) => {
                            setDistrictId(selectedOption)
                            getApplicationReportList(selectedOption, sortBy, 1, orderBy, searchData);
                          }}// Use onChange for real-time validation
                          placeholder="Select District"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <button
                          onClick={resetFilter}
                          className="ad-reset-btn w-auto px-4"
                        >
                          Reset
                        </button>
                      </div>
                  </div>
                  <div className="ad-reg-table less-width-table">
                    <Table size="sm" className="ad-cat-table">
                      <thead>
                        <tr>
                          <th className="ad-sno">S. No</th>
                          <th
                            className="ad-long "
                            onClick={(e) => getApplicationReportList(districtId, "district_name", currentPage + 1, orderBy === "asc" ? "desc" : "asc", searchData)}
                          >
                            District Name
                            <span>
                              <i className="fa-solid fa-sort"></i>
                            </span>
                          </th>
                          <th
                            className="ad-long cursor-pointer"
                            onClick={(e) => getApplicationReportList(districtId, "total_applications", currentPage + 1, orderBy === "asc" ? "desc" : "asc", searchData)}
                          >
                            {heading}
                            <span>
                              <i className="fa-solid fa-sort"></i>
                            </span>{" "}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {applicationReportList?.length > 0 ? (
                          applicationReportList?.map((item, index) => (
                            <tr key={index}>
                              <td>{currentPage * 10 + (index + 1)}</td>
                              <td>{item?.district_name ? item?.district_name : "--"}</td>
                              <td>{item?.total_applications ? item?.total_applications : "--"}</td>
                            </tr>
                          ))
                        ) : tableDataLoaded ? (
                          <NotFoundTable colSpan="8"></NotFoundTable>
                        ) : (
                          <></>
                        )}
                      </tbody>
                    </Table>
                    {totalPages > 1 && applicationReportList?.length > 0 && (
                      <div className="d-flex w-100 justify-content-end">
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          pageCount={totalPages} // Total number of pages from API
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={1}
                          onPageChange={handlePageChange} // Method to handle page click
                          containerClassName={"pagination justify-content-center"} // Custom CSS classes
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AdminDashboard>
      );
}

export default ApplicationReports