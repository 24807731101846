import React, { useEffect, useState } from 'react';
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { Link, useNavigate } from 'react-router-dom';
import { getMobileExistence, login } from '../../../services/api/auth';
import { clearCache } from '../../../services/api/outer';
import { ImageurlIcons, SetLocalStorage, SetLocalStorageStringify, validateonChangeHandle, validateonKeyUpHandle } from '../../../Utils/Utils';
import { useLanguage } from '../../LanguageProvider/LanguageProvider ';
import { BiRefresh } from 'react-icons/bi';
const HomebannerLogin = ({ bannerData }) => {
    //states
    const navigate = useNavigate()
    const [buttonDisable, setButtonDisable] = useState(false);
    const { language, setLanguage } = useLanguage();
    const [errorMessage, setErrorMessage] = useState();
    const [loginFailedAttempt, setLoginFailedAttempt] = useState();
    const [emptyClickMessage, setEmptyClickMessage] = useState();
    const [loginDetail, setLoginDetail] = useState({
        mobile_number: "",
        password: ""
    })
    const [loginError, setLoginError] = useState({
        mobile_number: "",
        password: ""
    })
    const [passwordVisible, setPasswordVisile] = useState(false);
    const [loginFormData, setLoginFormData] = useState([]);
    const [buttData, setButtData] = useState([]);
    const [formData, setFormData] = useState([]);
    const [captcha, setCaptcha] = useState("");
    const [captchaWrongMessage, setCaptchaWrongMessage] = useState();

    //  Get login forms fields
    const GetLoginFormField = () => {
        if (bannerData) {
            setLoginFormData(bannerData?.updatedData)
            setFormData(bannerData?.formFields)
            setButtData(bannerData?.buttonFields);
        }
        //getLoginFormField().then(res => {
        //console.log("res", res);
        // const updatedData = res?.data?.input_fields?.map(item => ({
        //     ...item,
        //     value: '',
        //     error: ''
        // }));
        // }).catch((error) => {
        // });
        // const option = {
        //     method: "GET",
        //     headers: {
        //         "access-control-allow-origin": "*",
        //         "content-type": "application/json",
        //     },
        //     url: `${Apiurl()}/login-form`,
        // };
        // axios(option)
        //     .then((e) => {
        //         const updatedData = e?.data?.data?.input_fields?.map(item => ({
        //             ...item,
        //             value: '',
        //             error: ''
        //         }));
        //         setLoginFormData(updatedData)
        //         setFormData(e?.data?.data?.formFields)
        //         setButtData([...e?.data?.data?.button_fields, ...e?.data?.data?.link_fields]);
        //     })
        // .catch((error) => {
        // });
    };
    useEffect(() => {
        GetLoginFormField();
        generateCaptcha();
    }, []);
    const handleClearCache = () => {
        clearCache().then(res => {

        }).catch((error) => {
        });
        // const option = {
        //     method: "GET",
        //     headers: {
        //         "access-control-allow-origin": "*",
        //         "content-type": "application/json",
        //     },
        //     url: `${Apiurl()}/clear-cache`,
        // };
        // axios(option)
        //     .then((e) => {

        //     })
        //     .catch((error) => {
        //     });
    }

    const handleSubmitLogin = async () => {
        handleClearCache();

        if(!allFieldsValid()){
            return;
        }

        const data = await loginFormData?.filter((l) => l.status == "1")?.find(item => item?.name_en.toLowerCase().includes("mobile"));
        if (data) {
            const exists = await fetchMobileExistence(data?.value);
            if (exists.status == 400) {
                setLoginFormData(prevData =>
                    prevData.map(i =>
                        i.id === data.id ? { ...i, error: { hi: "मोबाइल नंबर/उपयोगकर्ता नाम हमारे रिकॉर्ड में नहीं मिला।", en: "Mobile Number/UserName not found in our records." } } : i
                    )
                );
            } else {
                setLoginFormData(prevData =>
                    prevData.map(i =>
                        i.id === data.id ? { ...i, error: "" } : i
                    )
                );
                try {
                    const formData = new FormData();
                    loginFormData?.filter((l) => l.status == "1")?.forEach(i => {
                        if (i?.value) {
                            formData.append([i.input_field_name], i?.value);
                        }
                    });
                    login(formData).then(response => {
                        if (response?.status === 200 && response?.data?.failed_login_attempts || response?.status === 400) {
                            setErrorMessage(response?.messages)
                            setLoginFailedAttempt(response?.data)
                            if(response?.data?.failed_login_attempts === 5) {
                                setTimeout(() => {
                                    navigate("/forgot-password")
                                }, 2000);
                            }
                            return;
                        } else {
                            SetLocalStorage("userInfo", response?.data?.token)
                            SetLocalStorageStringify("userDetail", response?.data?.user)
                            SetLocalStorage("user_type", response?.data?.user?.roles);
                            SetLocalStorageStringify("tokenExpiry", response?.data?.expire_at)
                            if (response?.data?.user?.roles === 'superadmin' || response?.data?.user?.user_type === '1') {
                                navigate("/admin")
                            } else {
                                navigate("/dashboard")
                            }
                        }
                    })
                    // const response = await axios.post(`${Apiurl()}/login`, formData);
                    // if (response?.data?.status === 200 && response?.data?.data?.failed_login_attempts || response?.data?.status === 400) {
                    //     setErrorMessage(response?.data?.messages)
                    //     setLoginFailedAttempt(response?.data?.data)
                    //     return;
                    // } else {
                    //     SetLocalStorage("userInfo", response?.data?.data?.token)
                    //     SetLocalStorageStringify("userDetail", response?.data?.data?.user)
                    //     SetLocalStorageStringify("tokenExpiry", response?.data?.data?.expire_at)
                    //     if (response?.data?.data?.user?.roles === 'superadmin') {
                    //         navigate("/admin")
                    //     } else {
                    //         navigate("/dashboard")
                    //     }
                    // }
                } catch (error) {
                }
            }
        }
    }
    const fetchMobileExistence = (i) => {
        const formData = new FormData();
        formData.append("mobile_number", i);
        return getMobileExistence(formData).then(res => {
            if (res) {
                return res
            }
        }).catch(err => {

        });
        // try {
        //     const formData = new FormData();
        //     formData.append("mobile_number", i);
        //     const reponse = await axios.post(`${Apiurl()}/is-email-mobileno-exists`, formData);
        //     return reponse?.data
        // } catch (error) {
        // }
    }
    useEffect(() => {
        if (errorMessage || language) {
            setEmptyClickMessage({ hi: errorMessage?.hindi, en: errorMessage?.english })
        }
    }, [errorMessage, language])
    const allFieldsValid = () => {
        return loginFormData?.filter((l) => l.status == "1")?.every(item => item.value && item.error === "") && captchaWrongMessage == "";
    };
    const hadleShowMessgae = () => {
        if(loginFormData?.filter((l) => l.status == "1")?.every(item => item.error === "") && captchaWrongMessage == ""){
            setEmptyClickMessage({ hi: "कृपया सभी विवरण भरें", en: "Please fill all details" })
        } else {
            if(captchaWrongMessage !== ""){
                generateCaptcha()
            }
        }
    }

    const generateCaptcha = () => {
        const characters = "ABCDEFGHJKLMNPQRSTUVWXYZ123456789";
        let result = "";
        const charactersLength = characters.length;
    
        for (let i = 0; i < 6; i++) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        setCaptcha(result);
    };

    return (
        <div className='hb-login-form ' >
            {formData?.map((i, index) => (<h2 key={i?.id || index} className='hb-login'>{language ? i?.name_hi : i?.name_en}</h2>))}
            {loginFormData?.filter((l) => l.status == "1")?.map((item, index) => {
                return (
                    <div  key={item?.id || index} className='hb-input-box mb-2' onClick={() => { setEmptyClickMessage(""); setLoginFailedAttempt(); }}>
                        <label style={ {color : item?.font_color , fontSize : item?.font_size + "px"}} className='w-100'>{language ? item?.name_hi : item?.name_en}</label>
                        <div className={`used-icon-password-visible ${item?.name_en?.toLowerCase()?.includes("captcha") ? ' d-flex' : ''}`} >
                            <img className='hb-input-icon' src={`${ImageurlIcons()}/${item?.icon}`} alt="" />
                            <input
                                className={item?.name_en?.toLowerCase()?.includes("captcha") ? 'w-50' : 'w-100'}
                                placeholder={language ? item?.placeholder_hi : item?.placeholder_en}
                                type={item?.type == "password" ? `${passwordVisible ? 'text' : "password"}` : item?.type}
                                name={item?.input_field_name}
                                value={item?.name_en?.toLowerCase()?.includes("captcha") ? item?.value?.toUpperCase() : item?.value}
                                onChange={(e) => {
                                    setCaptchaWrongMessage("")
                                    if (!/^[a-zA-Z0-9\s]*$/.test(e?.target?.value) && item?.name_en?.toLowerCase()?.includes("captcha")){
                                        return;
                                    }
                                    const updatedFields = loginFormData.map(field => {
                                        if (field.id === item?.id) {
                                            return {
                                                ...field,
                                                value: e?.target?.value
                                            };
                                        }
                                        return field;
                                    });
                                    setLoginFormData(updatedFields);
                                    validateonChangeHandle(item, e, loginFormData, setLoginFormData);
                                    setLoginFailedAttempt("")

                                    if(item?.name_en?.toLowerCase()?.includes("captcha") && e?.target?.value?.length >= 6 ){
                                        if (captcha !== e?.target?.value.toUpperCase()) {
                                            setCaptchaWrongMessage({
                                                hi: "कैप्चा मेल नहीं खाया",
                                                en: "Captcha did not match",
                                              });
                                        }
                                    }
                                }}
                                onInput={(e) => {
                                    const inputValue = e.target.value;
                                    if (/^\d+$/.test(inputValue) && inputValue.length > 10) {
                                        e.target.value = inputValue.slice(0, 10);
                                    }
                                }}
                                onKeyUp={(e) => { validateonKeyUpHandle(item, e.target.value, loginFormData, setLoginFormData) }}
                            />
                            {
                                item?.type == "password" ? <>
                                    {
                                        passwordVisible ?
                                            <BsEye className="hb-icon-password-visible" onClick={() => setPasswordVisile(!passwordVisible)} /> :
                                            <BsEyeSlash className="hb-icon-password-visible" onClick={() => setPasswordVisile(!passwordVisible)} />
                                    }
                                </> : null
                            }
                            {item?.name_en?.toLowerCase()?.includes("captcha") ? <><div className="input_field captch_box ps-3 col-md-6">
                                <input
                                    type="text"
                                    className="generatedCaptcha"
                                    name="box"
                                    disabled
                                    value={captcha}
                                />
                                <BiRefresh
                                    onClick={generateCaptcha}
                                    className="suf-captcha"
                                />
                                    <input
                                    type="hidden"
                                    id="storedData"
                                    name="storedData"
                                    value={captcha}
                                />
                            </div> </> : null}
                        </div>
                        {item?.error?.en && <p className='validate-error'>{language ? item?.error?.hi : item?.error?.en}</p>}
                        {item?.name_en?.toLowerCase()?.includes("captcha") && captchaWrongMessage && <p className='validate-error'>{language ? captchaWrongMessage?.hi : captchaWrongMessage?.en}</p>}
                    </div>
                );
            })}
            <p className='validate-error text-center mb-2'>
                {language ? emptyClickMessage?.hi : emptyClickMessage?.en}
            </p>
            {loginFailedAttempt?.block_time && <p className='validate-error'>{`You can again login after ${loginFailedAttempt?.block_time}`}</p>}
            {/* <div className='hb-input-box mb-2'>
                <label className='w-100'>Mobile Number</label>
                <input
                    placeholder='Enter Mobile Number'
                    type="text"
                    name="mobile_number"
                    className='w-100'
                    maxLength={10}
                    value={loginDetail.mobile_number}
                    onKeyUp={() => handleKeyUpLoginDetails("mobile_number")}
                    onChange={(e) => {
                        let inputVal = e.target.value;
                        if (/^[12345]/.test(inputVal)) {
                            inputVal = '';
                        }
                        inputVal = inputVal.replace(/[^\d]/g, '');
                        setLoginDetail({ ...loginDetail, mobile_number: inputVal })
                    }}
                />
                {loginError.mobile_number && <p className='validate-error'>{loginError.mobile_number}</p>}
            </div>
            <div className='hb-input-box mb-2'>
                <label className='w-100'>Password</label>
                <div className="used-icon-password-visible">
                    <input
                        placeholder='Enter Password'
                        type={passwordVisible ? 'text' : "password"}
                        name="password"
                        maxLength={40}
                        className='w-100'
                        value={loginDetail.password}
                        onKeyUp={() => handleKeyUpLoginDetails("password")}
                        onChange={handleChangeLoginDetails}
                    />
                    {
                        passwordVisible ?
                            <BsEye className="hb-icon-password-visible" onClick={() => setPasswordVisile(!passwordVisible)} /> :
                            <BsEyeSlash className="hb-icon-password-visible" onClick={() => setPasswordVisile(!passwordVisible)} />
                    }
                </div>
                {loginError.password && <p className='validate-error'>{loginError.password}</p>}
                {loginFailedAttempt?.block_time && <p className='validate-error'>{`You can again login after ${loginFailedAttempt?.block_time}`}</p>}
            </div> */}
            {buttData?.map((butt) => {
                const text = language ? butt?.name_hi : butt?.name_en;
                // Regex for matching "Register" or "Signup" for both Hindi and English
                const regex = language
                    ? /पंजीकरण|साइन\s*अप/i
                    : /register|sign\s*up|signup/i;

                const match = text.match(regex);

                const beforeText = match ? text.substring(0, match.index) : text;
                const matchedText = match ? match[0] : '';
                const afterText = match ? text.substring(match.index + match[0].length) : '';
                return (
                    <>
                        {butt?.id === 4 ?
                            <>
                                <div className='lf-remember-box'>
                                    <div className="form-check">
                                        {/* <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                    <label className="form-check-label" for="flexCheckDefault">
                        Remember me
                    </label> */}
                                    </div>
                                    <Link style={{ backgroundColor: butt?.background_color, fontSize: `${butt?.font_size}px`, color: butt?.font_color, padding: "5px", textAlign: "center" }}to="/forgot-password">{language ? butt?.name_hi : butt?.name_en}</Link>
                                </div>
                            </>
                            :
                            butt?.id === 3 ?
                                <>
                                    {
                                        !allFieldsValid() ? <div className="hb-btn py-2 my-2">
                                            <button style={{ backgroundColor: butt?.background_color, fontSize: `${butt?.font_size}px`, color: butt?.font_color, padding: "5px", textAlign: "center" }} onClick={hadleShowMessgae}>
                                                {language ? butt?.name_hi : butt?.name_en}
                                            </button>
                                        </div> : 
                                        <div className="hb-btn py-2 my-2">
                                            <button style={{ backgroundColor: butt?.background_color, fontSize: `${butt?.font_size}px`, color: butt?.font_color, padding: "5px", textAlign: "center" }} onClick={handleSubmitLogin}>
                                                {language ? butt?.name_hi : butt?.name_en}
                                            </button>
                                        </div>
                                    }
                                </>
                                :
                                butt?.id === 5 ?
                                    <>
                                        <div className='hb-last' ></div>
                                        <div className='lf-last p-0 pt-2'>
                                            {match ? (
                                                <>
                                                    <p className='mb-0'  style={{ backgroundColor: butt?.background_color, fontSize: `${butt?.font_size}px`, color: butt?.font_color, padding: "5px", textAlign: "center" }}>{beforeText}
                                                        <Link style={{ backgroundColor: butt?.background_color, fontSize: `${butt?.font_size}px`, color: butt?.font_color, padding: "5px", textAlign: "center" }} to="/signup">{matchedText}</Link>
                                                        {afterText}</p>
                                                </>
                                            ) : (
                                                <p className='mb-0'><Link to="/signup">{language ? butt?.name_hi : butt?.name_en}</Link></p>
                                            )}
                                        </div></>
                                    : null
                        }
                    </>
                )
            })}
            {/* <div className='lf-remember-box'>
                <div className="form-check">
                    
                </div>
                <div>
                    <Link to="/forgot-password">Forgot Password?</Link>
                </div>
            </div>
            <div className="hb-btn py-2">
                <button disabled={buttonDisable} onClick={handleSubmitLogin}>Login</button>
            </div>
            <div className='hb-last' ></div>
            <div className='lf-last p-0 pt-2'>
                <p className='mb-0'>Don't have an account?<Link to="/signup"> Register</Link></p>
            </div> */}
        </div>
    )
}
export default HomebannerLogin
