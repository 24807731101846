import React, { useEffect, useState } from "react";
import AdminDashboard from "../AdminDashboard/AdminDashboard";
import {
  getallUsersListData,
  getUserLoginLogs,
  userLogListCsv,
  userLogListExcel,
  userLogListPdf,
} from "../../../services/api/admin";
import { HomeIcon } from "../../../components/Application/ApplicationIcon";
import ReactPaginate from "react-paginate";
import NotFoundTable from "../../../components/notFound/NotFoundTable";
import { formatDateTimeWithSlash, printData } from "../../../Utils/common";
import { useNavigate } from "react-router-dom";
import { Table } from "react-bootstrap";

const RoleWiseLogs = ({ role, title }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const [userLogList, setUserLogList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [listOfUser, setListOfUser] = useState([]);
  const [orderBy, setOrderBy] = useState("desc");
  const [apiMessage, setApiMessage] = useState({ type: "", message: "" });
  const [tableDataLoaded, setTableDataLoaded] = useState(false);
  const [searchTerm, setSearchTerm] = useState();
  const [sortBy, setSortBy] = useState("created_at");
  const [searchData, setSearchData] = useState("");

  useEffect(() => {
    userLogsList("created_at", "1", "desc", "");
    getListofUser();
  }, []);

  const getListofUser = async () => {
    try {
      const userDetailsData = await getallUsersListData();
      const data = userDetailsData?.data?.map((user) => ({
        label: user?.name,
        value: user?.id,
      }));
      setListOfUser(data);
    } catch (err) {
      setApiMessage({ type: "error", message: err?.message });
    }
  };

  const userLogsList = async (sortBy, pageNo, orderBy, searchBy) => {
    try {
      setSearchData(searchBy);
      setOrderBy(orderBy);
      setSortBy(sortBy);
      const usersListData = await getUserLoginLogs(
        sortBy,
        orderBy,
        searchBy,
        pageNo,
        role
      );
      if (usersListData.status === 200) {
        setCurrentPage(pageNo > 0 ? pageNo - 1 : pageNo);
        setUserLogList(usersListData?.data?.data || []);
        setTotalPages(usersListData?.data?.last_page || 0); // Ensure the correct key for total pages
        setTableDataLoaded(true);
      }
    } catch (err) {
      setTableDataLoaded(true);
      setApiMessage({ type: "error", message: err?.message });
    }
  };

  const handlePageClick = (selectedPage) => {
    userLogsList(sortBy, selectedPage.selected + 1, orderBy, searchData);
  };

  const pdfDownload = async () => {
            try {
              const response = await userLogListPdf(role)
              window.open(response?.file_path);
            } catch (err) {
              setApiMessage({ type: "error", message: err?.message })
            }
          }
        
          const excelDownload = async () => {
            try {
              const response = await userLogListExcel(role)
              window.open(response?.file_path);
            } catch (err) {
              setApiMessage({ type: "error", message: err?.message })
            }
          }
        
          const csvDownload = async () => {
            try {
              const response = await userLogListCsv(role)
              window.open(response?.file_path);
            } catch (err) {
              setApiMessage({ type: "error", message: err?.message })
            }
          }
        
          const exportPrint = async () => {
            try {
              // Fetch the PDF URL from the API
              const response = await userLogListPdf(role);
              const pdfUrl = response?.file_path;
          
              if (!pdfUrl) {
                setApiMessage({ type: "error", message: "Error getting printing data"})
                return
              }
          
              // Fetch the PDF file as a Blob
              const res = await fetch(pdfUrl);
              if (!res.ok) {
                setApiMessage({ type: "error", message: "Error getting printing data"})
                return
              }
              const pdfBlob = await res.blob();
          
              printData(pdfBlob)
              
            } catch (err) {
              setApiMessage({ type: "error", message: err?.message });
            }
          }

  return (
    <AdminDashboard>
      <div className="aod-outer">
        <div className="ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb">
          <div>
            <HomeIcon />
            <b onClick={() => navigate("/admin/")}>Home / </b>
            <span>{title}-Logs</span>
          </div>
          <div class="dropdown download_file_dropdown ms-auto d-flex gap-2 mb-3 flex-wrap align-items-center">
                <a class=" px-2 btn btn-danger btn-sm" onClick={() => pdfDownload()} > <i class="fa fa-file-pdf me-1" ></i> PDF</a>
                <a class=" px-2 btn btn-success btn-sm"  onClick={() => excelDownload()} ><i class="fa fa-file-excel  me-1"></i>
                                Excel </a>
                            {/* <a class=" px-2 btn btn-primary btn-sm" onClick={() => csvDownload()} ><i class="fa fa-file-csv  me-1"></i>
                                CSV</a> */}
                    <a class=" px-2 btn btn-info  btn-sm" onClick={() => exportPrint()}><i class="fa fa-print me-1"></i>
                        Print</a>
              </div> 
        </div>
        <div className="aod-inner pt-0">
          <div className="aod-bottom">
            <div className="adm-tabs-outer">
              <h4 className="mb-0 w-100">{title} Logs</h4>
              <div className="position-relative w-25">
                <input
                  className="px-2 form-control"
                  placeholder="Search"
                  type="text"
                  value={searchTerm} // Add a state for the input value
                  onChange={(e) => {
                    setSearchTerm(e.target.value); // Update the state
                    userLogsList(sortBy, 1, orderBy, e.target.value);
                  }}
                />
                {searchTerm && ( // Show the clear button only when there's text
                  <button
                    className="btn btn-clear position-absolute"
                    onClick={(e) => {
                      setSearchTerm(""); // Clear the input value
                      userLogsList(sortBy, 1, orderBy, ""); // Fetch data with empty search term
                    }}
                    style={{
                      top: "50%",
                      right: "10px",
                      transform: "translateY(-50%)",
                      border: "none",
                      background: "transparent",
                      fontSize: "1rem",
                      cursor: "pointer",
                    }}
                  >
                    &times; {/* This is the "X" icon */}
                  </button>
                )}
              </div>
            </div>
            <div className="aod-head"></div>
            <div className="ad-reg-table">
              <Table size="sm" className="ad-cat-table mt-4">
                <thead>
                  <tr>
                    <th className="ad-sno">S. No</th>
                    <th
                      className="ad-sno"
                      onClick={(e) =>
                        userLogsList(
                          "userName",
                          currentPage + 1,
                          orderBy === "asc" ? "desc" : "asc",
                          searchData
                        )
                      }
                    >
                      {" "}
                      Name{" "}
                      <span>
                        <i className="fa-solid fa-sort"></i>
                      </span>
                    </th>
                    <th className="ad-sno">IP Address</th>
                    <th
                      className="ad-sno"
                      onClick={(e) =>
                        userLogsList(
                          "browser",
                          currentPage + 1,
                          orderBy === "asc" ? "desc" : "asc",
                          searchData
                        )
                      }
                    >
                      {" "}
                      Browser{" "}
                      <span>
                        <i className="fa-solid fa-sort"></i>
                      </span>
                    </th>
                    <th
                      className="ad-long"
                      onClick={(e) =>
                        userLogsList(
                          "action",
                          currentPage + 1,
                          orderBy === "asc" ? "desc" : "asc",
                          searchData
                        )
                      }
                    >
                      {" "}
                      Action{" "}
                      <span>
                        <i className="fa-solid fa-sort"></i>
                      </span>
                    </th>
                    <th
                      className="ad-sno"
                      onClick={(e) =>
                        userLogsList(
                          "status",
                          currentPage + 1,
                          orderBy === "asc" ? "desc" : "asc",
                          searchData
                        )
                      }
                    >
                      {" "}
                      Status{" "}
                      <span>
                        <i className="fa-solid fa-sort"></i>
                      </span>
                    </th>
                    {/* <th className="ad-long">Remark</th> */}
                    <th
                      className="ad-long"
                      onClick={(e) =>
                        userLogsList(
                          "login_time",
                          currentPage + 1,
                          orderBy === "asc" ? "desc" : "asc",
                          searchData
                        )
                      }
                    >
                      {" "}
                      Login Time{" "}
                      <span>
                        <i className="fa-solid fa-sort"></i>
                      </span>
                    </th>
                    <th
                      className="ad-long"
                      onClick={(e) =>
                        userLogsList(
                          "logout_time",
                          currentPage + 1,
                          orderBy === "asc" ? "desc" : "asc",
                          searchData
                        )
                      }
                    >
                      {" "}
                      Logout Time{" "}
                      <span>
                        <i className="fa-solid fa-sort"></i>
                      </span>
                    </th>
                    <th
                      className="ad-long"
                      onClick={(e) =>
                        userLogsList(
                          "spend_time",
                          currentPage + 1,
                          orderBy === "asc" ? "desc" : "asc",
                          searchData
                        )
                      }
                    >
                      {" "}
                      Spend Time{" "}
                      <span>
                        <i className="fa-solid fa-sort"></i>
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {userLogList.length > 0 ? (
                    userLogList.map((item, index) => (
                      <tr
                        key={index}
                        className={index % 2 !== 0 ? "adm-even" : ""}
                      >
                        <td>{currentPage * 10 + (index + 1)}</td>
                        <td>{item?.userName ? item?.userName : "--"}</td>
                        <td>{item?.ip_address}</td>
                        <td>{item?.browser}</td>
                        <td>{item.action}</td>
                        <td>
                          <span
                            className={
                              item?.status == "Success"
                                ? "badge rounded-pill text-bg-success"
                                : "badge rounded-pill text-bg-danger"
                            }
                          >
                            {item.status}
                          </span>
                        </td>
                        {/* <td>{item.remark}</td> */}
                        <td>{formatDateTimeWithSlash(item.login_time)}</td>
                        <td>
                          {item.logout_time
                            ? formatDateTimeWithSlash(item.logout_time)
                            : "--"}
                        </td>
                        <td>
                          {item?.spend_time?.replace(
                            /(?<=\d)(?=[a-zA-Z])/g,
                            " "
                          ) || "--"}
                        </td>
                      </tr>
                    ))
                  ) : tableDataLoaded ? (
                    <NotFoundTable colSpan="8"></NotFoundTable>
                  ) : (
                    <></>
                  )}
                </tbody>
              </Table>
              {totalPages > 1 && userLogList?.length > 0 && (
                <div className="d-flex w-100 justify-content-end">
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    pageCount={totalPages} // Total number of pages from API
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={1}
                    onPageChange={handlePageClick} // Method to handle page click
                    containerClassName={"pagination justify-content-center"} // Custom CSS classes
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </AdminDashboard>
  );
};

export default RoleWiseLogs;
