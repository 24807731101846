import { React, useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import { HomeIcon } from "../../../components/Application/ApplicationIcon";
import { Message } from "../../../components/message";
import {
  getAdminPageDetails,
  getPrivacyPolicyDetails,
  updatePrivacyPolicyDetails,
} from "../../../services/api/admin";
import AdminDashboard from "../AdminDashboard/AdminDashboard";

const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],
    ["link", "image", "video", "formula"],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction

    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ["clean"],
  ],
};

const formats = [
  "header",
  "font",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "blockquote",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "code-block",
];

const EditPrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const [userList, setUserList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [apiMessage, setApiMessage] = useState({ type: "", message: "" });
  const [tableDataLoaded, setTableDataLoaded] = useState(false);
  const [privacyPolicy_en, setprivacyPolicy_en] = useState("");
  const [privacyPolicy_hi, setprivacyPolicy_hi] = useState("");
  const [
    mandatoryPrivacyPolicyEnErr,
    setMandatoryPrivacyPolicyEnErr,
  ] = useState("");
  const [
    mandatoryPrivacyPolicyHiErr,
    setMandatoryPrivacyPolicyHiErr,
  ] = useState("");

  useEffect(() => {
    getPrivacyPolicy();
  }, []);

  const getPrivacyPolicy = async () => {
    try {
      const formData = new FormData();
      formData.append("page_id", 1);
      const usersListData = await getAdminPageDetails(formData);
      if (usersListData.status === 200) {
        setUserList(usersListData?.data || []);
        setTableDataLoaded(true);
        setprivacyPolicy_en(usersListData?.data?.content_en);
        setprivacyPolicy_hi(usersListData?.data?.content_hi);
      }
    } catch (err) {
      setApiMessage({ type: "error", message: err?.message });
    }
  };

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
    getPrivacyPolicy();
  };

  const handleEditorChange = (field, value) => {
    // Update content
    if (field === "privacyPolicy_en") setprivacyPolicy_en(value);
    if (field === "privacyPolicy_hi") setprivacyPolicy_hi(value);
  };

  const updateFormField = async (item) => {
    try {
      setMandatoryPrivacyPolicyEnErr("");
      setMandatoryPrivacyPolicyHiErr("");

      if (!privacyPolicy_en.trim() || privacyPolicy_en === "<p><br></p>") {
        setMandatoryPrivacyPolicyEnErr(
          "Privacy Policy in English cannot be empty"
        );
      }
      if (!privacyPolicy_hi.trim() || privacyPolicy_hi === "<p><br></p>") {
        setMandatoryPrivacyPolicyHiErr(
          "Privacy Policy in Hindi cannot be empty"
        );
      }

      if (
        !privacyPolicy_en.trim() ||
        !privacyPolicy_hi.trim() ||
        privacyPolicy_hi === "<p><br></p>" ||
        privacyPolicy_en === "<p><br></p>"
      ) {
        return;
      }
      const formData = new FormData();
      formData.append("privacy_policy_id", 1);
      formData.append("content_en", privacyPolicy_en);
      formData.append("content_hi", privacyPolicy_hi);
      const updatePrivacyPolicy = await updatePrivacyPolicyDetails(formData);
      if (updatePrivacyPolicy && updatePrivacyPolicy.status == 200) {
        setApiMessage({
          type: "success",
          message: updatePrivacyPolicy.message,
        });
        getPrivacyPolicy();
      }
    } catch (error) {
      console.error(error);
      setApiMessage({ type: "error", message: error?.message });
    }
  };

  return (
    <AdminDashboard>
      <div className="aod-outer">
        {apiMessage && (
          <Message
            apiMessage={apiMessage}
            setApiMessage={setApiMessage}
          ></Message>
        )}
        <div className="ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb">
          <div>
            <HomeIcon />
            <b onClick={() => navigate("/admin/")}>Home /</b>
            <span> Privacy Policy</span>
          </div>
        </div>
        <div className="aod-inner pt-0">
          <div className="aod-bottom">
            <div className="adm-tabs-outer">
              <h4 className="mb-0 w-100">Privacy Policy</h4>
              <button
                className="adm-tab-btn btn-privacy-policy"
                onClick={() =>
                  navigate("/admin/privacy-policy/update/guidelines")
                }
              >
                <span></span> Privacy Policy
              </button>
            </div>
            <div className="aod-head"></div>
            <div className="suf-input-box aod-form-input mb-5 mt-3">
              <label className="lf-label w-100">Privacy Policy English</label>
              <ReactQuill
                name="privacyPolicy_en"
                value={privacyPolicy_en}
                onChange={(value) =>
                  handleEditorChange("privacyPolicy_en", value)
                }
                modules={modules}
                formats={formats}
                theme="snow"
              />
              {mandatoryPrivacyPolicyEnErr && (
                <p className="validate-error">{mandatoryPrivacyPolicyEnErr}</p>
              )}
            </div>
            <div className="suf-input-box aod-form-input mb-5">
              <label className="lf-label w-100">Privacy Policy Hindi</label>
              <ReactQuill
                name="privacyPolicy_hi"
                value={privacyPolicy_hi}
                onChange={(value) =>
                  handleEditorChange("privacyPolicy_hi", value)
                }
                modules={modules}
                formats={formats}
                theme="snow"
              />
              {mandatoryPrivacyPolicyHiErr && (
                <p className="validate-error">{mandatoryPrivacyPolicyHiErr}</p>
              )}
            </div>
          </div>
        </div>
        <div className="row ad-cat-row">
          <div className="col-md-12 mt-4 gap-4 d-flex">
            <button onClick={() => updateFormField()}>Update</button>
          </div>
        </div>
      </div>
    </AdminDashboard>
  );
};

export default EditPrivacyPolicy;
