import React, { useEffect, useState } from 'react'
import AdminDashboard from '../AdminDashboard/AdminDashboard'
import { useNavigate } from 'react-router-dom';
import { Message } from '../../../components/message';
import { getAllHeaderContentlListData, updateHeaderContent } from '../../../services/api/admin';

const HeaderContent = () => {
    const navigate = useNavigate();
    const [apiMessage, setApiMessage] = useState({ type: "", message: "" });
    const [selectedModuleIndex, setSelectedModuleIndex] = useState("top");
    const [headerContentData, setHeaderContentData] = useState({})
    const [contentEn, setContentEn] = useState("");
    const [contentHi, setContentHi] = useState("");
    const [err, setErr] = useState({
      title: "",
      contentEn: "",
      contentHi: "",
    });

    const headerContentList = [
        { label: "Title", type: "top" },
        { label: "Banner Left", type: "left" },
    ]
    
    useEffect(() => {
      headerContentDataBySection(headerContentList[0])
    }, [])
  
    const headerContentDataBySection = async (item) => {
      try {
        let formErr = { ...err };
        formErr.contentEn = "";
        formErr.contentHi = "";
        setErr(formErr);
        const dataList = await getAllHeaderContentlListData();
        const labelData = dataList?.data.find(
          (x) => x.type == item?.type
        );
        if (labelData) {
          setHeaderContentData(labelData);
          setContentEn(labelData?.content);
          setContentHi(labelData?.content_hi)
        } else {
          setContentEn("");
          setContentHi("");
        }
      } catch (err) {}
    };
  
    const handleInput = async (e) => {
      const { name, value } = e?.target;
      const cursorPosition = e.target.selectionStart;
      if (name == "contentEn") {
        setContentEn(value);
      } else if (name == "contentHi") {
        setContentHi(value);
      }
      setTimeout(() => {
        e.target.setSelectionRange(cursorPosition, cursorPosition);
      }, 0);
      validateForm(name, value);
    };
  
    const validateForm = (name, value) => {
      console.log(name, value);
      let formErr = { ...err };
      if (name == "contentEn") {
        if (!value?.trim()) {
          formErr.contentEn = "Please Enter English Content";
          setErr(formErr);
          return false;
        } else {
          formErr.contentEn = "";
        }
      } else if (name == "contentHi") {
        if (!value?.trim()) {
          formErr.contentHi = "Please Enter Hindi Content";
          setErr(formErr);
          return false;
        } else {
          formErr.contentHi = "";
        }
      }
      setErr(formErr);
      return true;
    };
  
    const handleSubmit = async (e) => {
      try {
        e.preventDefault();
        if (
          !validateForm("contentEn", contentEn) ||
          !validateForm("contentHi", contentHi)
        ) {
          return;
        }
  
        const formData = new FormData();
        formData.append("content_id", headerContentData?.id);
        formData.append("content", contentEn);
        formData.append("content_hi", contentHi);
        formData.append("type", selectedModuleIndex);
  
        const dataUpdate = await updateHeaderContent(formData);
        if (dataUpdate?.status === 200) {
          await headerContentDataBySection(headerContentData);
          setApiMessage({ type: "success", message: dataUpdate?.message });
        } else {
          setApiMessage({ type: "error", message: dataUpdate?.message });
        }
      } catch (err) {
        setApiMessage({ type: "error", message: err?.message });
      }
    };
  
    return (
      <AdminDashboard>
        <div className="aod-outer">
          <div className="ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb">
            <div>
              <b onClick={() => navigate("/admin/")}>Home /</b>
              <span> Header Content</span>
            </div>
          </div>
          {apiMessage && (
            <Message
              apiMessage={apiMessage}
              setApiMessage={setApiMessage}
            ></Message>
          )}
          <div className="aod-inner">
            <div className="content-wrapper" id="sms-page">
              <div className="flex-grow-1 container-p-y">
                <h4 className="mb-4">Header Contents</h4>
                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-4">
                    <div className="card mb-4 border-0">
                      <h5 className="card-header">Sections</h5>
                      <ul className="template-list">
                        {headerContentList?.map((item, index) => (
                          <a>
                            <li
                              className={`odd ${
                                selectedModuleIndex === item.type ? "active" : ""
                              }`}
                              id={`v-pills-${item.label}-tab`}
                              aria-selected={selectedModuleIndex === item.type}
                              onClick={() => {
                                setSelectedModuleIndex(item.type);
                                headerContentDataBySection(item);
                              }}
                            >
                              {item.label}
                            </li>
                          </a>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="col-xl-8 col-lg-8 col-md-8">
                    <div className="card mb-4 border-0">
                      <form className="card-body admin-role-form">
                        <div className="col-md-12 mt-4">
                          <label
                            for="exampleFormControlTextarea1"
                            className="form-label"
                          >
                            English Content
                          </label>
                          <textarea
                            className="form-control"
                            id="exampleFormControlTextarea1"
                            placeholder="Add here"
                            rows="3"
                            spellcheck="false"
                            name="contentEn"
                            value={contentEn}
                            onChange={handleInput}
                          ></textarea>
                          {err.contentEn && (
                            <p className="validate-error">{err.contentEn}</p>
                          )}
                        </div>
                        <div className="col-md-12 mt-4">
                          <label
                            for="exampleFormControlTextarea1"
                            className="form-label"
                          >
                            Hindi Content
                          </label>
                          <textarea
                            className="form-control"
                            id="exampleFormControlTextarea1"
                            placeholder="Add here"
                            rows="3"
                            spellcheck="false"
                            name="contentHi"
                            value={contentHi}
                            onChange={handleInput}
                          ></textarea>
                          {err.contentHi && (
                            <p className="validate-error">{err.contentHi}</p>
                          )}
                        </div>
  
                        <div className="pt-4">
                          <button
                            type="button"
                            className="btn btn-primary me-sm-3 me-1 theme-cta"
                            onClick={handleSubmit}
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
            <div className="content-backdrop fade"></div>
          </div>
        </div>
      </AdminDashboard>
    );
}

export default HeaderContent